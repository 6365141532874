import { db } from "../../../../firebase";

import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  setDoc,
  Timestamp
} from "firebase/firestore"; 


//const uid = localStorage.getItem("user"); 
//console.log(uid);

class ProjectDataService {  
  addProjects = (uid, newProject) => {
    //console.log(newProject)  
    const projectRef = collection(db, `projects/${uid}/blogPost/`);
    return addDoc(projectRef, newProject);  
  }

  updateProject = (id, updatedProject) => {
    const projectDoc = doc(db, "projects", id);
     return updateDoc(projectDoc, updatedProject);
  }

  deleteProjectContent = async (uid, prjID) => { 
      if(prjID){   
        const contentData = await this.getAllProjects(prjID);  
        contentData.docs.map(file => {
       //  //console.log(file.id);  
          const projectRef = doc(db, `projects/${uid}/blogPost/${prjID}/section/${file.id}`); 
          deleteDoc(projectRef)
        })
        const projRef = doc(db, `projects/${uid}/blogPost/${prjID}`); 
        deleteDoc(projRef)
    }   
  }

  getAllProjects = (uid) => {
    // //console.log(uid)
    // //console.log(localStorage.getItem("user"))
    const projectRef = collection(db, `projects/${uid}/blogPost/`); 
    return getDocs(projectRef)
  }

  getProject = (uid, id) => {
    const projectRef = doc(db, `projects/${uid}/blogPost/${id}`); 
    return getDoc(projectRef);
  }
  getProjectSections = (uid, id) => {
    const projectSecRef = collection(db, `projects/${uid}/blogPost/${id}/section/`); 
    return getDocs(projectSecRef);  
  }

  addProjectSections = (projIdObj, newSections) => {
    // //console.log(newSections)    
    const d = new Date();
    let newSecId = d.valueOf() + parseInt(newSections.order);
    // //console.log(d.valueOf())
    // //console.log(newSecId)
    let secObj = {
            id: newSecId,
            projectId: projIdObj.pId,
            sectionTitle: newSections.sectionTitle,
            sectionDescription: newSections.sectionDescription 
          } 
    //const projectSecRef = collection(db, `projects/${uid}/blogPost/${id}/section/`)
    //return addDoc(projectSecRef, newSections);  
    const docSecRef = doc(db, `projects/${projIdObj.uid}/blogPost/${projIdObj.pId}/section/${newSecId}`); 
    return setDoc(docSecRef, secObj); 
  } 

  addOneSections = (id, newSection) => {
     //console.log(newSection)     
     const projectSecRef = doc(db, `projects/${newSection.uid}/blogPost/${id}/section/${newSection.id}`)
     return setDoc(projectSecRef, newSection);   
  } 

  // addAllProjectSections = (id, newSections) => { 
  //   //console.log(newSections)   
  //   if(newSections){
  //        newSections.map((file, index) => {
  //             //console.log(newSections[index]);  
  //             // let secObj = {
  //             //   id: "",
  //             //   projectId: ,
  //             //   sectionTitle: ,
  //             //   sectionDescription: 
  //             // } 
  //             const projectSecRef = collection(db, `projects/${uid}/blogPost/${id}/section/`)
  //             setDoc(projectSecRef, newSections[index])
  //       }) 
  //   } 
  // } 


  updateProjectSection = (uid, secObj) => {
    const secId = secObj.id;
    const projId = secObj.projectId;
    const secData = secObj; 
   // //console.log(secObj); 
    const projectSecRef = doc(db, `projects/${uid}/blogPost/${projId}/section/${secId}`)
    return updateDoc(projectSecRef, secData);
  }
  deleteProjectSection = (uid, secObj) => {
    const secId = secObj.id;
    const projId = secObj.projectId; 
    const projectSecRef = doc(db, `projects/${uid}/blogPost/${projId}/section/${secId}`)
    return deleteDoc(projectSecRef)
  } 
  updateProjectIntro = (uid, updatedProject) => {
    const projectSecRef = doc(db, `projects/${uid}/blogPost/${updatedProject.id}`)
     return updateDoc(projectSecRef, updatedProject);
  }
  updateWordCount = (uid, countObj) => {
    const projectSecRef = doc(db, `projects/${uid}/blogPost/${countObj.id}`);
     return addDoc(projectSecRef, {wordCount: countObj.count});
  } 
}

export default new ProjectDataService();

 