import {React, useState, useEffect, useContext, useRef} from "react";
import { Button, Card, Form, Container, Row, Col, Accordion } from "react-bootstrap";
import { useNavigate } from "react-router"; 
import { useUserAuth } from "../../../context/UserAuthContext";
import { SubscriberContext } from "../../../context/subscriberContext";   
import { RiDeleteBinLine, RiFileCopy2Line, RiSaveLine, RiFileAddLine, RiSaveFill} from "react-icons/ri";
import Loader from "../../shared/utilities/loader/Loader";
import Loader2 from "../../shared/utilities/loader2/Loader2";
import ContentEditable from 'react-contenteditable';
import { useParams, Link  } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'; 
import toast, { Toaster } from 'react-hot-toast';
import CommonDataServices from "../../../services/common.services";
import ImageCreatorDataService from "./services/ImageCreator.services";  
import ImageCreatorAIServices from "./services/ImageCreatorAI.services"
import "./ImageCreator.css";  
import { ContentRewriteIcon, ImageCreatorIcon, SideArrow, SocialMediaIcon, TwitterIcon, UpgradeIcon, StoriesIcon, ImageCreationIcon  } from "../../../assets/svg/SvgIcons";
import UsageBadge from "../../shared/utilities/usage-badge/UsageBadge";
import { getFunctions, httpsCallable } from "firebase/functions";  
import SidebarPanel from "../../dashboard/sidebar/SidebarPanel"; 
import Dashboard from "../../dashboard/Dashboard"; 
import InlineLogin from "../../InlineLogin";   
import InlinePricing from "../../views/InlinePricing";

const ImageCreator = () => { 
  const { user } = useUserAuth();
  //if(user){ localStorage.setItem("user", user.uid)} 
  const navigate = useNavigate();  
  const functions = getFunctions(); 
  const params = useParams();
  const [data, setData] = useState([]);
  const [queryObj, setQueryObj] = useState(null);  
  const [mainParam, setMainParam] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [copied, setCopied] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [projectId, setProjectId] = useState(null);
  const [files, setFiles] = useState(1);
  const [editMode, setEditMode] = useState(false) 
  const [updating, setUpdating] = useState(false) 
  const [activeSection, setActiveSection] = useState(null);
  const [tockenWords, setTockenWords] = useState(1); 
  const [formValue, setFormValue] = useState({name:'', imageDescription:''});
  const [imageData, setImageData] = useState(null);
  const {subscriber, setSubscriber} = useContext(SubscriberContext);
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const [modalData, setModalData] = useState({})
  const handleClose = () => setShowModal(false);
  const [sideNav, setSideNav] = useState(false);
  
  const aiBoard = useRef(null); 
  const scollDown = () => {
    aiBoard.current.scrollTo(0, aiBoard.current.scrollHeight);
  }
  const updateSubscriber = async () =>{
    const subData = await CommonDataServices.getUsageLimit(user.uid);
    if(subData.data()){
      //console.log(subData.data())
      setSubscriber(subData.data())
    }
  }
  useEffect(() => {
    if(params.id){
      //console.log(params.id);
      getProjectContent(params.id);  
      setQueryObj(queryObj);
    }  
    window.scrollTo(0, 0);  
   },[]) 
   useEffect(() => {
    setTockenWords(tockenWords)
    //console.log(tockenWords)
  },[tockenWords])   
  const getProjectContent = async (conId) => {
    setLoading(true)
    setEditMode(true)
    const contentData = await ImageCreatorDataService.getAllProjectContent(user.uid, conId);
    const prjID = contentData.docs[0].data().projectId;
    setData(contentData.docs.map(doc => {
      // //console.log(doc);
      // //console.log(doc.data());
        return{
          id:doc.id,
          projectId: doc.data().projectId,
          text: doc.data().text,
          status: true 
        }
    }))  
    if(prjID){  
        //console.log(prjID)
        const getProjectDetails = await ImageCreatorDataService.getProjectData(user.uid, prjID); 
        ////console.log(getProjectDetails.data()) 
        const dataObj = getProjectDetails.data();  
        setQueryObj({
          productName: dataObj.title,
          variant: 2,
          type: dataObj.type,
          files: 3,
          productFeatures: "",
          creationDate: dataObj.creationDate
        })
    }  
    setLoading(false); 
  };  
 
  const checkUserStatus = () => {  
    const currentDate = Math.floor(Date.now() / 1000) 
    if(subscriber.totalWords >= subscriber.wordsLimit || subscriber.premiumState === false || currentDate > subscriber.planEndDate){
      let newFileModal = { title: 'Upgrade your account', discription: 'You have either exceeded the word limit or your plan has expired. Please upgrade your account.', action:true}
      setModalData(newFileModal)
      setShowModal(true) 
      return false;
     } else{
      return true;
     }
  } 

  
  const [loginState, setloginState] = useState(false); 
  const isAuthenticated = () => {
    if(user){
      return true;
    } else {
      setloginState(true)
      setShowModal(true) 
      return false;
    } 
  }
  const handleLoginSuccess = (user) => { 
    setloginState(false)
    setShowModal(false) 
    console.log('Login successful:');
  }; 
  const onFormSubmit = async (e) => { 
     e.preventDefault(); 
     if(subscriber.planType == "free"){
       let newFileModal = { title: 'Upgrade your account', 
       discription: 'The AI image generator tool not available with the free plan.', action:true}
       setModalData(newFileModal)
       setShowModal(true)  
       return false;
     }  

     let userState = checkUserStatus(); 
     if(!userState){return;}

    //  const currentDate = Math.floor(Date.now() / 1000) 
    //  if(subscriber.totalWords >= subscriber.wordsLimit || subscriber.premiumState === false || currentDate > subscriber.planEndDate){
    //   setShowModal(true)
    //   return;
    //  }
     setLoading(true)
     const formData = new FormData(e.target);
     const formDataObj = Object.fromEntries(formData.entries());
     setQueryObj(formDataObj); 
     //console.log(formDataObj) 
     // console.log(formDataObj.imageSize)  
     let paramConfig = {
      type: "ImageCreator", 
      imageDescription : formDataObj.imageDescription, 
      imageType : formDataObj.imageType,
      imageStyle : formDataObj.imageStyle,
      imageArtist : formDataObj.imageArtist,
      imageMood : formDataObj.imageMood,
      imageDetails : formDataObj.imageDetails ? formDataObj.imageDetails: "none",
      imageSize : formDataObj.imageSize ? formDataObj.imageSize: "252x252",
      imageVariant : formDataObj.imageVariant ? formDataObj.imageVariant: 1,
     }  
     setMainParam(paramConfig)  
    
    // To call local service 
    //  try {
    //   await ImageCreatorAIServices.generateimageCreator(paramConfig).then((response) =>{         
    //     let imgCredits = 50;
    //     imgCredits = 50 * parseInt(paramConfig.imageVariant)
    //     toast.success(imgCredits)        
    //     console.log(imgCredits);
    //     // CommonDataServices.imageUsageTrackingData(user.uid, imgCredits).then(() => {
    //     //   updateSubscriber();
    //     // });
    //     console.log(response);  
    //     let respChoices = response;
    //     let newImgUrls = respChoices.map(item => item.url); 
    //     setImgUrl(newImgUrls); 
    //         setLoading(false)
    //       });
    //     } catch (error) {
    //       console.log(error.message);
    //       setError(error)
    //       setLoading(false)
    //     } 

        try {
          const getAIdata = await httpsCallable(functions, 'wordkraftImageApi'); 
          await getAIdata(paramConfig).then((response) => {        
            let imgCredits = 50;
            imgCredits = 50 * parseInt(paramConfig.imageVariant)
            toast.success(imgCredits)        
           // console.log(imgCredits);
            CommonDataServices.imageUsageTrackingData(user.uid, imgCredits).then(() => {
              updateSubscriber();
            });
           // console.log(response);  
            let respChoices = response;
            let newImgUrls = respChoices.data.map(item => item.url); 
            setImgUrl(newImgUrls); 
                setLoading(false)
              });
            } catch (error) {
              console.log(error.message);
              setError(error)
              setLoading(false)
            } 
  }; 
 
    const downloadImage = (e) => { 
      setLoading(true) 
      let imgCode = e.target.name;
     // console.log(imgCode)

     const link = document.createElement('a');
     link.href = imgCode;
     link.download = `image.png`;
     document.body.appendChild(link);
     link.click();
     document.body.removeChild(link);
     setLoading(false)  
     toast.success('Image downloaded!')
    }; 

  useEffect(() => {
    setData(data) 
    wordCounter();   
  }, [data])

  const wordCounter = () => {  
    if(data){
      data.map((item, i) => {
        let count = item.text.split(' ').filter(word => word !== '').length; 
        data[i].count = count; 
      }) 
    } 
    setData(data) 
   }
  
   const addExample = () => {
    //setFormValue({blogAbout : "An article about financial planning"});
    var i = 0;
    var txt = 'a photo of a happy corgi puppy sitting and facing forward, studio light, longshot.';
    var speed = 50;
    typeWriter();
    function typeWriter() {
      if (i < txt.length) {
       // document.getElementById("txtArea").innerHTML += txt.charAt(i);
        let newValue = formValue.imageDescription += txt.charAt(i)
        setFormValue({...formValue, imageDescription : newValue});
        i++;
        setTimeout(typeWriter, speed);
      }
    }
    // const data = [{count: 7, text:"How to get started with financial planning"}, {count: 6, text:"How to save money for retirement "},
    // {count: 4, text:"Financial planning for couples"},{count: 5, text:"The Importance of Financial Planning "},{count: 7, text:"The Steps to Creating a Financial Plan"}];
    // setData([...data])
   } 
 

const saveContent = (e) => {
   const id = e.target.id;
   //console.log(id);
   if(id){  
    setUpdating(true)
    setActiveSection(id) 
    if(!projectId){
      //console.log(projectId)
      const contentObj = {
        type: "story-creator",
        creationDate: Math.floor(Date.now() / 1000),
        title: "Story Creator",
        files: files,
        status: true,
        language: queryObj.language ? queryObj.language: 'english',
        imageSize: queryObj.imageSize ? queryObj.imageSize: 'formal'  
        // content: [{text: data[id].text}]
      } 
      try{
        ImageCreatorDataService.addProject(user.uid, contentObj).then((data) => {  
          if(data){
            setProjectId(data.id);
            addContent(id, data.id);
            const projectId = data.id;
            try{
              ImageCreatorDataService.updateProjectId(user.uid, projectId);
            } catch (err){
              console.log(err.message);
            } 
          }
          //console.log(projectId)
        });    
      } catch (err) { 
        console.log(err.message);
        toast.error("Oops! something went wrong. Please check with the support team.")
      }       
      
    } else{ 
      addContent(id, projectId)
    } 
  }
 }

 const addContent = (id, projId) => {
    const newContentObj = {
      projectId: projId,
      text: data[id].text,
      files: files
    } 
    //console.log(data[id])
    //console.log(projId)
    try{
      ImageCreatorDataService.addProjectContent(user.uid, newContentObj).then((sec) => {  
        if(sec){
          //console.log(sec.id)  
          data[id].id = sec.id; 
          data[id].projectId = projId;
          setData([...data]) 
          let count = files + 1;
          setFiles(count)
          setUpdating(false)
        } 
      });     
    } catch (err) {
      setUpdating(false)
      console.log(err.message);
        toast.error("Oops! something went wrong. Please check with the support team.")
    } 
    //console.log(data)
 }

  const handleSideNav = () => {
  if(sideNav) {
    setSideNav(false)
   } else 
   {setSideNav(true)
   }
}
  return (
    <> 
           
      <div className="main-layout">
     {/* <Button className="backToDashboard" variant="link"  onClick={handleSideNav}> <SideArrow/> </Button> */}
      {/*<Link className="backToDashboard mobileView" to="/tools"><SideArrow/></Link> */}
   
          <section className="tool-panel">
              <Row>
                {!editMode &&
                    <Col md={4}>  
                    <Card>   
                      {/* <Card.Header>
                      <h2>Content Rewriter</h2> 
                      </Card.Header> */}
                          <Card.Body>  
                          <section className="tool-header">
<div>
      <div className="svgIcon imgCreator">  
       <ImageCreationIcon/>
        </div>
       <div>
       <h2>AI Image Generator</h2> 
       <p>Generate high-quality image for art, design, and advertising needs.</p>
       </div>
       </div>
       <UsageBadge/>
          </section> 
                          {/* <Card.Title><FcViewDetails /> Product description</Card.Title> */}
                              <Form onSubmit={onFormSubmit}> 
                                  <Form.Group className="mb-3" controlId="imageDescription">
                                  <Form.Label>Write an image description</Form.Label> <span className="float-right text-muted in-text">{formValue.imageDescription.length}/500 characters</span>
                                      <Form.Control
                                      as="textarea"
                                      maxLength="500" 
                                      placeholder="eg: a photo of a happy corgi puppy sitting and facing forward, studio light, longshot"
                                      style={{ height: '120px' }}
                                      name="imageDescription"
                                      required 
                                      value={formValue.imageDescription}
                                      onChange={((e) => {setFormValue({...formValue, imageDescription: e.target.value})})}
                                      /> 
                                  </Form.Group> 
                                  <Form.Group className="mt-3 mb-3 row"> 
                                    <Col className="col-6 mb-3"> 
                                        <Form.Label>Image Size</Form.Label>   
                                        <Form.Select aria-label="Default select example" name="imageSize" className="form-control">
                                            <option value="256x256">256px - 256px</option>
                                            <option value="512x512">512px - 512px</option>  
                                            <option value="1024x1024">1024px - 1024px</option> 
                                        </Form.Select>
                                    </Col> 
                                    <Col className="col-6 mb-3">
                                        <Form.Label>Variants</Form.Label>
                                        <Form.Select aria-label="Number of Variants" name="imageVariant" className="form-control">
                                            <option value="1">1 variant</option>
                                            <option value="2">2 variants</option>     
                                            <option value="3">3 variants</option>     
                                            <option value="4">4 variants</option>  
                                        </Form.Select>  
                                    </Col> 
                                  </Form.Group>  
                                  <div className="advanceOptions">
                                  <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Advanced Options</Accordion.Header>
        <Accordion.Body>
        <Form.Group className="mt-3 mb-3 row">
                                    <Col className="col-6 mb-3">
                                        <Form.Label>Classify</Form.Label>    
                                        <Form.Select name="imageType" id="imageType" className="form-control">
                                          <option value="none">None</option>
																		<option value="Realistic">Realistic</option>
																		<option value="Fictional">Fictional</option> 
																	  </Form.Select>
                                    </Col>
                                    <Col className="col-6 mb-3">
                                        <Form.Label>Style</Form.Label>    
                                        <Form.Select name="imageStyle" id="imageStyle" className="form-control"> 
                                          <option value="none">None</option>
                                          <option value="3d render">3d Render</option>
                                          <option value="abstract">Abstract</option>
                                          <option value="anime">Anime</option>
                                          <option value="art deco">Art Deco</option>
                                          <option value="cartoon">Cartoon</option>
                                          <option value="digital art">Digital Art</option>
                                          <option value="illustration">Illustration</option>
                                          <option value="line art">Line Art</option>
                                          <option value="one line drawing">One Line Drawing</option>
                                          <option value="origami">Origami</option>
                                          <option value="pixel art">Pixel Art</option>
                                          <option value="photography">Photography</option>
                                          <option value="pop art">Pop Art</option>
                                          <option value="retro">Retro</option>
                                          <option value="unreal engine">Unreal Engine</option>
                                          <option value="vaporwave">Vaporwave</option>
                                      </Form.Select>
                                    </Col>
                                    <Col className="col-6 mb-3">
                                        <Form.Label>Artist</Form.Label>    
                                        <Form.Select name="imageArtist" id="imageArtist" className="form-control">  
                                          <option value="none">None</option>
                                          <option value="andy warhol">Andy Warhol</option>
                                          <option value="ansel adams">Ansel Adams</option>
                                          <option value="claude monet">Claude Monet</option>
                                          <option value="dr. seuss">Dr. Seuss</option>
                                          <option value="pablo picasso">Pablo Picasso</option>
                                          <option value="pixar">Pixar</option>
                                          <option value="salvador dali">Salvador Dali</option>
                                          <option value="south park">South Park</option>
                                          <option value="van gogh">Van Gogh</option> 
                                      </Form.Select>
                                    </Col>
                                    <Col className="col-6 mb-3">
                                        <Form.Label>Mood</Form.Label>    
                                        <Form.Select name="imageMood" id="imageMood" className="form-control">  
                                          <option value="none">None</option>
                                          <option value="aggressive">Aggressive</option>
                                          <option value="angry">Angry</option>
                                          <option value="boring">Boring</option>
                                          <option value="bright">Bright</option>
                                          <option value="calm">Calm</option>
                                          <option value="cheerful">Cheerful</option>
                                          <option value="chilling">Chilling</option>
                                          <option value="colorful">Colorful</option>
                                          <option value="dark">Dark</option>
                                          <option value="neutral">Neutral</option>
                                      </Form.Select>
                                    </Col>
                                    {/* <Col className="col-6 mb-3">
                                        <Form.Label>Details</Form.Label>    
                                        <Form.Select name="imageDetails" id="imageDetails" className="form-control">  
                                            <option value="none">None</option>
                                            <option value="ambient light">Ambient light</option>
                                            <option value="black &amp; white">Black &amp; White</option>
                                            <option value="close-up">Close-up</option>
                                            <option value="full face portrait">Full face portrait</option>
                                            <option value="high resolution">High resolution</option>
                                            <option value="highly-detailed">Highly-detailed</option>
                                            <option value="photorealistic">Photorealistic</option>
                                            <option value="realistic">Realistic</option>
                                            <option value="sharp">Sharp</option>
                                      </Form.Select>
                                    </Col> */}
                                  </Form.Group> 
                            
          </Accordion.Body>
          </Accordion.Item>
          </Accordion>
          </div> 
                                  
                                  { user && <>
                                  <Button variant="primary" className="mt-3 w-100" type="submit" 
                                  disabled={loading && `disabled`}>
                                    ⁺₊✧  Generate Image
                                  </Button> 
                                  </>}
                                  {!user && <><Button variant="primary" className="mt-3 w-100" onClick={isAuthenticated}>
                                      Login to Generate
                                  </Button></>}
                                  </Form>
                                  <div className="mt-3"></div>
                                  {!formValue.imageDescription &&  <Button variant="link" className="exampleData m-auto mt-4" onClick={addExample}>Add example data</Button> }
                                  {/* <pre>{ JSON.stringify(formValue.name.length, undefined, 2)}</pre>       */}
                            </Card.Body>  
                      </Card>
                        <small className="imageNote"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" x="0px" y="0px" viewBox="0 0 330 330" enableBackground="new 0 0 330 330;"><g><g><g><path d="M165,0.008C74.019,0.008,0,74.024,0,164.999c0,90.977,74.019,164.992,165,164.992s165-74.015,165-164.992
 C330,74.024,255.981,0.008,165,0.008z M165,299.992c-74.439,0-135-60.557-135-134.992S90.561,30.008,165,30.008
 s135,60.557,135,134.991C300,239.436,239.439,299.992,165,299.992z"></path><path d="M165,130.008c-8.284,0-15,6.716-15,15v99.983c0,8.284,6.716,15,15,15s15-6.716,15-15v-99.983
 C180,136.725,173.284,130.008,165,130.008z"></path><path d="M165,70.011c-3.95,0-7.811,1.6-10.61,4.39c-2.79,2.79-4.39,6.66-4.39,10.61s1.6,7.81,4.39,10.61
 c2.79,2.79,6.66,4.39,10.61,4.39s7.81-1.6,10.609-4.39c2.79-2.8,4.391-6.66,4.391-10.61s-1.601-7.82-4.391-10.61
 C172.81,71.61,168.95,70.011,165,70.011z"></path></g></g></g> <g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg> Note: 
  This tool will deduct 65 words per image and generated images are not stored in your account, so please remember to download them.</small>
  

                    </Col>
                   }
                  <Col className={`ai-board ${editMode ? "col-md-12" : "col-md-8"}`}>  
                    <Card className={`${editMode ? "editMode" : ""}`}>     
                    <Card.Title className="ai-board-header aiImage"> 
                      AI Generated Image(s) 
                      {/* { imgUrl && <Button className="float-right imgDownload" variant="primary" size="sm" onClick={downloadImage}>Download Image</Button> } */}
                     </Card.Title>     
                    <Card.Body className="ai-board-body" ref={aiBoard}> 
                              {!imgUrl && !loading &&                                    
                                  <div className="noResults"> 
                                    <h5><i>🚀</i> Get start now...</h5>
                                    <p>Generate high quality image by adding your inputs</p></div> 
                              } 
                              {queryObj && imgUrl &&
                                  <div className="ai-section height-align h-100 text-center">
                                      <div className="ai-content ai-image">
                                        {/* {imgUrl && <img src={imgUrl} alt="img"/>} */}
                                          <ul className="imgList">
                                            {imgUrl.map((item, index) => (
                                              <li key={index}>
                                                 <img src={item} alt="img"/>
                                                 { imgUrl && <Button className="float-right imgDownload" variant="outline-primary" size="sm" onClick={downloadImage} name={item}>Download</Button> }
                                              </li>
                                            ))}
                                          </ul>
                                      </div>
                                   </div>
                                }    
                              {loading &&<div className="genMoreLoad"> <Loader /> </div> }
                            
                         </Card.Body>  
                    </Card>  
                  </Col>
              </Row>
          </section>
        </div>
      <Toaster position="top-center" reverseOrder={false} /> 
      <Modal show={showModal} onHide={handleClose} keyboard={false} className={loginState ? "loginState" : "upgradeModal"}> 
      {loginState && <Modal.Body className="text-center">  
        <InlineLogin onLoginSuccess={handleLoginSuccess}/>  
      </Modal.Body>} 
      {!loginState && 
      <Modal.Body className="text-center">                 
              {/* <div className="upgradeIcon"><UpgradeIcon/></div>                   
              {modalData && <>
             <h4>{modalData.title}</h4>
             <p>{modalData.discription}</p>
             </>
             }  
              <Button variant="primary" size="md" onClick={()=> navigate(`/pricing`)}>Upgrade</Button> */}
              <InlinePricing/>
          </Modal.Body>}
    </Modal> 
    </>
  );
};

export default ImageCreator; 