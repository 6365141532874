const MusicToolsData = {
    songLyricsDesigner: {
        prompt: "1101",
        icon: "🎶",
        title: "Song Lyrics Designer",
        category: "music",
        caption: `Unlock your songwriting potential with this AI assistant, crafting lyrics with a compelling narrative and emotional depth to connect with listeners.`,
        inputs: [
            { inputTitle: `What is the concept, theme or idea for the song lyrics?`, inputCaption: `Describe the main concept, theme, or story idea for your song`, type: 'textarea', inputExample: "A song about overcoming obstacles and achieving dreams" },
            { inputTitle: `What genre do you want for your song?`, inputCaption: `Specify the genre of your song (e.g., Pop, Rock, Hip-Hop)`, type: 'text', inputExample: "Pop" },
            { inputTitle: `How many verses would you like in the song?`, inputCaption: `Indicate the desired number of verses for the song`, type: 'text', inputExample: "3" },
            { inputTitle: `How would you like the overall mood or emotion of the song to feel?`, inputCaption: `Describe the mood or emotion you want to convey (e.g., joyful, melancholic, energetic)`, type: 'text', inputExample: "Hopeful and uplifting" }
        ]
    }, 
    songLyricsGenerator: {
        prompt: "1102",
        icon: "🎶",
        title: "Song Lyrics Generator",
        category: "music",
        caption: "Create unique and tailored song lyrics across a variety of genres, inspired by famous artists.",
        inputs: [
            { inputTitle: `Select Genre`, inputCaption: `Choose a genre for your song (e.g., Rap, Metal, Love Song, etc.)`, type: 'select', options: ['Freestyle Song', 'Line By Line', 'Rap', 'Metal', 'Love Song', 'Rhyming Song', 'Summer Song', 'Country', 'X Factor Song', 'Ballad', 'Christmas Song'], inputExample: "Love Song" },
            { inputTitle: `Song Name`, inputCaption: `Enter a name for your song.`, inputExample: "Eternal Love" },
            { inputTitle: `Artist Style `, inputCaption: `Select an artist to influence the style of your song .`, type: 'select', options: ['None','Ariana Grande', 'Drake', 'Adele', 'Eminem', 'Taylor Swift', 'Katy Perry', 'One Direction', 'Justin Bieber', 'Beyonce Knowles', 'The Beatles', 'Britney Spears', 'REM', 'Grease the Musical', 'Abba', 'The Village People', 'Frank Sinatra', 'The Cranberries', 'Simon and Garfunkel', 'The Beautiful South'], required: 'false', inputExample: "Taylor Swift" },
            { inputTitle: `Mood or Theme `, inputCaption: `Describe the mood or theme of the song (e.g., joyful, melancholic, romantic).`, type: 'text', required: 'false', inputExample: "Nostalgic" },
            { inputTitle: `Key Lyrics or Phrases `, inputCaption: `Include any key lyrics, phrases, or words you want in the song.`, type: 'textarea', required: 'false', inputExample: "Forever and always" }
        ]
    }, 
};

export default MusicToolsData;
