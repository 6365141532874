import React, {useState, useEffect, useContext} from "react"; 
import { loadStripe } from "@stripe/stripe-js";  
import { useUserAuth } from "../../context/UserAuthContext";
import { SubscriberContext } from "../../context/subscriberContext";  
import CommonDataServices from "../../services/common.services";
import { Button, Nav, Accordion, Container, NavLink } from "react-bootstrap"; 
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";   
import Loader from "../shared/utilities/loader/Loader"; 
import "./Success.css";   
import Loader2 from "../shared/utilities/loader2/Loader2";
import sendEventToFacebook from './sendEventToFacebook'; 

 
const Success = () => {   
  const navigate = useNavigate();  
  const functions = getFunctions(); 
  const {subscriber, setSubscriber} = useContext(SubscriberContext);
  const { user } = useUserAuth();
  const [userSub, setUserSub] = useState(null)
  const params = useParams();
  const [loading, setLoading] = useState(false); 

  useEffect(() => { 
    //console.log(params.id);
    if(params.id){
        //console.log(params.id);
        getSessionContent(params.id);  
        googleAdTrack(params.id); 
      }
    getSubscription(); 
  }, [])

  const getSubscription = async () => { 
    const userdata = await CommonDataServices.getTotalUsageData(user.uid);
   // //console.log(userdata.data());  
   // setSubscriber(userdata.data())
   // setUserSub(userdata.data())
  };

  const googleAdTrack = (tId) => { 
    var x = 0;
    var timer = setInterval(function(){ 
      if(window.location.pathname.includes('/success/')){ 
        if(x==0){
          window.gtag('event', 'conversion', {
            'send_to': 'AW-10964434218/MvDNCKGChIAYEKr6n-wo',
            'value': 1.0,
            'currency': 'INR',
            'transaction_id': tId
          });
          x=1;
        }
        clearInterval(timer)
      }
    }) 
  } 

  const getSessionContent = (id) => {
    setLoading(true)
    const sessionId = id;     
    const getSessObj = httpsCallable(functions, 'getSessionData');
    const param = {  sessionId : sessionId   }
        getSessObj(param).then((response) => { 
            const sessObject = response.data; 
            //console.log(sessObject) 
            const getSub = httpsCallable(functions, 'getStripeSubscription');
            const param = {  subId : sessObject.subscription   }
           // let subsObj = null;
                getSub(param).then((subResponse) => {
                  const subsObj = subResponse.data;
                    //console.log(subsObj)   
                    if(subsObj){
                        const dataObject = subsObj;  
                        //console.log(dataObject)
                        const priceId = dataObject.plan.id;
                      
                        let planType = null;
                        let wordsLimit = null;
                        let planDuration = null;
                
                        if(dataObject){  
                        /*Old Plan*/
                        if(priceId == "price_1LTpfiSJsVlrtsJgqNPseIxF" || priceId == "price_1LTq2vSJsVlrtsJgBWnPcYoB"){ 
                          planType = "Starter Plan";
                          wordsLimit = 50000;
                          planDuration = "Monthly";
                        } 
                        /*End */
                        if(priceId == "price_1LbiIjSJsVlrtsJgWTvOe9B2" || priceId == "price_1LbiagSJsVlrtsJgW2dQ3SRV"){ 
                          planType = "Starter Plan";
                          wordsLimit = 30000;
                          planDuration = "Monthly";
                        }
                        if(priceId == "price_1LbiVYSJsVlrtsJgwr8PbZYA" || priceId == "price_1LbifPSJsVlrtsJgWTMknav8"){ 
                          planType = "Professional Plan";
                          wordsLimit = 1000000;
                          planDuration = "Monthly";
                        }
                        if(priceId == "price_1LbiWdSJsVlrtsJgu6T4pvSG" || priceId == "price_1LbiegSJsVlrtsJgUpVB6E6b"){ 
                          planType = "Starter Plan";
                          wordsLimit = 360000;
                          planDuration = "Yearly";
                        }
                        if(priceId == "price_1LbiOpSJsVlrtsJgxzPxj8pV" || priceId == "price_1Lbid2SJsVlrtsJgudbPfOwy"){ 
                          planType = "Professional Plan";
                          wordsLimit = 10000000;
                          planDuration = "Yearly";
                        } 
                        /*Latest Monthly*/
                        if(priceId == "price_1M1UGrSJsVlrtsJga8yIsBNe" || priceId == "price_1M1UgPSJsVlrtsJgy7RDzxWU"){ 
                          planType = "Professional Plan";
                          wordsLimit = 15000;
                          planDuration = "Monthly";
                        } 
                        if(priceId == "price_1M1UKVSJsVlrtsJgQhvArPD2" || priceId == "price_1M1UgPSJsVlrtsJgEPBjLXpy"){ 
                          planType = "Professional Plan";
                          wordsLimit = 50000;
                          planDuration = "Monthly";
                        }
                        if(priceId == "price_1M1ULOSJsVlrtsJg4KBtD5KW" || priceId == "price_1M1UgPSJsVlrtsJgBz3mg6pk"){ 
                          planType = "Professional Plan";
                          wordsLimit = 100000;
                          planDuration = "Monthly";
                        }
                        if(priceId == "price_1M1ULvSJsVlrtsJgxiDhzLOm" || priceId == "price_1M1UgPSJsVlrtsJg1M89Bxg5"){ 
                          planType = "Professional Plan";
                          wordsLimit = 200000;
                          planDuration = "Monthly";
                        }
                        if(priceId == "price_1M1UMWSJsVlrtsJggL1Xb9G8" || priceId == "price_1M1UgPSJsVlrtsJgF587O7HM"){ 
                          planType = "Professional Plan";
                          wordsLimit = 500000;
                          planDuration = "Monthly";
                        }
                        if(priceId == "price_1M1UMwSJsVlrtsJgY6fQkdIG" || priceId == "price_1M1UgQSJsVlrtsJgwu0xpz39"){ 
                          planType = "Professional Plan";
                          wordsLimit = 1000000;
                          planDuration = "Monthly";
                        }
                        /*Latest Yearly*/
                        if(priceId == "price_1M1UV4SJsVlrtsJga0iztLqi" || priceId == "price_1M1UkxSJsVlrtsJgPH0mQRJ6"){ 
                          planType = "Professional Plan";
                          wordsLimit = 15000;
                          planDuration = "Yearly";
                        } 
                        if(priceId == "price_1M1UW6SJsVlrtsJgnj7p5kA8" || priceId == "price_1M1UkxSJsVlrtsJgFBBImmgT"){ 
                          planType = "Professional Plan";
                          wordsLimit = 50000;
                          planDuration = "Yearly";
                        }
                        if(priceId == "price_1M1UWOSJsVlrtsJgbJIXghhs" || priceId == "price_1M1UkxSJsVlrtsJgDn8wXltZ"){ 
                          planType = "Professional Plan";
                          wordsLimit = 100000;
                          planDuration = "Yearly";
                        }
                        if(priceId == "price_1M1UWiSJsVlrtsJgjURAKbdj" || priceId == "price_1M1UkxSJsVlrtsJgiuL4wqey"){ 
                          planType = "Professional Plan";
                          wordsLimit = 200000;
                          planDuration = "Yearly";
                        }
                        if(priceId == "price_1M1UX4SJsVlrtsJgJjrVVy2y" || priceId == "price_1M1UkySJsVlrtsJgTvyn25LA"){ 
                          planType = "Professional Plan";
                          wordsLimit = 500000;
                          planDuration = "Yearly";
                        }
                        if(priceId == "price_1M1UXiSJsVlrtsJgN8GPPl2I" || priceId == "price_1M1UkySJsVlrtsJg8T1AU8PC"){ 
                          planType = "Professional Plan";
                          wordsLimit = 1000000;
                          planDuration = "Yearly";
                        }
 
                        /* Unlimited plan*/
                        if(priceId == "price_1Mxn4ASJsVlrtsJgM1X4Nxc7" || priceId == "price_1Mxn4ASJsVlrtsJg6Oc0O0FJ" || priceId == "price_1NCzaZSJsVlrtsJgz1fyY8Rz" || priceId == "price_1NCzUuSJsVlrtsJgRmWiPjMo"){ 
                          planType = "Unlimited Plan";
                          wordsLimit = 2000000;
                          planDuration = "Monthly";
                        }
                        if(priceId == "price_1Mxn4ASJsVlrtsJgEFzrh6LG" || priceId == "price_1Mxn4ASJsVlrtsJg1f39CJRR" || priceId == "price_1NCzZtSJsVlrtsJggbAWpBCA" || priceId == "price_1NCzYVSJsVlrtsJg6LNaC4Eg"){ 
                          planType = "Unlimited Plan";
                          wordsLimit = 2000000;
                          planDuration = "Yearly";
                        }
                        
                        /* Starter plan*/
                        if(priceId == "price_1NFraISJsVlrtsJg7E5eCirW" || priceId == "price_1NFrYNSJsVlrtsJgXRI7Ucbx"){ 
                          planType = "Professional Plan";
                          wordsLimit = 50000;
                          planDuration = "Monthly";
                        }
                        if(priceId == "price_1NFrZmSJsVlrtsJgS7dNBsPh" || priceId == "price_1NFrZISJsVlrtsJgpzqoIxFb"){ 
                          planType = "Professional Plan";
                          wordsLimit = 50000;
                          planDuration = "Yearly";
                        }


                            if(dataObject){
                                let subObject = {
                                    current: dataObject.id,
                                    customerId: dataObject.customer,
                                    paidAmount: (dataObject.plan.amount/100) + " " + dataObject.plan.currency,
                                    currency: dataObject.plan.currency,
                                    renewalDate: dataObject.current_period_end,
                                    planStartDate: dataObject.current_period_start, 
                                    planEndDate: dataObject.current_period_end,
                                    // hostedInvoice: dataObject.hosted_invoice_url,
                                    // invoicePdf: dataObject.invoice_pdf, 
                                    premiumState : true,
                                    planDuration: planDuration,
                                    planType: planType,
                                    wordsLimit: wordsLimit,
                                    totalWords: 0 
                                    } 
                                    CommonDataServices.updateUserSubscription(user.uid, subObject);
                                    setSubscriber(subObject);
                                    setLoading(false);
                                } 
                            }
                       }
                    });  
                     
        // Prepare your event data
        const purchaseEventData = {
          event_name: "Purchase",
          event_time: Math.floor(Date.now() / 1000), // Current time in seconds
          action_source: "website",
          custom_data: {
            currency: "USD",
            value: "6.52" // Replace with the actual purchase amount
          }
        };
        // Send the event to Facebook
        sendEventToFacebook(purchaseEventData);
        }) 
  };

  return (
    <>  
      <Container>
      <div className="main-layout page-layout statusCard"> 
      {loading && <Loader2/>}
      {!loading && 
        <div> 
        <div className="successIcon">
            <i className="checkmark">✓</i>
            </div>
            <h2>Payment Successful</h2> 
            <h6>Your payment was successful! You can now continue using Wordkraft</h6>
            {subscriber && <>
            <div className="card p-4">
                <p><strong>Transation Id:</strong> <span>{subscriber.current}</span></p>
                <p><strong>Selected Plan:</strong> <span>{subscriber.planType}</span></p>
                <p><strong>Start Date:</strong> <span>
                {new Date(subscriber.planStartDate * 1000).toLocaleDateString('en-us',{day:'numeric', month:'short', year:'numeric'})}</span></p> 
                <Button variant="outline-primary mt-4" size="md" onClick={()=> navigate(`/tools`)}>Go to Tools Page</Button>
            </div>  
            </> } 
         </div>
        }
      </div> 
      </Container> 
    </>
  );
};

export default Success;