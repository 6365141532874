import {React, useState, useEffect, useContext, useRef} from "react"; 
import { Button, Card, Form, Container, Row, Col, Alert } from "react-bootstrap";
import { useNavigate } from "react-router";
import { RiDeleteBinLine, RiFileCopy2Line, RiSaveLine, RiFileAddLine, RiSaveFill} from "react-icons/ri";
import Loader from "../../shared/utilities/loader/Loader";
import Loader2 from "../../shared/utilities/loader2/Loader2";
import ContentEditable from 'react-contenteditable';
import { useParams, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Overlay from 'react-bootstrap/Overlay';
import toast, { Toaster } from 'react-hot-toast';
import BlogTitlesDataService from "./services/blogTitle.services";
import BlogTitlesAIServices from "./services/blogTitleAI.services"
import CommonDataServices from "../../../services/common.services";
import { useUserAuth } from "../../../context/UserAuthContext";
import { SubscriberContext } from "../../../context/subscriberContext";
import "./BlogTitle.css"; 
import { BlogTitleIcon, SideArrow, UpgradeIcon } from "../../../assets/svg/SvgIcons"; 
import UsageBadge from "../../shared/utilities/usage-badge/UsageBadge";
import { getFunctions, httpsCallable } from "firebase/functions";  
import SidebarPanel from "../../dashboard/sidebar/SidebarPanel"; 
import Dashboard from "../../dashboard/Dashboard"; 
import InlineLogin from "../../InlineLogin";   
import InlinePricing from "../../views/InlinePricing";

const BlogTitle = () => { 
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const functions = getFunctions(); 
  //if(user){ localStorage.setItem("user", user.uid)} 

  const { subscriber, setSubscriber } = useContext(SubscriberContext);

  const params = useParams();
  const [data, setData] = useState([]); 
  const [queryObj, setQueryObj] = useState(null);  
  const [mainParam, setMainParam] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [copied, setCopied] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [projectId, setProjectId] = useState(null);
  const [files, setFiles] = useState(1);
  const [editMode, setEditMode] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [activeSection, setActiveSection] = useState(null);
  const [tockenWords, setTockenWords] = useState(1);
  const [formValue, setFormValue] = useState({name:'', blogAbout:''});

  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const [modalData, setModalData] = useState({})
  const handleClose = () => setShowModal(false);
  const [sideNav, setSideNav] = useState(false); 
 

  useEffect( async () => {
    if(params.id){
      //console.log(params.id);
      getProjectContent(params.id);
      setQueryObj(queryObj);
    }  
    window.scrollTo(0, 0);
   },[])

   useEffect(() => {
    setTockenWords(tockenWords)
    //console.log(tockenWords)
  },[tockenWords])
  const getProjectContent = async (conId) => {
    setLoading(true)
    setEditMode(true)
    const contentData = await BlogTitlesDataService.getAllProjectContent(user.uid, conId);
    const prjID = contentData.docs[0].data().projectId;
    setData(contentData.docs.map(doc => {
      // //console.log(doc);
      // //console.log(doc.data());
        return{
          id:doc.id,
          projectId: doc.data().projectId,
          text: doc.data().text,
          status: true
        }
    }))
    if(prjID){
        //console.log(prjID)
        const getProjectDetails = await BlogTitlesDataService.getProjectData(user.uid, prjID);
        //console.log(getProjectDetails.data())
        const dataObj = getProjectDetails.data();
        setQueryObj({
          productName: dataObj.title,
          variant: 2,
          type: dataObj.type,
          files: 3,
          productFeatures: "",
          creationDate: dataObj.creationDate
        })
    }
    setLoading(false);
  };

  const aiBoard = useRef(null); 
  const scollDown = () => {
    aiBoard.current.scrollTo(0, aiBoard.current.scrollHeight);
  }
  const updateSubscriber = async () =>{
    const subData = await CommonDataServices.getUsageLimit(user.uid);
    if(subData.data()){
      //console.log(subData.data())
      setSubscriber(subData.data())
    }
  } 
  useEffect(() => {
    setMainParam(mainParam)
  },[mainParam])

  const [loginState, setloginState] = useState(false); 
  const isAuthenticated = () => {
    if(user){
      return true;
    } else {
      setloginState(true)
      setShowModal(true) 
      return false;
    } 
  }
  const handleLoginSuccess = (user) => { 
    setloginState(false)
    setShowModal(false) 
    console.log('Login successful:');
  }; 
  const onFormSubmit = async (e) => {
     e.preventDefault(); 
     //console.log(subscriber) 
     const currentDate = Math.floor(Date.now() / 1000) 

    //  //console.log("current date" + " " +  new Date(currentDate * 1000).toDateString())
    //  //console.log("planEnddate" + " " +  new Date(subscriber.planEndDate * 1000).toDateString())  
    //  //console.log("current date" + " " + currentDate)
    //  //console.log("planEnddate" + " " + subscriber.planEndDate) 

     if(subscriber){
      if(subscriber.totalWords >= subscriber.wordsLimit || subscriber.premiumState == false || currentDate > subscriber.planEndDate){ 
        setShowModal(true)
        return;
      }
     } 
     setLoading(true)
     const formData = new FormData(e.target);
     const formDataObj = Object.fromEntries(formData.entries());
     setQueryObj(formDataObj);
     //console.log(formDataObj)
     //variant, creativity
     let paramConfig = {
      type: "blogTitle",
      aboutVal : formDataObj.blogAbout,
      toneVal : formDataObj.tone ? formDataObj.tone: "Formal",
      languageVal : formDataObj.language ? formDataObj.language: "English",
      variantVal : formDataObj.variant ? formDataObj.variant: 7,
      creativeVal : formDataObj.creativity ? formDataObj.creativity: 0.85,
     }  
     paramConfig.variantVal = parseInt(formDataObj.variant) + 1;
     setMainParam(paramConfig) 
    //  //console.log(languageVal, variantVal, creativeVal)
     try {    
      // const getAIdata = await httpsCallable(functions, 'wordkraftAI');
      //   const param = {
      //     type: paramConfig.type, 
      //     about: paramConfig.aboutVal, 
      //     language: paramConfig.languageVal.toString(),
      //     tone: paramConfig.tone,
      //     atStop: (paramConfig.variantVal+".").toString(),
      //     wcount:100,
      //     creative:parseFloat(paramConfig.creativeVal),
      //     num:1 
      //   }
        const apiRequestBody = {
          "title": "blogTitle",
          "query": paramConfig.aboutVal, 
          "language":paramConfig.languageVal.toString(),
          "tone": paramConfig.tone,
        }  
        
        const response = await fetchAPIData(apiRequestBody);
       // console.log(response) 

       if(response){
      
        let oldOutLine = response.data;
        let sentences = oldOutLine.split(/\r?\n/);
        var updatedList = [];
        sentences.filter(removeNum);
        function removeNum(value) {
          updatedList.push(value.toString().replace(/\d+.\s/,'').replace(/['"]+/g, ''));
        }
        let newOutLine = updatedList.filter(removeEmpty);
        function removeEmpty(value) {
           return value.length > 6;
         }
         let newArry = [];
         newOutLine.map(item => {
              newArry.push({text: item})
         })
        //console.log(newArry)
        if(newArry){
          setData(newArry);
        }
        setLoading(false)
       }
      } catch (error) {
        //console.log(error.message);
        setError(error)
        setLoading(false)
      }
      
   };

const fetchAPIData = async (apiRequestBody) => {
    const fetchData = await fetch("https://us-central1-wordkraft-bb683.cloudfunctions.net/tool_api", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(apiRequestBody)
    });  
    const response = await fetchData.json();
    console.log(response)
    
    CommonDataServices.usageTrackingData2(user.uid, response).then(() => {
      updateSubscriber();
    });
    return response;
}

  useEffect(() => {
    setData(data)
    wordCounter();
  }, [data])

  const wordCounter = () => {
    if(data){
      data.map((item, i) => {
        let count = item.text.split(' ').filter(word => word !== '').length;
        data[i].count = count;
      })
    }
    setData(data)
   }


  const generateMore = async () => {
    const currentDate = Math.floor(Date.now() / 1000) 
if(subscriber.totalWords >= subscriber.wordsLimit || subscriber.premiumState === false || currentDate > subscriber.planEndDate){
      setShowModal(true)
      return;
     }
     setLoading(true)
     scollDown();
    try{
      
      const getAIdata = await httpsCallable(functions, 'wordkraftAI'); 
      const param = { 
        type: mainParam.type, 
        about: mainParam.aboutVal, 
        language: mainParam.languageVal.toString(),
        tone: mainParam.tone,
        atStop: (mainParam.variantVal+".").toString(),
        wcount:100,
        creative:parseFloat(mainParam.creativeVal),
        num:1 
      }
      getAIdata(param).then((response) => { 
        CommonDataServices.usageTrackingData(user.uid, response).then(() => {
          updateSubscriber();
        });
        //console.log(response)
        let oldOutLine = response.data.choices[0].text;
        let sentences = oldOutLine.split(/\r?\n/);
        var updatedList = [];
        sentences.filter(removeNum);
        function removeNum(value) {
          updatedList.push(value.toString().replace(/\d+.\s/,'').replace(/['"]+/g, ''));
        }
        let newOutLine = updatedList.filter(removeEmpty);
        function removeEmpty(value) {
           return value.length > 6;
         }
         let newArry = [];
         newOutLine.map(item => {
              newArry.push({text: item})
         })
        //console.log(newArry)
        let newArray2 = data.concat(newArry);
        if(newArray2){
          setData(newArray2);
        }
        setLoading(false)
        scollDown();
      });
    } catch (error) {
      //console.log(error.message);
      setLoading(false)
    } 
  }

 const deleteCotent = (e) => {
  const id = parseInt(e.target.id)
  const remObj = {
    projectId : data[id].projectId,
    id: data[id].id,
    files: (data.length - 1)
  }
  //console.log(id)
  //console.log(data)
  data.splice(id,1);
  //console.log(data)
  setData(data.filter(item => item))
  toast.success('Content deleted!')
  // try{
  //     if(data.length == 1){
  //         BlogTitlesDataService.removeDocContent(user.uid, remObj).then(() => {
  //           setData(data.filter(item => item.id !== remObj.id));
  //           navigate(`/my-content`);
  //         });
  //       } else{
  //         BlogTitlesDataService.removeContent(user.uid, remObj).then(() => {
  //         toast.success('Content deleted!')
  //           setData(data.filter(item => item.id !== remObj.id));
  //         });
  //       }
  //   } catch (err) {
  //     console.log(err.message);
  //    toast.error("Oops! something went wrong. Please check with the support team.")
  //   }
 }

const deleteDBCotent =(e) => {
  const id = parseInt(e.target.id)
  const remObj = {
    projectId : data[id].projectId,
    id: data[id].id,
    files: (data.length - 1)
  }
  try{
      if(data.length == 1){
          BlogTitlesDataService.removeDocContent(user.uid, remObj).then(() => {
            data.splice(id,1);
            setData(data.filter(item => item))
            navigate(`/my-content`);
          });
        } else{
          BlogTitlesDataService.removeContent(user.uid, remObj).then(() => {
            data.splice(id,1);
            setData(data.filter(item => item))
            toast.success('Content deleted!')
          });
        }
    } catch (err) {
      console.log(err.message);
        toast.error("Oops! something went wrong. Please check with the support team.")
    }
}

 const updateContent = (e) => {
    const id = parseInt(e.currentTarget.id);
    function strip_html_tags(str){
      if ((str===null) || (str===''))
          return false;
      else
      str = str.toString();
      return str.replace("<div>", "").replace("</div>", "");
     // return str.replace(/<[^>]*>/g, '');
    }
    const newText = strip_html_tags(e.target.value);
    data[id].text = newText;
    //console.log(data[id].text)
    data[id].count = data[id].text.split(' ').filter(word => word !== '').length;
    setData(data.filter(item => item));
 }


const sendContent = (e) => {
  const id = parseInt(e.currentTarget.id);
  //console.log(data[id])
  //console.log("on blur....");
  if(data[id].id){
    try{
      BlogTitlesDataService.updateProjectContent(user.uid, data[id]);
    } catch (err) {
      console.log(err.message);
    }
  }
}

 const saveContent = (e) => {
    const id =  e.target.id;
    //console.log(id)
    if(id){
      setUpdating(true)
    setActiveSection(id)
    //console.log(queryObj)
    //console.log(queryObj.productName)
    if(!projectId){
      //console.log(projectId)
      const contentObj = {
        type: "blog_titles",
        creationDate: Math.floor(Date.now() / 1000),
        title: queryObj.blogAbout,
        files: files,
        status: true,
        language: queryObj.language ? queryObj.language: 'english',
        tone: queryObj.tone ? queryObj.tone: 'formal'
        // content: [{text: data[id].text}]
      }
      try{
        BlogTitlesDataService.addProject(user.uid, contentObj).then((data) => {
          toast.success('Content saved!')
          if(data){
            setProjectId(data.id);
            addContent(id, data.id);
            const projectId = data.id;
            try{
              BlogTitlesDataService.updateProjectId(user.uid, projectId);
            } catch (err){
              console.log(err.message);
            }
          }
          //console.log(projectId)
        });
      } catch (err) {
        console.log(err.message);
        toast.error("Oops! something went wrong. Please check with the support team.")
      }

    } else{
      addContent(id, projectId)
    }
    }
 }

 const addContent = (id, projId) => {
   //console.log(queryObj)
   //console.log(projId)
   //console.log(projectId)
   //console.log(id)
   //console.log(data)
   //console.log(data[id]);
   //console.log(data[id].text)
    const newContentObj = {
      projectId: projId,
      text: data[id].text,
      files: files
    }
    //console.log(data[id])
    //console.log(projId)
    try{
      BlogTitlesDataService.addProjectContent(user.uid, newContentObj).then((sec) => {
        if(sec){
          //console.log(sec.id)
          data[id].id = sec.id;
          data[id].projectId = projId;
          setData([...data])
          let count = files + 1;
          setFiles(count)
          setUpdating(false)
        }
      });
    } catch (err) {
      setUpdating(false)
      console.log(err.message);
        toast.error("Oops! something went wrong. Please check with the support team.")
    }
    //console.log(data)
 }

 const removeContent = ( ) => {
 }


 const addExample = () => {
  //setFormValue({blogAbout : "An article about financial planning"});
  var i = 0;
  var txt = 'A blog on how digital marketing helps the success of a business.';
  var speed = 50;
  typeWriter();
  function typeWriter() {
    if (i < txt.length) {
     // document.getElementById("txtArea").innerHTML += txt.charAt(i);
      let newValue = formValue.blogAbout += txt.charAt(i)
      setFormValue({...formValue, blogAbout : newValue});
      i++;
      setTimeout(typeWriter, speed);
    }
  }
  // const data = [{count: 7, text:"How to get started with financial planning"}, {count: 6, text:"How to save money for retirement "},
  // {count: 4, text:"Financial planning for couples"},{count: 5, text:"The Importance of Financial Planning "},{count: 7, text:"The Steps to Creating a Financial Plan"}];
  // setData([...data])
 }

 const copyText = (e) => {
  toast.success('Copied to clipboard!')
  const id = parseInt(e.target.id);
  let copyText = data[id].text.replace(/\n/g, '').replace((/<br\s*[\/]?>/gi), "\n").replace(/&nbsp;/g, ' ');
  navigator.clipboard.writeText(copyText)
}
const handleSideNav = () => {
  if(sideNav) {
    setSideNav(false)
   } else 
   {setSideNav(true)
   }
}
  return (
    <>
         
      <div className="main-layout"> 
       {/* <Button className="backToDashboard" variant="link"  onClick={handleSideNav}> <SideArrow/>  </Button> */}  
      {/*<Link className="backToDashboard mobileView" to="/tools"><SideArrow/></Link> */}    
      <div className="main-content">
      {error && <Alert variant="danger" onClose={() => setError(null)} dismissibl> {error.message}  
      <Button className="closeAlert" onClick={() => setError(null)}> X </Button>  </Alert> }
      
      
          <section className="tool-panel">
              <Row>
                {!editMode &&
                    <Col md={4}>
                    <Card>
                          <Card.Body>  
      <section className="tool-header">
       <div>
         <div className="svgIcon"> <BlogTitleIcon/></div>
          <div>
          <h2>Blog Title</h2>
          <p>Write the SEO perfect blog title using AI from your inputs and ideas.</p>
            </div>
            </div>  
          </section>
                          {/* <Card.Title><FcViewDetails /> Product description</Card.Title> */}
                              <Form onSubmit={onFormSubmit}>
                             
                                  <Form.Group className="mb-3" controlId="productFeatures"> 
                                  <Form.Label>Blog post about?</Form.Label> <span className="float-right text-muted in-text">{formValue.blogAbout.length}/200</span>
                                      <Form.Control
                                      as="textarea"
                                      maxLength="200"
                                      placeholder="eg: A blog on how digital marketing helps the success of a business."
                                      style={{ height: '115px' }}
                                      name="blogAbout"
                                      value={formValue.blogAbout}
                                      onChange={((e) => {setFormValue({...formValue, blogAbout: e.target.value})})}
                                      required
                                      />
                                  </Form.Group>
                                  <Form.Group className="mt-3 mb-3 row">
                                    <Col>
                                        <Form.Label>Language</Form.Label>
                                        <Form.Select name="language" id="language" className="form-control">
                                        <option value="English (US)">English (US)</option>
<option value="English (UK)">English (UK)</option>
<option value="French">French</option>
<option value="Spanish">Spanish</option>
<option value="German">German</option>
<option value="Italian">Italian</option>
<option value="Dutch">Dutch</option>
<option value="Arabic">Arabic</option>
<option value="Portuguese">Portuguese</option>
<option value="Portuguese (BR)">Portuguese (BR)</option>
<option value="Swedish">Swedish</option>
<option value="Norwegian">Norwegian</option>
<option value="Danish">Danish</option>
<option value="Finnish">Finnish</option>
<option value="Romanian">Romanian</option>
<option value="Czech">Czech</option>
<option value="Slovak">Slovak</option>
<option value="Slovenian">Slovenian</option>
<option value="Hungarian">Hungarian</option>
<option value="Hebrew">Hebrew</option>
<option value="Polish">Polish</option>
<option value="Turkish">Turkish</option>
<option value="Russian">Russian</option>
<option value="Hindi">Hindi</option>
<option value="Thai">Thai</option>
<option value="Japonese">Japonese</option>
<option value="Chinese (Simplified)">Chinese (Simplified)</option>
<option value="Korean">Korean</option>
<option value="Indonesian">Indonesian</option>
<option value="Greek">Greek</option>
<option value="Bulgarian">Bulgarian</option>
<option value="Ukrainian">Ukrainian</option>
<option value="Estonian">Estonian</option>
<option value="Latvian">Latvian</option>
<option value="Lithuanian">Lithuanian</option>
<option value="Croatian">Croatian</option>
<option value="Serbian">Serbian</option>
<option value="Bosnian">Bosnian</option>
<option value="Macedonian">Macedonian</option>
<option value="Albanian">Albanian</option>
<option value="Vietnamese">Vietnamese</option>
																	  </Form.Select>
                                    </Col>
                                    <Col>
                                        {/* <Form.Label>Variant</Form.Label>
                                        <Form.Select aria-label="Default select example" name="variant" className="form-control">
                                            <option>1</option>
                                            <option>3</option>
                                            <option>5</option>
                                        </Form.Select>  */}
                                        <Form.Label>Tone of voice</Form.Label>
                                        <Form.Select aria-label="Tone of voice" name="tone" className="form-control">
                                            <option>Formal</option>
                                            <option>Professional</option>
                                            <option>Informative</option>
                                            <option>Friendly</option>
                                            <option>Bold</option>
                                            <option>Witty</option>
                                            <option>Adventurous</option>
                                            <option>Persuasive</option>
                                        </Form.Select>
                                    </Col>
                                  </Form.Group>
                                  <Form.Group className="mt-3 mb-3 row"> 
                                    <Col>
                                        <Form.Label>Variants</Form.Label>
                                        <Form.Select aria-label="Number of Variants" name="variant" className="form-control">
                                            <option value="5">5 variants</option>
                                            <option value="1">1 variant</option>
                                            <option value="2">2 variants</option>
                                            <option value="3">3 variants</option> 
                                            <option value="4">4 variants</option> 
                                            <option value="6">6 variants</option> 
                                        </Form.Select>  
                                    </Col>
                                    <Col>                                    
                                    <Form.Label>Creativity</Form.Label>
                                        <Form.Select aria-label="creativity Level" name="creativity" className="form-control">
                                            <option value="0.75">Regular</option>
                                            <option value="0.35">Low</option>
<option value="0.65">Medium</option>
<option value="1">High</option> 
                                        </Form.Select>
                                    </Col>
                                  </Form.Group>                                  
                                  { user && <>
                                  <Button variant="primary" className="mt-3 w-100" type="submit" disabled={loading && `disabled`}>
                                      ⁺₊✧  Generate content
                                  </Button> 
                                  </>}
                                  {!user && <><Button variant="primary" className="mt-3 w-100" onClick={isAuthenticated}>
                                      Login to Generate
                                  </Button></>}
                                  </Form> 
                            </Card.Body>
                      </Card>
                      {!formValue.blogAbout &&  <Button variant="link" className="exampleData" onClick={addExample}>Add example data</Button> }
                    </Col>
                   }
                  <Col className={`ai-board ${editMode ? "col-md-12" : "col-md-8"}`}>
                    <Card className={`${editMode ? "editMode" : ""}`}>
                    <Card.Title className="ai-board-header">
                      {data && queryObj && <div>Top Blog Titles: {queryObj.productName}</div>}
                     </Card.Title>
                    <Card.Body className="ai-board-body" ref={aiBoard}>
                              {!data.length && !loading &&
                                  <div className="noResults">
                                    <h5><i>🚀</i> Get start now...</h5>
                                    <p>Generate high quality content by adding your inputs</p>
                                    </div>
                              }
                              {data && queryObj && data.map((item, i) => (
                                  <div className="ai-section height-align text-center" key={i + 1}>
                                      <div className="ai-content">
                                          <div><ContentEditable className="editableContent" html={item.text} defaultValue={item.text} id={i} onChange={updateContent} onBlur={sendContent}/></div>
                                          <span className="index">{i + 1}</span>
                                          <div className="btn-group-wrap text-right">
                                          <div className="float-left display-flex">
                                            <span className="wordCount float-left">Words: {item.count}</span>{updating && activeSection == i &&  <span className="sectionUpdate"><Loader2/></span> }
                                          </div>
                                            <div style={{ pointerEvents: updating ? 'none' : 'auto' }}>
                                                <Button variant="outline-secondary" size="sm" id={i} onClick={copyText}><RiFileCopy2Line /> Copy </Button>
                                                {!item.id && <Button variant="outline-secondary" size="sm" id={i} onClick={saveContent} className={`ml-1 ${updating && activeSection == i ? "updating" : ""}`}><RiSaveLine /> save</Button>}
                                                {item.id && <Button className="ml-1" variant="outline-secondary" size="sm" id={i} onClick={removeContent}><RiSaveFill /> Saved</Button>}
                                                {!item.id && <Button className="ml-1" variant="outline-secondary" id={i} size="sm" onClick={deleteCotent}><RiDeleteBinLine /> Delete</Button>}
                                                {item.id && <Button className="ml-1" variant="outline-secondary" id={i} size="sm" onClick={deleteDBCotent}><RiDeleteBinLine /> Delete</Button>}
                                               </div>
                                             </div>
                                      </div>
                                   </div>
                                ))}
                               {loading &&<div className="genMoreLoad"> <Loader/> </div> }
                               {/* {!editMode && data  && <Button className="ml-auto mr-auto" variant="outline-primary" size="sm" onClick={generateMore} disabled={loading && `disabled`}>Generate More</Button> } */} 
                               {/* <pre>{ JSON.stringify(subscriber, undefined, 2)}</pre> */}
                        </Card.Body>
                    </Card>
                  </Col>
              </Row>
          </section>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
      {/* <Modal show={showModal} onHide={handleClose} keyboard={false} className="upgradeModal">
       
      </Modal> */}
    <Modal show={showModal} onHide={handleClose} keyboard={false} className={loginState ? "loginState" : "upgradeModal"}> 
      {loginState && <Modal.Body className="text-center">  
        <InlineLogin onLoginSuccess={handleLoginSuccess}/>  
      </Modal.Body>} 
      {!loginState && 
      <Modal.Body className="text-center">                 
              {/* <InlinePricing/> */}
              <InlinePricing/>
          </Modal.Body>}
    </Modal> 
    </>
  );
};

export default BlogTitle;



