import {React, useState, useEffect, useRef } from "react"; 
import "@stripe/stripe-js";
import { Routes, Route, useLocation } from "react-router-dom"; 
import "./App.css";
import { Button, Card, Container, Row, Col} from "react-bootstrap"; 

import Dashboard from "./components/dashboard/Dashboard";
import GPTtest from "./components/dashboard/GPTtest";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Reset from "./components/Reset";
import Landing from "./components/views/Landing";
import Profile from "./components/views/settings/Profile";
import ProtectedRoute from "./components/ProtectedRoute";
import ProductDescription from "./components/tools/product-description/ProductDescription";
import Header from './components/ui/header/Header'; 
import Pricing from "./components/views/Pricing";
import OfferCode from "./components/views/OfferCode";
import BlogPost from "./components/tools/blog-post/BlogPost"; 
import BlogIdeas from "./components/tools/blog-ideas/BlogIdeas";
import BlogTitle from "./components/tools/blog-title/BlogTitle"; 
import BlogIntro from "./components/tools/blog-intro/BlogIntro";
import BlogOutline from "./components/tools/blog-outline/BlogOutline"; 
import BlogConclusion from "./components/tools/blog-conclusion/BlogConclusion"; 
import ParagraphWriter from "./components/tools/paragraph-writer/ParagraphWriter";  
import WebsiteHeadlines from "./components/tools/website-headlines/WebsiteHeadlines";  
import WebsiteSubHeadline from "./components/tools/website-sub-headline/WebsiteSubHeadline";  
import ContentRewriter from "./components/tools/content-rewriter/ContentRewriter";
import MetaTitle from "./components/tools/meta-title/MetaTitle";
import MetaDescription from "./components/tools/meta-description/MetaDescription";
import AIDAContent from "./components/tools/aida-content/AIDAContent";
import FAQs from "./components/tools/faq/FAQs";
import CallToAction from "./components/tools/call-to-action/CallToAction";
import ProductReview from "./components/tools/product-review/ProductReview";
import CustomerReviewResponse from "./components/tools/customer-review-response/CustomerReviewResponse";
import FacebookAd from "./components/tools/facebook-ad/FacebookAd";
import GoogleAd from "./components/tools/google-ad/GoogleAd";
import LinkedinAd from "./components/tools/linkedin-ad/LinkedinAd";
import TweetIdeas from "./components/tools/tweet-ideas/TweetIdeas";
import YoutubeVideoTitle from "./components/tools/youtube-video-title/YoutubeVideoTitle";
import YoutubeVideoDescription from "./components/tools/youtube-video-description/YoutubeVideoDescription";
import YoutubeVideoTags from "./components/tools/youtube-video-tags/YoutubeVideoTags";
import YoutubeVideoScript from "./components/tools/youtube-video-script/YoutubeVideoScript"; 
import InstagramPostIdeas from "./components/tools/instagram-post-ideas/InstagramPostIdeas";
import QuoraAnswers from "./components/tools/quora-answers/QuoraAnswers";
import AmazonProductDescription from "./components/tools/amazon-product-description/AmazonProductDescription";
import AmazonProductBulletPoints from "./components/tools/amazon-product-bullet-points/AmazonProductBulletPoints";
import AmazonProductTitle from "./components/tools/amazon-product-title/AmazonProductTitle";
import AmazonAdHeadline from "./components/tools/amazon-ad-headline/AmazonAdHeadline";
import commonDataServices from "./services/common.services"; 
import { SubscriberContext } from "./context/subscriberContext"; 
import { useUserAuth } from "./context/UserAuthContext";    
import VerifyEmail from "./components/VerifyEmail";
import AboutUs from "./components/tools/about-us/AboutUs"; 
import { getFunctions, httpsCallable } from "firebase/functions"; 
import Success from "./components/views/Success";
import Cancel from "./components/views/Cancel";
import NotFound from "./components/views/NotFound";
import ColdEmail from "./components/tools/cold-email/ColdEmail";
import WelcomeEmail from "./components/tools/welcome-email/WelcomeEmail";
import CancellationEmail from "./components/tools/cancellation-email/CancellationEmail";
import EmailSubjectLine from "./components/tools/email-subject-line/EmailSubjectLine";
import FollowUpEmail from "./components/tools/follow-up-email/FollowUpEmail";
import ThankYouNote from "./components/tools/thank-you-note/ThankYouNote";
import CompanyMission from "./components/tools/company-mission/CompanyMission";
import CompanyVision from "./components/tools/company-vision/CompanyVision";
import QuestFormula from "./components/tools/quest-formula/QuestFormula";
import PainAgitateSolution from "./components/tools/pain-agitate-solution/PainAgitateSolution";
import BlogSection from "./components/tools/blog-section/BlogSection";
import ArticleRewriter from "./components/tools/article-rewriter/ArticleRewriter";
import Paraphrase from "./components/tools/paraphrase/Paraphrase";
import YoutubeCommentsResponder from "./components/tools/youtube-comments-responder/YoutubeCommentsResponder";
import QuickTemplate from "./components/tools/quick-template/QuickTemplate";
import Templates from "./components/dashboard/templates/Templates";
import ArticlePost from "./components/tools/articlePost/ArticlePost";
import ProsCons from "./components/tools/pros-cons/ProsCons";
import SentenceRewriter from "./components/tools/sentence-rewriter/SentenceRewriter";
import SentenceExpander from "./components/tools/sentence-expander/SentenceExpander";
import ParagraphPoints from "./components/tools/paragraph-to-points/ParagraphPoints";
import SocialMediaPost from "./components/tools/social-media-post/SocialMediaPost";
import InstagramCaption from "./components/tools/instagram-caption/InstagramCaption";
import InstagramHashtags from "./components/tools/instagram-hashtags/InstagramHashtags";
import LinkedInPost from "./components/tools/linkedin-post/LinkedInPost";
import TwitterThreads from "./components/tools/twitter-threads/TwitterThreads";
import TwitterGenerator from "./components/tools/twitter-generator/TwitterGenerator";
import GrammarChecker from "./components/tools/grammar-checker/GrammarChecker";
import TextSummarizer from "./components/tools/text-summarizer/TextSummarizer";
import StoryCreator from "./components/tools/story-creator/StoryCreator";
import ValueProposition from "./components/tools/value-proposition/ValueProposition";
import Newsletters from "./components/tools/newsletters/Newsletters";
import NameGenerator from "./components/tools/name-generator/NameGenerator";
import PressReleases from "./components/tools/press-releases/PressReleases";
import EssayOutlines from "./components/tools/essay-outlines/EssayOutlines";
import EssayIntros from "./components/tools/essay-intros/EssayIntros";
import MyContent from "./components/dashboard/projects/my-content";
import ArticleCustome from "./components/tools/article-custome/ArticleCustome";
import ArticleCustome2 from "./components/tools/article-custome2/ArticleCustome2";
import ArticleInterview from "./components/tools/article-Interview/ArticleInterview";
import ArticleInterviewList from "./components/tools/article-Interview-list/ArticleInterviewList";
import Wordle from "./components/tools/wordle/Wordle";
import ImageCreator from "./components/tools/image-creator/ImageCreator";
import InlineLogin from "./components/InlineLogin";
import BacklinkChecker from "./components/tools/backlink-checker/BacklinkChecker";
import KeywordResearchYoutube from "./components/tools/keyword-research-youtube/KeywordResearchYoutube";
import KeywordResearch from "./components/tools/keyword-research/KeywordResearch";
import KeywordAnalysis from "./components/tools/keyword-analysis/KeywordAnalysis"; 
import Tools from "./components/dashboard/tools/Tools";
import AiTools from "./components/tools/ai-tools/AiTools";
import Chat from "./components/tools/chat/Chat";
import BlogContent from "./components/dashboard/tools/BlogContent";
import SocialMedia from "./components/dashboard/tools/SocialMedia";
import WebsiteSEO from "./components/dashboard/tools/WebsiteSEO";
import Marketing from "./components/dashboard/tools/Marketing";
import OtherTools from "./components/dashboard/tools/Other";
import BusinessTemplates from "./components/dashboard/templates/BusinessTemplates";
import MarketingTemplates from "./components/dashboard/templates/MarketingTemplates";
import CareersTemplates from "./components/dashboard/templates/CareersTemplates";
import HrTemplates from "./components/dashboard/templates/HrTemplates";
import PersonalTemplates from "./components/dashboard/templates/PersonalTemplates";
import EmailTemplates from "./components/dashboard/templates/EmailTemplates"; 
import SidebarPanel from "./components/dashboard/sidebar/SidebarPanel";
import InlinePricing from "./components/views/InlinePricing";
import Admin from "./components/views/settings/Admin";
import InlinePricing2 from "./components/views/InlinePricing2";
import { FavoritesProvider } from './context/FavoritesContext';
import { SidebarProvider } from './context/SidebarContext'; 

function App() { 
  const { user} = useUserAuth();
  const functions = getFunctions(); 
  //if(user){ localStorage.setItem("user", user.uid)}   
  const [subscriber, setSubscriber] = useState(false); 
  const userSub = { subscriber, setSubscriber };    
  // if(user){
  //   const subData = CommonDataServices.getUsageLimit(user.uid);
  //   //console.log(subData)
  // }
  
  useEffect(() => {  
    const fetchData = async () => {
      if(user){
        //  console.log(userSub.subscriber.planType) 
        const subData = await commonDataServices.getUsageLimit(user.uid);   
        if(subData.data()){  
          setSubscriber(subData.data())
          let userData = subData.data();
          // console.log(subscriber) 
          //Logic to reset the totalwords on every 30 days
          let currentDate = Math.floor(Date.now() / 1000); 
      
          // const setEndDate = userData.planEndDate.toDate();
          const setEndDate = userData.planEndDate && userData.planEndDate.toDate ? userData.planEndDate.toDate() : null;
         // console.log(setEndDate)
          // console.log(Math.floor(setEndDate / 1000)) 
          // console.log(currentDate) 

          if(userData.planType === "Life Time Plan"){
            const planTier = userData.redeemedCodes.length;
            let tierWords;
            if(planTier){
              if(planTier == 1){
                tierWords = 20000;
              } else if(planTier == 2){
                tierWords = 50000;
              } else{
                tierWords = 100000;
              }
            } 
            //console.log(tierWords)
            const endDate = Math.floor(setEndDate / 1000);
            if(currentDate > endDate){ 
              let expDate  = new Date(); 
              expDate.setMonth(expDate.getMonth() + 1);  

              let subObject = {  
                planEndDate: expDate, 
                totalWords: 0,
                wordsLimit: tierWords
                } 
                await commonDataServices.updateUserSubscription(user.uid, subObject).then(async (resp) => {
                  const subData = await commonDataServices.getUsageLimit(user.uid); 
                  setSubscriber(subData.data()) 
                }); 
            }
          }


          if(userData.planType == "free"){
            if(currentDate > userData.planEndDate){ 
              let oneMonth = new Date();
              let expDate  = Math.round(oneMonth.setMonth(oneMonth.getMonth()+1) / 1000); 
              let subObject = { 
                planStartDate: currentDate, 
                planEndDate: expDate, 
                totalWords: 0,
                wordsLimit: 1500
                } 
                await commonDataServices.updateUserSubscription(user.uid, subObject).then(async (resp) => {
                  const subData = await commonDataServices.getUsageLimit(user.uid);
                  //console.log('monthly trial reset..')
                  //console.log(subData.data()) 
                  setSubscriber(subData.data()) 
                }); 
            } 
          }
          if(userData.planType != "free"){
             //console.log(userData)  
             if(currentDate > userData.planEndDate){ 
                //console.log(currentDate)   
                const subId = userData.current; //userData.current; // Get sub_id from local  "sub_1LooVxSJsVlrtsJgye5nkeXP"
                if(subId){ //if user had sub_id
                  const getSub = await httpsCallable(functions, 'getStripeSubscription'); 
                //  //console.log(subId)
                  const param = {  subId : subId   }
                  getSub(param).then(async (response) => {
                   // //console.log(response) 
                    let subRes = response.data; //get user sub data
                   // //console.log(subRes.status)
                    //console.log(subRes.current_period_end)
                    if(subRes.current_period_end > currentDate && subRes.status == "active"){ //check sub_end date is grater than current date (if he paid it will updates the end date to new date)
                      //console.log("Active renewal..")
                      let subObject = { 
                        planStartDate: subRes.current_period_start, 
                        planEndDate: subRes.current_period_end, 
                        renewalDate: subRes.current_period_end,
                        totalWords: 0 
                        } 
                        await commonDataServices.updateUserSubscription(user.uid, subObject).then(async (resp) => {
                          const subData = await commonDataServices.getUsageLimit(user.uid);
                          //console.log('Renewal done..')
                          //console.log(subData.data()) 
                          setSubscriber(subData.data()) 
                        }); 
                    } else{
                      //console.log("Past_due expired..")  
                    }
                  }) 
                }   
             }
          }
        } else{  
          await commonDataServices.setUserSubscription(user.uid).then(async (resp) => {
            const subData = await commonDataServices.getUsageLimit(user.uid);
             //console.log(subData.data()) 
            setSubscriber(subData.data()) 
          }); 
        }   

        // const fetchData = async () => {
        //   const getAIdata = await httpsCallable(functions, 'blogTitlesAI');
        //   const data = {
        //     postAbout: "Digital Marketing",
        //     language: "English",
        //     tone: "Professional" 
        //   }
        //   getAIdata(data).then((result) => {
        //     //console.log(result.data)
        //   })
        // }; 

        // fetchData(); 

      }
    };   
    if (user) {
      fetchData();
    }
   },[user])  

  const location = useLocation();
  const [sidebarVisible, setSidebarVisible] = useState(true); 
  
  const scrollableDivRef = useRef(null);
  useEffect(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop = 0;
    }
  }, [location]);
  
  useEffect(() => {
    const pathnames = [
      '/article-writer', '/blog-post', '/appsumo', '/pricing', '/profile', '/login', '/reset', '/signup', '/success/'
    ];  
    const isPathnameIncluded = pathnames.some(path => location.pathname.includes(path));
    setSidebarVisible(!isPathnameIncluded);
  }, [location]); 

  return (    <>
       <SubscriberContext.Provider value={userSub}> 
       <SidebarProvider>
       {/* <Header/>
       <Container className="columns withSidebar container">       
        {sidebarVisible && <SidebarPanel/>} 
     
      </Container> */}
   

    <Container fluid className="layout-container"> 
      <Header/>
      <FavoritesProvider userId={user?.uid || "vivhlk23S98slls"}>
      <Row className="layout-row">
        {sidebarVisible &&  
         <SidebarPanel/>
        }
        <Col xs={12} ref={scrollableDivRef} className={`scrollable-body ${sidebarVisible ? 'col-md-9' : 'noSidebar col-md-12'}`}>
        <div className={`container-wrappe`}>
       <Routes>  
            <Route path="/" element={<Dashboard />} /> 
            <Route path="/dashboard" element={ <Dashboard /> } />  
            <Route path="/gpttest" element={ <GPTtest /> } />  

            <Route path="/tools" element={<Tools/>}></Route>        
            <Route path="/tools/blog-content" element={<BlogContent/>}></Route> 
            <Route path="/tools/social-media" element={<SocialMedia/>}></Route> 
            <Route path="/tools/website-seo" element={<WebsiteSEO/>}></Route> 
            <Route path="/tools/marketing" element={<Marketing/>}></Route> 
            <Route path="/tools/other" element={<OtherTools/>}></Route> 


            <Route path="/templates" element={ <Templates/>} />
            <Route path="/templates/business" element={ <BusinessTemplates/>} />
            <Route path="/templates/marketing" element={ <MarketingTemplates/>} />
            <Route path="/templates/careers" element={ <CareersTemplates/>} />
            <Route path="/templates/hr" element={ <HrTemplates/>} />
            <Route path="/templates/personal" element={ <PersonalTemplates/>} />
            <Route path="/templates/emails" element={ <EmailTemplates/>} />
            
            <Route path="/my-content" element={ <ProtectedRoute> <MyContent /> </ProtectedRoute> } />
            {/* <Route path="/my-projects" element={ <ProtectedRoute> <Projects/> </ProtectedRoute> } /> */}
            <Route path="/product-description" element={ <ProductDescription /> } />        
            <Route path="/product-description/:id" element={ <ProtectedRoute> <ProductDescription /> </ProtectedRoute> } />
            <Route path="/flexy-template/" element={  <QuickTemplate/> } />
            <Route path="/ai-tools/" element={  <AiTools/> } />
            {/* <Route path="/flexy-template/" element={ <ProtectedRoute> <QuickTemplate/> </ProtectedRoute> } /> */}
            {/* <Route path="/flexy-template/:type?/:id?" element={ <ProtectedRoute> <QuickTemplate/> </ProtectedRoute> } /> */}
            
            <Route path="/article-interview" element={ <ProtectedRoute> <ArticleInterview/>  </ProtectedRoute> } />
            <Route path="/article-interview/:id" element={ <ProtectedRoute> <ArticleInterview/>  </ProtectedRoute> } />
            <Route path="/wordle" element={ <ProtectedRoute> <Wordle/> </ProtectedRoute> } />
            <Route path="/wordle/:id" element={ <ProtectedRoute> <Wordle/> </ProtectedRoute> } />

            <Route path="/chat" element={ <ProtectedRoute> <Chat/> </ProtectedRoute> } />
            <Route path="/chat/:id" element={ <ProtectedRoute> <Chat/> </ProtectedRoute> } />

            <Route path="/keyword-research" element={ <KeywordResearch/>  } />
            <Route path="/keyword-research/:id" element={ <ProtectedRoute> <KeywordResearch/> </ProtectedRoute> } />
            <Route path="/keyword-analysis" element={ <ProtectedRoute>  <KeywordAnalysis/> </ProtectedRoute> } />
            <Route path="/keyword-analysis/:id" element={ <ProtectedRoute> <KeywordAnalysis/> </ProtectedRoute> } />
            <Route path="/keyword-research-youtube" element={ <ProtectedRoute> <KeywordResearchYoutube/> </ProtectedRoute> } />
            <Route path="/keyword-research-youtube/:id" element={ <ProtectedRoute> <KeywordResearchYoutube/> </ProtectedRoute> } />
            <Route path="/backlink-checker" element={ <ProtectedRoute> <BacklinkChecker/> </ProtectedRoute> } />
            <Route path="/backlink-checker/:id" element={ <ProtectedRoute> <BacklinkChecker/> </ProtectedRoute> } />

            <Route path="/image-generator" element={ <ImageCreator/> } />
            <Route path="/image-generator/:id" element={ <ProtectedRoute> <ImageCreator/> </ProtectedRoute> } />
            <Route path="/article-interview-list" element={ <ArticleInterviewList/> } />
            <Route path="/article-interview-list/:id" element={ <ProtectedRoute> <ArticleInterviewList/>  </ProtectedRoute> } />
            <Route path="/article-custom" element={<ArticleCustome />} />
    <Route path="/article-custom/:id" element={<ProtectedRoute><ArticleCustome /></ProtectedRoute>} />
    <Route path="/article-custom2" element={<ArticleCustome2 />} />
    <Route path="/article-custom2/:id" element={<ProtectedRoute><ArticleCustome2 /></ProtectedRoute>} />
    <Route path="/article-writer" element={<ArticlePost />} />
    <Route path="/article-writer/:id" element={<ProtectedRoute><ArticlePost /></ProtectedRoute>} />
    <Route path="/blog-post" element={<BlogPost />} />
    <Route path="/blog-post/:id" element={<ProtectedRoute><BlogPost /></ProtectedRoute>} />
    <Route path="/blog-ideas" element={<BlogIdeas />} />
    <Route path="/blog-ideas/:id" element={<ProtectedRoute><BlogIdeas /></ProtectedRoute>} />
    <Route path="/blog-title" element={<BlogTitle />} />
    <Route path="/blog-title/:id" element={<ProtectedRoute><BlogTitle /></ProtectedRoute>} />
    <Route path="/blog-intro" element={<BlogIntro />} />
    <Route path="/blog-intro/:id" element={<ProtectedRoute><BlogIntro /></ProtectedRoute>} />
    <Route path="/blog-outline" element={<BlogOutline />} />
    <Route path="/blog-outline/:id" element={<ProtectedRoute><BlogOutline /></ProtectedRoute>} />
    <Route path="/blog-section" element={<BlogSection />} />
    <Route path="/blog-section/:id" element={<ProtectedRoute><BlogSection /></ProtectedRoute>} />
    <Route path="/blog-conclusion" element={<BlogConclusion />} />
    <Route path="/blog-conclusion/:id" element={<ProtectedRoute><BlogConclusion /></ProtectedRoute>} />
    <Route path="/paragraph-writer" element={<ParagraphWriter />} />
    <Route path="/paragraph-writer/:id" element={<ProtectedRoute><ParagraphWriter /></ProtectedRoute>} />
    <Route path="/website-headlines" element={<WebsiteHeadlines />} />
    <Route path="/website-headlines/:id" element={<ProtectedRoute><WebsiteHeadlines /></ProtectedRoute>} />
    <Route path="/website-sub-headline" element={<WebsiteSubHeadline />} />
    <Route path="/website-sub-headline/:id" element={<ProtectedRoute><WebsiteSubHeadline /></ProtectedRoute>} />
    <Route path="/content-rewriter" element={<ContentRewriter />} />
    <Route path="/content-rewriter/:id" element={<ProtectedRoute><ContentRewriter /></ProtectedRoute>} />
    <Route path="/meta-title" element={<MetaTitle />} />
    <Route path="/meta-title/:id" element={<ProtectedRoute><MetaTitle /></ProtectedRoute>} />
    <Route path="/meta-description" element={<MetaDescription />} />
    <Route path="/meta-description/:id" element={<ProtectedRoute><MetaDescription /></ProtectedRoute>} />
    <Route path="/aida-content" element={<AIDAContent />} />
    <Route path="/aida-content/:id" element={<ProtectedRoute><AIDAContent /></ProtectedRoute>} />
    <Route path="/faqs" element={<FAQs />} />
    <Route path="/faqs/:id" element={<ProtectedRoute><FAQs /></ProtectedRoute>} />
    <Route path="/call-to-action" element={<CallToAction />} />
    <Route path="/call-to-action/:id" element={<ProtectedRoute><CallToAction /></ProtectedRoute>} />
    <Route path="/product-review" element={<ProductReview />} />
    <Route path="/product-review/:id" element={<ProtectedRoute><ProductReview /></ProtectedRoute>} />
    <Route path="/customer-review-response" element={<CustomerReviewResponse />} />
    <Route path="/customer-review-response/:id" element={<ProtectedRoute><CustomerReviewResponse /></ProtectedRoute>} />
    <Route path="/facebook-ad" element={<FacebookAd />} />
    <Route path="/facebook-ad/:id" element={<ProtectedRoute><FacebookAd /></ProtectedRoute>} />
    <Route path="/google-ad" element={<GoogleAd />} />
    <Route path="/google-ad/:id" element={<ProtectedRoute><GoogleAd /></ProtectedRoute>} />
    <Route path="/linkedin-ad" element={<LinkedinAd />} />
    <Route path="/linkedin-ad/:id" element={<ProtectedRoute><LinkedinAd /></ProtectedRoute>} />
    <Route path="/tweet-ideas" element={<TweetIdeas />} />
    <Route path="/tweet-ideas/:id" element={<ProtectedRoute><TweetIdeas /></ProtectedRoute>} />
    <Route path="/youtube-video-title" element={<YoutubeVideoTitle />} />
    <Route path="/youtube-video-title/:id" element={<ProtectedRoute><YoutubeVideoTitle /></ProtectedRoute>} />
    <Route path="/youtube-video-description" element={<YoutubeVideoDescription />} />
    <Route path="/youtube-video-description/:id" element={<ProtectedRoute><YoutubeVideoDescription /></ProtectedRoute>} />
    <Route path="/youtube-video-script" element={<YoutubeVideoScript />} />
    <Route path="/youtube-video-script/:id" element={<ProtectedRoute><YoutubeVideoScript /></ProtectedRoute>} />
    <Route path="/youtube-video-tags" element={<YoutubeVideoTags />} />
    <Route path="/youtube-video-tags/:id" element={<ProtectedRoute><YoutubeVideoTags /></ProtectedRoute>} />
    <Route path="/youtube-comments-responder" element={<YoutubeCommentsResponder />} />
    <Route path="/youtube-comments-responder/:id" element={<ProtectedRoute><YoutubeCommentsResponder /></ProtectedRoute>} />
    <Route path="/instagram-post-ideas" element={<InstagramPostIdeas />} />
    <Route path="/instagram-post-ideas/:id" element={<ProtectedRoute><InstagramPostIdeas /></ProtectedRoute>} />
    <Route path="/quora-answers" element={<QuoraAnswers />} />
    <Route path="/quora-answers/:id" element={<ProtectedRoute><QuoraAnswers /></ProtectedRoute>} />
    <Route path="/amazon-product-description" element={<AmazonProductDescription />} />
    <Route path="/amazon-product-description/:id" element={<ProtectedRoute><AmazonProductDescription /></ProtectedRoute>} />
    <Route path="/amazon-product-bullet-points" element={<AmazonProductBulletPoints />} />
    <Route path="/amazon-product-bullet-points/:id" element={<ProtectedRoute><AmazonProductBulletPoints /></ProtectedRoute>} />
    <Route path="/amazon-product-title" element={<AmazonProductTitle />} />
    <Route path="/amazon-product-title/:id" element={<ProtectedRoute><AmazonProductTitle /></ProtectedRoute>} />
    <Route path="/amazon-ad-headline" element={<AmazonAdHeadline />} />
    <Route path="/amazon-ad-headline/:id" element={<ProtectedRoute><AmazonAdHeadline /></ProtectedRoute>} />
    <Route path="/cold-email" element={<ColdEmail />} />
    <Route path="/cold-email/:id" element={<ProtectedRoute><ColdEmail /></ProtectedRoute>} />
    <Route path="/welcome-email" element={<WelcomeEmail />} />
    <Route path="/welcome-email/:id" element={<ProtectedRoute><WelcomeEmail /></ProtectedRoute>} />
    <Route path="/cancellation-email" element={<CancellationEmail />} />
    <Route path="/cancellation-email/:id" element={<ProtectedRoute><CancellationEmail /></ProtectedRoute>} />
    <Route path="/email-subject-line" element={<EmailSubjectLine />} />
    <Route path="/email-subject-line/:id" element={<ProtectedRoute><EmailSubjectLine /></ProtectedRoute>} />
    <Route path="/follow-up-email" element={<FollowUpEmail />} />
    <Route path="/follow-up-email/:id" element={<ProtectedRoute><FollowUpEmail /></ProtectedRoute>} />
    <Route path="/thank-you-note" element={<ThankYouNote />} />
    <Route path="/thank-you-note/:id" element={<ProtectedRoute><ThankYouNote /></ProtectedRoute>} />
    <Route path="/company-mission" element={<CompanyMission />} />
    <Route path="/company-mission/:id" element={<ProtectedRoute><CompanyMission /></ProtectedRoute>} />
    <Route path="/company-vision" element={<CompanyVision />} />
    <Route path="/company-vision/:id" element={<ProtectedRoute><CompanyVision /></ProtectedRoute>} />
    <Route path="/about-us" element={<AboutUs />} />
    <Route path="/about-us/:id" element={<ProtectedRoute><AboutUs /></ProtectedRoute>} />

            <Route path="/quest-formula" element={ <QuestFormula /> } /> 
            <Route path="/quest-formula/:id" element={ <ProtectedRoute> <QuestFormula /> </ProtectedRoute> } />    
            <Route path="/pain-agitate-solution" element={ <PainAgitateSolution/> } />   
            <Route path="/pain-agitate-solution/:id" element={ <ProtectedRoute> <PainAgitateSolution/> </ProtectedRoute> } />  
              
            <Route path="/article-rewriter" element={ <ArticleRewriter />} />
            <Route path="/article-rewriter/:id" element={ <ProtectedRoute> <ArticleRewriter /> </ProtectedRoute> } />
              
            <Route path="/paraphrase" element={ <Paraphrase /> } />
            <Route path="/paraphrase/:id" element={ <ProtectedRoute> <Paraphrase /> </ProtectedRoute> } />

            <Route path="/pros-cons" element={ <ProsCons/> } />
            <Route path="/pros-cons/:id" element={ <ProtectedRoute><ProsCons/> </ProtectedRoute> } />

            <Route path="/sentence-rewriter" element={ <SentenceRewriter/> } />
            <Route path="/sentence-rewriter/:id" element={ <ProtectedRoute> <SentenceRewriter/> </ProtectedRoute> } />

            <Route path="/sentence-expander" element={ <SentenceExpander/> } />
            <Route path="/sentence-expander/:id" element={ <ProtectedRoute> <SentenceExpander/> </ProtectedRoute> } />

            <Route path="/paragraph-points" element={ <ParagraphPoints/>} />
            <Route path="/paragraph-points/:id" element={ <ProtectedRoute> <ParagraphPoints/> </ProtectedRoute> } />

            <Route path="/social-media-post" element={ <SocialMediaPost/> } />
            <Route path="/social-media-post/:id" element={ <ProtectedRoute> <SocialMediaPost/> </ProtectedRoute> } />

            <Route path="/instagram-caption" element={ <InstagramCaption/>  } />
            <Route path="/instagram-caption/:id" element={ <ProtectedRoute> <InstagramCaption/> </ProtectedRoute> } />
    
            <Route path="/instagram-hashtags" element={<InstagramHashtags/> } />
            <Route path="/instagram-hashtags/:id" element={ <ProtectedRoute> <InstagramHashtags/> </ProtectedRoute> } />

            <Route path="/linkedin-post" element={ <LinkedInPost/>} />
            <Route path="/linkedin-post/:id" element={ <ProtectedRoute> <LinkedInPost/> </ProtectedRoute> } />

            <Route path="/twitter-threads" element={ <TwitterThreads/>} />
            <Route path="/twitter-threads/:id" element={ <ProtectedRoute> <TwitterThreads/> </ProtectedRoute> } />

            <Route path="/twitter-generator" element={  <TwitterGenerator/> } />
            <Route path="/twitter-generator/:id" element={ <ProtectedRoute> <TwitterGenerator/> </ProtectedRoute> } />
    
            <Route path="/grammar-checker" element={  <GrammarChecker/>  } />
            <Route path="/grammar-checker/:id" element={ <ProtectedRoute> <GrammarChecker/> </ProtectedRoute> } />
          
            <Route path="/text-summarizer" element={ <TextSummarizer/> } />
            <Route path="/text-summarizer/:id" element={ <ProtectedRoute> <TextSummarizer/> </ProtectedRoute> } />
            
            <Route path="/story-creator" element={ <StoryCreator/> } />
            <Route path="/story-creator/:id" element={ <ProtectedRoute> <StoryCreator/> </ProtectedRoute> } />
            
            <Route path="/value-proposition" element={<ValueProposition/> } />
            <Route path="/value-proposition/:id" element={ <ProtectedRoute> <ValueProposition/> </ProtectedRoute> } />
    
            <Route path="/newsletters" element={  <Newsletters/> } />
            <Route path="/newsletters/:id" element={ <ProtectedRoute> <Newsletters/> </ProtectedRoute> } />
            
            <Route path="/name-generator" element={  <NameGenerator/>  } />
            <Route path="/name-generator/:id" element={ <ProtectedRoute> <NameGenerator/> </ProtectedRoute> } />
    
            <Route path="/press-releases" element={ <PressReleases/>  } />
            <Route path="/press-releases/:id" element={ <ProtectedRoute> <PressReleases/> </ProtectedRoute> } />
    
            <Route path="/essay-intros" element={  <EssayIntros/> } />
            <Route path="/essay-intros/:id" element={ <ProtectedRoute> <EssayIntros/> </ProtectedRoute> } />
    
            <Route path="/essay-outlines" element={  <EssayOutlines/> } />
            <Route path="/essay-outlines/:id" element={ <ProtectedRoute> <EssayOutlines/> </ProtectedRoute> } />
    

    {/*    <Route path="/" element={ <ProtectedRoute>  </ProtectedRoute> } />
            <Route path="//:id" element={ <ProtectedRoute> </ProtectedRoute> } />
            <Route path="/" element={ <ProtectedRoute>  </ProtectedRoute> } />
            <Route path="//:id" element={ <ProtectedRoute> </ProtectedRoute> } /> */}

            <Route path="/admin" element={ <ProtectedRoute> <Admin/> </ProtectedRoute> } />
            <Route path="/profile" element={ <ProtectedRoute> <Profile />  </ProtectedRoute> } /> 
            <Route path="/pricing" element={ <ProtectedRoute> <InlinePricing />  </ProtectedRoute> } /> 
            {/* <Route path="/pricing" element={userSub.subscriber.planType === "Unlimited Plan" || userSub.subscriber.planType === "Professional Plan"? (
                <ProtectedRoute>
                <InlinePricing2/>
                </ProtectedRoute>
              ) : (
                <ProtectedRoute>
                <InlinePricing/>
                </ProtectedRoute>
              )} /> */}
            <Route path="/appsumo" element={ <OfferCode /> } />
            <Route path="/login" element={<Login />} />
            <Route path="/login/:type" element={<Login />} />
            <Route path="/Inlinelogin" element={ <InlineLogin/>} />
            <Route path="/Inlinelogin/:type" element={ <InlineLogin/>} /> 
            <Route path="/landing" element={<Landing />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/reset" element={<Reset />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/success/" element={<Success />} />
            <Route path="/success/:id" element={<Success />} />
            <Route path="/cancel" element={<Cancel />} />
            <Route path="*" element={ <NotFound/> } />
          </Routes>
       </div>
          </Col>
      </Row>
        </FavoritesProvider>
    </Container>
    </SidebarProvider>   
    </SubscriberContext.Provider>   
        </>
  );
}

export default App;