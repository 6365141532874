import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Button, Accordion, Tabs, Tab } from "react-bootstrap";
import { useNavigate } from "react-router"; 
import { useUserAuth } from "../../context/UserAuthContext";   
import { useLocation  } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";    
import axios from "axios";
    import "./Pricing.css"; 
import Loader2 from "../shared/utilities/loader2/Loader2";


let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe("pk_live_51Kzc9vSJsVlrtsJgpAfNUX5BUXA8Rb3uKyIEFtFaSibM5rNeaXVRvCOiOXBic7GN2MD4SGliDtWJ8kZT5R6HhNz800iag3rG8U");
  }

  return stripePromise;
};

const InlinePricing = () => {  
  const [loading, setLoading] = useState(false);
  const { user } = useUserAuth();

  const location = useLocation();
  const [inPage, SetInPage] = useState(false);

  useEffect(() => { 
    if (location.pathname.includes('/pricing')) {
      SetInPage(true);
    } else {
      SetInPage(false);
    }
  }, [location]);

  // const [planObjMonth, setPlanObjMonth] = useState(
  //   {planType: 1, planPrice: "1499", planWords: "20,00,000", planPriceID:"price_1NCzaZSJsVlrtsJgz1fyY8Rz"} 
  // ) 
  // const [planObjYear, setPlanObjYear] = useState(
  //   {planType: 1, planPrice: "1084", planWords: "20,00,000", planPriceID:"price_1NCzZtSJsVlrtsJggbAWpBCA", planAnnualPrice: "12999"} 
  // )
  // const [planObjMonthUs, setPlanObjMonthUs] = useState(
  //   {planType: 1, planPrice: "19", planWords: "20,00,000", planPriceID:"price_1NCzUuSJsVlrtsJgRmWiPjMo"}  
  // ) 
  // const [planObjYearUs, setPlanObjYearUs] = useState(
  //   {planType: 1, planPrice: "15", planWords: "20,00,000", planPriceID:"price_1NCzYVSJsVlrtsJg6LNaC4Eg", planAnnualPrice: "185"} 
  // )
  const [planObjMonth, setPlanObjMonth] = useState(
    {planType: 1, planPrice: "2449", planWords: "20,00,000", planPriceID:"price_1Mxn4ASJsVlrtsJgM1X4Nxc7"} 
  ) 
  const [planObjYear, setPlanObjYear] = useState(
    {planType: 1, planPrice: "1874", planWords: "20,00,000", planPriceID:"price_1Mxn4ASJsVlrtsJgEFzrh6LG", planAnnualPrice: "22488"} 
  )
  const [planObjMonthUs, setPlanObjMonthUs] = useState(
    {planType: 1, planPrice: "29", planWords: "20,00,000", planPriceID:"price_1Mxn4ASJsVlrtsJg6Oc0O0FJ"}  
  ) 
  const [planObjYearUs, setPlanObjYearUs] = useState(
    {planType: 1, planPrice: "22", planWords: "20,00,000", planPriceID:"price_1Mxn4ASJsVlrtsJg1f39CJRR", planAnnualPrice: "264"} 
  )

  const [planObjMonth1, setPlanObjMonth1] = useState(
    {planType: 1, planPrice: "699", planWords: "50,000", planPriceID:"price_1NFrYNSJsVlrtsJgXRI7Ucbx"} 
  ) 
  const [planObjYear1, setPlanObjYear1] = useState(
    {planType: 1, planPrice: "489", planWords: "50,000", planPriceID:"price_1NFrZISJsVlrtsJgpzqoIxFb", planAnnualPrice: "5868"} 
  )
  const [planObjMonthUs1, setPlanObjMonthUs1] = useState(
    {planType: 1, planPrice: "9", planWords: "50,000", planPriceID:"price_1NFraISJsVlrtsJg7E5eCirW"}  
  ) 
  const [planObjYearUs1, setPlanObjYearUs1] = useState(
    {planType: 1, planPrice: "7", planWords: "50,000", planPriceID:"price_1NFrZmSJsVlrtsJgS7dNBsPh", planAnnualPrice: "84"} 
  )

  const [selectedPlan, setSelectedPlan] = useState(1)
  const navigate = useNavigate(); 
  const functions = getFunctions(); 
  const [key, setKey] = useState('monthly');
  const [state, setState] = useState({
    ip: "",
    countryName: "",
    countryCode: "",
    city: "",
    timezone: ""
  });
  
  //const stripe =    window.Stripe('pk_test_51Kzc9vSJsVlrtsJgysz6iWCYrNBieshzuXYk4OAdYr83Ip7u3iAfWLGYmiCWG5xY9mm703ARAjXIRtNo1UQsUlAz00vQq96pB1');
  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/") 
      .then((response) => {
        let data = response.data;
        setState({
          ...state, 
          countryName: data.country_name,
          countryCode: data.country_code, 
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  };
  const priceRange = (e) => {
    const range = e.target.value - 1;
    // //console.log(range)
    // //console.log(planObj[range].planPrice)
    let selPlan = parseInt(range);
    setSelectedPlan(selPlan);
  }
  const checkOut = async (e) => { 
    const priceId = e.target.id;
    const stripe = await getStripe();
    setLoading(true);
   // //console.log("clicked" + priceId) 
    const getCheckoutSession = httpsCallable(functions, 'createStripeCheckout'); 
    const param = {
      uid: user.uid,
      priceId: priceId
    }
    getCheckoutSession(param).then(async (response) => {  
          const sessionId = response.data.id;
          await stripe.redirectToCheckout({sessionId: sessionId})
          setLoading(false);
    }); 
  }
  const cancelSub = async () => { 
    const stripe = await getStripe();
    
    const delSub = httpsCallable(functions, 'deleteStripeSubscription');
    const param = {
      subId : 'subId'
    }
    delSub(param).then((response) => {
      //console.log(response)
    })  
  }
  useEffect(() => {
    getGeoInfo();
  }, []);
  return (
    <>   
      <div className={`page-layout inline-pricing ${inPage === true ? "inPage" : ""}`}> 
            <div className="header-txt sub-head text-center"> 
               {!inPage && <>
                <h2>Upgrade</h2>
                <p>Get unlimited words per month by upgrading today!</p> 
               </> } 
               {inPage && <div className="mb-5">
                <h2>Unlimited Plan</h2>
                <p>Get unlimited words per month by upgrading today!</p> 
               </div> } 
               
             </div>  
            <div className="container">  
               <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3 pricingTabs"
    > 
      <Tab eventKey="monthly" title="Monthly">
      <div className="loaderInline">{loading && <Loader2/>}</div> 
      <div className="row">
        
      <div className="col-md-6"> 
<div className="card pricing-box d-flex mx-auto activebox">
    <div className="card-body">
        <h4 className="card-title">Pro</h4>        
        <h3 className="unlimited-words">Starter plan</h3> 
        <p className="caption">Generate 50,000 words per month</p>
        <h5 className="card-subtitle mb-1"> 
        {state.countryName == "India" && <>&#8377;699</>}
        {state.countryName !== "India" && <>&#36;9</>}
           <span className="ml-1">/mo</span>
           </h5>  
        <div>   
          {state.countryName == "India" && <Button variant="primary" disabled={loading} size="md" className="buttonWrap pricBtn" id={planObjMonth1.planPriceID} onClick={checkOut}>Upgrade Now</Button>}
          {state.countryName !== "India" &&<Button variant="primary" disabled={loading} size="md" className="buttonWrap pricBtn" id={planObjMonthUs1.planPriceID} onClick={checkOut}>Upgrade Now</Button>}
        </div>
                          <span className="text-mute"> Cancel anytime </span>
    </div>
</div> 
</div> 
      <div className="col-md-6"> 
<div className="card pricing-box d-flex mx-auto activebox">
    <div className="card-body">
        <h4 className="card-title">Pro</h4>        
        <h3 className="unlimited-words">Unlimited plan</h3>  
        <p className="caption">Generate UNLIMITED words per month</p>
        <h5 className="card-subtitle mb-1"> 
        {state.countryName == "India" && <>&#8377;2,499</>}
        {state.countryName !== "India" && <>&#36;29</>}
           <span className="ml-1">/mo</span>
           </h5>  
        <div>   
          {state.countryName == "India" && <Button variant="primary" disabled={loading} size="md" className="buttonWrap pricBtn" id={planObjMonth.planPriceID} onClick={checkOut}>Upgrade Now</Button>}
          {state.countryName !== "India" &&<Button variant="primary" disabled={loading} size="md" className="buttonWrap pricBtn" id={planObjMonthUs.planPriceID} onClick={checkOut}>Upgrade Now</Button>}
        </div>
                          <span className="text-mute"> Cancel anytime </span>
    </div>
</div> 
</div> 
 </div>
 
<div className="row planFeatures">
<div className="col-md-6"> 
<div className="card-text my-3 planIncludes">
                <ul>  
                    <li><i className="tickMark">✔</i> Write 3000 words Article</li> 
                    <li><i className="tickMark">✔</i> WordPress Auto Post</li> 
                    <li><i className="tickMark">✔</i> 245+ AI tools</li>
                    <li><i className="tickMark">✔</i> 78+ pre-build templates</li> 
                </ul>
            </div>
			
</div>
<div className="col-md-6"> 
<div className="card-text my-3 planIncludes"> 
                <ul>  
                    <li><i className="tickMark">✔</i> SEO - Keyword Research</li> 
                    <li><i className="tickMark">✔</i> SmartChat</li> 
                    <li><i className="tickMark">✔</i> Image Generator</li> 
                    <li><i className="tickMark">✔</i> 37 Languages support</li> 
                </ul>
            </div>
			
</div>
</div>
      </Tab>
    

      <Tab eventKey="yearly" title={`Yearly (${30} % off)`}>
      <div className="loaderInline">{loading && <Loader2/>}</div> 
      <div className="row"> 
      <div className="col-md-6">
                <div className="card pricing-box d-flex mx-auto activebox">
                    <div className="card-body">
                    <div className="offerTxt">30 % off</div>
                        <h4 className="card-title">Pro</h4>        
                        <h3 className="unlimited-words">Starter plan</h3>  
        <p className="caption">Generate 50,000 words per month</p>
        {state.countryName == "India" &&<div className="offHead">You Save 2520</div>}
        {state.countryName !== "India" &&<div className="offHead">You Save $24</div>}
                        <h5 className="card-subtitle mb-1"> 
                        {state.countryName == "India" && <>&#8377;489 <span className="ml-1">/mo</span> <br/> 
                        <span className="perMonth atYear"> &#8377;5868/yr</span>
                        </>}
                        {state.countryName !== "India" && <>&#36;7 <span className="ml-1">/mo</span> <br/>
                        <span className="perMonth atYear"> &#36;84/yr</span>
                        </>} 
                            </h5>     
                        <div>
                       
                        {state.countryName == "India" && <Button variant="primary" disabled={loading} size="md" className="buttonWrap pricBtn" id={planObjYear1.planPriceID} onClick={checkOut}>Upgrade Now</Button>}
                        {state.countryName !== "India" &&<Button variant="primary" disabled={loading} size="md" className="buttonWrap pricBtn" id={planObjYearUs1.planPriceID} onClick={checkOut}>Upgrade Now</Button>}
                          </div> 
                          <span className="text-mute"> Cancel anytime </span>
                    </div>
                </div>
            </div>   
      <div className="col-md-6">
                <div className="card pricing-box d-flex mx-auto activebox">
                    <div className="card-body">
                    <div className="offerTxt">30 % off</div>
                        <h4 className="card-title">Pro</h4>        
                        <h3 className="unlimited-words">Unlimited plan</h3>  
        <p className="caption">Generate UNLIMITED words per month</p>
        {state.countryName == "India" &&<div className="offHead">You Save 7500</div>} 
        {state.countryName !== "India" &&<div className="offHead">You Save $84</div>}
                        <h5 className="card-subtitle mb-1"> 
                        {state.countryName == "India" && <>&#8377;1,874 <span className="ml-1">/mo</span> <br/>  
                        <span className="perMonth atYear"> &#8377;22488yr</span>
                        </>}
                        {state.countryName !== "India" && <>&#36;22 <span className="ml-1">/mo</span> <br/>
                        <span className="perMonth atYear"> &#36;264/yr</span>
                        </>} 
                            </h5>     
                        <div>
                       
                        {state.countryName == "India" && <Button variant="primary" disabled={loading} size="md" className="buttonWrap pricBtn" id={planObjYear.planPriceID} onClick={checkOut}>Upgrade Now</Button>}
                        {state.countryName !== "India" &&<Button variant="primary" disabled={loading} size="md" className="buttonWrap pricBtn" id={planObjYearUs.planPriceID} onClick={checkOut}>Upgrade Now</Button>}
                          </div> 
                          <span className="text-mute"> Cancel anytime </span>
                    </div>
                </div>
            </div>  
           </div>
           
<div className="row planFeatures">
<div className="col-md-6"> 
<div className="card-text my-3 planIncludes">
                <ul>  
                    <li><i className="tickMark">✔</i> Write 3000 words Article</li> 
                    <li><i className="tickMark">✔</i> WordPress Auto Post</li> 
                    <li><i className="tickMark">✔</i> 245+ AI tools</li>
                    <li><i className="tickMark">✔</i> 78+ pre-build templates</li> 
                </ul>
            </div>
			
</div>
<div className="col-md-6"> 
<div className="card-text my-3 planIncludes"> 
                <ul>  
                    <li><i className="tickMark">✔</i> SEO - Keyword Research</li> 
                    <li><i className="tickMark">✔</i> SmartChat</li> 
                    <li><i className="tickMark">✔</i> Image Generator</li> 
                    <li><i className="tickMark">✔</i> 37 Languages support</li> 
                </ul>
            </div>
			
</div>
</div>
      </Tab> 
    </Tabs> 

    {inPage && <> 
    
    <div className="row mt-5 faqs">
            <div className="col-md-12">
                <div className="my-4 text-center">
                <h3>FAQ's</h3>
                <div>Frequently asked questions, answered.</div>
                </div>
            <Accordion defaultActiveKey="0" flush >
  <Accordion.Item eventKey="0" className="rounded mb-3">
    <Accordion.Header>How can I contact support?</Accordion.Header>
    <Accordion.Body>
    You can click on the chat button to have a word with our support team. If you prefer email, drop us a line at support@wordkraft.ai
   </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="1" className="rounded mb-3">
    <Accordion.Header>Are there any video tutorials I can refer to?</Accordion.Header>
    <Accordion.Body>
    Yes, we have a bunch of them on <a href="https://www.youtube.com/@wordkraftai" target="_blank" rel="noopener noreferrer">our YouTube channel!</a> We have videos for almost every feature and issue you may face.
    
    
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="2" className="rounded mb-3">
    <Accordion.Header>Can I use the generated content for commercial purposes?</Accordion.Header>
    <Accordion.Body>
    Yes, you have the rights for the generated content. You are free to use it however you want.
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="3" className="rounded mb-3">
    <Accordion.Header>How long does it take to generate a 1,000-word article?</Accordion.Header>
    <Accordion.Body>
    Using Wordkraft, you can generate a 1000-word article draft in less than 1 minutes. 
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="4" className="rounded mb-3">
    <Accordion.Header>Are the generated outputs free of plagiarism?</Accordion.Header>
    <Accordion.Body>
    Yes, the outputs are free of plagiarism. Our A.I generates a unique piece of content for every output.
    </Accordion.Body>
  </Accordion.Item> 
</Accordion>
            </div>
        </div>

        </>}
          </div>
        </div> 
      {/* <Toaster position="top-center" reverseOrder={false} /> */}
    </>
  );
};

export default InlinePricing;