import React, { useState } from 'react';

function GPTTest() {
  const [prompt, setPrompt] = useState('');
  const [maxTokens, setMaxTokens] = useState(300);
  const [responseContent, setResponseContent] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Update this URL to your Firebase Function endpoint
    const firebaseFunctionUrl = 'https://us-central1-your-project-id.cloudfunctions.net/generateAIContent';

    const requestData = {
      prompt: prompt,
      maxTokens: maxTokens, // Make sure this matches the parameter expected by your Firebase Function
    };

    try {
      const response = await fetch(firebaseFunctionUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers your function might require
        },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      setResponseContent(JSON.stringify(data, null, 2));
    } catch (error) {
      console.error('Error fetching AI content:', error);
      setResponseContent('Failed to fetch AI content.');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="prompt">Prompt:</label>
          <input
            id="prompt"
            type="text"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="maxTokens">Max Tokens:</label>
          <input
            id="maxTokens"
            type="number"
            value={maxTokens}
            onChange={(e) => setMaxTokens(e.target.value)}
          />
        </div>
        <button type="submit">Generate Content</button>
      </form>
      {responseContent && (
        <div>
          <h2>Response:</h2>
          <pre>{responseContent}</pre>
        </div>
      )}
    </div>
  );
}

export default GPTTest;
