const RePurposeContentData = {
    videoToArticleConverter: {
        prompt: "1201",
        icon: "🎥",
        title: "Video to Article Converter",
        category: "repurpose",
        caption: `Effortlessly transform YouTube video transcripts into unique, SEO-optimized articles in any desired language.`,
        inputs: [
            { inputTitle: `Enter your Video Transcript`, inputCaption: `Paste the transcript of your YouTube video here`, type: 'textarea', inputExample: "John Doe: Hello, everyone! Today, we're going to discuss the importance of time management..." }
        ]
    },
    logoDesignIdeas: {
        prompt: "1202",
        icon: "📹",
        title: "Article to Video Script Converter",
        category: "repurpose",
        caption: `Seamlessly convert written articles into compelling scripts for videos, streamlining your video content creation process.`,
        inputs: [
            { inputTitle: `Enter your whole article below`, inputCaption: `Paste the text of your article to convert it into a video script`, type: 'textarea', inputExample: "In this article, we explore the impact of climate change on marine life and the urgent need for conservation efforts..." }
        ]
    },
    blogToReelsScript: {
        prompt: "1203",
        icon: "🎬",
        title: "Blog to Reels Script",
        category: "repurpose",
        caption: `Efficiently distill essential elements from blogs and articles into concise, engaging scripts for YouTube Shorts or Instagram Reels.`,
        inputs: [
            { inputTitle: `Enter your blog post or article`, inputCaption: `Paste your blog post or article content here`, type: 'textarea', inputExample: "Are you struggling to find time for your hobbies? Check out these 5 tips to manage your schedule effectively..." }
        ]
    },
    youtubeVideoToBlogPost: {
        prompt: "1204",
        icon: "📰",
        title: "YouTube video to Blog Post",
        category: "repurpose",
        caption: `Transform YouTube video transcripts into captivating blog posts, enhancing readability, infusing SEO strategies, and crafting effective headlines and conclusions to retain the video's essence.`,
        inputs: [
            { inputTitle: `Provide the YouTube video transcript`, inputCaption: `Insert the transcript of the YouTube video to convert it into a blog post`, type: 'textarea', inputExample: "Speaker 1: Welcome to our channel! Today, we're going to discuss the latest trends in digital marketing..." }
        ]
    },
};

export default RePurposeContentData;
