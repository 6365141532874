import {React, useState, useEffect, useContext} from "react";
import { Button, Card, Container} from "react-bootstrap"; 
import { Link } from "react-router-dom";
import { useUserAuth } from "../../../context/UserAuthContext";  
import toast, { Toaster } from 'react-hot-toast'; 
import { FcPaid } from "react-icons/fc";
import "./Tools.css"; 
import { YouTubeIcon, TwitterIcon, QuoraIcon, FacebookIcon, LinkedinIcon, AmazonIcon, GoogleAdIcon, CustomerReviewIcon, InstagramIcon, ProductReviewIcon, BlogIcon, BlogIdeaIcon, BlogTitleIcon, BlogIntroIcon, BlogOutlineIcon, BlogConclusionIcon, ParagraphIcon, ContentRewriteIcon, WebsiteHeadingIcon, WebsiteSubHeadingIcon, MetaTitleIcon, MetaDescriptionIcon, AboutUsIcon, FaqsIcon, CallToActionIcon, AidaIcon, EmailIcon, ParaphraseIcon, UniversalIcon, ArticleIcon, BlogSectionIcon, SentenceExpanderIcon, ProsAndConsIcon, ParagraphWriterIcon, ParaphraseIcon1, ParagraphToPointsIcon, CompanyVisionIcon, GrammarCheckerIcon, ValuePropositionIcon, NewsLettersIcon, NameGeneratorIcon, PressReleasesIcon, EssayIntrosIcon, EssayOutlinesIcon, FlexyTemplateIcon, TextSummarizerIcon, StoriesIcon, SocialMediaIcon, ImageCreationIcon, KeywordResearchIcon } from "../../../assets/svg/SvgIcons";
import SidebarPanel from "../sidebar/SidebarPanel"; 

const SocialMedia = () => {
   
  return (
    <>  
      
      <Container className="main-layout">    
      <div className="header-txt"> 
                    <h2>Social Media</h2> 
                   <p className="text-left"> 
                    Our AI tools are designed to help you generate high quality content quick and easily.</p>  
                    </div>      
            <div className=""> 
          <div className="card-grid-row mt-1">   
                  <Card data-category="socialMedia"> 
                   <Link to="/youtube-video-title">
                     <div className="card-wrap">
                           <div className="card-icon">
                               <div className="svgIcon">
                              <YouTubeIcon />
                           </div> 
                           </div>
                          <Card.Body> 
                          <Card.Title>YouTube Video Title</Card.Title>
                          <Card.Text>
                          Write a catchy and attention-grabbing title for your video using AI.
                          </Card.Text> 
                         </Card.Body> 
                        </div>
                       </Link>
                  </Card> 
                    <Card data-category="socialMedia"> 
                     <Link to="/youtube-video-description">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <YouTubeIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>YouTube Video Description</Card.Title>
                            <Card.Text>
                            Write an impressive YouTube Video Description using AI support.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="socialMedia"> 
                     <Link to="/youtube-video-tags">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <YouTubeIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>YouTube Video Tags</Card.Title>
                            <Card.Text>
                            Create an high-quality Youtube video tags for SEO using AI.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>   
                    <Card data-category="socialMedia"> 
                     <Link to="/youtube-video-script">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <YouTubeIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>YouTube Video Script</Card.Title>
                            <Card.Text>
                            Generate a perfect step-by-step YouTube Video Script using AI.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="socialMedia"> 
                     <Link to="/youtube-comments-responder">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <YouTubeIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>YouTube Comments Responder</Card.Title>
                            <Card.Text>
                            Write a response to public comments that are positive and helpful.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="socialMedia"> 
                     <Link to="/quora-answers">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <QuoraIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Quora Answers</Card.Title>
                            <Card.Text>
                            Using AI write a perfect Quora answers and get more attention.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="socialMedia"> 
                     <Link to="/social-media-post">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon"> 
                                <SocialMediaIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Social Media Post</Card.Title>
                            <Card.Text>
                            Write a social media post of your topic to be published on different platform.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="socialMedia"> 
                     <Link to="/instagram-caption">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <InstagramIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Instagram Caption</Card.Title>
                            <Card.Text>
                            Create a perfect attention grabbing Instagram captions.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="socialMedia"> 
                     <Link to="/instagram-hashtags">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <InstagramIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Instagram Hastag</Card.Title>
                            <Card.Text>
                            Create a trendy and attention grabbing Instagram hashtags.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="socialMedia"> 
                     <Link to="/linkedin-post">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <LinkedinIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>LinkedIn Post</Card.Title>
                            <Card.Text>
                            Generate the informative and attractive LinkedIn post content
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="socialMedia"> 
                     <Link to="/twitter-threads">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <TwitterIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Twitter threads</Card.Title>
                            <Card.Text>
                            Create the perfect engaging threads on Twitter about any topic.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="socialMedia"> 
                     <Link to="/twitter-generator">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <TwitterIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Twitter generator</Card.Title>
                            <Card.Text>
                            Generate informative and impressive tweets that will engage your followers.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
                    
             </div>
          </div>
        </Container>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default SocialMedia;