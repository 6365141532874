const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({ apiKey: process.env.REACT_APP_OPENAI_API_KEY });
const openai = new OpenAIApi(configuration);

class AIDAContentAIServices {    
 

  generateAIDAContent = (formDataObj) => {
    return openai.createCompletion("text-davinci-002", {  
      prompt: `Write an  Attention:, Interest:, Desire:, and Action: content from below data:
      \nProduct Name: ${formDataObj.projectValue1}\nProduct Description: ${formDataObj.projectValue2}\nNote:write in ${formDataObj.language} language\nTone of Voice:${formDataObj.tone}`, 
      temperature: 0.7,
      max_tokens: 100,
      top_p: 1.0,
      frequency_penalty: 0.0,
      presence_penalty: 0.0,  
      n: 1 //parseInt(`${formDataObj.variant}`) 
     });
   }

   generateMoreAIDAContent = (queryObj) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Write an  Attention:, Interest:, Desire:, and Action: content from below data:
      \nProduct Name: ${queryObj.projectValue1}\nProduct Description: ${queryObj.projectValue2}\nNote:write in ${queryObj.language} language\nTone of Voice:${queryObj.tone}`, 
      temperature: 0.7,  
      max_tokens: 100,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,  
      n: 1  
     });
   }

   addMoreAIDAContent = (queryObj, pDes) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Write an  Attention:, Interest:, Desire:, and Action: content from below data:
      \nProduct Name: ${queryObj.projectValue1}\nProduct Description: ${queryObj.projectValue2}\nNote:write in ${queryObj.language} language\nTone of Voice:${pDes}`,
      temperature: 0.7,  
      max_tokens: 50,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      stop:["."]
     });
   }

}

export default new AIDAContentAIServices();

 