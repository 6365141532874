const { Configuration, OpenAIApi } = require("openai"); 
const configuration = new Configuration({ apiKey: "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" });
const openai = new OpenAIApi(configuration);

class ImageCreatorAIServices {    
 

  // generateimageCreator = (dataObj) => {
  //   console.log(dataObj)
  //   let imagePrompt = `${dataObj.imageDescription}, the image should ${dataObj.imageType}, ${dataObj.imageStyle}, ${dataObj.imageArtist}, ${dataObj.imageMood}`
  //   return openai.createImage({
  //     model: "image-alpha-001",
  //     prompt: imagePrompt,
  //     n: parseInt(dataObj.imageVariant),
  //     size: dataObj.imageSize, 
  //     response_format:"b64_json",
  //   }); 
  //  }

  // generateimageCreator = async (dataObj) => {
  //   console.log(dataObj)
  //   let imagePrompt = `${dataObj.imageDescription}, the image should ${dataObj.imageType}, ${dataObj.imageStyle}, ${dataObj.imageArtist}, ${dataObj.imageMood}`
  //   const response = await openai.createImage({
  //     model: "dall-e-3",
  //     prompt: imagePrompt,
  //     n: parseInt(dataObj.imageVariant),
  //     size: dataObj.imageSize, 
  //   });
  //   console.log(response.data.data[0].url)
  //   return response.data.data;
  // }

  generateimageCreator = async (dataObj) => {
    console.log(dataObj)
    let imagePrompt = `${dataObj.imageDescription}, the image should be ${dataObj.imageType}, ${dataObj.imageStyle}, ${dataObj.imageArtist}, ${dataObj.imageMood}`
    
    const response = await openai.createImage({
      model: "dall-e-2", // Update the model name to the appropriate one for DALL-E 2
      prompt: imagePrompt,
      n: parseInt(dataObj.imageVariant),
      size: dataObj.imageSize, 
    });
  
    console.log(response.data.data[0].url)
    return response.data.data; // Assuming the response structure is the same
  }

  
}

export default new ImageCreatorAIServices();

 