const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({ apiKey: process.env.REACT_APP_OPENAI_API_KEY });
const openai = new OpenAIApi(configuration);

class EssayIntrosAIServices {    
 

  generateEssayIntros = (formDataObj) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Rephrase the following content: ${formDataObj.productFeatures}
      \nKeywords: ${formDataObj.productName}\nNote:write in ${formDataObj.language} language\nTone of Voice:${formDataObj.tone}`, 
      temperature: 0.7,
      max_tokens: 200,
      top_p: 1.0,
      frequency_penalty: 0.0,
      presence_penalty: 0.0,  
      n: 1  
     });
   }

   generateMoreEssayIntros = (queryObj) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Rephrase the following content: ${queryObj.productFeatures}
      \nKeywords: ${queryObj.productName}\nNote:write in ${queryObj.language} language\nTone of Voice:${queryObj.tone}`,
      temperature: 0.7,  
      max_tokens: 200,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,  
      n: 1  
     });
   }

   addMoreEssayIntros = (queryObj, pDes) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Expand the ${queryObj.productFeatures}\nKeywords: ${pDes}`,
      temperature: 0.7,  
      max_tokens: 100,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0
     });
   }

}

export default new EssayIntrosAIServices();

 