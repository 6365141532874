import {React, useState, useEffect, useContext} from "react";
import { Button, Card, Container} from "react-bootstrap"; 
import { Link } from "react-router-dom";
import { useUserAuth } from "../../../context/UserAuthContext";  
import toast, { Toaster } from 'react-hot-toast'; 
import { FcPaid } from "react-icons/fc";
import "./Tools.css"; 
import { YouTubeIcon, TwitterIcon, QuoraIcon, FacebookIcon, LinkedinIcon, AmazonIcon, GoogleAdIcon, CustomerReviewIcon, InstagramIcon, ProductReviewIcon, BlogIcon, BlogIdeaIcon, BlogTitleIcon, BlogIntroIcon, BlogOutlineIcon, BlogConclusionIcon, ParagraphIcon, ContentRewriteIcon, WebsiteHeadingIcon, WebsiteSubHeadingIcon, MetaTitleIcon, MetaDescriptionIcon, AboutUsIcon, FaqsIcon, CallToActionIcon, AidaIcon, EmailIcon, ParaphraseIcon, UniversalIcon, ArticleIcon, BlogSectionIcon, SentenceExpanderIcon, ProsAndConsIcon, ParagraphWriterIcon, ParaphraseIcon1, ParagraphToPointsIcon, CompanyVisionIcon, GrammarCheckerIcon, ValuePropositionIcon, NewsLettersIcon, NameGeneratorIcon, PressReleasesIcon, EssayIntrosIcon, EssayOutlinesIcon, FlexyTemplateIcon, TextSummarizerIcon, StoriesIcon, SocialMediaIcon, ImageCreationIcon, KeywordResearchIcon } from "../../../assets/svg/SvgIcons";
import SidebarPanel from "../sidebar/SidebarPanel"; 

const Tools = () => {
  const { user } = useUserAuth();
  if(user){ localStorage.setItem("user", user.uid)} 
 
  const [selctedItem, setSelctedItem] = useState('all')
  const [allItem, setAllItem] = useState(true) 
  const [showItem, setShowItem] = useState(true) 
  const handleTab =(e)=> {
    if(e.target.title != 'all'){
      setAllItem(false)
    } else(
      setAllItem(true)
    )
    setSelctedItem('');
    setSelctedItem(e.target.title); 
  }

  return (
    <>  
      
      <Container className="main-layout">    
      <div className="header-txt"> 
                    <h2>Tools</h2> 
                    {/* <p className="text-left"><strong>Huge collection of ready to use templates.</strong><br/> 
                    Our AI templates are designed to help you generate high quality content quickly and easily.</p>   */}
                    </div>      
            <div className="">
              <div className="card-sort-btns mb-4">
                  {/* <Button variant="outline-primary" size="sm" className={(selctedItem == 'all' ? "active" : "")} title="all" onClick={handleTab}>All <span className="badge badge-light" title="all">67</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'blog' ? "active" : "")} title="blog" onClick={handleTab}>Blog Content<span className="badge badge-light" title="blog">16</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'website' ? "active" : "")} title="website" onClick={handleTab}>Website & SEO<span className="badge badge-light" title="website">12</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'socialMedia' ? "active" : "")} title="socialMedia" onClick={handleTab}>Social Media<span className="badge badge-light" title="socialMedia">12</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'ads' ? "active" : "")} title="ads" onClick={handleTab}>Marketing<span className="badge badge-light" title="ads">10</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'more' ? "active" : "")} title="more" onClick={handleTab}>More..<span className="badge badge-light" title="more">17</span></Button> */}
                  
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'blog' ? "active" : "")} title="blog" onClick={handleTab}>Blog Tools<span className="badge badge-light" title="blog">16</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'website' ? "active" : "")} title="website" onClick={handleTab}>Social Media Tools<span className="badge badge-light" title="website">23</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'socialMedia' ? "active" : "")} title="socialMedia" onClick={handleTab}>Youtube Tools<span className="badge badge-light" title="socialMedia">8</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'ads' ? "active" : "")} title="ads" onClick={handleTab}>SEO Tools<span className="badge badge-light" title="ads">8</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'ads' ? "active" : "")} title="ads" onClick={handleTab}>Advertising Tools<span className="badge badge-light" title="ads">9</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'ads' ? "active" : "")} title="ads" onClick={handleTab}>Writing Assistant<span className="badge badge-light" title="ads">6</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'ads' ? "active" : "")} title="ads" onClick={handleTab}>Website Content<span className="badge badge-light" title="ads">7</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'ads' ? "active" : "")} title="ads" onClick={handleTab}>E-mail Tools<span className="badge badge-light" title="ads">11</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'ads' ? "active" : "")} title="ads" onClick={handleTab}>E-Commerce Tools<span className="badge badge-light" title="ads">18</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'ads' ? "active" : "")} title="ads" onClick={handleTab}>Copywriting Tools<span className="badge badge-light" title="ads">17</span></Button> 
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'ads' ? "active" : "")} title="ads" onClick={handleTab}>Idea Generation<span className="badge badge-light" title="ads">2</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'ads' ? "active" : "")} title="ads" onClick={handleTab}>Image Prompts<span className="badge badge-light" title="ads">2</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'ads' ? "active" : "")} title="ads" onClick={handleTab}>Re-Purpose Content<span className="badge badge-light" title="ads">4</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'ads' ? "active" : "")} title="ads" onClick={handleTab}>HR Tools<span className="badge badge-light" title="ads">12</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'ads' ? "active" : "")} title="ads" onClick={handleTab}>Support<span className="badge badge-light" title="ads">7</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'ads' ? "active" : "")} title="ads" onClick={handleTab}>Sales<span className="badge badge-light" title="ads">5</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'ads' ? "active" : "")} title="ads" onClick={handleTab}>Film Making Tools<span className="badge badge-light" title="ads">3</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'ads' ? "active" : "")} title="ads" onClick={handleTab}>Music Tools<span className="badge badge-light" title="ads">1</span></Button>
                  <Button variant="outline-primary" size="sm" className={(selctedItem == 'more' ? "active" : "")} title="more" onClick={handleTab}>More..<span className="badge badge-light" title="more">5</span></Button>
               </div>  
          <div className="card-grid-row mt-1">    
 
          {(allItem || selctedItem == 'blog') &&  
                    <div className="secHeading">
                      <h3>Blog</h3>
                    </div>
                    } 
                  {(allItem || selctedItem == 'blog') &&
                   <Card data-category="blog"> 
                     <Link to="/article-writer">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                             <ArticleIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Article Writer</Card.Title>
                            <Card.Text>
                            Write articles in seconds with the most advanced AI writing assistant. 
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>   
                    }
                  {(allItem || selctedItem == 'blog') &&
                   <Card data-category="blog"> 
                     <Link to="/blog-post">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                             <BlogIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Blog Post Writer</Card.Title>
                            <Card.Text>
                            Write a long-form blog post within a minutes using the most advanced AI.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>   
                    }
                      {(allItem || selctedItem == 'blog') &&
                      <Card data-category="blog"> 
                     <Link to="/keyword-research">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon"> 
                             <ParagraphToPointsIcon/>
                             </div>
                             </div>
                              <Card.Body>  
                            <Card.Title>Keyword Research</Card.Title>
                            <Card.Text>
                            Get latest keyword research for improved SEO and increased traffic!
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                    }
                    {(allItem || selctedItem == 'blog') &&
                   <Card data-category="blog"> 
                     <Link to="/blog-ideas">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <BlogIdeaIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Blog Ideas</Card.Title>
                            <Card.Text> 
                            Generate blog ideas using AI for unique and interesting Ideas.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
                    }
                    {(allItem || selctedItem == 'blog') &&
                   <Card data-category="blog"> 
                     <Link to="/blog-title">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <BlogTitleIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Blog Title</Card.Title>
                            <Card.Text>
                            Write the SEO perfect blog title using AI from your inputs and ideas.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
                    }
                    {(allItem || selctedItem == 'blog') &&
                   <Card data-category="blog"> 
                     <Link to="/blog-intro">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <BlogIntroIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Blog Intro</Card.Title>
                            <Card.Text>
                            Write the Blog Intro using AI to make a good impression on your readers.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>   
                    }
                    {(allItem || selctedItem == 'blog') &&
                   <Card data-category="blog"> 
                     <Link to="/blog-outline">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <BlogOutlineIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Blog Outline</Card.Title>
                            <Card.Text>
                             Write an outline for a blog post using AI to engage your readers.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
                    }
                    
                    {(allItem || selctedItem == 'blog') &&
                   <Card data-category="blog"> 
                     <Link to="/blog-section">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <ParagraphIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Blog Section</Card.Title>
                            <Card.Text>
                            Generate a blog section using blog sub-heading and title.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    }
                       {(allItem || selctedItem == 'blog') &&
                   <Card data-category="blog"> 
                     <Link to="/blog-conclusion">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <BlogConclusionIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Blog Conclusion</Card.Title>
                            <Card.Text>
                            Write a conclusion for your blog post using AI to wow your readers.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
                    }
                    {(allItem || selctedItem == 'blog') &&
                   <Card data-category="blog"> 
                     <Link to="/article-rewriter">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <ContentRewriteIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Article Rewriter</Card.Title>
                            <Card.Text>
                            Re-write your existing article into a new version of article using AI.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
                    }
                       {(allItem || selctedItem == 'blog') &&
                   <Card data-category="blog"> 
                     <Link to="/sentence-rewriter">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <ContentRewriteIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Sentence Rewriter</Card.Title>
                            <Card.Text>
                            Rewrite your existing sentence into a new version using AI.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
                    }
                       {(allItem || selctedItem == 'blog') &&
                   <Card data-category="blog"> 
                     <Link to="/sentence-expander">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon"> 
                                <SentenceExpanderIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Sentence Expander</Card.Title>
                            <Card.Text>
                            Expand your existing sentence into a paragraph using AI.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
                    }
                       {(allItem || selctedItem == 'blog') &&
                   <Card data-category="blog"> 
                     <Link to="/pros-cons">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon"> 
                                <ProsAndConsIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Pros & Cons</Card.Title>
                            <Card.Text>
                            Generate pros and cons for your product, service, website, and blog articles.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
                    }  
                    
                    {(allItem || selctedItem == 'blog') &&
                   <Card data-category="blog"> 
                     <Link to="/paragraph-writer">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon"> 
                                <ParagraphWriterIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Paragraph Writer</Card.Title>
                            <Card.Text>
                            Quickly generate entire paragraphs of content on its own.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    }
                     {(allItem || selctedItem == 'blog') &&
                   <Card data-category="blog"> 
                     <Link to="/paragraph-points">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon"> 
                                <ParagraphToPointsIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Paragraph to Points</Card.Title>
                            <Card.Text>
                            Quickly generate entire paragraphs of content on its own.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    }
                      {(allItem || selctedItem == 'blog') &&
                   <Card data-category="more"> 
                     <Link to="/paraphrase">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">  
                                <ParaphraseIcon1/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Paraphrase</Card.Title>
                            <Card.Text>
                             Repharase the meaning of a text or passage using other words.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
                    }
                    {(allItem || selctedItem == 'website') &&  
                    <div className="secHeading">
                      <h3>Website</h3>
                    </div>
                    } 
                    {(allItem || selctedItem == 'website') &&  
                   <Card data-category="website"> 
                     <Link to="/website-headlines">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <WebsiteHeadingIcon /> 
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Website Headlines</Card.Title>
                            <Card.Text>
                            Write an SEO frendly website headlines using AI with more accurately.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
                     }
                     { (allItem || selctedItem == 'website') && 
                   <Card data-category="website"> 
                     <Link to="/website-sub-headline">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <WebsiteSubHeadingIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Website Sub-Headline</Card.Title>
                            <Card.Text>
                            Write a website sub-headlines using AI in order to improve engagement.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                     }
                     { (allItem || selctedItem == 'website') &&    
                   <Card data-category="website"> 
                     <Link to="/meta-title">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <MetaTitleIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Meta Title</Card.Title>
                            <Card.Text> 
                            Generate SEO frendly Meta Title for your blog post and website using AI.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>     
                     }
                     { (allItem || selctedItem == 'website') &&   
                   <Card data-category="website"> 
                     <Link to="/meta-description">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <MetaDescriptionIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Meta Descriptions</Card.Title>
                            <Card.Text>
                            The use of AI in Meta descriptions can help to improve the quality of the page.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>     
                     }
                     { (allItem || selctedItem == 'website') &&   
                   <Card data-category="website"> 
                     <Link to="/faqs">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <FaqsIcon /> 
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>FAQs</Card.Title>
                            <Card.Text>
                            Generate a FAQ using AI from your product and services description.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>     
                     } 
                     { (allItem || selctedItem == 'website') &&   
                   <Card data-category="website"> 
                     <Link to="/about-us">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <AboutUsIcon />
                                </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>About Us</Card.Title>
                            <Card.Text>
                            Write About Us page on our website can be easily completed using AI.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
                     }
                    { (allItem || selctedItem == 'website') &&   
                    <Card data-category="website"> 
                     <Link to="/company-vision">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon"> 
                                <CompanyVisionIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Company Vision</Card.Title>
                            <Card.Text>
                            Align your business values and goals into your vision statement.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                     }
                    { (allItem || selctedItem == 'website') &&   
                    <Card data-category="website"> 
                     <Link to="/company-mission">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <AidaIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Company Mission</Card.Title>
                            <Card.Text>
                            Convert your company core values into a clear mission statement.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                     }
                         { (allItem || selctedItem == 'website') &&   
                   <Card data-category="website"> 
                     <Link to="/call-to-action">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon"> 
                                <CallToActionIcon />
                                </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Call To Action</Card.Title>
                            <Card.Text>
                            Write the call to action using AI for more effective way to communicate.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                     }
                       { (allItem || selctedItem == 'website') &&   
                    <Card data-category="website"> 
                     <Link to="/aida-content">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <AidaIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>AIDA Content</Card.Title>
                            <Card.Text> 
                            Generate a content with Attention, Interest, Desire and Action.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                     }
                          { (allItem || selctedItem == 'website') &&   
                    <Card data-category="website"> 
                     <Link to="/quest-formula">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <AidaIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>QUEST Formula</Card.Title>
                            <Card.Text> 
                            Generate a Qualify, Understand, Educate, Stimulate, and Transition copy.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                     }
                          { (allItem || selctedItem == 'website') &&   
                    <Card data-category="website"> 
                     <Link to="/pain-agitate-solution">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <AidaIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Pain Agitate Solution</Card.Title>
                            <Card.Text>
                            A copywriting formula to explain how to avoid pain to get gain.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                     }
                     {(allItem || selctedItem == 'socialMedia') &&  
                    <div className="secHeading">
                      <h3>Social Media</h3>
                    </div>
                    } 
                    {/* {(allItem || selctedItem == 'socialMedia') &&  
                    <Card data-category="socialMedia"> 
                     <Link to="/">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <YouTubeIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>YouTube Video Topic Ideas</Card.Title>
                            <Card.Text>
                            Generate exceptionally effective copy for your Facebook Ads.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                    }  */}
                    {(allItem || selctedItem == 'socialMedia') &&  
                  <Card data-category="socialMedia"> 
                   <Link to="/youtube-video-title">
                     <div className="card-wrap">
                           <div className="card-icon">
                               <div className="svgIcon">
                              <YouTubeIcon />
                           </div> 
                           </div>
                          <Card.Body> 
                          <Card.Title>YouTube Video Title</Card.Title>
                          <Card.Text>
                          Write a catchy and attention-grabbing title for your video using AI.
                          </Card.Text> 
                         </Card.Body> 
                        </div>
                       </Link>
                  </Card> 
                  }
                    {(allItem || selctedItem == 'socialMedia') &&  
                    <Card data-category="socialMedia"> 
                     <Link to="/youtube-video-description">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <YouTubeIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>YouTube Video Description</Card.Title>
                            <Card.Text>
                            Write an impressive YouTube Video Description using AI support.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                    }
                     {(allItem || selctedItem == 'socialMedia') &&  
                    <Card data-category="socialMedia"> 
                     <Link to="/youtube-video-tags">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <YouTubeIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>YouTube Video Tags</Card.Title>
                            <Card.Text>
                            Create an high-quality Youtube video tags for SEO using AI.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                    }
                     {(allItem || selctedItem == 'socialMedia') &&  
                    <Card data-category="socialMedia"> 
                     <Link to="/youtube-video-script">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <YouTubeIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>YouTube Video Script</Card.Title>
                            <Card.Text>
                            Generate a perfect step-by-step YouTube Video Script using AI.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                    }
                    {(allItem || selctedItem == 'socialMedia') &&  
                    <Card data-category="socialMedia"> 
                     <Link to="/youtube-comments-responder">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <YouTubeIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>YouTube Comments Responder</Card.Title>
                            <Card.Text>
                            Write a response to public comments that are positive and helpful.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                    }
                    {/* {(allItem || selctedItem == 'socialMedia') &&  
                    <Card data-category="socialMedia"> 
                     <Link to="/instagram-post-ideas">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <InstagramIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Instagram Post Ideas</Card.Title>
                            <Card.Text>
                            Generate a engaging Instagram post ideas using AI.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                    } */}
                    {(allItem || selctedItem == 'socialMedia') &&  
                    <Card data-category="socialMedia"> 
                     <Link to="/quora-answers">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <QuoraIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Quora Answers</Card.Title>
                            <Card.Text>
                            Using AI write a perfect Quora answers and get more attention.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                    }
                         {(allItem || selctedItem == 'socialMedia') &&  
                    <Card data-category="socialMedia"> 
                     <Link to="/social-media-post">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon"> 
                                <SocialMediaIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Social Media Post</Card.Title>
                            <Card.Text>
                            Write a social media post of your topic to be published on different platform.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                    }
                         {(allItem || selctedItem == 'socialMedia') &&  
                    <Card data-category="socialMedia"> 
                     <Link to="/instagram-caption">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <InstagramIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Instagram Caption</Card.Title>
                            <Card.Text>
                            Create a perfect attention grabbing Instagram captions.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                    }
                         {(allItem || selctedItem == 'socialMedia') &&  
                    <Card data-category="socialMedia"> 
                     <Link to="/instagram-hashtags">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <InstagramIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Instagram Hastag</Card.Title>
                            <Card.Text>
                            Create a trendy and attention grabbing Instagram hashtags.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                    } 
                       {(allItem || selctedItem == 'socialMedia') &&  
                    <Card data-category="socialMedia"> 
                     <Link to="/linkedin-post">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <LinkedinIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>LinkedIn Post</Card.Title>
                            <Card.Text>
                            Generate the informative and attractive LinkedIn post content
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                    }
                    {/* {(allItem || selctedItem == 'socialMedia') &&  
                    <Card data-category="socialMedia"> 
                     <Link to="/tweet-ideas">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <TwitterIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Tweet Ideas</Card.Title>
                            <Card.Text>
                            Use AI to come up with creative ideas for tweets that will engage your followers.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                    } */}
                    {(allItem || selctedItem == 'socialMedia') &&  
                    <Card data-category="socialMedia"> 
                     <Link to="/twitter-threads">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <TwitterIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Twitter threads</Card.Title>
                            <Card.Text>
                            Create the perfect engaging threads on Twitter about any topic.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                    }
                    {(allItem || selctedItem == 'socialMedia') &&  
                    <Card data-category="socialMedia"> 
                     <Link to="/twitter-generator">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <TwitterIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Twitter generator</Card.Title>
                            <Card.Text>
                            Generate informative and impressive tweets that will engage your followers.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                    }
                     {(allItem || selctedItem == 'ads') &&  
                    <div className="secHeading">
                      <h3>Marketing</h3>
                    </div>
                    }
                     {(allItem || selctedItem == 'ads') &&   
                    <Card data-category="ads"> 
                     <Link to="/facebook-ad">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <FacebookIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Facebook Ad</Card.Title>
                            <Card.Text>
                            Write a Facebook Ad using AI can help you create targeted ads for a customer.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                    }
                    {(allItem || selctedItem == 'ads') &&  
                    <Card data-category="ads"> 
                     <Link to="/google-ad">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <GoogleAdIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Google Ad</Card.Title>
                            <Card.Text>
                            Write a Google Ad using AI can help you to reach targeted ideal customers.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                    }
                          {(allItem || selctedItem == 'ads') &&  
                    <Card data-category="ads"> 
                     <Link to="/linkedin-ad">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <LinkedinIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>LinkedIn Ad</Card.Title>
                            <Card.Text>
                            Write a LinkedIn ad that uses AI to target a specific audience & business.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                    } 
                     {(allItem || selctedItem == 'ads') &&
                    <Card data-category="ecommerce"> 
                     <Link to="/product-description">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <FcPaid />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Product Description</Card.Title>
                            <Card.Text>
                            Write the product description which gives the reason to buy your product
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>       
                  }
                    {(allItem || selctedItem == 'ads') &&  
                    <Card data-category="ecommerce"> 
                     <Link to="/amazon-product-description">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <AmazonIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Amazon Product Description</Card.Title>
                            <Card.Text>
                            Write an amazing Product description using AI technolgoy.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
 }
 {(allItem || selctedItem == 'ads') &&  
                    <Card data-category="ecommerce"> 
                     <Link to="/amazon-product-bullet-points">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <AmazonIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Amazon Product Bullet Points</Card.Title>
                            <Card.Text>
                             Generate a product bullet points form product description using AI.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                     }
                     {(allItem || selctedItem == 'ads') &&  
                    <Card data-category="ecommerce"> 
                     <Link to="/amazon-ad-headline">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <AmazonIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Amazon Ad Headline</Card.Title>
                            <Card.Text>
                            Write an catchy Amazon AD headline using AI to grab the attention.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                     }
                     {(allItem || selctedItem == 'ads') &&  
                    <Card data-category="ecommerce"> 
                     <Link to="/amazon-product-title">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <AmazonIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Amazon Product Title</Card.Title>
                            <Card.Text>
                            Write an effective Amazon product title using AI to catch more eyes. 
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                     }
                     { (allItem || selctedItem == 'ads') &&   
                   <Card data-category="website"> 
                     <Link to="/product-review">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <ProductReviewIcon />
                                </div>
                                </div>
                              <Card.Body> 
                            <Card.Title>Product Review</Card.Title>
                            <Card.Text>
                            Generate a product review with certain information about the product.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
                     } 
{ (allItem || selctedItem == 'ads') &&   
                   <Card data-category="website"> 
                     <Link to="/customer-review-response">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <CustomerReviewIcon />
                            </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Customer Review Response</Card.Title>
                            <Card.Text>
                            A customer review response using AI can help you save time by automatically.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
                     }   
                    {
                    // (allItem || selctedItem == 'ecommerce') &&  
                    // <div className="secHeading">
                    //   <h3>E-commerce</h3>
                    // </div>
                    }
                   
                       {(allItem || selctedItem == 'more') &&  
                    <div className="secHeading">
                      <h3>More</h3>
                    </div>
                    }

                  {(allItem || selctedItem == 'more') &&
                   <Card data-category="more"> 
                     <Link to="/image-generator">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon"> 
                             <ImageCreationIcon/>
                             </div>
                             </div>
                              <Card.Body>  
                              <span className="newFeature">New</span>
                            <Card.Title>Image Generator</Card.Title>
                            <Card.Text>
                            Generate high-quality image for art, design, and advertising needs.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>   
                    }

                     {(allItem || selctedItem == 'more') &&  
                    <Card data-category="more"> 
                     <Link to="/cold-email">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <EmailIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Cold Email</Card.Title>
                            <Card.Text>
                            Generate an focused and attractive email to get more attention.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                     }
                          {(allItem || selctedItem == 'more') &&  
                    <Card data-category="more"> 
                     <Link to="/welcome-email">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <EmailIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Welcome Email</Card.Title>
                            <Card.Text>
                            Generate an impressive welcome email for your new customers.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                     }
                                  {(allItem || selctedItem == 'more') &&  
                    <Card data-category="more"> 
                     <Link to="/cancellation-email">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <EmailIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Cancellation Email</Card.Title>
                            <Card.Text>
                            Generate an impactful email to re-engage inactive customers.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                     }
                                                         {(allItem || selctedItem == 'more') &&  
                    <Card data-category="more"> 
                     <Link to="/follow-up-email">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <EmailIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Follow-Up Email</Card.Title>
                            <Card.Text>
                            Generate unique and effective follow-up email draft.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                     }
                                          {(allItem || selctedItem == 'more') &&  
                    <Card data-category="more"> 
                     <Link to="/email-subject-line">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <EmailIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Email Subject Line</Card.Title>
                            <Card.Text>
                            Generate exceptionally and impactful email subject lines.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                     }

{(allItem || selctedItem == 'more') &&  
                    <Card data-category="more"> 
                     <Link to="/thank-you-note">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <EmailIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Thank You Note</Card.Title>
                            <Card.Text>
                            Generate a professional thank you note for your personal or business.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                     }
                          
{(allItem || selctedItem == 'more') &&  
                    <Card data-category="more"> 
                     <Link to="/grammar-checker">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon"> 
                               <GrammarCheckerIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Grammar Checker</Card.Title>
                            <Card.Text>
                            Instantly corrects grammar, spelling, and other writing errors in your text.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                     }
                     
{(allItem || selctedItem == 'more') &&  
                    <Card data-category="more"> 
                     <Link to="/text-summarizer">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <TextSummarizerIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Text Summarizer (TL;DR)</Card.Title>
                            <Card.Text>
                            Summmarize the paragraph into short and easy to understand text.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                     }
                     
{(allItem || selctedItem == 'more') &&  
                    <Card data-category="more"> 
                     <Link to="/story-creator">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <StoriesIcon/> 
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Story Creator</Card.Title>
                            <Card.Text>
                            Create your own creative story using the AI technology
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                     }
                      {(allItem || selctedItem == 'more') &&   
                    <Card data-category="ads"> 
                     <Link to="/value-proposition">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <ValuePropositionIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Value Proposition</Card.Title>
                            <Card.Text>
                            Create impressive one-line sentence to your target customers. 
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                    }
                       {(allItem || selctedItem == 'more') &&   
                    <Card data-category="ads"> 
                     <Link to="/newsletters">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <NewsLettersIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Newsletters</Card.Title>
                            <Card.Text>
                            Generate informative and engaging company Newsletter using AI assitance.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                    }
                       {(allItem || selctedItem == 'more') &&   
                    <Card data-category="ads"> 
                     <Link to="/name-generator">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <NameGeneratorIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Name Generator</Card.Title>
                            <Card.Text>
                            Generate creative, trendy and catchy names for every thing.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                    }
                       {(allItem || selctedItem == 'more') &&   
                    <Card data-category="ads"> 
                     <Link to="/press-releases">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <PressReleasesIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Press Releases</Card.Title>
                            <Card.Text>
                            Write a press release about your product or service announcments to media.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                    }
                      {(allItem || selctedItem == 'more') &&  
                    <Card data-category="more"> 
                     <Link to="/essay-intros">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <EssayIntrosIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Essay Intros</Card.Title>
                            <Card.Text>
                             Write best informative introduction of your essay idea.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>
                    }
                    {(allItem || selctedItem == 'more') && 
                    <Card data-category="more"> 
                     <Link to="/essay-outlines">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <EssayOutlinesIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Essay Outlines</Card.Title>
                            <Card.Text>
                            Generate the structure or talking points of your essay.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>
                    }
                                    {(allItem || selctedItem == 'more') &&
                   <Card data-category="blog"> 
                     <Link to="/flexy-template">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <FlexyTemplateIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Flexy Template</Card.Title>
                            <Card.Text>
                            Design your template to generate uniqe content using the most advanced AI.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>   
                    }
{/*                      
                     {(allItem || selctedItem == 'ecommerce') &&  
                    <Card data-category="ecommerce"> 
                     <Link to="/">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <FcFeedback />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Welcome Email</Card.Title>
                            <Card.Text>
                            Generate exceptionally effective copy for your Facebook Ads.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                     }
                     {(allItem || selctedItem == 'ecommerce') &&  
                    <Card data-category="ecommerce"> 
                     <Link to="/">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <FcFeedback />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Email Subject Lines</Card.Title>
                            <Card.Text>
                            Generate exceptionally effective copy for your Facebook Ads.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                     }
                     {(allItem || selctedItem == 'ecommerce') &&  
                    <Card data-category="ecommerce"> 
                     <Link to="/">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <FcFeedback />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Follow-Up Email</Card.Title>
                            <Card.Text>
                            Generate exceptionally effective copy for your Facebook Ads.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>
                     }
                     {(allItem || selctedItem == 'ecommerce') &&  
                    <Card data-category="ecommerce"> 
                     <Link to="/">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <FcFeedback />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Confirmation Email</Card.Title>
                            <Card.Text>
                            Generate exceptionally effective copy for your Facebook Ads.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>
                     }
                     {(allItem || selctedItem == 'ecommerce') &&  
                    <Card data-category="ecommerce"> 
                     <Link to="/">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <FcFeedback />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Cancellation Email</Card.Title>
                            <Card.Text>
                            Generate exceptionally effective copy for your Facebook Ads.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>
                     }
                   
                      {(allItem || selctedItem == 'more') && 
                    <Card data-category="more"> 
                     <Link to="/">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <FcDocument />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Rephrase Sentence</Card.Title>
                            <Card.Text>
                            Generate exceptionally effective copy for your Facebook Ads.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>
                    }
                     {(allItem || selctedItem == 'more') && 
                    <Card data-category="more"> 
                     <Link to="/">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <FcDocument />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Shorten Paragraph</Card.Title>
                            <Card.Text>
                            Generate exceptionally effective copy for your Facebook Ads.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>
                    }
                        {(allItem || selctedItem == 'more') && 
                    <Card data-category="more"> 
                     <Link to="/">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <FcDocument />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Lengthen Paragraph</Card.Title>
                            <Card.Text>
                            Sentences can be expanded into long paragraph that include more details
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>
                    }
                    {(allItem || selctedItem == 'more') && 
                    <Card data-category="more"> 
                     <Link to="/">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <FcDocument />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Job Ad</Card.Title>
                            <Card.Text>
                            Quickly create a job ad based on some basic details, like title, requirements, etc. 
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>
                    }
                    {(allItem || selctedItem == 'more') && 
                    <Card data-category="more"> 
                     <Link to="/">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <FcDocument />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Summarize Points</Card.Title>
                            <Card.Text>
                            Analyze your text or documents and convey the important concepts in bullet form.
                           
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>
                    } */}
                     {/* https://www.youtube.com/watch?v=UBG_YiTg_Bs&ab_channel=AdrianTwarog */}
             </div>
          </div>
        </Container>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default Tools;