import {React, useState, useEffect, useContext, useRef} from "react";
import { Badge, Button, Nav, NavDropdown, Card, Tabs, Tab, TabContent, TabContainer, CardBody, CardImg, FormGroup, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useUserAuth } from "../../../context/UserAuthContext";   
import toast, { Toaster } from 'react-hot-toast'; 
import { RiDeleteBinLine, RiFileEditLine} from "react-icons/ri";
import ProjectDataService from "../../tools/blog-post/services/blogPost.services";  
import Loader from "../../shared/utilities/loader/Loader"; 
import "./Projects.css";     
import QuickTemplateServices from "../../tools/quick-template/services/QuickTemplate.services"; 
import AIToolsDataService from "../../tools/ai-tools/services/AiTools.services"; 
import articlePostServices from "../../tools/articlePost/services/articlePost.services";
import ProjectsServices from "./services/Projects.services";
import SidebarPanel from "../sidebar/SidebarPanel";
import Loader2 from "../../shared/utilities/loader2/Loader2";

const MyContent = () => { 
  const { user } = useUserAuth();
  //if(user){ localStorage.setItem("user", user.uid)} 

  const navigate = useNavigate(); 
  const [allCategories, setAllCategories] = useState([
    "chat","blogPost", "blogTitles", "blogIntro", "blogOuline", "blogSection", "blogConclusion", "articleRewriter", "sentenceRewriter",
    'sentenceExpander', "prosCons", "paragraphWriter", "paragraphPoints", "paraphrase",
    'websiteHeadlines', "websiteSubheadlines", "metaTitle", "metaDescription", "faqs", "aboutUs", "companyVision", "companyMission", "callToAction",
    "aidaContent", "questFormula", "painAgitateSolution", 
    "youtubeVideoTitle", "youtubeVideoDescription", "youtubeVideoTags", "youtubeVideoScript", "youtubeCommentsResponder", "quoraAnswers",
    "socialMediaPost", "instagramCaption", "instagramHashtags", "linkedInPost", "twitterThreads", "twitterGenerator",
    "facebookAd", "googleAd", "linkedinAd", "productDescription", "amazonProductDescription", "amazonProductBulletPoints",
    "amazonAdHeadline", "amazonProductTitle", "productReview", "customerReviewResponse", 
    "coldEmail", "welcomeEmail", "cancellationEmail", "followUpEmail", "emailSubjectLine", "thankYouNote",
    "grammarChecker", "textSummarizer", "storyCreator", "valueProposition", "newsletters", "nameGenerator", "pressReleases", "essayIntros",
    "essayOutlines"   
  ])
  const [category, setCategory] = useState([]);
  const [message, setMessage] = useState(null)
  const [projects, setProjects] = useState([])   

  const [myTemplates, setMyTemplates] = useState([])  
  const [toolsContent, setToolsContent] = useState([])  
  
  const [article, setArticle] = useState([]) 

  const [loading, setLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);
  const [noRecords, setNoRecords] = useState(false);
  
  const [projectData, setProjectData] = useState() 
  
  const [navPath, setNavPath] = useState() 
  const [pathName, setPathName] = useState()  
  const [activeItem, setActiveItem] = useState(null) 
  const [defaultActive, setDefaultActive] = useState(null) 
  

  useEffect(() => {   
     getMyTemplates(); 
     getToolsContent();
     getArticle();  
     getCategory();   
  }, []); 

  useEffect(() => { 
    if(category.length > 0){  
      let item = category[0].name;  
      setDefaultActive(item) 
      handleSelect(item)
    }   
  }, [category]); 

    const getCategory = () => {
      setMainLoading(true)
      allCategories.forEach (async (item) => {
        try{ 
          await ProjectsServices.getCategory(user.uid, item).then((data) => {  
             let length = data.docs.length; 
          
            var text = item;
            var result = text.replace( /([A-Z])/g, " $1" );
            var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
           // console.log(finalResult)
             let obj = {name: item, count:length, title: finalResult}
             if(length){ 
              setCategory(category => [...category, obj]);
             } 
             setMainLoading(false)                 
          })  
          } catch (err) { 
            setMainLoading(false)
            console.log(err.message); 
          }
      });    
     // handleSelect(category[0].name);  
    }

const handleSelect = (async (eventKey) => {
  let item = eventKey;
  setLoading(true);
 //console.log(item)
  setActiveItem(item)
  setPathName(item);
  let newArry = []
  //alert(`selected ${eventKey}`);
  try{ 
    await ProjectsServices.getCategory(user.uid, item).then((data) => {  
       if(data.docs.length > 0){ 
        data.docs.map(doc => {  
          let newObj = doc.data();
           newArry.push(newObj)
        //   console.log(doc.data())
        //  console.log(doc.data().type) 

           let nav = doc.data().type;
           if(nav == "blog_post_builder"){nav = "blog-post"}
           else if(nav == "blog_titles"){nav = "blog-title"}
           nav = nav.replace(/_/g, "-");
           nav = nav.replace(/ /g, "-");
          //  console.log(nav.toLowerCase())
           doc.data().type = nav.toLowerCase();
         //  console.log(doc.data())
           setNavPath(nav.toLowerCase()); 
      })}
    }) 
    setProjectData(newArry)
    setLoading(false);
    } catch (err) { 
      console.log(err.message); 
      setLoading(false);
    }
})
const deleteItem = async (e) => {
  let projId = e.currentTarget.id;  
  let obj = {uid: user.uid, prjID: projId, name: pathName}
 // console.log(obj)
  await ProjectsServices.deleteProjectContent(obj).then(() => {
    setProjectData(projectData.filter(item => item.id !== projId)); 
    toast.success('Content deleted!') 
  })   
} 
//Flexy Templates
const getMyTemplates = async () => {
  setNoRecords(false)
  let newArry = []
  setLoading(true);
  try{ 
    await QuickTemplateServices.getAllProjects(user.uid).then((data) => { 
      //console.log(data.docs);
          if(data.docs.length > 0){ 
              data.docs.map(doc => { 
                let newObj = doc.data();
                newArry.push(newObj)
                // console.log(doc.data().type)
                setLoading(false);
            })
          } else { 
                setLoading(false);
                setNoRecords(true)
          }
      }); 
      setMyTemplates(newArry)   
    } catch (err) { 
    console.log(err.message); 
  }
}
const deleteMyTemplate = async (e) => {
  let projId = e.currentTarget.id;  
  await QuickTemplateServices.deleteProjectContent(user.uid, projId).then(() => {
    setMyTemplates(myTemplates.filter(item => item.id !== projId)); 
    toast.success('Content deleted!') 
  })   
}  
const [lastDoc, setLastDoc] = useState(null);
useEffect(() => {
  getToolsContent();
}, []);
//Flexy Templates
const getToolsContent = async () => {
  setLoading(true);
  try {
    const data = await AIToolsDataService.getAllProjects(user.uid, lastDoc);
   // console.log("Fetched data:", data.docs); // Log the fetched data
    const documents = data.docs;
    if (documents.length > 0) {
      const newContent = documents.map(doc => ({ id: doc.id, ...doc.data() }));
      setToolsContent([...toolsContent, ...newContent]);
      setLastDoc(documents[documents.length - 1]);
    } else {
      setNoRecords(true);
    }
    setLoading(false);
  } catch (err) {
    console.error("Error fetching data:", err.message); // Log any errors
    setLoading(false);
  }
};
// const getToolsContent = async () => {
//   setNoRecords(false)
//   let newArry = []
//   setLoading(true);
//   try{ 
//     await AIToolsDataService.getAllProjects(user.uid).then((data) => { 
//       //console.log(data.docs);
//           if(data.docs.length > 0){ 
//               data.docs.map(doc => { 
//                 let newObj = doc.data();
//                 newArry.push(newObj)
//                 // console.log(doc.data().type)
//                 setLoading(false);
//             })
//           } else { 
//                 setLoading(false);
//                 setNoRecords(true)
//           }
//       }); 
//       setToolsContent(newArry)   
//     } catch (err) { 
//     console.log(err.message); 
//   }
// }
const deleteToolsContent = async (e) => {
  let projId = e.currentTarget.id;  
  await AIToolsDataService.deleteProjectContent(user.uid, projId).then(() => {
    setToolsContent(toolsContent.filter(item => item.id !== projId)); 
    toast.success('Content deleted!') 
  })   
}  
const getArticle = async () => {
    setNoRecords(false)
    let newArry = []
    setLoading(true);
    try{ 
      await articlePostServices.getAllProjects(user.uid).then((data) => { 
        //console.log(data.docs);
            if(data.docs.length > 0){ 
                data.docs.map(doc => { 
                  let newObj = doc.data();
                  newArry.push(newObj)
                 // console.log(doc.data().type)
                  setLoading(false);
              })
            } else { 
                  setLoading(false);
                  setNoRecords(true)
            }
        }); 
        setArticle(newArry)   
      } catch (err) { 
      console.log(err.message); 
    }
  }
  const deleteArticle = async (e) => {
    let projId = e.currentTarget.id;  
    await articlePostServices.deleteProjectContent(user.uid, projId).then(() => {
      setArticle(article.filter(item => item.id !== projId)); 
      toast.success('Content deleted!') 
    })   
  } 

  return (
    <>   
      
      <Container className="main-layout">  
                <div className="header-txt text-center"> 
                    <h2>My Content</h2>  
               </div> 
               {/* {(!category.length > 0) && (!article.length > 0) && (!myTemplates.length > 0) &&  <> <div className="noResults"><h6>No saved content found!!  </h6></div>  </>}  */}
            <div className="blog-post-projects">   
                  
                    <Tab.Container className="projectTabContainer" defaultActiveKey={defaultActive}>
                      <Row>
                        <Col sm={3} className="tabButtons sideBar">  
                        <Card> 
                          <Card.Header>
                            <h3>Category</h3>
                          </Card.Header> 
                         <Card.Body> 
                         {mainLoading && <div className="project-content-loader"> <Loader2 /> </div>  }
                          <Nav variant="pills" className="flex-column project-nav" onSelect={handleSelect}> 
                          {toolsContent.length >= 1 &&
                            <Nav.Item>
                              <Nav.Link eventKey="tools-content">Tools Content <b className="float-right">{toolsContent.length}</b></Nav.Link>
                            </Nav.Item>} 
                          {myTemplates.length >= 1 &&
                            <Nav.Item>
                              <Nav.Link eventKey="quick-template">My Templates <b className="float-right">{myTemplates.length}</b></Nav.Link>
                            </Nav.Item>} 
                            {article.length >= 1 &&
                            <Nav.Item>
                              <Nav.Link eventKey="article-writer">Article Writer <b className="float-right">{article.length}</b></Nav.Link>
                            </Nav.Item>}
                            {/* <pre>{ JSON.stringify(category, undefined, 2)}</pre>  */}
                            {category.map( (item, index) => { 
                              return (
                            <Nav.Item>
                              <Nav.Link eventKey={item.name}>{item.title} <b className="float-right">{item.count}</b></Nav.Link>
                            </Nav.Item>
                            )})}
                          </Nav>
                         </Card.Body>  
                          </Card>  
                        </Col>
                        <Col sm={9}>
                        <Card className="tableContent">
                         <Card.Body>  
                          <Tab.Content className="tabContainer">  
                        {/* <pre>{ JSON.stringify(toolsContent, undefined, 2)}</pre>   */}
                          {!loading && <>

                            {toolsContent &&
                            <Tab.Pane eventKey="tools-content">   
                                      <table className="table tableFixHead table-hover myTemplates">
                                      <thead> 
                                      <tr> 
                                        <th width="25%">Name</th> 
                                        <th width="35%">Content</th> 
                                        <th width="18%">Date</th>
                                        <th width="20%">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody> 
                                    {!loading && toolsContent && toolsContent.map( (project, index) => {
                                    if(project.type == 'tools-content'){
                                    return (                      
                                      <tr key={project.id}> 
                                      <td>{project.title}</td>  
                                      <td>
                                        {/* Wrapping <p> tag inside <span> might not be semantically correct. 
                                             It's better to use either <span> or <p>. */}
                                        <div className="textTruncate" dangerouslySetInnerHTML={{ __html: project.text }}></div>
                                      </td>
                                      <td>{new Date(project.creationDate * 1000).toDateString()}</td>
                                      <td>
                                        <span className="d-flex">
                                          <Button variant="outline-secondary" size="sm" id={project.id} 
                                                  onClick={(e) => {navigate(`/ai-tools/?prompt=${project.prompt}&id=${project.id}`); }}>
                                            <RiFileEditLine/> Edit
                                          </Button>
                                          <Button variant="outline-secondary" size="sm" id={project.id} 
                                                  onClick={deleteToolsContent}>
                                            <RiDeleteBinLine/> Delete
                                          </Button>
                                        </span>
                                      </td>
                                    </tr>
                                        )}                                    
                                    })} 
                                    {!loading && toolsContent.length == 0 && 
                                            <tr className="noResults">
                                              <td colSpan="4"> <span>No content found!.</span></td>
                                            </tr>
                                      }
                                    </tbody> 
                               </table>  
                               {toolsContent.length > 0 && (
            <button onClick={getToolsContent} className="load-more-btn btn btn-outline-primary btn-sm" disabled={loading || noRecords}>
              Load More
            </button>
          )}
                            </Tab.Pane>}

                          {myTemplates &&
                            <Tab.Pane eventKey="quick-template">   
                                      <table className="table tableFixHead table-hover myTemplates">
                                      <thead> 
                                      <tr> 
                                        <th width="40%">Name</th>  
                                        <th width="30%">Date</th>
                                        <th width="30%">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody> 
                                    {!loading && myTemplates && myTemplates.map( (project, index) => {
                                    if(project.type == 'quick-template'){
                                    return (                      
                                      <tr key={project.id}> 
                                        <td>{project.title}</td>  
                                        <td>{new Date(project.creationDate * 1000).toDateString()}</td>
                                        <td><Button variant="outline-secondary" size="sm" id={project.id} onClick={(e) => {navigate(`/flexy-template/?id=${project.id}`); } }><RiFileEditLine/> Edit</Button> 
                                        <Button variant="outline-secondary" size="sm" id={project.id} onClick={deleteMyTemplate}><RiDeleteBinLine/> Delete</Button></td>
                                      </tr> 
                                        )}                                    
                                    })} 
                                    {!loading && myTemplates.length == 0 && 
                                            <tr className="noResults">
                                              <td colSpan="4"> <span>No content found!.</span></td>
                                            </tr>
                                      }
                                    </tbody> 
                               </table>  
                            </Tab.Pane>}

                            {article &&
                            <Tab.Pane eventKey="article-writer"> 
                                      <table className="table tableFixHead table-hover myTemplates">
                                      <thead> 
                                      <tr> 
                                        <th width="35%">Title</th>  
                                        <th width="20%">Word Count</th>
                                        <th width="20%">Date</th>
                                        <th width="25%">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody> 
                                    {!loading && article && article.map( (project, index) => {
                                    if(project.type == 'article-writer'){
                                    return (                      
                                      <tr key={project.id}> 
                                        <td>{project.title}</td>  
                                        <td>{project.wordCount}</td>  
                                        <td>{new Date(project.creationDate * 1000).toDateString()}</td>
                                        <td><Button variant="outline-secondary" size="sm" id={project.id} onClick={(e) => {navigate(`/article-writer/?id=${project.id}`); } }><RiFileEditLine/> Edit</Button> 
                                        <Button variant="outline-secondary" size="sm" id={project.id} onClick={deleteArticle}><RiDeleteBinLine/> Delete</Button></td>
                                      </tr> 
                                        )}                                    
                                    })} 
                                    {!loading && article.length == 0 && 
                                            <tr className="noResults">
                                              <td colSpan="4"> <span>No content found!.</span></td>
                                            </tr>
                                      }
                                    </tbody> 
                               </table>  
                            </Tab.Pane>}   
                            {/* <pre>{ JSON.stringify(projectData, undefined, 2)}</pre>    */}
                                  <Tab.Pane eventKey={activeItem}> 
                                  {projectData && projectData.length > 0 &&  
                                                    <table className="table tableFixHead table-hover">
                                                    <thead> 
                                                    <tr> 
                                                      <th width="55%">Topic</th> 
                                                      {/* <th width="20%">Saved Content</th> */}
                                                      <th width="20%">Date</th>
                                                      <th width="25%">Actions</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody> 
                                                  {projectData.map( (project, index) => { 
                                                  return (                      
                                                    <tr key={project.id}> 
                                                    <td> {project.type != "chat"
                                                               ? project.title
                                                             : (project.text).substring(0, 40) + "..."}</td> 
                                                      {/* {project.type} */}
                                                      {/* <td>{project.files}</td>  */}
                                                      <td>{new Date(project.creationDate * 1000).toDateString()}</td>
                                                      <td><Button variant="outline-secondary" size="sm" id={project.id} onClick={(e) => {navigate(`/${navPath}/${e.currentTarget.id}`); } }><RiFileEditLine/> Edit</Button> 
                                                      <Button variant="outline-secondary" size="sm" id={project.id} onClick={deleteItem}><RiDeleteBinLine/> Delete</Button>
                                                      </td> 
                                                    </tr> 
                                                      )                                    
                                                  })} 
                                                  {!loading && projectData.length == 0 && 
                                                          <tr className="noResults">
                                                            <td colSpan="4"> <span>No content found!.</span></td>
                                                          </tr>
                                                    }
                                                  </tbody> 
                                              </table>  
                                               } 
                                          </Tab.Pane> 
                                          
                                          </>}
                                          {loading && <>  <div className="project-content-loader"> <Loader2 /> </div>  </>}  
                          </Tab.Content>
                          </Card.Body>
                         </Card>
                        </Col>
                      </Row> 
                    </Tab.Container> 
                    <p>{message && message.msg}</p> 
                   
                 
                  
          </div>
        </Container>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default MyContent;