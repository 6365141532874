const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({ apiKey: process.env.REACT_APP_OPENAI_API_KEY });
const openai = new OpenAIApi(configuration);

class BlogPostAIServices {    

  generateBlogIdeas = (formDataObj) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Generate 6 long blog post titles:\nPost About: ${formDataObj.blogIdea}
      \nKeywords: ${formDataObj.blogKeywords}
      \nNote:write in ${formDataObj.language} language
      \nYear: 2022`, 
      temperature: 0.8,
      max_tokens: 100,
      top_p: 1.0,
      frequency_penalty: 0.52,
      presence_penalty: 0.5,
      logprobs:1,
      stop: ["7."],
     });
   }
   reGenerateBlogTitles = (queryData, blogIdeaValue) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Generate 6 long blog post titles:\nPost About:${blogIdeaValue}
      \nKeywords: ${queryData.blogKeywords}
      \nNote:write in ${queryData.language} language
      \nYear: 2022
      \nCharacters: 50-60`,
      temperature: 0.8,
      max_tokens: 100,
      top_p: 1.0,
      frequency_penalty: 0.52,
      presence_penalty: 0.5,
      stop: ["7."],
      logprobs:1,
     });
   }
   generateBlogIntro = (queryData, blogTitle) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt:`Generate the blog introduction in to a detailed professional, witty and clever explanation for the following details:
      Blog Title: ${blogTitle} 
      \nKeywords: ${queryData.blogKeywords}
      \nNote:write in ${queryData.language} language`,
      temperature:0.8,
      max_tokens:100,
      top_p:1,
      frequency_penalty:0,
      presence_penalty:0, 
      n: 3 
     });
   }
   reGenerateBlogIntro = (queryData, blogTitle) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Write a brief blog post introduction for ${blogTitle}
      \nKeywords: ${queryData.blogKeywords}
      \nNote:write in ${queryData.language} language`,
      // temperature: 0.75,  
      // max_tokens: 64,  
      // frequency_penalty: 0,
      // presence_penalty: 0,  
      temperature: 0.8,
      max_tokens: 64, 
      frequency_penalty: 0.0,
      presence_penalty: 0.0,
      n: 3,
     });
   }

   generateBlogOutline = (queryData, blogTitle) => {
    return openai.createCompletion("text-davinci-002", {  
       prompt: `Generate the blog outline for topic:\n${blogTitle}
       \nKeywords: ${queryData.blogKeywords}
       \nNote:write in ${queryData.language} language`,
        // temperature: 0.6,
        // max_tokens: 64,
        temperature: 0.8,
        max_tokens: 100,
        top_p: 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0,
        n:1
     });
   }

   reGenerateBlogOutline = (queryData, blogTitle) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Generate the blog outline for topic:\n${blogTitle}
      \nKeywords: ${queryData.blogKeywords}
      \nNote:write in ${queryData.language} language`, 
       temperature: 0.8,
       max_tokens: 100,
       top_p: 1.0,
       frequency_penalty: 0.0,
       presence_penalty: 0.0,
       n:1
     });
   }

   generateAllBlogSections = (blogPost) => { 
    //console.log(blogPost)
    let sectionData = [];
    let response;
    
      blogPost.sections.map((item, index) => { 
        //console.log(blogPost.sections[index].secTitle)
        response = openai.createCompletion("text-davinci-002", {  
            prompt: `Post Title: ${blogPost.title}, Generate the section content for Sub-heading: ${blogPost.sections[index].secTitle}`, 
            temperature: 0.8,
            max_tokens:100,
            top_p: 1.0,
            frequency_penalty: 0.0,
            presence_penalty: 0.0,
            n:1
        });
        // let val = response.then(res => res.data.choices[index].text)
          //console.log(response)
          sectionData.push(response) 
       // sectionData.push({sectionTitle:blogPost.sections[index], sectionText: val})
      })
  
      // //console.log(sectionData)
      //  //console.log(response.then(res => {//console.log(res.data.choices[0].text)}))
      if(sectionData.length > 1){
        //console.log(sectionData) 
      } 
      
      return Promise.all(sectionData);
   }

   generateBlogSections = (queryData, postObj) => { 
    //console.log(queryData)
    return openai.createCompletion("text-davinci-002", { 
      // prompt:`Generate the blog post section content from the following details:
      //         \nBlog post section heading: ${postObj.postSectionTitle}
      //         \nKeywords: ${queryData.blogKeywords}
      //         \nNote:write in ${queryData.language} language`,
      //         temperature:0.8,
              prompt: `Post Title: ${postObj.postTitle}, Generate the section content for Sub-heading: ${postObj.postSectionTitle}`, 
              max_tokens:150,
              top_p:1,
              frequency_penalty:0,
              presence_penalty:0,
              n:1,
              // prompt: `Generate detailed content for subsection based on these notes:\n
              // Blog Title: ${blogPost.title}\n
              // Blog Sub Heading: ${blogPost.sections[index].secTitle}`,
              // // temperature: 0.6,  
              // // max_tokens: 64,
              // temperature: 0.3,
              // max_tokens: 64,
              // top_p: 1.0,
              // frequency_penalty: 0.0,
              // presence_penalty: 0.0,   
              // n:1,
              // frequency_penalty: 0,
              // presence_penalty: 0,
     });
   }

   reGenerateSectionContent = (postTitle, secTitle) => {
     //console.log(postTitle)
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Post Title: ${postTitle}, Generate the section content for Sub-heading: ${secTitle}`,
      temperature: 0.7, 
      max_tokens: 150,
      top_p: 1.0,
      frequency_penalty: 0,
      presence_penalty: 0,
     });
   }
   
   writeMoreSectionContent = (secTitle, secDesc) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Expand the blog post section content: ${secDesc}`, 
      temperature: 0.7,
      max_tokens: 100,  
      top_p: 1.0,
      frequency_penalty: 0,
      presence_penalty: 0,
     });
   }
   reGenerateIntroContent = (postTitle) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Write a detailed blog post introduction for ${postTitle}`,
      // temperature: 0.75,  
      // max_tokens: 64,  
      // frequency_penalty: 0,
      // presence_penalty: 0,  
      temperature: 0.8,
      max_tokens: 64, 
      frequency_penalty: 0.0,
      presence_penalty: 0.0 
     });
   }

   generateNewIntro = (postIntro) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Expand blog post intro for ${postIntro}`,      
      // temperature: 0.75,  
      // max_tokens: 64,  
      // frequency_penalty: 0,
      // presence_penalty: 0,  
      temperature: 0.8,
      max_tokens: 64, 
      frequency_penalty: 0.0,
      presence_penalty: 0.0 
     });
   }
   

}

export default new BlogPostAIServices();

 