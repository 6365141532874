import {React, useState, useEffect, useContext, useCallback, useRef} from "react";
import { useQuill } from "react-quilljs";
import { createClient } from 'pexels';
import { CopyToClipboard } from "react-copy-to-clipboard"; 
import { Button, Navbar, Nav, Dropdown, Popover, Card, Form, Container, Row, Col, Alert, Tab, Tabs, ModalTitle, OverlayTrigger, Tooltip } from "react-bootstrap"; 
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useNavigate } from "react-router";
import { useUserAuth } from "../../../context/UserAuthContext";
import { SubscriberContext } from "../../../context/subscriberContext";
import { FcList } from "react-icons/fc";
import { VscCopy, VscNewFile, VscInfo, VscChevronUp, VscChevronDown, VscTrash } from "react-icons/vsc";
import { RiFileEditLine} from "react-icons/ri";
import "./ArticlePost.css";
import Loader from "../../shared/utilities/loader/Loader";
import ContentEditable from 'react-contenteditable';
import Modal from 'react-bootstrap/Modal';
import Overlay from 'react-bootstrap/Overlay';
import TextareaAutosize from 'react-textarea-autosize';
import ArticlePostAIServices from "./services/articlePostAI.services";
import ArticlePostDataService from "./services/articlePost.services";
import CommonDataServices from "../../../services/common.services";
import { sendPasswordResetEmail } from "firebase/auth";
import { useParams, Link  } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Accordion from 'react-bootstrap/Accordion';
import { serverTimestamp } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";  
import SidebarPanel from "../../dashboard/sidebar/SidebarPanel";
import Dashboard from "../../dashboard/Dashboard";
import { EditorSvg, InfoIcon, SideArrow, UpgradeIcon, ExpandIcon, RewriteIcon, WriteMoreIcon, SectionIcon, PaperPlaneIcon, CopyTextIcon, DraggableIcon } from "../../../assets/svg/SvgIcons";
import RichTextEditor from "../../shared/utilities/rich-text-editor/RichTextEditor";
import { RiDeleteBinLine } from "react-icons/ri";
import { RiFileCopy2Line, RiSaveLine} from "react-icons/ri";
import "quill/dist/quill.snow.css"; // Add css for snow theme
import { useLocation } from 'react-router-dom';
import articlePostServices from "./services/articlePost.services";
import articlePostAIServices from "./services/articlePostAI.services";
import Loader2 from "../../shared/utilities/loader2/Loader2";
import InlineLogin from "../../InlineLogin";   
import InlinePricing from "../../views/InlinePricing";  
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";  



const ArticlePost = () => {
  const { user } = useUserAuth();
  const functions = getFunctions();
  //if(user){ localStorage.setItem("user", user.uid)}
  const location = useLocation();
  const seoObject = location.state?.myProp;

  const theme = 'snow';
  var toolbarOptions = [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['image', 'link'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],
    [{ 'align': [] }],
    ['undo', 'redo'] // Add the undo and redo buttons to the toolbar
  ];
  const modules = {
    toolbar: toolbarOptions
  };

  const placeholder = '🚀 Get start now...';
  const formats = [
    'header',
    'align',
    'bold', 'italic', 'underline', 'image', 'link',
    'list', 'bullet',
    'indent', 'indent',
    'strike',    
    'undo', 'redo'
];

  const navigate = useNavigate();
  const [message, setMessage] = useState(null)
  const params = useParams();
  const [projects, setProjects] = useState(null);
  const [blogIdeaData, setBlogIdeaData] = useState([]);
  const [blogIntroData, setBlogIntroData] = useState(null);
  const [blogOutline, setBlogOutline] = useState(null);
  const [articlePost, setArticlePost] = useState({idea: "", title:"", keywords:" ", language:"Engilsh", sections:[] });
  const [blogOutlineData, setBlogOutlineData] = useState([{secData:"", secTitle:""}]);
  const [activeSection, setActiveSection] = useState(null);
  const [loading, setLoading] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [contGen, setContGen] = useState(false);
  const [error, setError] = useState(null);
  const [copied, setCopied] = useState(false);
  const [wizard, setWizard] = useState('step1');
  const [introBtn, setIntroBtn] = useState(false);
  const [outlineBtn, setOutlineBtn] = useState(false);
  const [newBlogTitle, setNewBlogTitle] = useState('Write your blog title..');
  const [showBlogTitle, setShowBlogTitle] = useState(false);
  const [editIntro, setEditIntro] = useState(false);
  const [selTitle, setSelTitle] = useState();
  const [selIntro, setSelIntro] = useState();
  const [ideaValue, setIdeaValue] = useState('');
  const [blogEditor, setBlogEditor] = useState(false);
  const [wordCount, setWordCount] = useState(null);
  const [selOutline, setSelOutline] = useState([])
  const [sectionData, setSectionData] = useState([])
  const [myWordRange, setMyWordRange] = useState("1000") 
  const [netError, setNetError] = useState(false);   
  const [blogPost, setBlogPost] = useState("");
  const [tempPost, setTempPost] = useState("");

  const {subscriber, setSubscriber} = useContext(SubscriberContext);
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const [modalData, setModalData] = useState({})
  const [accept, setAccept] = useState(false);

  const [showDetails, setShowDetails] = useState(false);
  const [target, setTarget] = useState(null);
  const [justTxt, setJustTxt] = useState('hello');
  const [queryData, setQueryData] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [projectSections, setProjectSections] = useState(null)
  const [projectLoading, setProjectLoading] = useState(null);

  const [metaData, setMetaData] = useState(null);
  const [queryObj, setQueryObj] = useState(null);
  const [mainParam, setMainParam] = useState(null);
  const [textLength, setTextLength] = useState(null);
  const [wordChangeStatus, setWordChangeStatus] = useState(false);

  const [startOver, setStartOver] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [tagType, setTagType] = useState(null);
  const [newAcceptedData, setNewAcceptedData] = useState(null);
  const [getContent, setGetContent] = useState(null)
  const [noRecords, setNoRecords] = useState(false); 

  const [tagCount, setTagCount] = useState(null) 
  const [article, setArticle] = useState([]) 
  const [userState, setUserState] = useState(null) 
  const [blogTitleVal, setBlogTitleVal] = useState() 
  const [blogKeywordsVal, setBlogKeywordsVal] = useState() 
  const [seoContent, setSeoContent] = useState(false) 
  const [sectionWords, setSectionWords] = useState(0) 
  
const [partValue, setPartValue] = useState(5);
const [showPerecent, setShowPerecent] = useState(false);
  
const [metaTitle, setMetaTitle] = useState("");
const [metaDescription, setMetaDescription] = useState("");
const [metaKeywords, setMetaKeywords] = useState("");
const [featureImg, setFeatureImg] = useState("");

const [upgradeTxt, setUpgradeTxt] = useState({ title: 'Upgrade your account',
discription: 'You have either exceeded the word limit or your plan has expired. Please upgrade your account.',
action:true})
const handleClick = (event) => {
  setShowDetails(!showDetails);
  setTarget(event.target);
};

const [images, setImages] = useState([]);
const [page, setPage] = useState(1);
const [imgLoading, setImgLoading] = useState(false);   
const [query, setQuery] = useState(); 
const client = createClient('uJSYKl9smttNl5bbDcjiweCcvucZ9UOS6GpmG4Fq0VWCud9cF79mtnzG');
//https://www.pexels.com/api/documentation/?language=javascript#photos-search__parameters   (resource)

const fetchImages = useCallback(async () => {
  setImgLoading(true);

  const params = {
    per_page: 5,
    page,
    locale: 'en-us',
    size: 'small',
    orientation: 'landscape',
    query,
  };

  const response = await client.photos.search(params);
  setImages((prevImages) => [...prevImages, ...response.photos]);
  setImgLoading(false);
}, [page, query]);

useEffect(() => {
  fetchImages();
}, [fetchImages]);

useEffect(() => {
  setPage(1);
  setImages([]); // Clear previous images when the query changes
}, [query]); 

const handleSearchSubmit = (e) => {
  e.preventDefault(); 
  setQuery(e.target.query.value);
};

const handleAddImage = (image) => { 
  const imageUrl = image.src.landscape;
  //console.log(imageUrl);
  const quillEditor = quillRef.current.__quill;
  const range = quillEditor.getSelection();
  if(range){
    quillEditor.insertEmbed(range.index, 'image', imageUrl);
  } else{
    toast.error('Position your cursor in the specified location within the editor')
  }
  
};

const handleFeatureImage = (image) => { 
  const imageUrl = image.src.landscape;
  //console.log(imageUrl); 
  setFeatureImg(imageUrl);
  setImageURL(imageUrl)
};
const handleLoadMore = () => {
  setPage((prevPage) => prevPage + 1);
};


  useEffect(() => {
    if (seoObject) {
      //console.log(seoObject); 
      setSeoContent(true)
      if(seoObject.keywords){
        const arryItem = seoObject.keywords;
        const trimmedArray = arryItem.map(str => str.trim());
        const myKeywords = trimmedArray.join(', ');  
       // console.log(myKeywords);
        setBlogKeywordsVal(myKeywords)
        setArticlePost(articlePost => ({
          ...articlePost,
          keywords : myKeywords
        })); 
      }
      if(seoObject.title){ 
        setBlogTitleVal(seoObject.title) 
        setArticlePost(articlePost => ({
          ...articlePost,
          title : seoObject.title
        })); 
      }
    }
  }, [seoObject]);

  const startNewPost = (e) => {
    setShowModal(false);
    setWizard('step1')
    setBlogEditor(false)
    setArticlePost({idea: "", title:"", keywords:"", sections:[] })
   // navigate("/blog-post");
     setBlogIdeaData([])
     setBlogIntroData(null)
     setBlogOutline(null)
     setBlogOutlineData([])
  }
  const acceptChange = (e) => {
      setWordChangeStatus(true)
      setShowModal(false);
  }
  const handleClose = (e) => {
     setShowModal(false);
  }

  const { quill, quillRef } = useQuill({ theme, modules, formats, placeholder });


  useEffect(() => {
  const fileId = new URLSearchParams(location.search).get("id")
    //console.log(fileId);

    if(fileId){
      getProjectContent(fileId);
      setQueryObj(queryObj);
    }  
    window.scrollTo(0, 0); 
},[location, quill])


const getProjectContent = async (prjID) => {
  setLoading(true)
  setBlogEditor(true)
  setProjectId(prjID)
  if(prjID){
      //console.log(prjID)
      const getProjectDetails = await ArticlePostDataService.getProjectData(user.uid, prjID);
       const dataObj = getProjectDetails.data();
      //   console.log(dataObj)
      if (quill){
        var delta = quill.clipboard.convert(dataObj.text);
        quill.setContents(delta, 'silent');
      } 
      let updatedValue = {};
          updatedValue = {title: dataObj.title, language:dataObj.language };
          console.log(updatedValue)
      setArticlePost(articlePost => ({
            ...articlePost,
            ...updatedValue
          })); 
      setTitle(articlePost.title);
      setQueryObj({
        text: dataObj.text,
        id:dataObj.id,
        status: dataObj.status,
        keywords: dataObj.keywords,
        language: dataObj.language,
        title: dataObj.title,
        creationDate: dataObj.creationDate
      })
      console.log(queryObj);

      if(articlePost.keywords.length < 4){
        const apiRequestBody = {
          "title": "blog-keywords",
          "query": dataObj.title, 
          "language":dataObj.language 
        }  
        try { 
          const response = await fetchAPIData(apiRequestBody); 
          let keyWordsData = response.data;  
          // console.log(keyWordsData)
          // if (!dataObj.hasOwnProperty("metaTitle")) { } 
          let getMetaTit = { 
              "title": "metaTitle", 
              "query": dataObj.title, 
              "language": dataObj.language,
              "keyWords": response.data 
            }
          let getMetaDesc = { 
              "title": "metaDescription", 
              "query": dataObj.title, 
              "language": dataObj.language,
              "keyWords": response.data 
          }   
          getMetaTitle(getMetaTit)
          getMetaDescription(getMetaDesc) 
          console.log(keyWordsData)
          setMetaKeywords(keyWordsData)

          setQuery(keyWordsData)
          setArticlePost(prevArticlePost => ({
            ...prevArticlePost,
            keywords: keyWordsData
          }));
        } catch (error) {
          console.error("Error:", error); 
        }   
        } else{
          setQuery(dataObj.keywords)
        }  
    // console.log(articlePost)
  }
  setLoading(false);
};

useEffect(() => { 
   if(user){
    getArticle();
    if(params.id){ 
      setBlogEditor(true);
      setWizard('step5')
    }
    updateSubscriber();
   } 
 },[])

  const getArticle = async () => {
  setNoRecords(false)
  let newArry = []
  //setLoading(true);
  try{ 
    await articlePostServices.getAllProjects(user.uid).then((data) => { 
      //console.log(data.docs);
          if(data.docs.length > 0){ 
              data.docs.map(doc => { 
                let newObj = doc.data();
                newArry.push(newObj)
                //console.log(doc.data().type)
               // setLoading(false);
            })
          } else { 
              //  setLoading(false);
                setNoRecords(true)
          }
      }); 
      setArticle(newArry)   
    } catch (err) { 
    console.log(err.message); 
  }
}
const deleteArticle = async (e) => {
  let projId = e.currentTarget.id;  
  await articlePostServices.deleteProjectContent(user.uid, projId).then(() => {
    setArticle(article.filter(item => item.id !== projId)); 
    toast.success('Content deleted!') 
  })   
} 


   const aiBoard = useRef(null);
  const scollDown = () => {
    aiBoard.current.scrollTo(0, aiBoard.current.scrollHeight);
  }
  const updateSubscriber = async () =>{
    if(user){
      const subData = await CommonDataServices.getUsageLimit(user.uid);
      if(subData.data()){
        //console.log(subData.data())
        setSubscriber(subData.data())
      }
    } 
  }
const getMetaTitle = async (getMeta) => {
  const apiRequestBody = getMeta; 
      // const apiRequestBody = {
      //   "title": "metaTitle",
      //   "query": articlePost.title, 
      //   "language": articlePost.language ? articlePost.language: "english",
      //   "keyWords": article.keyWords ? article.keyWords: "" 
      // }  
      try { 
        const response = await fetchAPIData(apiRequestBody); 
        let metaData = response.data;
        metaData = metaData.replace(/["']/g, '');    
        setMetaTitle(metaData)  
        setArticlePost(prevArticlePost => ({
          ...prevArticlePost,
          metaTitle: metaData
        }));
      } catch (error) {
        console.error("Error:", error); 
      } 
}
const getMetaDescription = async (getMeta) => {  
  const apiRequestBody = getMeta;
    // const apiRequestBody = {
    //   "title": "metaDescription",   
    //   "query": articlePost.title, 
    //   "language": articlePost.language ? articlePost.language: "english",
    //   "keyWords": article.keyWords ? article.keyWords: "" 
    // } 
    try { 
      const response = await fetchAPIData(apiRequestBody); 
       let metaData = response.data; 
       metaData = metaData.replace(/["']/g, '');   
       setMetaDescription(metaData)  
       setArticlePost(prevArticlePost => ({
        ...prevArticlePost,
        metaDescriptionData: metaData
      }));
    } catch (error) {
      console.error("Error:", error); 
    } 
}

//  const reGetBlogOutline = async () => {
//   let userState = checkUserStatus(); 
//   if(!userState){return;}
//   if(subscriber.planType == "free"){
//     let newFileModal = { title: 'Upgrade your account', 
//     discription: 'You are in free plan! Please upgrade your account to use this feature.', action:true}
//     setModalData(newFileModal)
//     setShowModal(true)  
//     return false;
//   } 

//   setLoading(true)
//   let blogTitle = articlePost.title;
//   console.log(articlePost.title)
//   try {
//     const getAIdata = await httpsCallable(functions, 'wordkraftAI');
//     const param = {
//       type:"articleOutline",
//       title:articlePost.title,
//       keywords: articlePost.keywords,
//       language: articlePost.language,
//       tone: "formal",
//       wcount:250,
//       creative:0.7,
//       num:1
//     }
//     getAIdata(param).then((response) => {
//        CommonDataServices.usageTrackingData(user.uid, response).then(() => {
//           updateSubscriber();
//         });
//        let oldOutLine = response.data.choices[0].text;
//        //console.log(oldOutLine)
//        let sentences = oldOutLine.split(/\r?\n/);
//        const newOutLine = sentences.filter(myFunction);
//        function myFunction(value) {
//           return value.length > 6;
//        }

//        let newArry = [];
//        newOutLine.forEach(function(element, index) {
//         // let outVal = element.replace(/\d+.\s/,'');
//         // outVal = outVal.replace(/^\s+|\s+$/gm,'');
//         let outVal = element;
//         if(outVal !== "Blog Outline:"){
//           newArry.push({secTitle: outVal, secData:''})
//         } 
//        })
//      //  console.log(newArry)
//        let attachArray = blogOutlineData.concat(newArry);

       

//        setBlogOutlineData(attachArray); // new in use
//        setLoading(false);
//       // let updatedOlogOutline = blogOutline.concat(newOutLine); // old not used
//       //setBlogOutline(updatedOlogOutline); // old not used
//        setOutlineBtn(true);
//     });
//   } catch (error) {
//     //console.log(error.message);
//     setLoading(false)
//   }
// };


const reGetBlogOutline = async() => {  
  // const apiRequestBody = {
  //   "model": "gpt-3.5-turbo",
  //   "messages": [{"role": "user", "content": "Hello tell me the story!"}]
  // }
  let blogTitle = articlePost.title;
  let keyWords = articlePost.keywords ? articlePost.keywords: " ";
  let language = articlePost.language;
  setLoading(true)
  //console.log(articlePost)
  const apiRequestBody = {
    "title": "blog-outline",
    "query": blogTitle,
    "keyWords":keyWords,
    "language":language 
  }  
 // console.log(apiRequestBody)
  try { 
    const response = await fetchAPIData(apiRequestBody);
   // console.log(response) 

   let oldOutLine = response.data;
   //console.log(oldOutLine)
   let sentences = oldOutLine.split(/\r?\n/); 
  // console.log(sentences)
   const newOutLine = sentences.filter(myFunction);
   function myFunction(value) {
      return value.length > 6;
   }

   let newArry = [];
   newOutLine.forEach(function(element, index) {
    let outVal = element.replace(/^(?=[MDCLXVI])M*(C[MD]|D?C{0,3})(X[CL]|L?X{0,3})(I[XV]|V?I{0,3})\.\s*|\d+\.\s*/, '');
 
    if(outVal !== "Blog Outline:"){
      newArry.push({secTitle: outVal, secData:''})
    } 
   })
   //console.log(newArry)
   let attachArray = blogOutlineData.concat(newArry); 
   let filtArr = [...blogOutlineData, ...attachArray]; //remove empty title
   const filteredArray = filtArr.filter(item => item.secTitle !== '');

   setBlogOutlineData(filteredArray);
 
   setLoading(false); 
  } catch (error) {
    console.error("Error:", error);
    setLoading(false);
  } 
}

useEffect(() => {
  //  console.log(blogOutlineData);
}, [blogOutlineData]);

 

const addArticleTitle=(e)=>{
  let titleData = e.target.value;
 // //console.log(titleData)
  let updatedValue = {title: titleData};
  setArticlePost(articlePost => ({
        ...articlePost,
        ...updatedValue
      }));
}
const addArticleKeywords=(e)=>{
  let keywordsData = e.target.value;
 // //console.log(keywordsData)
  let updatedValue = {keywords: keywordsData};
  setArticlePost(articlePost => ({
        ...articlePost,
        ...updatedValue
      }));
}

const addArticleLanguage=(e)=>{
  let languageData = e.target.value;
 // //console.log(languageData)
  let updatedValue = {language: languageData};
  setArticlePost(articlePost => ({
        ...articlePost,
        ...updatedValue
      }));
}
  const selectBlogOutline=(e)=>{
   //let str = e.target.value;
   let strId = parseInt(e.target.dataset.id);
   let str = blogOutlineData[strId].item;
   let sectionArray = articlePost.sections;
  // //console.log(articlePost)
    // //console.log(blogOutlineData[strId].status)
   if (e.target.checked == true){
        let updatedValue = sectionArray.push({secTitle:str, secData:''})
        setArticlePost(articlePost => ({
          ...articlePost,
          ...updatedValue
        }));
    } else {
      sectionArray = sectionArray.filter(function( obj ) {
        return obj.secTitle !== str;
      });
      let updatedValue = articlePost.sections = sectionArray;
    }
  }
  const updateBlogOutline = (e) => {
    const ind = parseInt(e.currentTarget.id);
    let targetValue = e.target.value;
   // //console.log(ind);
    let newArry = blogOutlineData;
                  newArry[ind].secTitle = targetValue;
   // //console.log(newArry);
    setBlogOutlineData(newArry);
  }
const checkUpdate = (e) => {

}
const deleteBlogOutline = (e) => {
  const ind = parseInt(e.currentTarget.id);
  const index = ind;
  if (index > -1) {
    blogOutlineData.splice(index, 1);
  }
   setBlogOutlineData(blogOutlineData.filter(data => data));
}

const addWordRange = (e) => {
   let wordRange = e.target.value;
      //  console.log(wordRange)
       setMyWordRange(wordRange);
  //  let updatedValue = {language: languageData};
    // setArticlePost(articlePost => ({
    //       ...articlePost,
    //       ...updatedValue
    //     })); 
}

const addSubHeading = (e) => {
  blogOutlineData.push({secTitle:'  ', secData:''})
  // //console.log(blogOutlineData) 
  if(blogOutlineData.length > 4){
    if(subscriber.planType == "free" && subscriber.wordsLimit == 1000){
      toast.error('The free plan has limited access!')
      return;
    }
  }
  setBlogOutlineData(blogOutlineData.filter(data => data));
}
 /*---------------------
ALL SECTION GENERATOR
 ----------------------*/
const checkUserStatus = () => {  
  const currentDate = Math.floor(Date.now() / 1000)
  if(subscriber.totalWords >= subscriber.wordsLimit || subscriber.premiumState === false || currentDate > subscriber.planEndDate){
    let newFileModal = { title: 'Upgrade your account', discription: 'You have either exceeded the word limit or your plan has expired. Please upgrade your account.', action:true}
    setModalData(newFileModal)
    setShowModal(true) 
    return false;
   } else{
    return true;
   }
} 

const getMykeyword = async () => {
  let blogTitle = articlePost.title;
  let keyWords = articlePost.keywords ? articlePost.keywords: "";
  let language = articlePost.language ? articlePost.language: "english";

    if(keyWords.length < 2){
        const apiRequestBody = {
          "title": "blog-keywords",
          "query": blogTitle, 
          "language":language 
        }  
        try { 
            const response = await fetchAPIData(apiRequestBody); 
            let keyWordsData = response.data;  
            let keyWords = response.data;  
            //console.log(keyWordsData)
            setMetaKeywords(keyWordsData)
            setQuery(keyWordsData)
            setArticlePost(prevArticlePost => ({
              ...prevArticlePost,
              keywords: keyWordsData
            }));
          return response.data;
        } catch (error) {
          console.error("Error:", error); 
        }   
    } else{
      setQuery(keyWords)
      return keyWords;
    } 
}

const genSection = async () => { 
  let userState = checkUserStatus(); 
        if(!userState){return;}

        setBlogEditor(true); 
        setLoading(true)
        const myKeywords = await getMykeyword(); 
       // //console.log(quill)
        let secValue = articlePost.sections = blogOutlineData;
        setArticlePost(articlePost => ({
          ...articlePost,
          ...secValue
        }));

       //console.log(articlePost)

      //  const getAIdata = httpsCallable(functions, 'wordkraftArticleApi'); 
     
      const generateAllSections = async () => {
        let sectionData = [];
        //remove empty subtitle item
        setTitle(articlePost.title);
        setBlogPost(`${articlePost.title}\n\n`)
        setShowPerecent(true)
        const articlePostFilter = articlePost.sections.filter(element => {
          if (Object.keys(element.secTitle).length !== 0) {
            return true;
          }
          return false;
        });
        articlePost.sections = articlePostFilter;
      
        for (let index = 0; index < articlePost.sections.length; index++) {
          const objSec = {
            postTitle: articlePost.title,
            secTitle: articlePost.sections[index].secTitle,
            keyWords: articlePost.keywords,
            wordRange: myWordRange
          }
         // console.log(objSec);  
      
          let result;
          // let attempts = 0;
          // const maxAttempts = 5; 
          // while (!result && attempts < maxAttempts) {
          //   console.log(attempts)
          //   result = await fetchArticleData(objSec);
          //   attempts++;
          // }
          result = await fetchArticleData(objSec);

         // console.log(result);
          // If result is undefined, exit the function and stop execution
          if (!result) {
              console.error("Error: result is undefined...");
             // console.log(sectionWords)
              return null;
          }  
          sectionData.push(result);
        }
        return sectionData;
      } 

      const mySections = await generateAllSections()
      //const myKeywords = await getMykeyword(); 
      // console.log(mySections);
      let fullText; 
      const allResponse = mySections;
      // mySections.then((allResponse) => {
            //  console.log(allResponse)
            var wordCount = 0;
            var textGroup = '';
            allResponse.map((response, index) => {
             // console.log(response)

              // let respData = response.choices[0].text;
              // fullText = response.choices[0].text;
              // textGroup += response.choices[0].text;
              let respData = response.data;
              fullText = response.data;
              textGroup += response.data;

             // //console.log(response.data.choices[0])
             let spaceRemoveData;
             if(respData){
              spaceRemoveData = respData.replace(/(\r\n|\n|\r){2}/,"")
             }              

              //remove till pullstop
              //var array = '123.2345.34.testingbull.iuiuiuiu'.split(/\.(?=[^\.]+$)/);
              let withFullStopData = spaceRemoveData.split(/\.(?=[^\.]+$)/); 
             // console.log(withFullStopData)
              //let updatedValue = articlePost.sections[index].secData = withFullStopData[0];
              let updatedValue = articlePost.sections[index].secData = respData;
              setArticlePost(articlePost => ({
                             ...articlePost,
                              ...updatedValue
                           }));  
               wordCount += parseInt(fullText.split(' ').filter(word => word !== '').length); 
 

               if((allResponse.length - 1) == index){ 
                   // console.log(articlePost)
                  //  console.log(myKeywords)
                  let getMetaTit = { 
                      "title": "metaTitle", 
                      "query": articlePost.title, 
                      "language": articlePost.language,
                      "keyWords": myKeywords
                    }
                  let getMetaDesc = { 
                      "title": "metaDescription", 
                      "query": articlePost.title, 
                      "language": articlePost.language,
                      "keyWords": myKeywords
                  }   
                  getMetaTitle(getMetaTit)
                  getMetaDescription(getMetaDesc)  
                  setMetaKeywords(myKeywords)        
                 // console.log(myKeywords)
                    // //console.log(textGroup)
                    // response.choices[0].text = textGroup; 
               }      
             })
               setProjects(articlePost)

               let articleSections = articlePost.sections;

              articleSections.forEach((element, index) => {
                 let secTitle = element.secTitle;
                 let secData = element.secData;
                //  secData = secData.replace(/^\s+|\s+$/g, '');
                //  let sTitle = `${secTitle}:`
                //  secData = secData.replace(new RegExp(sTitle, "g"), "").replace(/(\r\n\r){1}/,"");
                
                // fullText = `<h2>${secTitle}</h2>\n\n\n${secData}\n\n\n`
                 //fullSec = fullSec.replace(/^\s+|\s+$/g, '');
                // fullText += fullText

                if(index === 0){
                  quill.clipboard.dangerouslyPasteHTML(0, `<h1>${articlePost.title}</h1>`);
                 }

                 var length1 = quill.getLength();

                 let string = secTitle;
                 string = string.toLowerCase();  
                 const introString = 'introduction'; 
                 const conclusionString = 'conclusion';
                 
                //  if(!string.includes(introString)){
                //     quill.clipboard.dangerouslyPasteHTML(length1, `<h2>${secTitle}</h2>`);
                //  }
                if(string.includes(conclusionString)){
                  quill.clipboard.dangerouslyPasteHTML(length1, `<h2>${secTitle}</h2>`);
                }
                
                var length2 = quill.getLength();

                // quill.insertText(length2, secData, 'normal', false);
                quill.clipboard.dangerouslyPasteHTML(length2, secData);

                // var length3 = quill.getLength(); 
                // let space2 = `\n`; 
                // quill.insertText(length3, space2, 'normal', false);

                //  //console.log(length1)
                //  //console.log(length2)

                if((articleSections.length - 1) == index) { 
                  //console.log(sectionWords)
                  if(quill){
                    const quillEditorContent = quill.root.innerHTML; 
                    const count = quillEditorContent.trim().split(/\s+/).length;;
                   // console.log('Word count:', count);                
                    CommonDataServices.usageTrackingData4(user.uid, count).then(() => {     
                      updateSubscriber();
                      saveContent(); 
                    }).catch((error) => {
                      console.error("Error tracking data: ", error);
                    });
                  }
                }
                
               });
               setLoading(false)
       // });

    }

useEffect(() => {
  //console.log(blogPost); 
}, [blogPost]);
const maxRetry = 5;
const fetchArticleData = async (objSec, retryCount = 0) => {
  const secArry = articlePost.sections;
  const outLine = secArry.map(item => item.secTitle).join('\n');
  let language = articlePost.language ? articlePost.language : "english";
  let blogContent;
  setBlogPost((prevBlogPost) => {
    blogContent = prevBlogPost;
    return blogContent;
  }); 
 // console.log(objSec)
  const apiRequestBody = {
    "title": "article sections",
    "postTitle": objSec.postTitle,
    "secTitle": objSec.secTitle,
    "keyWords": objSec.keyWords ? objSec.keyWords : " ",
    "wordRange": objSec.wordRange,
    "language": language,
    "outLine": outLine
  };
 // console.log(apiRequestBody)
  const functionUrl = "https://us-central1-wordkraft-bb683.cloudfunctions.net/tool_api2";
  
  try {
    const response = await fetch(functionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(apiRequestBody),
    });
    //console.log(response)
    if (response.status === 500) {
      if (retryCount < 2) {
        // Retry the request after a delay
        console.log(response.status)
        await new Promise(resolve => setTimeout(resolve, 3000));
        return fetchArticleData(objSec, retryCount + 1); // Retry the fetchArticleData function with an incremented retry count
      } else {
        throw new Error('Maximum retries exceeded');
      }
    }
    
    const resObj = await response.json();

    
    const removeTitle = `${objSec.secTitle}\n\n`;
    let upObj = resObj.data.replace(removeTitle, ""); // removed title from section content
    const dataObj = {
      title: resObj.title,
      data: upObj
    };

    setBlogPost((prevBlogPost) => {
      const updatedBlogPost = prevBlogPost + `${objSec.secTitle}\n\n ${dataObj.data}\n\n`;
      return updatedBlogPost;
    }); 
    const sectArry = articlePost.sections;
    const totalValue = sectArry.length;
    const targetTitle = objSec.secTitle;
    const currIndex = sectArry.findIndex(obj => obj.secTitle === targetTitle);
    const newIndex = currIndex + 1;

    const resultPer = calculatePercentage(newIndex, totalValue);
    setPartValue(resultPer);
    
    if (resultPer === 100) {
      setShowPerecent(false);
    }
    
    return dataObj;
  } catch (error) {
    // setLoading(false);
    // console.error('Error during fetch operation:', error);
    // setNetError(true)
    // toast.error('A network error occurred. Please check your connection and try again.');
    console.error('Error during fetch operation:', error);
    if (retryCount < maxRetry) {
      console.log('Retry request:', retryCount + 1);
      await new Promise(resolve => setTimeout(resolve, 3000));
      return fetchArticleData(objSec, retryCount + 1);
    } else {
      setLoading(false);
      setNetError(true);
      toast.error('A network error occurred. Please check your connection and try again.');
    }
  }
};

 
function calculatePercentage(part, total) {
  const percentage = (part / total) * 100;
  return Math.round(percentage);
}

const updateBlogPost = (objSec, dataObj) => {
  setBlogPost((prevBlogPost) => {
    const updatedBlogPost = prevBlogPost + `${objSec.secTitle}\n\n ${dataObj.data}\n\n`;
   // console.log(updatedBlogPost);
    return updatedBlogPost;
  });
};



const fetchAPIData = async (apiRequestBody) => {
    const fetchData = await fetch("https://us-central1-wordkraft-bb683.cloudfunctions.net/tool_api", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(apiRequestBody)
    });  
    const response = await fetchData.json();
   // console.log(response)
    
    CommonDataServices.usageTrackingData2(user.uid, response).then(() => {
      updateSubscriber();
    });
    return response;
}

 useEffect(() => {
    setWordCount(wordCount);
     //console.log(wordCount)
  }, [wordCount])


  const [indexNumber, setIndexNumber] = useState(null)


  const moveUp = (e) =>{
    let fromIndex = parseInt(e.currentTarget.id);
    let toIndex = fromIndex - 1;
    let newArray = []
      projectSections.forEach((item) => {
        let newObject = {
              id : item.id,
              projectId : item.projectId,
              sectionDescription : item.sectionDescription,
              sectionTitle : item.sectionTitle,
              order : item.order
        }
        newArray.push(newObject);
      })

    function moveArrayItemToNewIndex(arr, old_index, new_index) {
      if (new_index >= arr.length) {
          var k = new_index - arr.length + 1;
          while (k--) {
              arr.push(undefined);
          }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
  };
  if(fromIndex !== 0){
    setProjectSections(moveArrayItemToNewIndex(newArray, fromIndex, toIndex));
   }
  }

  const moveDown = (e) =>{
    let fromIndex = parseInt(e.currentTarget.id);
    let toIndex = fromIndex + 1;
     let newArray = []
      projectSections.forEach((item) => {
        let newObject = {
              id : item.id,
              projectId : item.projectId,
              sectionDescription : item.sectionDescription,
              sectionTitle : item.sectionTitle,
              order : item.order
        }
        newArray.push(newObject);
      })

    function moveArrayItemToNewIndex(arr, old_index, new_index) {
      if (new_index >= arr.length) {
          var k = new_index - arr.length + 1;
          while (k--) {
              arr.push(undefined);
          }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
  };

   if(projectSections.length !== toIndex){
    setProjectSections(moveArrayItemToNewIndex(newArray, fromIndex, toIndex));
   }
  }


const copyMyText = (e) => {
  let edtorTxt = quill.getText();
  if(edtorTxt.length > 5){
    navigator.clipboard.writeText(edtorTxt)
    toast.success('Copied Text to clipboard!')
  } else{
    toast.error('There is NO content to copy!')
  }
 }
 const copyMyHTML = (e) => {
  let edtorTxt = quill.root.innerHTML
  if(edtorTxt.length > 5){
    navigator.clipboard.writeText(edtorTxt)
    toast.success('Copied HTML to clipboard!')
  } else{
    toast.error('There is NO content to copy!')
  }
 }

 const titleRef = useRef(null);
 const descriptionRef = useRef(null);
 const keywordsRef = useRef(null);

 const copyToClipboard = (ref) => {
   ref.current.select();
   document.execCommand('copy');
   toast.success('Copied to clipboard!')
 };

  const reWrite = async() => {
    let userState = checkUserStatus(); 
        if(!userState){return;}
    setLoading(true)
    var range = quill.getSelection();
    let reWriteText;
    if (range) {
      if (range.length === 0){
        //console.log("User cursor is at index", range.index);
        setLoading(false)
      } else {
        reWriteText = quill.getText(range.index, range.length);
        //console.log("User has highlighted: ", reWriteText);
      }
    } else {
      //console.log("User cursor is not in editor");
      setLoading(false)
    }
    if(reWriteText){
      try{
        // let paramConfig = mainParam;
        // const getAIdata = await httpsCallable(functions, 'wordkraftAI');
        // const param = {
        //   type:"paraphrase",
        //   about: reWriteText,
        //   language: articlePost.language,
        //   tone:"formal",
        //   wcount:75,
        //   creative:0.7,
        //   num:1
        // }
        const apiRequestBody = {
          "title": "paraphrase",
          "query": reWriteText, 
          "language":articlePost.language ? articlePost.language: queryObj.language,
          "tone": 'formal'
        }  
        
        const response = await fetchAPIData(apiRequestBody);
       // console.log(response) 
    
        if(response){         
          let newData = response.data;
          newData = newData.replace(/^\s+|\s+$/g, ''); 

          if(newData.length < 4){
            toast.error('Try different inputs!')
            setLoading(false)
            return
          }   

          setModalType("newPost")
          let newFileModal = {
            title: 'Rewrite sentence',
            discription: 'Please check the re-written sentence below.',
            oldData:reWriteText,
            newData:newData,
            action:true}
          setModalData(newFileModal)
          setShowModal(true)

          setLoading(false) 

          if(newData){
            quill.deleteText(range.index, range.length);
            quill.cursorPosition = range.index;
            //quill.clipboard.dangerouslyPasteHTML(quill.cursorPosition, newData);
            setNewAcceptedData({postion:quill.cursorPosition, data: newData, oldData: reWriteText})
          }

        };
      } catch (error) {
        //console.log(error.message);
        setLoading(false)
      }
    }
  };
  
  const writeMore = async() => {
    let userState = checkUserStatus(); 
        if(!userState){return;}
    setLoading(true)

   
    if(getContent){
      // //console.log(getContent)
      try{ 
        // const getAIdata = await httpsCallable(functions, 'wordkraftAI');
        // const param = {
        //   type:"paragraphWriterMore",
        //   about: getContent.wContent,
        //   language:articlePost.language ? articlePost.language: queryObj.language,
        //   tone:'formal',
        //   wcount:70,
        //   creative:0.7,
        //   num:1
        // }
        const apiRequestBody = {
          "title": "paragraphWriterMore",
          "query": getContent.wContent, 
          "language":articlePost.language ? articlePost.language: queryObj.language,
          "tone": 'formal'
        }  
        
        const response = await fetchAPIData(apiRequestBody);
       // console.log(response) 
    
       if(response){
          // CommonDataServices.usageTrackingData(user.uid, response).then(() => {
          //   updateSubscriber();
          // });
          let newData = response.data; 
          newData = newData.replace(/^\s+|\s+$/g, ''); 
          // //console.log(newData);
          
          let getTxt = getContent.wContent;

          if(newData.length < 4 || newData == getTxt){
            toast.error('Try different inputs!')
            setLoading(false)
            return
          }

          setModalType("newPost")
          let newFileModal = {
            title: 'Write more',
            discription: 'Please check the AI generated content for your article.', 
            newData:newData,
            action:true}
            setModalData(newFileModal)
            setShowModal(true)

          setLoading(false) 

          if(newData){
            setNewAcceptedData({postion:getContent.wPosition, data:' ' + newData})
          }

          setLoading(false)

        };
      } catch (error) {
        //console.log(error.message);
        setLoading(false)
      }
    }
  };


  const expandTxt = async() => {
    let userState = checkUserStatus(); 
        if(!userState){return;}
    setLoading(true)
    var range = quill.getSelection();
    let expandText = "";
    if (range) {
      if (range.length === 0){
        //console.log("User cursor is at index", range.index);
        setLoading(false)
      } else {
        expandText = quill.getText(range.index, range.length);
        //console.log("User has highlighted: ", expandText);
      }
    } else {
      //console.log("User cursor is not in editor");
      setLoading(false)
    }
    if(expandText){
      try{
        // let paramConfig = mainParam;
        // const getAIdata = await httpsCallable(functions, 'wordkraftAI');
        // const param = {
        //   type:"blogSectionMore",
        //   about: expandText,
        //   language:articlePost.language ? articlePost.language: queryObj.language,
        //   tone:'formal',
        //   wcount:100,
        //   creative:0.7,
        //   num:1
        // }
        const apiRequestBody = {
          "title": "blogSectionMore",
          "query": expandText, 
          "language":articlePost.language ? articlePost.language: queryObj.language,
          "tone": 'formal'
        }          
        const response = await fetchAPIData(apiRequestBody);
       // console.log(response) 
    
        if(response) {
     
          let newData = response.data;
          newData = newData.replace(/^\s+|\s+$/g, ''); 
          let getTxt = quill.getText(range.index, range.length);

          if(newData.length < 4 || newData == getTxt){
            toast.error('Try different inputs!')
            setLoading(false)
            return
          }

          setModalType("newPost")
          let newFileModal = {
            title: 'Expand sentence',
            discription: 'Please check the expanded sentence below.',
            oldData:expandText,
            newData:newData,
            action:true}
            setModalData(newFileModal)
            setShowModal(true)

          setLoading(false)
        
          if(newData){
          //  quill.deleteText(range.index, range.length);
            quill.cursorPosition = range.index + range.length;
            setNewAcceptedData({postion:quill.cursorPosition, data:' ' + newData, oldData: expandText})

            // if (newData.includes(getTxt)) {
            //  // quill.clipboard.dangerouslyPasteHTML(quill.cursorPosition, newData);
            //   setNewAcceptedData({postion:quill.cursorPosition, data: newData, oldData: expandText})
            // } else{
            //   //quill.clipboard.dangerouslyPasteHTML(quill.cursorPosition, getTxt + newData);
            //   setNewAcceptedData({postion:quill.cursorPosition, data: getTxt + newData, oldData: expandText})
            // }
          }
          setLoading(false)

        };
      } catch (error) {
        //console.log(error.message);
        setLoading(false)
      }
    }
  };


  const paragraphTxt = async() => {
    let userState = checkUserStatus(); 
        if(!userState){return;}
    setLoading(true)
    var range = quill.getSelection();
    let expandText = "";
    if (range) {
      if (range.length === 0){
        //console.log("User cursor is at index", range.index);
        setLoading(false)
      } else {
        expandText = quill.getText(range.index, range.length);
        //console.log("User has highlighted: ", expandText);
      }
    } else {
      //console.log("User cursor is not in editor");
      setLoading(false)
    }
    if(!articlePost.keywords){
      articlePost.keywords = "";
    }
    // //console.log(expandText)    
    // //console.log(articlePost.title) 
    // //console.log(articlePost.keywords) 
    // //console.log(articlePost.language)  
    if(expandText){
      try{ 
        // const getAIdata = await httpsCallable(functions, 'wordkraftAI');
        // const param = {
        //     type: "articleParagraph",
        //     title: articlePost.title ? articlePost.title: queryObj.title,
        //     about: expandText,
        //     keywords: articlePost.keywords ? articlePost.keywords: queryObj.title ? articlePost.keywords: " ",
        //     tone: "Formal",
        //     language: articlePost.language ? articlePost.language: queryObj.language,
        //     variant:1,
        //     creative: 0.7
        // }
        //console.log(param)
        const apiRequestBody = {
          "title": "articleParagraph",
          "query": expandText,
          "articleTitle": articlePost.title ? articlePost.title: queryObj.title,
          "keyWords": articlePost.keywords ? articlePost.keywords: queryObj.title ? articlePost.keywords: " ",
          "language":articlePost.language ? articlePost.language: queryObj.language,
          "tone": 'formal'
        }          
        const response = await fetchAPIData(apiRequestBody);
       // console.log(response) 
    
        if(response){ 
          let newData = response.data;
          //console.log(newData)
          newData = newData.replace(/^\s+|\s+$/g, ''); 
          //console.log(newData)
          let getTxt = quill.getText(range.index, range.length);

          if(newData.length < 4 || newData == getTxt){
            toast.error('Try different inputs!')
            setLoading(false)
            return
          }

          setModalType("newPost")
          let newFileModal = {
            title: 'Paragraph generator',
            discription: 'Please check the paragraph.',
            oldData:expandText,
            newData:newData,
            action:true}
          setModalData(newFileModal)
          setShowModal(true)

          setLoading(false)
      
          if(newData){
           // quill.deleteText(range.index, range.length);
            quill.cursorPosition = range.index + range.length + 1;
            //console.log(quill.cursorPosition)
            // //console.log(range.index)
            // //console.log(range.length)
            let formatData = `${newData}`
            if (newData.includes(getTxt)) {
             // quill.clipboard.dangerouslyPasteHTML(quill.cursorPosition, newData);
              setNewAcceptedData({postion:quill.cursorPosition, data: formatData, oldData: expandText})
            } else{
              //quill.clipboard.dangerouslyPasteHTML(quill.cursorPosition, getTxt + newData);
              setNewAcceptedData({postion:quill.cursorPosition, data: formatData, oldData: expandText})
            }
          }
          setLoading(false)

        };
      } catch (error) {
        //console.log(error.message);
        setLoading(false)
      }
    }
  };
  const acceptedData = () => {
    if(newAcceptedData){
     //quill.clipboard.dangerouslyPasteHTML(newAcceptedData.postion, newAcceptedData.data); 
     quill.insertText(newAcceptedData.postion, newAcceptedData.data, 'normal', false);
     setShowModal(false) 
    }
  }

  const retainOldData = () => {
    //console.log("retain old..")
    if(newAcceptedData){
      //quill.clipboard.dangerouslyPasteHTML(newAcceptedData.postion, newAcceptedData.oldData);
         quill.insertText(newAcceptedData.postion, newAcceptedData.oldData, 'normal', false);
      setShowModal(false)
    }
  } 

  
  const [loginState, setloginState] = useState(false); 
  const isAuthenticated = () => {
    if(user){
      return true;
    } else {
      setloginState(true)
      setShowModal(true) 
      return false;
    } 
  }
  const handleLoginSuccess = (user) => { 
    setloginState(false)
    setShowModal(false) 
    console.log('Login successful:');
  }; 
  const onFormSubmit = async (e) => { 
    e.preventDefault(); 
    const formData = new FormData(e.target);
    const formDataObj = Object.fromEntries(formData.entries());
    // //console.log(formDataObj.projectValue1)
 }; 

  useEffect(() => { 
    if (quill) { 
      quill.on('editor-change', function(eventName, ...args) {
        if (eventName === 'text-change') {
          // args[0] will be delta
          var text = quill.getText();
          var wordCounter = text.split(/\s+/).length;
          setWordCount(wordCounter)

          let htmlDoc = quill.root.innerHTML; 
          let p = (htmlDoc.match(/<p>/g) || []).length;
          let pb = (htmlDoc.match(/<p><b/g) || []).length;
          let h1 = (htmlDoc.match(/<h1>/g) || []).length;
          let h2 = (htmlDoc.match(/<h2>/g) || []).length;
          let link = (htmlDoc.match(/<a/g) || []).length;
          let img = (htmlDoc.match(/<img/g) || []).length;
          let ul = (htmlDoc.match(/<ul/g) || []).length;
          setTagCount({
            pTag: (p - pb),
            h1Tag: h1,
            h2Tag: h2,
            linkTag: link,
            imgTag: img,
            ulTag: ul
          })  
        } else if (eventName === 'selection-change') {
          var range = quill.getSelection();
          if(range){
            var cursorPosition = range.index;
            var content = quill.getText((range.index - 800), 800)
            // var contentHtml = quill.root.innerHTML
            // console.log(content)
            setGetContent({wPosition: cursorPosition, wContent: content}) 
             //console.log(getContent)
          }    
          let tagOfType = window.getSelection().anchorNode.parentElement.nodeName;
          setTagType(tagOfType) 
          if(args){ 
            if(args[0]){
              setTextLength(args[0].length)
            }
          }
        }
      });
      // quill.on('selection-change', function (delta, old, source) {  
      //  }); 
       // quill.insertText(0, "hellow", 'bold', false);
    }
  }, [quill]);

const countWordsInQuill = () => {
  const htmlString = quill.root.innerHTML;
  const textContent = new DOMParser().parseFromString(htmlString, "text/html").body.textContent;
  const wordsArray = textContent.trim().split(/\s+/);
  const wordCount = wordsArray.length;
  setWordCount(wordCount);
  //console.log(wordCount);
  // Return 0 if the wordsArray contains only an empty string (when there is no content in the editor)
  return wordsArray[0] === "" ? 0 : wordCount;
}
  
const saveContent = () => {
      if(!projectId){
        const wordCount2 = countWordsInQuill(); 
        //console.log(wordCount2)
        const contentObj = {
          type: "article-writer",
          creationDate: Math.floor(Date.now() / 1000),
          title:articlePost.title,
          keywords: articlePost.keywords ? articlePost.keywords:"",
          language: articlePost.language,
          status: true,
          tone: 'formal',
          text: quill.root.innerHTML,
          wordCount: wordCount2
        } 
        try{
          ArticlePostDataService.addProject(user.uid, contentObj).then((data) => {
            if(data){
              setProjectId(data.id);
              const projectId = data.id;
              toast.success('Article saved!')
              try{
                ArticlePostDataService.updateProjectId(user.uid, projectId);
              } catch (err){
                console.log(err.message);
                toast.error('Oops! some thing went worng!')
              }
            }
            //console.log(projectId)
          });
        } catch (err) {
          console.log(err.message);
          toast.error("Oops! something went wrong. Please check with the support team.")
        }
      } else{
        const contentObj = {
          type: "article-writer",
          creationDate: Math.floor(Date.now() / 1000),
          title:articlePost.title,
          keywords: articlePost.keywords ? articlePost.keywords:"",
          language: articlePost.language,
          status: true,
          tone: 'formal',
          text: quill.root.innerHTML,
          prjId: projectId,
          wordCount: wordCount
        }
       // console.log(contentObj)
        try{
          ArticlePostDataService.updateProject(user.uid, contentObj);
              toast.success('Article updated!')
          } catch (err){
              console.log(err.message);
              toast.error('Oops! some thing went worng!')
          }
      }
    }
     

    const [inputText, setInputText] = useState('');
    const [responseText, setResponseText] = useState('');
  
    const cloudFunctionURL =
      'https://us-central1-wordkraft-bb683.cloudfunctions.net/chatBot';
  
    const sendRequest = async () => {
      try {
        const response = await fetch(cloudFunctionURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ text: inputText }),
        });
  
        if (response.ok) {
          const result = await response.json();
          setResponseText(result);
        } else {
          throw new Error('Request failed');
        }
      } catch (error) {
        console.error(error);
        setResponseText('Error: Unable to get the response');
      }
    };

  
    const handleDragEnd = (result) => {
      if (!result.destination) {
        return;
      }
    
      const items = Array.from(blogOutlineData);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
    
      setBlogOutlineData(items);
    };
    
 
    const [title, setTitle] = useState(''); 
    const [category, setCategory] = useState('');
    const [status, setStatus] = useState('draft');
    const [featureImage, setFeatureImage] = useState(null);
    const [imageURL, setImageURL] = useState("");
  
    const handleTitleChange = (e) => {
      setTitle(e.target.value);
    }; 
  
    const handleCategoryChange = (e) => {
      setCategory(e.target.value);
    };

    const [isDraft, setIsDraft] = useState(true);
    const handleStatusChange = (e) => {
      setStatus(e.target.value);
      const status = e.target.value;
      setIsDraft(status === 'draft'); 
    };
  
    const handleImageChange = (e) => {
      const file = e.target.files[0];         
      setFeatureImage(file); 
      setFeatureImg(URL.createObjectURL(file));
    };

    const handleImageUrlChange = (e) => {
      setImageURL(e.target.value);
    };
  
    const handlePublishSubmit = (e) => {
      e.preventDefault();
      // Logic to handle form submission
      console.log('Form submitted');
      console.log('Title:', title); 
      console.log('Category:', category);
      console.log('Status:', status);
      console.log('Feature Image:', featureImage);
    };


  const [siteUrl, setSiteUrl] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [publishing, setPublishing] = useState(false);
  const [publishError, setPublishError] = useState(false);
  const [postPublished, setPostPublished] = useState(false)
  
  
  const handleWPAuth = async (event) => {
    event.preventDefault();
    // All validations passed
    const param = { siteUrl, username, password };
    //  const param = { 
    //       siteUrl: 'https://dailymockup.com', 
    //       username: 'dailymockup',
    //       password: 'nZj1CjN5wTD9WfPTDkSpUeNz',  // Removed spaces
    //   }; 
    //console.log(param);
    try {
      const wpAuthenticate = await httpsCallable(functions, 'wpAuthenticate');
      const result = await wpAuthenticate(param);
      console.log(result)
      if (result.data.result.success) { 
        toast.success("Blog post created successfully.");
      } else {
        toast.error("Failed to create blog post. Please try again.");
      }
    } catch (error) {
      toast.error('Oops! some thing went worng!')
      console.error(error); // Handle the error
    }
  };

  const handlePublishPost = async (e) => {
    setPublishing(true)
    setPublishError(false)
    e.preventDefault();
    let edtorTxt = quill.root.innerHTML

    function removeTagsAndContent(inputString) {
      var outputString = inputString.replace(/<h1\b[^>]*>(.*?)<\/h1>/i, '');
      outputString = outputString.replace(/<p><br><\/p>/i, '');
      return outputString;
    }
    let metatitle = "";
      let metadescription = "";
    if(metaTitle){
      metatitle = metaTitle;
      metadescription = metaDescription;
    } 

    var postHTML = removeTagsAndContent(edtorTxt);
    let wpPassword = password; 
    let newPassword = wpPassword.replace(/\s+/g, ''); 
    let categoryID;

    if(category){
      categoryID = parseInt(category)
    } else{
      categoryID = null;
    }

    const param = { 
      siteUrl, 
      username, 
      password:newPassword, 
      postTitle: articlePost.title, 
      postContent: postHTML, 
      category:categoryID,
      meta_title: metatitle ? metatitle: "", 
      meta_description: metadescription ? metadescription: "",
      status
    }; 
 
    if (featureImage) { 
      const file = featureImage;
      let base64;
      const reader = new FileReader();
  
      const readFile = new Promise((resolve, reject) => {
        reader.onload = async () => {
          const arrayBuffer = reader.result;
          base64 = btoa(new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
          param.imageBuffer = base64;
          param.fileName = "image.jpg";
          resolve();
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file); 
      });
  
      try {
        await readFile;
      } catch (error) {
        console.error('File reading failed', error);
        // handle the error and return if needed
        return;
      }
    } else if (imageURL) {
      try {
        const response = await fetch(imageURL);
        const blob = await response.blob();
        let base64data = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });

        base64data = base64data.replace("data:image/jpeg;base64,", ""); // remove prefix
        param.imageBuffer = base64data;
        param.fileName = "image.jpg";
      } catch (error) {
        console.error('Image fetching failed', error);
        return;
      }
    }

   // console.log(param)
    try {
      const publishPost = await httpsCallable(functions, 'publishPost');
     // console.log(param)
      const result = await publishPost(param);  
    
      if (result.data && result.data.success) {
       // console.log(result.data); 
        setPostPublished(true);
        setTimeout(() => {
          setPostPublished(false);
        }, 2000);   
        toast.success('Published successfully!')
        setPublishing(false)
      }
    } catch (error) {
      console.error(error); // Handle the error
      toast.error('Oops! some thing went worng!')
      setPublishing(false)
      setPublishError(true)
    }
  };
 
  const [selectedFile, setSelectedFile] = useState(null);

    const fileSelectedHandler = event => {
        setSelectedFile(event.target.files[0]);
    }

    const fileUploadHandler = async () => {
      const reader = new FileReader();
      reader.onload = async () => {
        const arrayBuffer = reader.result;
        const base64String = btoa(new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
        const functions = getFunctions();
        const uploadImageToWordpress = httpsCallable(functions, 'uploadImageToWP');
    
        try {
          const result = await uploadImageToWordpress({
            imageBuffer: base64String,
            fileName: selectedFile.name
          });
          console.log(result.data);
        } catch (err) {
          console.error(err);
        }
      }
      reader.onerror = error => console.log('Error: ', error);
      reader.readAsArrayBuffer(selectedFile);
    } 

  const renderTooltip = (props, tooltipText) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltipText}
    </Tooltip>
  );
  return (
    <> 
    {blogEditor &&
       <section className="header-txt article-post-header">
            <div className="editorHeader">
             <h5 className="float-left editor-icon"><EditorSvg/>Article Editor</h5>
          
             <div className="float-right customEditorBtns">
                  <div className="wktooltip">
                  <span><b>{wordCount}</b> words</span>
                  <Button className="ml-auto mr-auto rewrite" variant="link" size="sm"
                                                              onClick={writeMore}> <WriteMoreIcon/> Write More</Button>
                  <Button className="ml-auto mr-auto rewrite" variant="link" size="sm"
                                                              onClick={reWrite} disabled={textLength < 20 || textLength > 700}><RewriteIcon/> Rewrite</Button>
                  </div>
                  <div className="wktooltip">
                  <Button className="ml-1 mr-auto expand" variant="link" size="sm"
                                              onClick={expandTxt} disabled={textLength < 20 || textLength > 700 || tagType != "P"}><ExpandIcon/> Expand</Button>
                  </div>
                  <div className="wktooltip">
                  <Button className="ml-1 mr-auto expand" variant="link" size="sm"
                                                onClick={paragraphTxt} disabled={tagType != "H2" && tagType != "H3" && tagType != "H4" && tagType != "H5"}><ExpandIcon/> {tagType} Paragraph</Button>
                  </div>
                  <div className="wktooltip">
                  <Dropdown>
                  <Dropdown.Toggle variant="link" id="dropdown-copy" className="mr-0" disabled={contGen}><VscCopy/> Copy</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as="button" onClick={copyMyText}>Copy Text</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={copyMyHTML}>Copy HTML</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                  {/* <Button variant="link copy" size="sm" className="ml-1 mr-auto" onClick={copyMyText}><RiFileCopy2Line /> Copy</Button> */}
                  </div>
                  <div className="wktooltip">
                  <Button variant="link save" size="sm" className="ml-1 mr-auto" id={1} onClick={saveContent}><RiSaveLine /> Save</Button>
                  </div>
              </div>


            {/*   <Button variant="link" onClick={handleClick} disabled={contGen}><VscInfo/> Details</Button>
             <Overlay
              show={showDetails}
              target={target}
              placement="bottom"
              containerPadding={20}
            >
              <Popover id="popover-contained">
                <Popover.Header as="h3">Content Brief</Popover.Header>
                {projectData &&
                <Popover.Body>
                  <div className="mb-2"><strong>Blog Idea:</strong> {projectData.idea ? projectData.idea : '-'}</div>
                  <div className="mb-2"><strong>Blog Title:</strong> {projectData.title}</div>
                  <div className="mb-2"><strong>Blog Keywords:</strong> {projectData.keywords ? projectData.keywords : '-'}</div>
                  <div className="mb-2"><strong>Language:</strong> {projectData.language ? projectData.language : 'English'}</div>
                </Popover.Body>
                }
              </Popover>
            </Overlay>
            <Button variant="link" onClick={newPost} disabled={contGen}><VscNewFile/> New</Button>   */}
              </div>
          </section> }
      <Container>
      <div className={`article-post ${blogEditor ? "inEditor" : "outEditor"}`}>

          <section className="tool-panel">

              {!blogEditor && <>
              {error && <Alert variant="danger" onClose={() => setError(null)} dismissibl> {error.message}  <Button className="closeAlert" onClick={() => setError(null)}> X </Button>
               </Alert> }
              <section className="header-txt mt-3 text-center">
              <h2 className="text-center">Generate Article</h2>
              <p>Create an impressive long article using AI in just few seconds</p>
              </section>  
              
              {/* <div className="dashboardNav2 text-center  mb-4">
              <Link className="backToDashboard2" to="/tools">Back to Tools</Link>
                </div> */}
              </>
             }
              <Row>
                  <Col md={12} className="ai-board">
                  {!blogEditor && <div className="infoTooltip2"><InfoIcon/>
        <span className="tooltiptext">Content generated automatically using AI engine. Please excuse irrelevant content and reach out to us for any issues.</span>
      </div> }
      {!blogEditor &&  <>
                    <Card>
                    <Card.Body className="ai-board-body" ref={aiBoard}>
                                    <div className="ai-section height-align text-center">
                                        <div className="float-left w-100 mb-1 pt-4">
                                        {/* <label className="ai-content customBlogTitle row border-0 m-0 mt-5" >
                                            <div className="col-md-3 col-sm">
                                               <Button variant="primary text-center d-block" onClick={cusBlogTitle} value={newBlogTitle} disabled={!newBlogTitle && newBlogTitle.length < 20}>Add Title</Button>
                                            </div>
                                        </label>  */}
                                          <div className="row">
                                  <Form.Group className="mb-3 col-12 text-left">
                                    <Form.Label>Article Title</Form.Label>
                                    <Form.Control type="text" name="blogTitle" defaultValue={blogTitleVal} className="col-md-12" placeholder="Add your article title" onChange={addArticleTitle}/>
                                  </Form.Group>
                                           </div>
                                        <div className="row">
                                  <Form.Group className="mb-3 col-6 text-left">
                                      <Form.Label>Keywords (optional)</Form.Label>
                                      <Form.Control type="text" name="blogKeywords" defaultValue={blogKeywordsVal} placeholder="eg: Paid marketing, SEO" onBlur={addArticleKeywords}/>
                                  </Form.Group>
                                  <Form.Group className="mb-3 col-6 text-left">
                                      <Form.Label>Language</Form.Label>
                                      <Form.Select name="language" id="language" className="form-control" onChange={addArticleLanguage}>
                                      <option value="English (US)">English (US)</option>
<option value="English (UK)">English (UK)</option>
<option value="French">French</option>
<option value="Spanish">Spanish</option>
<option value="German">German</option>
<option value="Italian">Italian</option>
<option value="Dutch">Dutch</option>
<option value="Arabic">Arabic</option>
<option value="Portuguese">Portuguese</option>
<option value="Portuguese (BR)">Portuguese (BR)</option>
<option value="Swedish">Swedish</option>
<option value="Norwegian">Norwegian</option>
<option value="Danish">Danish</option>
<option value="Finnish">Finnish</option>
<option value="Romanian">Romanian</option>
<option value="Czech">Czech</option>
<option value="Slovak">Slovak</option>
<option value="Slovenian">Slovenian</option>
<option value="Hungarian">Hungarian</option>
<option value="Hebrew">Hebrew</option>
<option value="Polish">Polish</option>
<option value="Turkish">Turkish</option>
<option value="Russian">Russian</option>
<option value="Hindi">Hindi</option>
<option value="Thai">Thai</option>
<option value="Japonese">Japonese</option>
<option value="Chinese (Simplified)">Chinese (Simplified)</option>
<option value="Korean">Korean</option>
<option value="Indonesian">Indonesian</option>
<option value="Greek">Greek</option>
<option value="Bulgarian">Bulgarian</option>
<option value="Ukrainian">Ukrainian</option>
<option value="Estonian">Estonian</option>
<option value="Latvian">Latvian</option>
<option value="Lithuanian">Lithuanian</option>
<option value="Croatian">Croatian</option>
<option value="Serbian">Serbian</option>
<option value="Bosnian">Bosnian</option>
<option value="Macedonian">Macedonian</option>
<option value="Albanian">Albanian</option>
<option value="Vietnamese">Vietnamese</option>
<option value="Hindi">Hindi</option>
<option value="Bengali">Bengali</option>
<option value="Telugu">Telugu</option>
<option value="Marathi">Marathi</option>
<option value="Tamil">Tamil</option>
<option value="Urdu">Urdu</option>
<option value="Gujarati">Gujarati</option>
<option value="Kannada">Kannada</option>
<option value="Odia">Odia</option>
<option value="Malayalam">Malayalam</option>
																	  </Form.Select>
                                  </Form.Group>
                                  </div>
                                        </div>
                                    </div>
                                      <div>

                                      <div className="row mb-2">
                                      <div className="col-md-6">
                                      <Form.Label>Article subheadings</Form.Label>
                                      </div>
                                      <div className="col-md-6">
                                        <Button variant="outline-primary" size="sm" onClick={reGetBlogOutline} 
                                        className="float-right" type="submit" disabled={!user || (articlePost.title.length < 8) && (!seoContent)}>
                                          Generate Article subheadings</Button> 
                                      </div>
                                      </div>
                                            {/* {blogOutlineData && blogOutlineData.map((element, index) => (
                                            <div className="ai-section height-align text-center articleOutlineInput" key={index + 1}>
                                               <div className="ai-content flexbox" >
                                               <ContentEditable className="subHeading" html={element.secTitle} defaultValue={element.secTitle} id={index} onChange={updateBlogOutline} placeholder="Type subheading..." />
                                                <div className="selectBtn">
                                                    <Button variant="outline-secondary" size="sm" className="deleteItem mt-1" id={index} onClick={deleteBlogOutline}><RiDeleteBinLine/></Button> 
                                                </div>
                                            </div>
                                            </div>
                                            ))} */}
                                            <div className="outlineDroppable">
                                            <DragDropContext onDragEnd={handleDragEnd}>
    <Droppable droppableId="droppable">
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {blogOutlineData.map((element, index) => (
            <Draggable key={index} draggableId={index.toString()} index={index}>
              {(provided) => (
                <div
                  className="ai-section height-align text-center articleOutlineInput"
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <div className="ai-content flexbox">
                    {/* <FiMove className="drag-icon" /> */}
                    <DraggableIcon/>
                    <ContentEditable
                      className="subHeading"
                      html={element.secTitle}
                      defaultValue={element.secTitle}
                      id={index}
                      onChange={updateBlogOutline}
                      placeholder="Type subheading..."
                    />
                    <div className="selectBtn">
                      <Button variant="outline-secondary" size="sm" className="deleteItem mt-1" id={index} onClick={deleteBlogOutline}>
                        <RiDeleteBinLine />
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>
                                            </div>
                                            <div>
                                            </div>
                                      </div> 
                                      
                                      <Button variant="outline-secondary" size="sm" className="addItem mr-0 float-right" onClick={addSubHeading}>+ Add new</Button>
                                      
                                      {loading &&  
                                      <div className="w-100 text-center float-left">
                                         <Loader2 />
                                      </div>
                                      }
                                      </Card.Body>
                                      </Card>
                                      <div className="mainButtonss mt-3 pb-1">
                                      {!user && <><Button variant="primary" className="mx-auto" onClick={isAuthenticated}>
                                              Login to Generate
                                          </Button></>}
                                          
                                      {/* { user && <> 
                                       <Button variant="primary" className="mx-auto" onClick={genSection} disabled={blogOutlineData.length < 3 || articlePost.title.length < 5}>Generate Article</Button>
                                          </>}  */}
                                          </div>

                                      <div className="mainButtons pb-2">
                                      { user && <>
                                       <Form.Select name="wordRange" id="wordRange" className="form-control" defaultValue={'1000'}  onClick={addWordRange}>  
                                            <option value="1000">1000+ Words</option>
                                            <option value="2000">2000+ Words</option>
                                            <option value="3000">3000+ Words</option>
                                            </Form.Select>    
                                         <Button variant="success" size="md" className="" onClick={genSection} disabled={blogOutlineData.length < 3 || articlePost.title.length < 5}>Generate Article</Button>
                                          </>} 
                                          </div>
                                   </> } 

                          {/* <Card className={`mt-0 ${blogEditor ? "showEditor" : "hideEditor"}`}>
                          <Card.Body className="ai-board-body p-0 qullEditorWrap" ref={aiBoard}>
                                <div className="qullTextEditor"> <div ref={quillRef} /> </div>
                                {loading &&<div className="genMoreLoad"> <Loader /> </div> }
                          </Card.Body>
                          </Card> */}


                  </Col>
              </Row>

          </section>
        </div>
      </Container>
      <Container className={`articleEditor article-post mt-0 ${blogEditor ? "showEditor" : "hideEditor"}`}>
<Row className="h-100">
<Col className="col-md-2 editorOptions articleNav px-0"> 
<Card className={`mt-0 p-3 imageSidbar ${blogEditor ? "showEditor" : "hideEditor"}`}>
  {article &&                
      <div>
                {/* <table className="table table-hover myArticles table-fixed1">
                <thead> 
                <tr> 
                  <th width="100%">Articles <span className="aCount">{article.length}</span></th>    
                </tr>
              </thead>
              <tbody> 
              {article && article.map( (project, index) => {
              if(project.type == 'article-writer'){
              return (                      
                <tr key={project.id}> 
                  <td>
                    <div>
                    <Link to={`/article-writer/?id=${project.id}`} className="link" size="sm" id={project.id} disabled={showPerecent}>
                    {project.title}</Link> 
                    </div>
                    <div className="footerTxt float-left w-100">
                       <span><strong>Date:</strong> {new Date(project.creationDate * 1000).toDateString()} </span> 
                       <span><strong>Words:</strong> {project.wordCount}</span>  
                        </div>
                    </td>  
                </tr> 
                  )}                                    
              })} 
              {article.length == 0 && 
                      <tr className="noResults">
                        <td colSpan="4"> <span>No content found!.</span></td>
                      </tr>
                }
              </tbody> 
          </table>  */}
           <div className="articleImages"> 
           {images.map((image) => (<>
            <div key={image.id} className="aImage"> 
              <p>Photo by{' '} <a href={image.photographer_url} target="_blank" rel="noopener noreferrer"> {image.photographer} </a> </p>
              <img src={image.src.small} alt={image.alt} /> 
              <div className="btnSection">
                <button onClick={() => handleFeatureImage(image)} className="btn btn-link btn-sm">
                Set Feature Img 
                  </button>
                <button className="btn btn-link btn-sm" onClick={() => handleAddImage(image)} disabled={showPerecent}>Add Image</button>
              </div> 
            </div> 
          </>))}
      {imgLoading && (
        <div className="w-100 text-center text-center">
          <Loader2 />
        </div>
      )}
     <div className="mt-3 mb-3 mx-auto text-center">
     {!imgLoading && (images.length > 1) && <button onClick={handleLoadMore} className="btn btn-link btn-sm loadmore">Load More</button>}  
     </div>
    </div> 
      </div>  }
      </Card>
        <form onSubmit={handleSearchSubmit}>
      <div className="input-group search-input-group">
          <div className="form-outline">
          <input type="text" id="form1" className="form-control" 
                  name="query"
                  placeholder="Search for images..."
                  defaultValue={query}
                  readOnly={showPerecent}
                  /> 
          </div>
          <button type="submit" className="btn btn-primary"  disabled={showPerecent}>
            <PaperPlaneIcon/>
          </button>
      </div> 
        </form>
  </Col>
     <Col className="col-md-7 editorMain px-0">
         <Card className={`mt-0 ${blogEditor ? "showEditor" : "hideEditor"}`}>
         <div className="editorTools">
                     <div className="wktooltip"> 
                     <Button className="ml-auto mr-auto rewrite" variant="link" size="sm"
                                                                 onClick={writeMore} disabled={textLength > 1}><WriteMoreIcon/> Write More</Button>
                     <Button className="ml-auto mr-auto rewrite" variant="link" size="sm"
                                                                 onClick={reWrite} disabled={textLength < 20 || textLength > 700}><RewriteIcon/> Rewrite</Button>
                     </div>
                     <div className="wktooltip expandBtn">
                     <Button className="ml-1 mr-auto expand" variant="link" size="sm"
                                                 onClick={expandTxt} disabled={textLength < 20 || textLength > 700 || tagType != "P"}><ExpandIcon/> Expand</Button>
                     </div>
                     <div className="wktooltip">
                     <Button className="ml-1 mr-auto expand" variant="link" size="sm"
                                                   onClick={paragraphTxt} disabled={tagType != "H2" && tagType != "H3" && tagType != "H4" && tagType != "H5"}><SectionIcon/> Paragraph</Button>
                     </div>  
                 </div>
         <Card.Body className="ai-board-body p-0 qullEditorWrap" ref={aiBoard}>
               <div className="qullTextEditor"> <div ref={quillRef} /> </div>
               {loading &&<div className="genMoreLoad"> 
                    <div className="articleLoader mx-auto">
                      {showPerecent && <><h3>Generating Article... ({partValue}%)</h3>
                       <p>Please wait, this can take a few minutes.</p>  
                       </>}
                      {!showPerecent && <><h3>Loading Content...</h3>
                       <p>Please wait, this can take a few seconds.</p>  
                       </>} 
                      <Loader2 />
                   </div>
                </div> }
               {netError &&  <Alert variant="danger"> Oops! A network error occurred. Please try again. 
               <Button className="closeAlert" onClick={() => setNetError(false)}> X </Button> </Alert>}
         </Card.Body> 
         </Card>
     </Col>
     <Col className="col-md-3 editorOptions pl-0 pr-0">
         <Card className={`mt-0 ${blogEditor ? "showEditor" : "hideEditor"}`}>
             <Card.Body className="articleTabWrap">
             <Tabs
                  defaultActiveKey="articleInfo"
                  id="uncontrolled-tab-example"
                  className="autoPosting mb-3"
                >
                  <Tab eventKey="articleInfo" title="Article Info">
                  <div className="row sec-action mt-3 mb-5">
                
                <div className="col-12 mb-3">   
                       <Button variant="primary save" size="md" className="ml-1 mr-auto" id={1} onClick={saveContent} disabled={loading}><RiSaveLine /> Save</Button>
                       </div> 
                  <div className="col-12">
                    <Dropdown>
                       <Dropdown.Toggle variant="secondary" size="md" id="dropdown-copy" className="mr-0" disabled={contGen}><VscCopy/> Copy content</Dropdown.Toggle>
                       <Dropdown.Menu>
                         <Dropdown.Item as="button" onClick={copyMyText}>Copy Text</Dropdown.Item>
                         <Dropdown.Item as="button" onClick={copyMyHTML}>Copy HTML</Dropdown.Item>
                       </Dropdown.Menu>
                     </Dropdown>
                  </div> 
                </div>   
              <div className="row mb-5">
              <div className="col-md-12">
                  <div className="tile">
                      <div className="wrapper">
                          {/* <div className="header">SEO Stats</div>  */}
                          {tagCount && <>
                          <div className="stats"> 
                              <div>
                                  <strong>Words</strong> {wordCount}
                              </div> 
                              <div>
                                  <strong>H1</strong> {tagCount.h1Tag}
                              </div> 
                          </div>
                          <div className="stats"> 
                              <div>
                                  <strong>H2</strong> {tagCount.h2Tag}
                              </div>
  
                              <div>
                                  <strong>Paragraphs</strong> {tagCount.pTag}
                              </div> 
                          </div> 
                          <div className="stats"> 
                             <div>
                                  <strong>Links</strong> {tagCount.linkTag}
                              </div>
                              <div>
                                  <strong>Images</strong> {tagCount.imgTag}
                              </div> 
                          </div> 
                          </>}
                      </div> 
              </div>
              </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-12">
                  <div className="advanceOptions metaDataWrap">
                                    <Accordion>
                                      <Accordion.Item eventKey="0">
                                        <Accordion.Header>Meta Data</Accordion.Header>
                                        <Accordion.Body>  
                                        <div className="metaData">
                                    <div className="input-label">
                                    <strong>Meta Title</strong> 
                                        <button
                                          className="btn btn-outline-secondary  p-0"
                                          onClick={() => copyToClipboard(titleRef)}
                                        >
                                          <CopyTextIcon/>
                                        </button>
                                    </div>
                                      <div className="input-group">
                                        <input
                                          className="form-control meta-title"
                                          type="text"
                                          value={metaTitle}
                                          onChange={(e) => setMetaTitle(e.target.value)}
                                          ref={titleRef}
                                        />
                                      </div>
                                      <p className="mute">{metaTitle.length} character </p>
                                      <div className="input-label">
                                      <strong>Meta Description</strong>
                                        <button
                                          className="btn btn-outline-secondary  p-0"
                                          onClick={() => copyToClipboard(descriptionRef)}
                                        >
                                          <CopyTextIcon/>
                                        </button>
                                      </div>
                                      <div className="input-group">
                                        <textarea
                                          className="form-control meta-description" 
                                          rows="3"
                                          value={metaDescription}
                                          onChange={(e) => setMetaDescription(e.target.value)}
                                          ref={descriptionRef}
                                        />
                                      </div>
                                      <p className="mute">{metaDescription.length} characters</p>
                                      <div className="input-label">
                                      <strong>Keywords</strong>
                                        <button
                                          className="btn btn-outline-secondary p-0"
                                          onClick={() => copyToClipboard(keywordsRef)}
                                        >
                                          <CopyTextIcon/>
                                        </button>
                                        </div>
                                      <div className="input-group">
                                        <input
                                          className="form-control meta-keywords"
                                          type="text"
                                          value={metaKeywords}
                                          onChange={(e) => setMetaKeywords(e.target.value)}
                                          ref={keywordsRef}
                                        />
                                      </div>
                                    </div> 
                                                {/* {metaData &&    
                                                    <div>
                                                      {metaData}
                                                    </div>  
                                                  } */}
                                        </Accordion.Body>
                                      </Accordion.Item> 
                                    </Accordion>
                  </div>
                </div>
              </div>
                  </Tab>
                  <Tab eventKey="publish" title="WordPress Auto Posting">
                      <div className="publishPost"> 
          {/* <div>
              <input type="file" onChange={fileSelectedHandler} />
              <button onClick={fileUploadHandler}>Upload</button>
          </div> */}

          {/* <Form onSubmit={handleWPAuth}>
          <h6 className="mt-4 mb-3">WordPress Authentication </h6>
          <Form.Group controlId="website" className="mb-3">
          <Form.Label>Website URL</Form.Label>
          <Form.Control
          type="text"
          placeholder="https://dailymockup.com"
          value={siteUrl}
          onChange={(e) => setSiteUrl(e.target.value)}
          required
          />
          </Form.Group>

          <Form.Group className="mb-3">
          <Form.Label>Username</Form.Label>
          <Form.Control
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required 
          />
          </Form.Group>

          <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
          type="password"
          placeholder="Application Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          />
          </Form.Group>

          <Button variant="primary" type="submit" disabled={!siteUrl || !username || !password}>
          Authenticate
          </Button>
          </Form> */}
               {publishError &&  <Alert variant="danger"> An error occurred. Please verify your WordPress username and password, then try again.
               <Button className="closeAlert" onClick={() => setPublishError(false)}> X </Button> </Alert>}
                      <Form onSubmit={handlePublishPost}>
                      <Form.Group as={Row} controlId="website" className="inputControl mb-3">
  <Form.Label column sm={3} className="pr-0">Site URL   
  <OverlayTrigger
        placement="left"
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => renderTooltip(props, "Enter the URL of your WordPress site. Example: https://yourwebsite.com")}
      >
        <span className="helpSec d-inline-block">
          <sup>(?)</sup>
        </span>
      </OverlayTrigger>
      </Form.Label>
  <Col sm={9} className="pl-0">
    <Form.Control
      type="text"
      placeholder="https://your-wp-site.com"
      value={siteUrl}
      onChange={(e) => setSiteUrl(e.target.value)}
      required
    />
      </Col>
  </Form.Group>
  
  <Form.Group as={Row} className="inputControl mb-3">
    <Form.Label column sm={3} className="pr-0">Username   
      <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={(props) => renderTooltip(props, "Enter the username you use to log into your WordPress dashboard")}
          >
            <span className="helpSec d-inline-block">
              <sup>(?)</sup>
            </span>
          </OverlayTrigger></Form.Label>
    <Col sm={9} className="pl-0">
      <Form.Control
        type="text"
        placeholder="WP Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        required 
      />
          </Col>
    </Form.Group>

    <Form.Group as={Row} className="inputControl mb-3">
    <Form.Label column sm={3} className="pr-0">Password  
      <OverlayTrigger
        placement="left"
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => renderTooltip(props, "Enter the application password generated from your WordPress dashboard")}
      >
        <span className="helpSec d-inline-block">
          <sup>(?)</sup>
        </span>
      </OverlayTrigger></Form.Label>
    <Col sm={9} className="pl-0">
      <Form.Control
        type="password"
        placeholder="WP Site Application Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      </Col>
    </Form.Group> 
    <span className="installGuide">
    <a href="https://wordkraft.ai/tools/setup-guide-for-wordpress-auto-posting" target="_blank" rel="noopener noreferrer">
    Setup guide for WordPress Auto Posting</a>
    </span> 
    <hr className="mt-0"/>
      {/* <Form.Group controlId="title" className="inputControl mb-3">
        <Form.Label>Article Title</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter title"
          value={title}
          onChange={handleTitleChange}
        />
      </Form.Group> */}
  
      {/* <Form.Group controlId="featureImage" className="mb-3">
        <Form.Label>Feature Image</Form.Label>
        <Form.Control type="file" onChange={handleImageChange} />
      </Form.Group> */}
 
      <Form.Group as={Row} controlId="featureImage" className="mb-3">
      <Form.Label column sm={3} className="pr-0">Feature Image</Form.Label>  
      <Col sm={9} className="pl-0">
        {featureImg &&
            <div className="mb-1">
          <img src={featureImg} alt="Preview" width="100%" />
          </div>
        } 
        <Form.Control type="file" onChange={handleImageChange} />
        <p className="txt-mute mb-0"><i>Add new image or pick from sidebar</i></p>
        </Col>
      </Form.Group>
        {/* 
      <Form.Group controlId="imageUrl" className="mb-3">
        <Form.Label>Or Provide Image URL</Form.Label>
        <Form.Control type="text" onChange={handleImageUrlChange} />
      </Form.Group> */}


      <Form.Group as={Row} controlId="category" className="inputControl mb-3">
        <Form.Label column sm={3} className="pr-0">Category Id
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => renderTooltip(props, "Enter the ID of the category you want to assign to the post. You can find this in the categories section of your WordPress dashboard")}
      >
        <span className="helpSec d-inline-block">
          <sup>(?)</sup>
        </span>
      </OverlayTrigger>
      </Form.Label> 
      <Col sm={9} className="pl-0">
        <Form.Control  
          type="number"
          placeholder="Category Id"
          value={category}
          onChange={(e) => setCategory(e.target.value)} 
            />
            </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="status" className="inputControl mb-4">
        <Form.Label column sm={3} className="pr-0">Status</Form.Label> 
        <Col sm={9} className="pl-0">
       <Form.Control as="select" value={status} onChange={handleStatusChange}>
          <option>draft</option>
          <option>publish</option>
        </Form.Control> 
      <p className="mt-1">  <i>{isDraft ? "Article will be saved as a draft in WordPress site." : "Article will be published immediately."}</i></p>
        </Col>
      </Form.Group>

     <div>
     {/* <Form.Label>Publish to wordpress site</Form.Label>  */}
      <div className="publishBtnWrap">
        <Button variant="primary" type="submit" disabled={!siteUrl || !username || !password || publishing}>
            {isDraft ? "Save Draft" : "Publish Article"}
          </Button>
          {publishing &&
            <span>
            <Loader2/>
          </span>
          }
          {postPublished &&
            <span className="published">Published successfully!</span>
          }
      </div>  
     </div>
    </Form>
                      </div>
                  </Tab> 
              </Tabs> 
             </Card.Body>
           </Card>                              
     </Col>
</Row>
</Container>
<Toaster position="top-center" reverseOrder={false} />

      {!loginState &&
      <Modal
        show={showModal}
        onHide={handleClose}
        keyboard={false}
        backdrop="static"
        className="aiModal upgradeModal"
        >
        <Modal.Body className="text-center">
          {modalData &&
            <>
              {modalData.title == "Upgrade your account" && <>
                {/* <div className="upgradeIcon"><UpgradeIcon/></div>
                <h3 className="m-0 mb-2">{modalData.title}</h3>
                <p>{modalData.discription}</p> */}
                <InlinePricing/>
                </>
             }
              <div className="row px-4">
                 {modalData.title == 'Rewrite sentence' && <>
                  <h6>Old Sentence</h6>
                      <div className="colz">
                          {modalData.oldData}
                      </div>
                      <h6>New Sentence</h6>
                  </>}
                  {modalData.newData && 
                  <div className="colz">
                      {modalData.newData}
                  </div>
                  }
                </div>
            </>
          }
          <div className="text-center mt-3">
            {modalType == "newPost" &&  <>
         
            {modalData.title == "Rewrite sentence" && <Button variant="secondary" size="md" className="mr-4" onClick={retainOldData}> Cancel </Button> }
            {modalData.title != "Rewrite sentence" && <Button variant="secondary" size="md" className="mr-4" onClick={handleClose}> Cancel </Button> }
            <Button variant="primary" size="md" onClick={acceptedData} data-id="newPost">Accept new</Button></>
            }
            {!modalData.action && modalData.title !== "Upgrade your account" &&
            <Button variant="primary" size="md" onClick={handleClose}>Ok</Button>
            }
             {modalData.title == "Upgrade your account" && <>
             <Button variant="secondary" size="md" className="mr-4" onClick={handleClose}> Cancel </Button>
           <Button variant="primary" size="md" onClick={()=> navigate(`/pricing`)}>Upgrade</Button>
           </>}
         </div>
        </Modal.Body>
      </Modal>}
      {loginState &&
      <Modal show={showModal} onHide={handleClose} keyboard={false} className={loginState ? "loginState" : "upgradeModal"}> 
            <Modal.Body className="text-center">  
              <InlineLogin onLoginSuccess={handleLoginSuccess}/>  
            </Modal.Body> 
       </Modal> 
       } 
    </>
  );
};

export default ArticlePost;



