import {React, useState, useEffect, useContext, useRef} from "react";
import { Helmet } from 'react-helmet';
import { useQuill } from "react-quilljs";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router"; 
import { useUserAuth } from "../../../context/UserAuthContext";
import { SubscriberContext } from "../../../context/subscriberContext";   
import { RiFileCopy2Line, RiSaveLine} from "react-icons/ri";
import Loader from "../../shared/utilities/loader/Loader";
import Loader2 from "../../shared/utilities/loader2/Loader2"; 
import { Link  } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'; 
import toast, { Toaster } from 'react-hot-toast';
import CommonDataServices from "../../../services/common.services";
import AIToolsDataService from "./services/AiTools.services";  
import "./AiTools.css";  
import Accordion from 'react-bootstrap/Accordion';
import { SideArrow, UniversalIcon, ExpandIcon, RewriteIcon, UpgradeIcon } from "../../../assets/svg/SvgIcons";
import UsageBadge from "../../shared/utilities/usage-badge/UsageBadge";
import { getFunctions, httpsCallable } from "firebase/functions";  
import SidebarPanel from "../../dashboard/sidebar/SidebarPanel"; 
import Templates from "../../dashboard/templates/Templates";   
import "quill/dist/quill.snow.css"; // Add css for snow theme
import {marked} from 'marked';
import { useLocation } from 'react-router-dom'; 
import InlineLogin from "../../InlineLogin";   
import InlinePricing from "../../views/InlinePricing";
import TemplatesData from "../../../services/templaetsData/TemplatesData";
import ToolsData from "../../../services/toolsData/ToolsData";
import { useFavorites } from '../../../context/FavoritesContext';  
import MarkdownIt from 'markdown-it';

const AiTools = () => { 
  const md = new MarkdownIt();
  const { user } = useUserAuth();  
  const navigate = useNavigate();  
  const functions = getFunctions();  
  const theme = 'snow';
  var toolbarOptions = [    
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }], 
    ['bold', 'italic', 'underline', 'strike'],            
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],      
    [{ 'indent': '-1'}, { 'indent': '+1' }],   
    [{ 'align': [] }], 
    ['clean']                                      
  ];
  const modules = { 
    toolbar: toolbarOptions 
  };
 
  const placeholder = '';
  const formats = [
    'header',
    'align',
    'bold', 'italic', 'underline', 
    'list', 'bullet',
    'indent', 'indent',
    'strike','clean'
];
  const { quill, quillRef } = useQuill({ theme, modules, formats, placeholder }); 
  
 // const {id, category} = useParams();
  const [data, setData] = useState([]);
  const [queryObj, setQueryObj] = useState(null);
  const [mainParam, setMainParam] = useState(null);
  const [loading, setLoading] = useState(false); 
  const [projectId, setProjectId] = useState(null);
  const [files, setFiles] = useState(1);
  const [editMode, setEditMode] = useState(false)  
  const [tockenWords, setTockenWords] = useState(1); 
  const [formValue, setFormValue] = useState({name:'', toolObj:''});
  const [textLength, setTextLength] = useState(0);
  const {subscriber, setSubscriber} = useContext(SubscriberContext);
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true); 
  const handleClose = () => setShowModal(false);
  const [sideNav, setSideNav] = useState(false);
  
  const [cmpFormObj, setCmpFormObj] = useState(null);
  const aiBoard = useRef(null); 
 
  const updateSubscriber = async () =>{
    const subData = await CommonDataServices.getUsageLimit(user.uid);
    if(subData.data()){
      //console.log(subData.data())
      setSubscriber(subData.data())
    }
  } 
  const location = useLocation();

  useEffect(() => {   
  const fileType = new URLSearchParams(location.search).get("prompt")
  const fileId = new URLSearchParams(location.search).get("id")
  const tempData = TemplatesData;
  const toolsData = ToolsData;
  const promptId = new URLSearchParams(location.search).get("prompt");
    //console.log(toolsData);
    //console.log(fileId); 
  
    if(fileId){ 
      getProjectContent(fileId);  
      //setQueryObj(queryObj);
    }  
    window.scrollTo(0, 0);  
    
if(fileType){
  //console.log(fileType);
  for (var key in toolsData) { 
    if (!toolsData.hasOwnProperty(key)) continue; 
    var obj = toolsData[key];
    for (var prop in obj) {  
        if (!obj.hasOwnProperty(prop)) continue;  
        if(obj.prompt == fileType){
          if (quill) {
            quill.setText('');
          }
          setData([])
          setQueryObj(null)
          setProjectId(null) 
          setCmpFormObj(null)
         // console.log(obj.title)
          document.title = `${obj.title} - ${promptId}`;
          setFormValue({toolObj : obj });
        } 
        // console.log(prop + " = " + obj[prop]);
      }
     }
   }   
   
},[location, quill]) 


  useEffect(() => {
    setTockenWords(tockenWords)
    //console.log(tockenWords)
  },[tockenWords])   

  const getProjectContent = async (prjID) => {
    setLoading(true)
    setEditMode(false) 
    setProjectId(prjID)
    if(prjID){  
        //console.log(prjID)
        const getProjectDetails = await AIToolsDataService.getProjectData(user.uid, prjID); 
        const dataObj = getProjectDetails.data();  
        dataObj.id = prjID;
        setCmpFormObj(dataObj);
        // console.log(dataObj)         
        setFormValue({toolObj : dataObj.toolObj });
        // if (quill){ 
        //   quill.insertText(0, dataObj.text, 'bold', false); 
        // }  

      // const markdownContent = `# Heading\n\nThis is *Markdown* content.\n\n## Another Heading\n\n1. List item\n2. Another item`;
        // const markdownContent = `# Heading
        // This is *Markdown* content.
        // ## Another Heading
        // 1. List item
        // 2. Another item`;
        
       // console.log(dataObj.text)
        // const htmlContent = md.render(dataObj.text);
        // console.log(htmlContent)

        // const htmlContent = md.render(dataObj.text);
        // if (quill) {
        //     quill.clipboard.dangerouslyPasteHTML(htmlContent);
        // } 

        if (quill) {
            quill.clipboard.dangerouslyPasteHTML(dataObj.text);
        }
        

       // setFormValue({...formValue, toolObj : obj });
        //console.log(dataObj)

        // setFormValue({...formValue, 
        //   name : dataObj.title, 
        //   benefits :dataObj.about
        // });
         setQueryObj(dataObj)
        // setQueryObj({
        //   productName: dataObj.title,
        //   variant: 2,
        //   type: dataObj.type,
        //   files: dataObj.files,
        //   text: dataObj.text,
        //   productFeatures: dataObj.about,
        //   about: dataObj.about,
        //   tone: dataObj.tone,
        //   id:dataObj.id,
        //   status: dataObj.status,
        //   language:dataObj.language,
        //   title: dataObj.title,
        //   creationDate: dataObj.creationDate
        // })
    }  
    setLoading(false); 
  };  

  
  const [loginState, setloginState] = useState(false); 
  const isAuthenticated = () => {
    if(user){
      return true;
    } else {
      setloginState(true)
      setShowModal(true) 
      return false;
    } 
  }
  const handleLoginSuccess = (user) => { 
    setloginState(false)
    setShowModal(false) 
    console.log('Login successful:');
  }; 


const onFormSubmit = async (e) => { 
     e.preventDefault();
     const currentDate = Math.floor(Date.now() / 1000) 
if(subscriber.totalWords >= subscriber.wordsLimit || subscriber.premiumState === false || currentDate > subscriber.planEndDate){
      setShowModal(true)
      return;
     }
     setLoading(true)
     const formData = new FormData(e.target);
    // console.log(Object.fromEntries(formData.entries()))
     let formDataObj = Object.fromEntries(formData.entries());
     formDataObj.num = 1;
     setQueryObj(formDataObj); 
    
     console.log(formDataObj)   
     let paramConfig = {
      type: "quickTemplate",
      titleVal: formDataObj.title,
      aboutVal : formDataObj.category,
      toneVal : formDataObj.tone ? formDataObj.tone: "Formal",
      languageVal : formDataObj.language ? formDataObj.language: "English",
      variantVal : formDataObj.variant ? formDataObj.variant: 2,
      creativeVal : formDataObj.creativity ? formDataObj.creativity: 0.85,
     }    

     setMainParam(paramConfig)   
    const apiRequestBody = formDataObj; 
   // console.log(apiRequestBody)
     try {      
      const response = await fetchAPIData(apiRequestBody);
      if(response){    
        let joinArray = data.concat(response); 
        setData(joinArray);  
        setLoading(false)
        //console.log(response.data.choices[0].text) 
        let dataObj = {text: ''};
        joinArray.forEach(element => {
          let choicTxt = element.text; 
         // console.log(joinArray.length) 
          if(joinArray.length == 1){
            dataObj.text += choicTxt
          } else{
            dataObj.text += choicTxt + `\n\n--------------------------------------\n\n`
          } 
        }); 

       //  quill.setText(dataObj.text);
       
       const htmlContent = md.render(dataObj.text);
       if (quill) {
           quill.clipboard.dangerouslyPasteHTML(htmlContent);
       } 
       
       

        const inputKeys = Object.keys(formDataObj).filter(key => key.startsWith('input'));
const inputValues = inputKeys.reduce((obj, key) => {
    obj[key] = formDataObj[key];
    return obj;
}, {});

// Map through the inputs array and assign values from formDataObj
formValue.toolObj.inputs = formValue.toolObj.inputs.map((input, index) => {
    const valueKey = `input${index}`;
    return {
        ...input,
        inputValue: inputValues[valueKey] || ""
    };
});
      
        formDataObj.toolObj = formValue.toolObj;
        formDataObj.text = htmlContent;  
        saveContent(formDataObj, htmlContent);
        setLoading(false)

        setCmpFormObj(formDataObj)
        //console.log(formDataObj)  
        // console.log(formValue)
        // console.log(htmlContent)      
      }; 
    } catch (error) {
      //console.log(error.message);
      setLoading(false) 
    } 
  }; 
  const fetchAPIData = async (apiRequestBody) => {
    const fetchData = await fetch("https://us-central1-wordkraft-bb683.cloudfunctions.net/aitools_api", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(apiRequestBody)
    });  
    const response = await fetchData.json();
    //console.log(response)
   CommonDataServices.usageTrackingData3(user.uid, response).then(() => {
      updateSubscriber();
    });
    return response;
}
  useEffect(() => {
    setData(data) 
    wordCounter();   
  }, [data])

  const wordCounter = () => {  
    if(data){
      data.map((item, i) => {
        let count = item.text.split(' ').filter(word => word !== '').length; 
        data[i].count = count; 
      }) 
    } 
    setData(data) 
   }
   useEffect(() => {
    if (!projectId && cmpFormObj) {
    // console.log(cmpFormObj);
      // Perform actions that depend on the updated cmpFormObj
    }
  }, [cmpFormObj, projectId]);
 
const saveContent = (objData) => {
    //const id = e.currentTarget.id; 
    let contentObj = objData;   
    // console.log(projectId)
    // console.log(objData)
    // console.log(cmpFormObj)
    if(cmpFormObj){
      contentObj = cmpFormObj;
    } else{
      contentObj = objData;
    }
    if(!contentObj.id){    
      // console.log(contentObj)  
      contentObj.type = 'tools-content';
      // contentObj.status = true;
      contentObj.creationDate = Math.floor(Date.now() / 1000);
      // //contentObj.text = quill.getText(); 
      // contentObj.text = htmlContent; 
      // contentObj.text = htmlContent;
      try{
        AIToolsDataService.addProject(user.uid, contentObj).then((data) => {  
          console.log(data)
          if(data){
            setProjectId(data.id); 
            const projectId = data.id;
            // console.log(projectId)            
            setCmpFormObj({...cmpFormObj, id: projectId})
            toast.success('Tool Content saved!')  
            try{
              AIToolsDataService.updateProjectId(user.uid, projectId);
            } catch (err){
              console.log(err.message);
              toast.error('Oops! some thing went worng!')  
            } 
          }
          //console.log(projectId)
        });    
      } catch (err) { 
        console.log(err.message);
        toast.error("Oops! something went wrong. Please check with the support team.")
      }    
    } else{ 
    //  console.log('save')
      // const contentObj = {
      //   type: "tools-content",
      //   creationDate: Math.floor(Date.now() / 1000),
      //   title: queryObj.productName,
      //   about: queryObj.productFeatures,
      //   files: files,
      //   status: true,
      //   language: queryObj.language ? queryObj.language: 'english',
      //   tone: queryObj.tone ? queryObj.tone: 'formal',
      //   text: quill.getText(),
      //   //text:quill.root.innerHTML,
      //   prjId: projectId
      // } 
      console.log(cmpFormObj)
      const contentObj = cmpFormObj;
      contentObj.text = quill.root.innerHTML;      
      contentObj.type = 'tools-content'; 
      contentObj.creationDate = Math.floor(Date.now() / 1000);

      // console.log(contentObj)
      // console.log(quill.getText())
      // console.log(quill.root.innerHTML)
      try{
            AIToolsDataService.updateProject(user.uid, contentObj);
            toast.success('Content updated!')  
        } catch (err){
            console.log(err.message);
            toast.error('Oops! some thing went worng!')  
        } 
    } 
 }
 
// const updateContent = (e) => {
//   //const id = e.currentTarget.id; 
//   console.log(cmpFormObj)  
//   console.log(queryObj)
//   console.log(projectId)
//   // const contentObj = {
//   //   type: "tools-content",
//   //   creationDate: Math.floor(Date.now() / 1000),
//   //   title: queryObj.productName,
//   //   about: queryObj.productFeatures,
//   //   files: files,
//   //   status: true,
//   //   language: queryObj.language ? queryObj.language: 'english',
//   //   tone: queryObj.tone ? queryObj.tone: 'formal',
//   //   //text: quill.getText(),
//   //   text:quill.root.innerHTML,
//   //   prjId: queryObj.id
//   // } 
//   const contentObj = cmpFormObj;
//   contentObj.id = projectId;
//   contentObj.text = quill.root.innerHTML;     
//   contentObj.type = 'tools-content'; 
//   contentObj.creationDate = Math.floor(Date.now() / 1000);
//   // console.log(contentObj)
//   // console.log(quill.getText())
//   // console.log(quill.root.innerHTML)
//   try{
//         AIToolsDataService.updateProject(user.uid, contentObj);
//         toast.success('Content updated!')  
//     } catch (err){
//         console.log(err.message);
//         toast.error('Oops! some thing went worng!')  
//     } 
// }
  
 const copyMyText = (e) => { 
  let edtorTxt = quill.getText();
  if(edtorTxt.length > 5){ 
    navigator.clipboard.writeText(edtorTxt) 
    toast.success('Copied to clipboard!')
  } else{
    toast.error('There is NO content to copy!')
  }
 }
 const copyText = (e) => {  
  toast.success('Copied to clipboard!')
  const id = parseInt(e.target.id);  
  let copyText = data[id].text.replace(/\n/g, '').replace((/<br\s*[\/]?>/gi), "\n").replace(/&nbsp;/g, ' ');    
  navigator.clipboard.writeText(copyText) 
}
  const handleSideNav = () => {
  if(sideNav) {
    setSideNav(false)
   } else 
   {setSideNav(true)
   }
}  
 
  const reWrite = async() => {  
    setLoading(true)   
    var range = quill.getSelection();
    let reWriteText;
    if (range) {
      if (range.length === 0){
        //console.log("User cursor is at index", range.index);
        setLoading(false)  
      } else {
        reWriteText = quill.getText(range.index, range.length); 
        //console.log("User has highlighted: ", reWriteText); 
      }
    } else {
      //console.log("User cursor is not in editor");
      setLoading(false)  
    } 
    if(reWriteText){
      try{
        let paramConfig = mainParam;
        // const getAIdata = await httpsCallable(functions, 'wordkraftAI'); 
        // const param = {
        //   type:"paraphrase",  
        //   about: reWriteText,         
        //   language:paramConfig.languageVal,
        //   tone:paramConfig.toneVal, 
        //   wcount:75,
        //   creative:0.7,
        //   num:1 
        // }

        const apiRequestBody = {
          "title": "paraphrase",
          "query": reWriteText,   
          "language": paramConfig.languageVal,
          "tone": paramConfig.toneVal,
          "num": 1
        }   
        const response = await fetchAPIData(apiRequestBody);
       // console.log(response) 
       if(response){  
          let newData = response[0].text; 
          if(newData.length < 4){
            toast.error('Try different inputs!')            
            setLoading(false)  
            return
          }
          if(newData){
            quill.deleteText(range.index, range.length);
            quill.cursorPosition = range.index;
            quill.clipboard.dangerouslyPasteHTML(quill.cursorPosition, newData); 
          } 
          wordCounter();  
          setLoading(false)   
        };
      } catch (error) {
        //console.log(error.message);
        setLoading(false)
      }
    } 
  };
  
  const expandTxt = async() => {  
    setLoading(true)   
    var range = quill.getSelection();
    let expandText = "";
    if (range) {
      if (range.length === 0){
        //console.log("User cursor is at index", range.index);
        setLoading(false)  
      } else {
        expandText = quill.getText(range.index, range.length); 
        //console.log("User has highlighted: ", expandText); 
      }
    } else {
      //console.log("User cursor is not in editor");
      setLoading(false)  
    } 
    if(expandText){
      try{
        let paramConfig = mainParam;
        // const getAIdata = await httpsCallable(functions, 'wordkraftAI'); 
        // const param = {
        //   type:"paraphraseMore",  
        //   about: expandText,         
        //   language:paramConfig.languageVal,
        //   tone:paramConfig.toneVal, 
        //   wcount:100,
        //   creative:parseFloat(paramConfig.creativeVal),
        //   num:1 
        // }

        const apiRequestBody = {
          "title": "SentenceExpander",
          "query": expandText,  
          "language":paramConfig.languageVal,
          "tone": paramConfig.toneVal,
          "num":1
        }     
        const response = await fetchAPIData(apiRequestBody);
       // console.log(response)  
        
        if(response){   
       
          let newData = response[0].text; 
          let getTxt = quill.getText(range.index, range.length);

          if(newData.length < 4 || newData == getTxt){
            toast.error('Try different inputs!')            
            setLoading(false)  
            return
          }
          if(newData){
           
            quill.deleteText(range.index, range.length);
            quill.cursorPosition = range.index;
        
            if (newData.includes(getTxt)) { 
              quill.clipboard.dangerouslyPasteHTML(quill.cursorPosition, newData); 
            } else{
              quill.clipboard.dangerouslyPasteHTML(quill.cursorPosition, getTxt + newData); 
            }
          } 
          wordCounter();  
          setLoading(false)         
        };
      } catch (error) {
        //console.log(error.message);
        setLoading(false)
      }
    } 
  };

  const handleChange = () => { 
    if (quill) { 
    
    }
  } 
  const { isFavorite, updateFavorites } = useFavorites();
  const handleFavorites = async (e) => {
    const toolPrompt = e.currentTarget.getAttribute('data-id');
    const toolName = e.currentTarget.getAttribute('data-name');
    const favObj = { toolPrompt, toolName };

    try {
        await AIToolsDataService.toggleFavourite(user.uid, favObj);
        updateFavorites(); // Update favorites after toggling
    } catch (err) {
        console.log(err.message);
        toast.error("Oops! something went wrong. Please check with the support team.");
    }
};
const toolPrompt = formValue && formValue.toolObj.prompt;
const isFav = isFavorite(toolPrompt);
const favBtnClass = isFav ? "favBtn active" : "favBtn";

  useEffect(() => {
    if (quill) {
      quill.on('editor-change', function(eventName, ...args) {
        if (eventName === 'text-change') {
          // args[0] will be delta  
        } else if (eventName === 'selection-change') { 
        //  //console.log('selection-change', args); 
          // if(args){
          //   setTextLength(args[0].length)
          // }  
        }
      }); 
      quill.on('selection-change', function (delta, old, source) {
        //console.log(delta.length) 
          if(delta.length){
            setTextLength(delta.length)
          } 
       }); 
    }
  }, [quill]);

  const fillWithSampleData = () => {
    const updatedInputs = formValue.toolObj.inputs.map(input => ({
      ...input,
      inputValue: input.inputExample // Update the inputValue with inputExample
    }));
  
    setFormValue({
      ...formValue,
      toolObj: {
        ...formValue.toolObj,
        inputs: updatedInputs
      }
    });
  };
// Make sure you handle state update on input change
const handleInputChange = (e, index) => {
  const { name, value } = e.target; // Get the name and value from the event target
  setFormValue(prevState => {
    const updatedInputs = [...prevState.toolObj.inputs];
    updatedInputs[index] = {
      ...updatedInputs[index],
      inputValue: value, // Update the inputValue for the modified input
    };
    return {
      ...prevState,
      toolObj: {
        ...prevState.toolObj,
        inputs: updatedInputs,
      },
    };
  });
};
const copyFormHtmlToClipboard = () => {
  // Select the form element
  const formElement = document.querySelector('form');

  // Clone the form element to manipulate without altering the original
  const clonedForm = formElement.cloneNode(true);

  // Remove elements that should not be copied (e.g., advanceOptions div, buttons)
  const elementsToRemove = clonedForm.querySelectorAll('.advanceOptions, button');
  elementsToRemove.forEach(el => el.remove());

  // Generate the HTML string of the cleaned form
  const formHtml = clonedForm.outerHTML;

  // Copy the HTML string to the clipboard
  navigator.clipboard.writeText(formHtml)
    .then(() => toast.success('Form HTML copied to clipboard!'))
    .catch(err => console.error('Failed to copy form HTML: ', err));
};
const copyQuillEditorHtmlToClipboard = () => {
  // Assuming quillRef is a reference to your Quill editor's container
  const editorHtml = quillRef.current.querySelector('.ql-editor').innerHTML;

  // Copy the HTML string to the clipboard
  navigator.clipboard.writeText(editorHtml)
    .then(() => toast.success('Editor content copied to clipboard!'))
    .catch(err => console.error('Failed to copy editor content: ', err));
};

  return (
  <>  
      <Helmet>
        <title>{`${formValue && formValue.toolObj.title}`}</title>
      </Helmet>
      <div className="main-layout">
      <div className="flexyWrap"> 
      {/* <Button className="backToDashboard" variant="link"  onClick={handleSideNav}> <SideArrow/> </Button> */}
      {/*<Link className="backToDashboard mobileView" to="/templates"><SideArrow/></Link> */}
  
          <section className="tool-panel flexy-template">
              <Row>
                {!editMode &&
                    <Col md={5}>  
                    <Card>   
                      {/* <Card.Header>
                      <h2>Content Rewriter</h2> 
                      </Card.Header> */} 
                          <Card.Body>  
                          <section className="tool-header">
<div>
      <div className="svgIcon emoji">  
      {formValue && formValue.toolObj.icon}
        </div>
       <div className="tool-heading">
            <h2>{formValue && formValue.toolObj.title}</h2>
            <span className="favWrap">
                <Button 
                    onClick={handleFavorites} 
                    variant="link" 
                    className={favBtnClass} 
                    data-id={toolPrompt} 
                    data-name={formValue && formValue.toolObj.title}
                >
                    ❤️
                </Button>
            </span>
        </div>
       <div className="caption">{formValue && formValue.toolObj.caption}</div>
      
       </div>
       <UsageBadge/>
          </section> 
                          {/* <Card.Title><FcViewDetails /> Product description</Card.Title> */}
                          <Form onSubmit={onFormSubmit}> 
  {formValue.toolObj.inputs && formValue.toolObj.inputs.map((input, index) => {
    // Determine if the input is required
    const isRequired = input.required !== 'false';

    return (
      <Form.Group className="mb-4" key={index}>
        <Form.Label>
          {input.inputTitle}
          {!isRequired && <span className="text-muted"> (optional)</span>}
        </Form.Label>
        {input.type === 'textarea' ? ( 
          <Form.Control 
            as="textarea" 
            rows={input.rowCount || 3} 
            name={`input${index}`} 
            placeholder={input.inputCaption} 
            required={isRequired} 
            value={input.inputValue || ''} // Bind input value to state
            onChange={e => handleInputChange(e, index)} // Implement this function to handle changes
            {...(input.limit ? { maxLength: input.limit } : {})}
          />
        ) : input.type === 'select' ? (
          <Form.Select 
            name={`input${index}`} 
            required={isRequired} 
            value={input.inputValue || ''} // Bind select value to state
            onChange={e => handleInputChange(e, index)}
          >
            {input.options.map((option, optIndex) => (
              <option key={optIndex} value={option}>{option}</option>
            ))}
          </Form.Select>
        ) : (
          <Form.Control 
            type="text" 
            name={`input${index}`} 
            placeholder={input.inputCaption} 
            required={isRequired} 
            value={input.inputValue || ''} // Bind input value to state
            onChange={e => handleInputChange(e, index)}
          />
        )}
      </Form.Group>
    );
  })}
        <Form.Control type="hidden" name='title' value={formValue.toolObj.title}/>
         <Form.Control type="hidden" name='prompt' value={formValue.toolObj.prompt} />
<div className="advanceOptions">
                                  <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Advanced Options</Accordion.Header>
        <Accordion.Body>
        <Form.Group className="mt-3 mb-3 row">
                                    <Col>
                                        <Form.Label>Language</Form.Label>    
                                        <Form.Select name="language" id="language" className="form-control">
																		<option value="English (US)">English (US)</option>
<option value="English (UK)">English (UK)</option>
<option value="French">French</option>
<option value="Spanish">Spanish</option>
<option value="German">German</option>
<option value="Italian">Italian</option>
<option value="Dutch">Dutch</option>
<option value="Arabic">Arabic</option>
<option value="Portuguese">Portuguese</option>
<option value="Portuguese (BR)">Portuguese (BR)</option>
<option value="Swedish">Swedish</option>
<option value="Norwegian">Norwegian</option>
<option value="Danish">Danish</option>
<option value="Finnish">Finnish</option>
<option value="Romanian">Romanian</option>
<option value="Czech">Czech</option>
<option value="Slovak">Slovak</option>
<option value="Slovenian">Slovenian</option>
<option value="Hungarian">Hungarian</option>
<option value="Hebrew">Hebrew</option>
<option value="Polish">Polish</option>
<option value="Turkish">Turkish</option>
<option value="Russian">Russian</option>
<option value="Hindi">Hindi</option>
<option value="Thai">Thai</option>
<option value="Japonese">Japonese</option>
<option value="Chinese (Simplified)">Chinese (Simplified)</option>
<option value="Korean">Korean</option>
<option value="Indonesian">Indonesian</option>
<option value="Greek">Greek</option>
<option value="Bulgarian">Bulgarian</option>
<option value="Ukrainian">Ukrainian</option>
<option value="Estonian">Estonian</option>
<option value="Latvian">Latvian</option>
<option value="Lithuanian">Lithuanian</option>
<option value="Croatian">Croatian</option>
<option value="Serbian">Serbian</option>
<option value="Bosnian">Bosnian</option>
<option value="Macedonian">Macedonian</option>
<option value="Albanian">Albanian</option>
<option value="Vietnamese">Vietnamese</option>
																	  </Form.Select>
                                    </Col>
                                    <Col> 
                                        <Form.Label>Tone of voice</Form.Label>   
                                        <Form.Select aria-label="Default select example" name="tone" className="form-control">
    <option>Formal</option>  
    <option>Professional</option>
    <option>Confident</option>
    <option>Persuasive</option>
    <option>Authoritative</option>
    <option>Friendly</option>
    <option>Empathetic</option>
    <option>Serious</option>
    <option>Sympathetic</option>
    <option>Informal</option>
    <option>Warm</option>
    <option>Emotional</option>
    <option>Optimistic</option>
    <option>Playful</option>
    <option>Humorous</option>
    <option>Witty</option>
    <option>Ironic</option>
    <option>Sarcastic</option>
    <option>Pessimistic</option>
    <option>Cynical</option>
    <option>Tentative</option>
    <option>Adventurous</option>
    <option>Bold</option>
    <option>Cold</option>
    <option>Relaxed</option>
    <option>Luxury</option>
    <option>Clinical</option>
                                        </Form.Select>
                                    </Col> 
                                  </Form.Group>
                                   {/* <Form.Group className="mt-3 mb-3 row"> 
                                    <Col>
                                        <Form.Label>Variants</Form.Label>
                                        <Form.Select aria-label="Number of Variants" name="num" className="form-control">
                                            <option value="1">1 variant</option>
                                            <option value="2">2 variants</option>
                                            <option value="3">3 variants</option> 
                                            <option value="4">4 variants</option>
                                            <option value="5">5 variants</option>
                                            <option value="6">6 variants</option> 
                                        </Form.Select>  
                                    </Col>
                                    <Col>                                    
                                    <Form.Label>Creativity</Form.Label>
                                        <Form.Select aria-label="creativity Level" name="creativity" className="form-control">
                                            <option value="0.75">Regular</option>
                                            <option value="0.35">Low</option>
                                            <option value="0.65">Medium</option>
                                            <option value="1">High</option> 
                                        </Form.Select>
                                    </Col>
                           </Form.Group> */}
        </Accordion.Body>
      </Accordion.Item> 
    </Accordion>
                                  </div>
  { user && 
    <> 
      <Button variant="primary" className="mt-4 w-100" type="submit" disabled={loading}>
        ⁺₊✧ Generate content
      </Button> 
      <Button variant="link" className="exampleData" onClick={fillWithSampleData}>
        Fill With Sample Data
      </Button>
      {/* <Button variant="secondary" className="mt-2" onClick={copyFormHtmlToClipboard}>
  Copy Form HTML
</Button>
<Button variant="secondary" className="mt-2" onClick={copyQuillEditorHtmlToClipboard}>
  Copy Editor Content
</Button> */}
    </>
  }
  {!user && 
    <Button variant="primary" className="mt-4 w-100" onClick={isAuthenticated}>
      Login to Generate
    </Button>
  }
</Form>
                                  {/* <pre>{ JSON.stringify(formValue.name.length, undefined, 2)}</pre>       */}
                            </Card.Body>  
                      </Card>
                    </Col>
                   }
                  <Col className={`ai-board ${editMode ? "col-md-12" : "col-md-7"}`}>  
                    <Card className={`${editMode ? "editMode" : ""}`}>     
                    {/* <Card.Title className="ai-board-header"> 
                     <div className="float-left template-title">
                        {formValue && <> {formValue.name} </>}
                        {!formValue && <> {'Flexy Template'} </>}
                      </div> 
                     </Card.Title>      */}
                       <div className="customEditorBtns"> 
                          <div className="wktooltip">
                          <Button className="ml-auto mr-auto rewrite" variant="outline-secondary" size="sm" 
                                                                      onClick={reWrite} disabled={textLength < 50 || textLength > 200}><RewriteIcon/></Button>
                            <span className="wktooltiptext">Rewrite sentence</span>
                          </div>
                          <div className="wktooltip">
                          <Button className="ml-1 mr-auto expand" variant="outline-secondary" size="sm" 
                                                        onClick={expandTxt} disabled={textLength < 80 || textLength > 300}><ExpandIcon/></Button>
                            <span className="wktooltiptext">Extend sentence</span>
                          </div>
                          <div className="wktooltip">
                          <Button variant="outline-secondary copy" size="sm" className="ml-1 mr-auto" onClick={copyMyText}><RiFileCopy2Line /></Button>
                            <span className="wktooltiptext">Copy</span>
                          </div>
                          <div className="wktooltip">
                          <Button variant="outline-secondary save" size="sm" className="ml-1 mr-auto" id={1} onClick={saveContent}><RiSaveLine /></Button> 
                            <span className="wktooltiptext">Save</span>
                          </div>  </div>
                    <Card.Body className="ai-board-body" ref={aiBoard}>  
                  
                              <div className="qullTextEditor aiToolsEditor"> <div ref={quillRef} onChange={handleChange}/> </div> 
                              {loading &&<div className="genMoreLoad"> <Loader /> </div> }  
                        </Card.Body>  
                    </Card>  
                  </Col>
              </Row>
          </section>

          </div>
        </div>
      <Toaster position="top-center" reverseOrder={false} />
          <Modal show={showModal} onHide={handleClose} keyboard={false} className={loginState ? "loginState" : "upgradeModal"}> 
      {loginState && <Modal.Body className="text-center">  
        <InlineLogin onLoginSuccess={handleLoginSuccess}/>  
      </Modal.Body>} 
      {!loginState && 
      <Modal.Body className="text-center">                 
              <InlinePricing/>
          </Modal.Body>}
    </Modal> 
      </>
  );
};

export default AiTools;



