const FilmMakingToolsData = {
    aiFilmScriptWriter: {
        prompt: "701",
        icon: "🎬",
        title: "AI Film Script Writer",
        category: "film",
        caption: `Transform your film's concept into a compelling script with rich scenes and dynamic dialogue. Just share your film's details and let the AI do the rest.`,
        inputs: [
            { inputTitle: `What is the Genre of the film?`, inputCaption: `Specify the genre of your film (e.g., Drama, Comedy, Thriller)`, inputExample: `Science Fiction`, type: 'text' },
            { inputTitle: `What is the Film Style?`, inputCaption: `Describe the film style or tone (e.g., Realistic, Fantasy, Noir)`, inputExample: `Noir-inspired futuristic dystopia`, type: 'text' },
            { inputTitle: `Duration of the Film`, inputCaption: `Indicate the expected length of the film in minutes or hours`, inputExample: `2 hours`, type: 'text' },
            { inputTitle: `Add your one-line story`, inputCaption: `Optional: Provide a brief one-sentence summary of your film's plot`, inputExample: `A time-traveler fights to prevent a dystopian future while grappling with the morality of his actions.`, type: 'textarea', required: 'false' }
        ]
    },
    movieTrailerScript: {
        prompt: "703",
        icon: "🎞️",
        title: "Movie Trailer Script",
        category: "film",
        caption: `Craft captivating movie trailer scripts easily by specifying your film's genre and desired trailer length. Perfect for grabbing audience attention.`,
        inputs: [
            { inputTitle: `What is the Genre of the film?`, inputCaption: `Indicate the genre to set the tone for the trailer`, inputExample: `Thriller`, type: 'text' },
            { inputTitle: `What is the desired duration of your trailer?`, inputCaption: `Mention the length of the trailer in minutes or seconds`, inputExample: `90 seconds`, type: 'text' },
            { inputTitle: `Add your one-line story`, inputCaption: `Optional: Give a brief summary or hook for the trailer`, inputExample: `In a race against time, a detective must solve a series of cryptic puzzles to save his city from chaos.`, type: 'textarea', required: 'false' }
        ]
    },
};

export default FilmMakingToolsData;
