const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({ apiKey: process.env.REACT_APP_OPENAI_API_KEY });
const openai = new OpenAIApi(configuration);

class InstagramPostIdeasAIServices {    

  generateInstagramPostIdeas = (formDataObj) => {
    return openai.createCompletion("text-davinci-002", {   
      prompt: `Generate 5 Instagram Post Ideas from the notes below:
      \nProduct/Topic: ${formDataObj.projectValue1}
      \nProduct/Topic Description: ${formDataObj.projectValue2}
      \nLanguage:write in ${formDataObj.language} language
      \nTone of Voice:${formDataObj.tone}
      \nYear: 2022`, 
      temperature: 0.85,
      max_tokens: 150,
      top_p: 1.0,
      frequency_penalty: 0.52,
      presence_penalty: 0.5,
      logprobs:1,
      stop: ["6."],
     });
   }

   generateMoreInstagramPostIdeas = (queryObj) => { 
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Generate 5 Instagram Post Ideas from the notes below:
      \nProduct/Topic: ${queryObj.projectValue1}
      \nProduct/Topic Description: ${queryObj.projectValue2}
      \nLanguage:write in ${queryObj.language} language
      \nTone of Voice:${queryObj.tone}
      \nYear: 2022`, 
      temperature: 0.85,
      max_tokens: 150,
      top_p: 1.0,
      frequency_penalty: 0.52,
      presence_penalty: 0.5,
      logprobs:1,
      stop: ["6."],
     });
   } 
  
}

export default new InstagramPostIdeasAIServices();

 