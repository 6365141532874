// FavoritesContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import AIToolsDataService from '../components/tools/ai-tools/services/AiTools.services'; 

const FavoritesContext = createContext({
    favorites: [],
    updateFavorites: () => {},
    isFavorite: () => false
  });
  

export const useFavorites = () => useContext(FavoritesContext);

export const FavoritesProvider = ({ children, userId }) => {
    const [favorites, setFavorites] = useState([]);

    // Function to fetch and update favorites
    const fetchAndUpdateFavorites = async () => { 
        if (userId) { // Ensure there is a userId available
            try {
                const updatedFavorites = await AIToolsDataService.getFavorites(userId); 
                setFavorites(updatedFavorites);
            } catch (error) {
                console.error("Error fetching favorites: ", error);
            }
        }
    };

    // Fetch favorites when component mounts or userId changes
    useEffect(() => {
        fetchAndUpdateFavorites();
    }, [userId]);

    // Function to update favorites, to be called after adding/removing a favorite
    const updateFavorites = async () => {
        await fetchAndUpdateFavorites();
    };
    const isFavorite = (toolPrompt) => {
        return favorites.some(fav => fav.toolPrompt === toolPrompt);
    };
    return (
        <FavoritesContext.Provider value={{ favorites, updateFavorites, isFavorite }}>
            {children}
        </FavoritesContext.Provider>
    );
};
