const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({ apiKey: process.env.REACT_APP_OPENAI_API_KEY });
const openai = new OpenAIApi(configuration);

class BlogIdeasAIServices {    
 

  generateBlogIdeas = (formDataObj) => {
    return openai.createCompletion("text-davinci-002", { 
      // prompt: `Write the complete product description in to a detailed professional, witty and clever explanation for the following product:
      // \nProduct: ${formDataObj.productName}\nFeatures: ${formDataObj.productFeatures}\nNote:write in ${formDataObj.language} language\nTone of Voice:${formDataObj.tone}`, 
      prompt: `Generate a blog post title idea for ${formDataObj.productFeatures}.:\nNote: Tell me how this blog title attracts the customer using colon \language: ${formDataObj.language}\nTone of Voice:${formDataObj.tone}`, 
      temperature: 0.75,
      max_tokens: 50,
      top_p: 1.0,
      frequency_penalty: 0.0,
      presence_penalty: 0.0,  
      n: 5 //parseInt(`${formDataObj.variant}`) 
     });
   }

   generateMoreBlogIdeas = (queryObj) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Generate a blog post title idea for ${queryObj.productFeatures}.:\nNote: Tell me how this blog title attracts the customer using colon \language: ${queryObj.language}\nTone of Voice:${queryObj.tone}`, 
      temperature: 0.75,
      max_tokens: 50, 
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,  
      n: 3  
     });
   }

   addMoreBlogIdeas = (queryObj, pDes) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Expand the blog idea for the following details:\nBlog About: ${pDes}`,
      temperature: 0.7,  
      max_tokens: 20,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0
     });
   }

}

export default new BlogIdeasAIServices();

 