import React from "react";  
import "./Loader.css"; 

const Loader = () => {  
  return (
    <>  
     {/* <div className="loader"></div>  */}
      <div className="loaderWrap">
        <strong>Generating content...</strong>
        <p>please wait a few seconds for it to load.</p>
           <div className="loader"></div>
      </div>
    </>
  );
};

export default Loader;