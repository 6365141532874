const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({ apiKey: process.env.REACT_APP_OPENAI_API_KEY });
const openai = new OpenAIApi(configuration);

class CallToActionAIServices {    
 

  generateCallToAction = (formDataObj) => {
    return openai.createCompletion("text-davinci-002", {  
      prompt: `Generate 5 call to action lines (or hooks) from the below details:
      Website: ${formDataObj.projectValue1}, Content: ${formDataObj.projectValue2}, Note:write in ${formDataObj.language} language, Tone of Voice:${formDataObj.tone}`, 
      temperature:1,
      max_tokens:100,
      top_p:1,
      frequency_penalty:0,
      presence_penalty:0, 
      n: 1 //parseInt(`${formDataObj.variant}`)  
     });
   }

   generateMoreCallToAction = (queryObj) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Generate 5 CTA lines (or hooks) from the below details:
      Website: ${queryObj.projectValue1}\nContent: ${queryObj.projectValue2}\nNote:write in ${queryObj.language} language\nTone of Voice:${queryObj.tone}`, 
      temperature: 1,  
      max_tokens: 100,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,  
      n: 1 
     });
   }

   addMoreCallToAction = (queryObj, pDes) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Expand the CTA lines (or hooks) from the below details:
      Website: ${queryObj.projectValue1}\nContent: ${queryObj.projectValue2}\nNote:write in ${queryObj.language} language\nTone of Voice:${pDes}`,
      temperature: 0.7,  
      max_tokens: 30,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0
     });
   }

}

export default new CallToActionAIServices();

 