const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({ apiKey: process.env.REACT_APP_OPENAI_API_KEY });
const openai = new OpenAIApi(configuration);

class AboutUsAIServices {    
 

  generateAboutUs = (formDataObj) => {
    return openai.createCompletion("text-davinci-002", {  
      prompt: `Write a detailed long About us page content from the following details:
      \nBusiness Name: ${formDataObj.projectValue1}\nEstablished Year: ${formDataObj.projectValue2}\nBusiness Services/Products:${formDataObj.projectValue3}
      \nTeam Name:${formDataObj.projectValue4} \nNote:write in ${formDataObj.language} language\nTone of Voice:${formDataObj.tone}`, 
      temperature: 0.7,
      max_tokens: 450,
      top_p: 1.0,
      frequency_penalty: 0.0,
      presence_penalty: 0.0,  
      n: 1 //parseInt(`${formDataObj.variant}`) 
     });
   }

   generateMoreAboutUs = (queryObj) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Write a detailed long About us page content from the following details:
      \nBusiness Name: ${queryObj.projectValue1}\nEstablished Year: ${queryObj.projectValue2}\nBusiness Services/Products:${queryObj.projectValue3}
      \nTeam Name:${queryObj.projectValue4} \nNote:write in ${queryObj.language} language\nTone of Voice:${queryObj.tone}`, 
      temperature: 0.7,  
      max_tokens: 450,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,  
      n: 1  
     });
   }

   addMoreAboutUs = (queryObj, pDes) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Expand about us page content from the following details:
      \nAbout us page: ${pDes}\nNote:write in ${queryObj.language} language\nTone of Voice:${queryObj.language}`,
      temperature: 0.7,  
      max_tokens: 154,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0
     });
   }

}

export default new AboutUsAIServices();

 