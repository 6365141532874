const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({ apiKey: process.env.REACT_APP_OPENAI_API_KEY });
const openai = new OpenAIApi(configuration);

class TweetIdeasAIServices {    

  generateTweetIdeas = (formDataObj) => {
    return openai.createCompletion("text-davinci-002", {   
      prompt: `Generate 7 Tweet Ideas from the notes below:
      \nProduct: ${formDataObj.projectValue1}
      \nProduct Description: ${formDataObj.projectValue2}
      \nLanguage:write in ${formDataObj.language} language
      \nTone of Voice:${formDataObj.tone}
      \nYear: 2022`, 
      temperature: 0.85,
      max_tokens: 100,
      top_p: 1.0,
      frequency_penalty: 0.52,
      presence_penalty: 0.5,
      logprobs:1,
      stop: ["7."],
     });
   }

   generateMoreTweetIdeas = (queryObj) => { 
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Generate 7 Tweet Ideas from the notes below:
      \nProduct: ${queryObj.projectValue1}
      \nProduct Description: ${queryObj.projectValue2}
      \nLanguage:write in ${queryObj.language} language
      \nTone of Voice:${queryObj.tone}
      \nYear: 2022`, 
      temperature: 0.85,
      max_tokens: 100,
      top_p: 1.0,
      frequency_penalty: 0.52,
      presence_penalty: 0.5,
      logprobs:1,
      stop: ["7."],
     });
   } 
  
}

export default new TweetIdeasAIServices();

 