const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({ apiKey: process.env.REACT_APP_OPENAI_API_KEY });
const openai = new OpenAIApi(configuration);

class CompanyMissionAIServices {    
 

  generateCompanyMission = (formDataObj) => {
    return openai.createCompletion("text-davinci-002", {  
      prompt: `Generate Facebook Ad copy (Headline:, Introductory Text:, Call-To-Action Button:) from the below notes:
      \nProduct Name: ${formDataObj.projectValue1}\nProduct Description: ${formDataObj.projectValue2}\nAudience: ${formDataObj.projectValue3}\nNote:write in ${formDataObj.language} language\nTone of Voice:${formDataObj.tone}`, 
      temperature: 0.8,
      max_tokens: 100,
      top_p: 1.0,
      frequency_penalty: 0.0,
      presence_penalty: 0.0,  
      n: 2 //parseInt(`${formDataObj.variant}`) 
     });
   }

   generateMoreCompanyMission = (queryObj) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Generate Facebook Ad copies (Headline:, Introductory Text:, Call-To-Action button:) from the below notes:
      \nProduct Name: ${queryObj.projectValue1}\nProduct Description: ${queryObj.projectValue2}\nAudience: ${queryObj.projectValue3}\nNote:write in ${queryObj.language} language\nTone of Voice:${queryObj.tone}`, 
      temperature: 0.8,  
      max_tokens: 100,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,  
      n: 2  
     });
   }

   addMoreCompanyMission = (queryObj, pDes) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Expand the Facebook Ad copies from the below notes:
      \nFacebook Ad: ${pDes}\nNote:write in ${queryObj.language} language\nTone of Voice:${queryObj.tone}`,
      temperature: 0.8,  
      max_tokens: 64,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      stop:["."]
     });
   }

}

export default new CompanyMissionAIServices();

 