import {React, useState, useEffect, useContext} from "react";
import { Button, Card, Container} from "react-bootstrap"; 
import { Link } from "react-router-dom";
import { useUserAuth } from "../../../context/UserAuthContext";  
import toast, { Toaster } from 'react-hot-toast'; 
import { FcPaid } from "react-icons/fc";
import TemplatesData from "../../../services/templaetsData/TemplatesData";
import "./Templates.css"; 
import { YouTubeIcon, TwitterIcon, QuoraIcon, FacebookIcon, LinkedinIcon, AmazonIcon, GoogleAdIcon, CustomerReviewIcon, InstagramIcon, ProductReviewIcon, BlogIcon, BlogIdeaIcon, BlogTitleIcon, BlogIntroIcon, BlogOutlineIcon, BlogConclusionIcon, ParagraphIcon, ContentRewriteIcon, WebsiteHeadingIcon, WebsiteSubHeadingIcon, MetaTitleIcon, MetaDescriptionIcon, AboutUsIcon, FaqsIcon, CallToActionIcon, AidaIcon, EmailIcon, ParaphraseIcon, UniversalIcon, TempIcon } from "../../../assets/svg/SvgIcons";
import SidebarPanel from "../sidebar/SidebarPanel";
import PersonalData from "../../../services/templaetsData/PersonalData";

const PersonalTemplates = () => { 
  const [tempObj, setTempObj] = useState(null);  
  useEffect(() => {
    if(PersonalData){
      setTempObj(PersonalData);
      //console.log(PersonalData) 
    }     
  },[]);    

  return (
    <> 
      {/* <div className="p-4 box mt-3 text-center">
        Hello Welcome to Templates <br />
        {user && user.displayName}
      </div> */}
       
      <Container className="main-layout templates">               
                <div className="header-txt"> 
                    <h2>Personal Templates</h2> 
                      <p className="text-left">
                    Our AI templates are designed to help you generate high quality content quick and easily.</p>   
                    </div>  
            <div className=""> 
 
          <div className="card-grid-row mt-1"> 
        
   
          {tempObj && Object.keys(tempObj).map((obj, index) =>  
                 <Card title="template" key={index} className={tempObj[obj].category}> 
                 <Link to={`/flexy-template/?type=${tempObj[obj].title}`}>
                   <div className="card-wrap">
                         <div className="card-icon">
                           <div className="svgIcon">
                         {/* <BlogIcon /> */}
                         <TempIcon/>
                         </div>
                         </div>
                          <Card.Body> 
                        <Card.Title>{tempObj[obj].title}</Card.Title>
                        <Card.Text> 
                         {tempObj[obj].caption}
                        </Card.Text> 
                       </Card.Body> 
                      </div>
                     </Link>
                </Card> 
          )}


 
             </div>
          </div>
        </Container>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default PersonalTemplates;
 