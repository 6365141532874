const IdeaGenerationData = {
    nicheIdeaGenerator: {
        prompt: "901",
        icon: "💡",
        title: "Niche Idea Generator",
        category: "idea",
        caption: "Discover unique and captivating niche ideas for content creation tailored to your interests in specific categories and topics.",
        inputs: [{ inputTitle: `What are you interested in?`, inputCaption: `e.g., Finance, Health, Technology, Fashion tec..`, inputExample: "Travel" }]
    },
    businessNameGenerator: {
        prompt: "902",
        icon: "🏷️",
        title: "Business Name Generator",
        category: "idea",
        caption: "Generate creative and memorable business names by describing your company's core activities and offerings.",
        inputs: [
            { inputTitle: `What's Your Business About?`, inputCaption: `Describe the nature of your business, including key products or services`, type: 'textarea', inputExample: "An eco-friendly clothing brand focusing on sustainable materials and fashion-forward designs" }
        ]
    },
    // marketingStrategyGenerator: {
    //     prompt: "903",
    //     icon: "📈",
    //     title: "Marketing Strategy Generator",
    //     category: "idea",
    //     caption: "Develop innovative marketing strategies tailored to your business needs, target audience, and market trends.",
    //     inputs: [
    //         { inputTitle: "Describe Your Target Market", inputCaption: "e.g., Demographics, Interests, Location, etc.." }
    //     ]
    // },
    productIdeaGenerator: {
        prompt: "904",
        icon: "🛍️",
        title: "Product Ideas",
        category: "idea",
        caption: "Explore unique product ideas based on current market demands and your personal interests or expertise.",
        inputs: [
            { inputTitle: "What Market or Industry Are You Interested In?", inputCaption: "e.g., Tech, Beauty, Health, etc..", inputExample: "Fitness and wellness" }
        ]
    },
    appConceptGenerator: {
        prompt: "906",
        icon: "📱",
        title: "App Concept Ideas",
        category: "idea",
        caption: "Generate innovative app concepts that cater to untapped market needs or enhance existing solutions.",
        inputs: [
            { inputTitle: "What Problem Do You Want to Solve?", inputCaption: "Describe a problem or need in the market", type: "textarea", inputExample: "Simplifying meal planning for busy individuals" }
        ]
    },
    startupPitchIdeaGenerator: {
        prompt: "907",
        icon: "🚀",
        title: "Startup Pitch Generator",
        category: "idea",
        caption: "Craft compelling startup pitch ideas that clearly communicate your vision, value proposition, and market potential.",
        inputs: [
            { inputTitle: "What Is Unique About Your Startup?", inputCaption: "Highlight the uniqueness of your startup idea or model", type: 'textarea', inputExample: "A peer-to-peer tutoring platform connecting students with verified tutors in real-time" }
        ]
    },
    eventPlanningIdeaGenerator: {
        prompt: "908",
        icon: "🎉",
        title: "Event Planning Ideas",
        category: "idea",
        caption: "Get inspired with unique and practical ideas for event planning, suitable for various types of gatherings and occasions.",
        inputs: [
            { inputTitle: "What Type of Event Are You Planning?", inputCaption: "e.g., Corporate, Wedding, Birthday, etc..", inputExample: "Virtual team-building events" }
        ]
    },
    recipeIdeaGenerator: {
        prompt: "910",
        icon: "🍳",
        title: "Recipe Generator",
        category: "idea",
        caption: "Discover new and exciting recipe ideas based on dietary preferences, cuisine types, and cooking methods.",
        inputs: [
            { inputTitle: "What Cuisine or Diet Preferences?", inputCaption: "e.g., Italian, Vegan, Gluten-Free, etc..", inputExample: "Mediterranean cuisine" }
        ]
    },
    DIYProjectIdeaGenerator: {
        prompt: "911",
        icon: "🔨",
        title: "DIY Project Ideas",
        category: "idea",
        caption: "Find innovative and fun DIY project ideas for home improvement, crafts, and personal hobbies.",
        inputs: [
            { inputTitle: "What Type of DIY Are You Interested In?", inputCaption: "e.g., Home Decor, Upcycling, Crafts, etc..", inputExample: "DIY skincare products" }
        ]
    },
    travelDestinationIdeaGenerator: {
        prompt: "912",
        icon: "✈️",
        title: "Travel Destination Ideas",
        category: "idea",
        caption: "Explore unique travel destination ideas based on your interests, budget, and desired travel experiences.",
        inputs: [
            { inputTitle: "What Are Your Travel Preferences?", inputCaption: "e.g., Adventure, Relaxation, Culture, Budget, etc..", inputExample: "Budget-friendly backpacking trips in Southeast Asia" }
        ]
    }
};

export default IdeaGenerationData;
