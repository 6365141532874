const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({ apiKey: process.env.REACT_APP_OPENAI_API_KEY });
const openai = new OpenAIApi(configuration);

class YoutubeCommentsResponderAIServices {    
 

  generateYoutubeCommentsResponder = (formDataObj) => {
    return openai.createCompletion("text-davinci-002", {  
      prompt: `Write a customer review response addressing customer name from the following details:
      \nProduct: ${formDataObj.projectValue1}\nCustomer Review: ${formDataObj.projectValue2}\nCustomer:${formDataObj.projectValue3}\nNote:write in ${formDataObj.language} language\nTone of Voice:${formDataObj.tone}`, 
      temperature: 0.7,
      max_tokens: 100,
      top_p: 1.0,
      frequency_penalty: 0.0,
      presence_penalty: 0.0,  
      n: 2 //parseInt(`${formDataObj.variant}`) 
     });
   }

   generateMoreYoutubeCommentsResponder = (queryObj) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Write a customer review response addressing customer name from the following details:
      \nProduct: ${queryObj.projectValue1}\nCustomer Review: ${queryObj.projectValue2}\nCustomer:${queryObj.projectValue3}\nNote:write in ${queryObj.language} language\nTone of Voice:${queryObj.tone}`, 
      temperature: 0.7,  
      max_tokens: 100,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,  
      n: 2  
     });
   }

   addMoreYoutubeCommentsResponder = (queryObj, pDes) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Expand customer review response from the following details:
      \nCustomer Review: ${pDes}\nNote:write in ${queryObj.language} language\nTone of Voice:${queryObj.language}`,
      temperature: 0.7,  
      max_tokens: 64,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0
     });
   }

}

export default new YoutubeCommentsResponderAIServices();

 