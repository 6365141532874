import React, {useState, useEffect, useContext} from "react"; 
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert, Button, Card } from "react-bootstrap"; 
import GoogleButton from "react-google-button";
import { useUserAuth } from "../context/UserAuthContext";
import { AuthErrorCodes } from "@firebase/auth";
import { GoogleAdIcon, LogoSvg } from "../assets/svg/SvgIcons";
import { useParams } from "react-router-dom";
import './loginForm.css' 

const InlineLogin = ({onLoginSuccess}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); 
  const [success, setSuccess] = useState(""); // add success state variable
  const params = useParams();

  const { logIn, googleSignIn, user } = useUserAuth();
    
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      //await logIn(email, password);
      const user = await logIn(email, password);
      //console.log(params)
      // let redirectUrl = '/stock-image'
      // if(params){ 
      //   if(params.type.length > 4){
      //     // redirectUrl = `/flexy-template/${params.type}`;
      //     redirectUrl = '/stock-image';
      //   } 
      // }  
      // navigate(redirectUrl);
     // console.log(user)
      if (onLoginSuccess) {
        onLoginSuccess(user);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      const user = await googleSignIn();
      //navigate("/stock-image");
      // let redirectUrl = '/stock-image'
      // if(params){ 
      //   if(params.type.length > 4){
      //     // redirectUrl = `/flexy-template/${params.type}`;
      //     redirectUrl = '/stock-image'
      //   } 
      // } 
      // navigate(redirectUrl);
      //console.log(user)
      if (onLoginSuccess) {
        onLoginSuccess(user);
      }
    } catch (error) {
      //console.log(error.message);
    }
  };

  return (
    <div className="inlineLogin"> 
      <Card className="imageCard">
         <div className="imagekraft-benifts">
         <strong>Benefits of Account</strong>
          <ul className="mt-3"> 
          <li><span className="ico">✅</span> Access 250+ AI Tools</li> 
          <li><span className="ico">✅</span> Keyword Research</li>
          <li><span className="ico">✅</span> Long Article Writer</li>
          <li><span className="ico">✅</span> Image Creation Access</li>
          <li><span className="ico">✅</span> Social Media Content</li>
          <li><span className="ico">✅</span> Marketing Content</li>  
          <li><span className="ico">✅</span> SmartChat</li>
          </ul>
          <br/>
          <div>❤️ Trusted By 1,50,000 Users</div>
         </div>
      </Card>
      <Card className="card loginForm"> 
      <Card.Body>
      <div className="p-0 box"> 
          <h4>You're almost there...</h4>
          <p className="mb-4">Please login or create a new account.</p>
          {error && <Alert variant="danger">{error}</Alert>}
          <div>
            <Button className="w-100 googleSignin" onClick={handleGoogleSignIn}><GoogleAdIcon /> Connect with Gmail </Button> 
          </div>
          <hr />
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="email"
                placeholder="Email address"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-4" controlId="formBasicPassword">
              <Form.Control
                type="password"
                placeholder="Password"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <div className="d-grid gap-2">
              <Button variant="primary" type="Submit">
                Login
              </Button>
            </div>
          </Form>
         
        </div>
        <div className="pt-3 box text-center">
          <p className="mb-0"><Link to="/reset" className="linkFont">Forgot password</Link>   
         <Link to="/signup" className="linkFont ml-4">Sign up</Link></p>
        </div>
      </Card.Body>
      </Card> 
    </div>
  );
};

export default InlineLogin;