const WebsiteContentData = {
    domainNameIdeas: {
        prompt: "1701",
        icon: "🌐",
        title: "Domain Name Ideas",
        category: "website",
        caption: "Explore a variety of unique and captivating domain name options for your website. Begin your naming adventure now!",
        inputs: [
            { inputTitle: `Describe what your Website or App is about?`, inputCaption: 'Briefly describe the purpose or theme of your website/app.', type: 'textarea', inputExample: "A social networking platform for pet lovers" },
            { inputTitle: `Enter the TLD or extension you want (like .com, .in, .ai, .org)`, inputCaption: 'Specify preferred TLDs (e.g., .com, .org).', required: 'false', inputExample: ".com" }
        ]
    }, 
    creativeHomePage: {
        prompt: "1702",
        icon: "🏠", 
        title: "Creative Home Page",
        category: "website",
        caption: "Design landing pages that convert, featuring well-structured content and persuasive copy.",
        inputs: [
            { inputTitle: `Product/Brand Name`, inputCaption: 'Enter your product or brand name.', inputExample: "PetPals" },
            { inputTitle: `What are you selling or promoting? (Include key features of your product or service)`, inputCaption: 'Detail your product or service, highlighting key features.', type: 'textarea', inputExample: "PetPals is a social networking platform designed to connect pet owners around the world. It features profile creation for pets, photo sharing, forums, and local meet-up events." },
            { inputTitle: `Who is your Ideal Customer? (describe their interests)`, inputCaption: 'Describe the interests and characteristics of your ideal customer.', type: 'textarea', inputExample: "Our ideal customer is a pet enthusiast who loves connecting with other pet owners, sharing photos and stories about their pets, and participating in community events." }
        ]
    }, 
    servicePageContent: {
        prompt: "1703",
        icon: "💼", 
        title: "Service Page Content",
        category: "website",
        caption: "Develop engaging and SEO-optimized content for your service pages, available in any language.",
        inputs: [
            { inputTitle: `Product/Brand Name`, inputCaption: 'Enter your product or brand name.', inputExample: "PetPals" },
            { inputTitle: `What are you selling or promoting? (Include key features of your product or service)`, inputCaption: 'Describe your product or service and its key features.', type: 'textarea', inputExample: "PetPals is a social networking platform designed to connect pet owners around the world. It features profile creation for pets, photo sharing, forums, and local meet-up events." },
            { inputTitle: `What Results or Benefits can customers expect from using your product or service?`, inputCaption: 'Outline the expected results or benefits for customers.', type: 'textarea', inputExample: "By using PetPals, customers can connect with like-minded pet owners, share experiences, and participate in local events, creating a vibrant and supportive community for pets and their owners." }
        ]
    }, 
    aboutUsPage: {
        prompt: "1704",
        icon: "👥",
        title: "About Us Page",
        category: "website",
        caption: "Narrate your company's story, mission, and offerings on your 'About Us' page in a comprehensive manner.",
        inputs: [
            { inputTitle: `Product/Brand Name`, inputCaption: 'Enter your product or brand name.', inputExample: "PetPals" },
            { inputTitle: `What's Your Business About?`, inputCaption: 'Provide an overview of your business.', type: 'textarea', inputExample: "PetPals is a social networking platform designed to connect pet owners around the world. Our mission is to create a global community where pet owners can share their experiences, find support, and discover local pet-related events." },
            { inputTitle: `What are you selling or promoting? (Include key features of your product or service)`, inputCaption: 'Describe what you are selling or promoting.', type: 'textarea', inputExample: "PetPals offers a range of features including profile creation for pets, photo sharing, discussion forums, and event organization tools, all tailored to the needs of pet owners." }
        ]
    }, 
    getServiceDescriptions: {
        prompt: "1705",
        icon: "🖋️",
        title: "Get Service Descriptions",
        category: "website",
        caption: "Create succinct and appealing descriptions for the array of services your business offers.",
        inputs: [
            { inputTitle: `Product/Brand Name`, inputCaption: 'Enter your product or brand name.', inputExample: "PetPals" },
            { inputTitle: `What Service(s) do you Provide?`, inputCaption: 'List the services you provide.', type: 'textarea',  inputExample: "1. Profile creation for pets\n2. Photo sharing\n3. Discussion forums" },
            { inputTitle: `No. of Words for Each Service`, inputCaption: 'Specify the word count for each service description.', required: 'false', inputExample: "100" }
        ]
    }, 
    individualServicePage: {
        prompt: "1706",
        icon: "📄", 
        title: "Individual Service - Full Page",
        category: "website",
        caption: "Produce compelling content for individual service webpages, showcasing the value of your products.",
        inputs: [
            { inputTitle: `Describe your Product / Service`, inputCaption: 'Detail your product or service, including its benefits.', type: 'textarea', inputExample: "PetPals is a social networking platform designed to connect pet owners around the world. Our platform allows users to create profiles for their pets, share photos, participate in forums, and organize local meet-up events." },
            { inputTitle: `Enter Maximum words`, inputCaption: 'Specify the maximum word count for the page.', inputExample: "500" }
        ]
    }, 
    landingPageContentCreator: {
        prompt: "1708",
        icon: "🎯",
        title: "Landing Page Content Creator",
        category: "website",
        caption: "Craft compelling and effective content for your landing page, designed to engage visitors and drive conversions.",
        inputs: [
            { inputTitle: `Product/Service Being Promoted`, inputCaption: 'Detail the product or service you are promoting on this landing page.', type: 'textarea', inputExample: "PetPals" },
            { inputTitle: `Target Audience`, inputCaption: 'Describe your target audience, including demographics and interests.', type: 'textarea', inputExample: "Our target audience includes pet owners of all ages and backgrounds who are interested in connecting with other pet owners and participating in local pet-related events." },
                        { inputTitle: `Desired Call-to-Action`, inputCaption: 'Specify the main call-to-action (CTA) you want to include on the landing page.', type: 'textarea', inputExample: "Sign up for PetPals and join the global pet lover community!" },
            { inputTitle: `Key Selling Points`, inputCaption: 'Highlight the key selling points or benefits of your product/service.', type: 'textarea', inputExample: "1. Connect with pet owners globally\n2. Share photos and stories about your pets\n3. Participate in local pet-related events" }
        ]
    },
    FAQPageContent: {
        prompt: "1709",
        icon: "❓",
        title: "FAQ Page Content",
        category: "website",
        caption: "Create a comprehensive and informative FAQ page that addresses common queries related to your product or service.",
        inputs: [
            { inputTitle: `Product/Brand Name`, inputCaption: 'Enter your product or brand name.', inputExample: "PetPals" },
            { inputTitle: `List the common questions your customers ask`, inputCaption: 'Provide typical questions received from customers.', type: 'textarea', inputExample: "1. How do I create a profile for my pet?\n2. Can I upload multiple photos?\n3. Are there any membership fees?" }
        ]
    },
    customerTestimonialPage: {
        prompt: "1710",
        icon: "🗣️",
        title: "Customer Testimonial Page",
        category: "website",
        caption: "Craft a captivating testimonial page that showcases customer experiences and the impact of your product or service.",
        inputs: [
            { inputTitle: `Product/Brand Name`, inputCaption: 'Enter your product or brand name.', inputExample: "PetPals" },
            { inputTitle: `Describe the type of testimonials you want to feature`, inputCaption: 'Indicate whether you seek video, written, case studies, etc.', type: 'textarea', inputExample: "We are looking to feature written testimonials from our users, highlighting their experiences with PetPals and how it has impacted their lives and their relationships with their pets." }
        ]
    },
    contactUsPageContent: {
        prompt: "1711",
        icon: "☎️",
        title: "Contact Us Page Content",
        category: "website",
        caption: "Design an engaging and user-friendly 'Contact Us' page to facilitate easy communication with your visitors.",
        inputs: [
            { inputTitle: `Product/Brand Name`, inputCaption: 'Enter your product or brand name.', inputExample: "PetPals" },
            { inputTitle: `What contact methods do you offer?`, inputCaption: 'List the ways customers can reach you (email, phone, form, etc.).', type: 'textarea', inputExample: "1. Email: support@petpals.com\n2. Phone: 1-800-PET-PALS\n3. Contact Form on our website" }
        ]
    },
    productDescriptionPages: {
        prompt: "1712",
        icon: "🛍️",
        title: "Product Description Pages",
        category: "website",
        caption: "Create detailed and persuasive product descriptions that highlight features, benefits, and unique selling points.",
        inputs: [
            { inputTitle: `Product Name`, inputCaption: 'Enter the name of your product.', inputExample: "PetPals Premium Membership" },
            { inputTitle: `Describe your product's features and benefits`, inputCaption: 'Detail the key features and benefits of your product.', type: 'textarea', inputExample: "1. Access to exclusive pet events\n2. Advanced photo sharing features\n3. Priority customer support" }
        ]
    },
    termsAndConditionsPage: {
        prompt: "1713",
        icon: "📜",
        title: "Terms and Conditions Page",
        category: "website",
        caption: "Generate clear and comprehensive terms and conditions tailored to your website's services and user interactions.",
        inputs: [
            { inputTitle: `Product/Brand Name`, inputCaption: 'Enter your product or brand name.', inputExample: "PetPals" },
            { inputTitle: `Outline the primary services or interactions on your site`, inputCaption: 'Describe the main services or user interactions on your website.', type: 'textarea', inputExample: "1. Profile creation for pets\n2. Photo sharing\n3. Discussion forums" }
        ]
    },
    privacyPolicyPage: {
        prompt: "1714",
        icon: "🔒",
        title: "Privacy Policy Page",
        category: "website",
        caption: "Develop a detailed privacy policy page that ensures transparency and trust with your website's users.",
        inputs: [
            { inputTitle: `Product/Brand Name`, inputCaption: 'Enter your product or brand name.', inputExample: "PetPals" },
            { inputTitle: `Detail the type of data you collect and how it's used`, inputCaption: 'Explain your data collection practices and usage.', type: 'textarea', inputExample: "We collect user data such as email addresses and pet information solely for the purpose of operating our platform and providing personalized user experiences. We do not share this data with third parties without user consent." }
        ]
    },
    websitePopup: {
        prompt: "411",
        icon: "💥",
        title: "Website Pop-up Messages",
        category: "website",
        caption: `Design compelling website pop-up messages to ensure key information captures visitor attention.`,
        inputs: [         
            { inputTitle: `Type of the popup`, inputCaption: `Select the type of popup message you need.`, type: "select", options: ['Newsletter Sign-Up', 'Discount Offers', 'Flash Sales Announcement', 'Exit-Intent Popup', 'Feedback Form', 'Event Promotion', 'Ebook or Whitepaper Download', 'Product Recommendation', 'Countdown Timer', 'Trial Sign-Up', 'User Onboarding Popup', 'Contest and Giveaway', 'Chat Support', 'B2B Lead Generation Form', 'Membership Enrollment', 'Waitlist Sign-Up for New Product', 'Appointment Booking', 'Restock Notification', 'Virtual Event Registration', 'other'], inputExample: "Discount Offers" },
            { inputTitle: `What is your product/service?`, inputCaption: `Describe your product or service.`, inputExample: "PetPals is a social networking platform for pet lovers that allows users to connect, share photos, and participate in local events." },
            { inputTitle: `What is your goal with this popup?`, inputCaption: `Explain the objective of the popup.`, required: 'false', inputExample: "The goal of this popup is to encourage users to sign up for our premium membership by offering them an exclusive discount." }
        ]
    },
}
export default WebsiteContentData;

