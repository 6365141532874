const EmailToolsData = {  
    salesEmailV1: {
        prompt:"501",
        icon: "📧",
        title:"Sales Email v1",
        category:"email",
        caption:`Generate sales emails that connect with your audience and significantly increase your conversion rates.`,
        inputs :[
            {inputTitle: `What are you selling or promoting?`, inputCaption:`Include key features of your product or service`, inputExample: `Eco-friendly water bottles that keep drinks cold for 24 hours`, type:'textarea'},
            {inputTitle: `Who is your Ideal Customer?`, inputCaption:`describe their interests`, inputExample: `Environmentally conscious consumers who value sustainability`, type:'textarea'}
        ]
    }, 
    salesEmailV2: {
        prompt:"502",
        icon: "📩",
        title:"Sales Email v2",
        category:"email",
        caption:`Craft custom sales emails that reflect your brand's unique voice and cater to your specific audience.`,
        inputs :[
            {inputTitle: `What are you selling or promoting?`, inputCaption:`Include key features of your product or service`, inputExample: `Innovative fitness tracking app that offers personalized workouts`, type:'textarea'},
            {inputTitle: `Product/Brand Name`, inputCaption:``, inputExample: `FitTrack Pro`},
            {inputTitle: `Who is your Ideal Customer?`, inputCaption:`describe their interests`, inputExample: `Fitness enthusiasts looking for a customizable workout experience`, type:'textarea'}
        ]
    }, 
    promotionEmail: {
        prompt:"503",
        icon: "🎁", 
        title:"Promotion E-Mail",
        category:"email",
        caption:`Compose engaging marketing emails that effectively promote your products or services in any desired language.`,
        inputs :[
            {inputTitle: `Describe your Product / Service`, inputCaption:``, inputExample: `A line of premium organic teas sourced directly from local farms in Sri Lanka`, type:'textarea'}
        ]
    }, 
    emailSequenceGenerator: {
        prompt:"504",
        icon: "🔗",
        title:"Email Sequence Generator",
        category:"email",
        caption:`Build a series of persuasive emails that spotlight your product's features, converting leads into customers.`,
        inputs :[
            {inputTitle: `What are you selling or promoting?`, inputCaption:`Include key features of your product or service`, inputExample: `A cloud-based project management tool designed for remote teams`, type:'textarea'}
        ]
    }, 
    emailSubjectLines: {
        prompt:"505",
        icon: "🔖", 
        title:"Email Subject Lines",
        category:"email",
        caption:`Create email subject lines that capture attention, using proven copywriting techniques to engage your recipients.`,
        inputs :[
            {inputTitle: `What's the primary goal or reason you're reaching out to the recipient?`, inputCaption:``, inputExample: `Introducing a new product feature that simplifies team collaboration`},
            {inputTitle: `What type of email are you planning to send?`, inputCaption:``, inputExample: `Promotional Emails`, type:"select", options:['Cold Outreach Emails', 'Job Application Emails', 'Networking Follow-up Emails', 'Promotional Emails', 'Newsletter Emails', 'Transactional Emails (Order Confirmations; Receipts; Password Resets)', 'Welcome Emails (for new subscribers or customers)', 'Feedback and Survey Emails', 'Event Invitation Emails', 'Re-engagement or Win-back Emails', 'other']},
            {inputTitle: `Please provide the draft of the email you're sending. `, inputCaption:``, inputExample: `Dear valued customer, We're excited to introduce...`, type:'textarea', required:'false'}
        ]
    }, 
    abandonedCartEmails: {
        prompt:"506",
        icon: "🛒",
        title:"Abandoned Cart E-Mails",
        category:"email",
        caption:`Design personalized email campaigns targeting abandoned shopping carts to turn potential losses into sales.`,
        inputs :[
            {inputTitle: `Product/Brand Name`, inputCaption:``, inputExample: `Glam Cosmetics`},
            {inputTitle: `What are you selling or promoting?`, inputCaption:`Include key features of your product or service`, inputExample: `Luxury skincare products that are cruelty-free and made from natural ingredients`, type:'textarea'}
        ]
    }, 
    leadMagnetIdeas: {
        prompt:"507",
        icon: "🧲",
        title:"Lead Magnet Ideas",
        category:"email",
        caption:`Develop enticing lead magnet concepts that deliver quick wins and valuable content to your ideal clients.`,
        inputs :[
            {inputTitle: `What's Your Business About?`, inputCaption:``, inputExample: `Digital marketing services focused on SEO and content strategy`, type:'textarea'},
            {inputTitle: `Who is your Ideal Customer?`, inputCaption:`describe their interests`, inputExample: `Small business owners looking to increase their online presence`, type:'textarea'}
        ]
    }, 
    newsletterEmail: {
        prompt:"508",
        icon: "📰", 
        title:"Newsletter E-Mail",
        category:"email",
        caption:`Efficiently create newsletters that keep your audience informed and engaged, tailored to your business's updates and topics.`,
        inputs :[
            {inputTitle: `Product/Brand Name`, inputCaption:``, inputExample: `EcoWear - Sustainable Fashion`},
            {inputTitle: `What's Your Business About?`, inputCaption:``, inputExample: `Promoting sustainable fashion and eco-friendly clothing lines`, type:'textarea'},
            {inputTitle: `Specify the theme (or the main topic )for this newsletter.`, inputCaption:``, inputExample: `Our Journey Towards Zero Waste in Fashion`, type:'textarea'},
            {inputTitle: `What’s the 1st update or topic you want to cover in the newsletter?`, inputCaption:``, inputExample: `Introduction of our new biodegradable clothing line`, type:'textarea'},
            {inputTitle: `What’s the 2nd update or topic you want to feature in the newsletter?`, inputCaption:``, inputExample: `Behind the scenes: The making of eco-friendly fabrics`, type:'textarea'},
            {inputTitle: `What’s the 3rd update or topic you want to feature in the newsletter?`, inputCaption:``, inputExample: `Upcoming events: Join us at the Sustainable Fashion Week`, type:'textarea'}
        ]
    }, 
    coldEmail: {
        prompt:"509",
        icon: "❄️",
        title:"Cold Email",
        category:"email",
        caption:`Guide your cold email creation with a focus on an enticing subject line, structured content, and a clear call to action.`,
        inputs :[
            {inputTitle: `What's the primary goal or reason you're reaching out to the recipient?`, inputCaption:``, inputExample: `To introduce our new project management software`},
            {inputTitle: `Provide the full details and highlights of the person you're reaching out to?`, inputCaption:``, inputExample: `Jane Doe, CEO of Tech Innovations - known for her commitment to enhancing productivity in the workplace`, type:'textarea'},
            {inputTitle: `Describe yourself?`, inputCaption:`Include Name, Company or Organization, role, etc...`, inputExample: `John Smith, Co-founder of NextGen Software`, type:'textarea'},    
            {inputTitle: `What unique opportunity, benefit, or solution are you offering to the recipient?`, inputCaption:``, inputExample: `Exclusive early access to our software with a special discount for your team`},
            {inputTitle: `After reading your email, what specific action would you like the recipient to take?`, inputCaption:``, inputExample: `Reply to this email to schedule a free demo`}
        ]
    }, 
    followUpColdEmail: {
        prompt:"510",
        icon: "🔂", 
        title:"Follow-up Cold Email",
        category:"email",
        caption:`This tool aids in formulating a follow-up cold email with a compelling subject, concise message, and strong call to action.`,
        inputs :[
            {inputTitle: `Please share the original cold email you sent.`, inputCaption:``, inputExample: `Dear Jane, Last week I reached out to introduce NextGen Software...`, type:'textarea'},
            {inputTitle: `Did the recipient reply? If yes, please share their response.`, inputCaption:``, inputExample: `No response received`, type:'textarea'},
            {inputTitle: `Any new updates or offers for the follow-up?`, inputCaption:``, inputExample: `Adding a new feature to our software that directly addresses your company's needs`, type:'textarea'}
        ]
    },  
}
export default EmailToolsData;

