const SEOToolsData = {  
    metaTitleDescription: {
        prompt: "1401",
        icon: "🔖",
        title: "Meta Title and Description",
        category: "seo",
        caption: "Craft the ideal SEO-optimized title and description for your blog or website with ease.",
        inputs: [{inputTitle: `Enter your target keyword`, inputCaption: 'Type in the main keyword for your content.', inputExample: `Organic Gardening Tips`}]
    }, 
    clickbaitTitleGenerator: {
        prompt: "1403",
        icon: "🎣",
        title: "Clickbait Title Generator",
        category: "seo",
        caption: "Generate catchy and attention-grabbing clickbait titles.",
        inputs: [
            { inputTitle: 'Topic or Subject', inputCaption: 'Enter the main topic or subject for your article or content.', type: 'text', inputExample: `Weight Loss Secrets` },
            { inputTitle: 'Target Audience', inputCaption: 'Describe your target audience (optional).', type: 'text', required: 'false', inputExample: `Busy Professionals` }
        ]
    },    
    quoraAnswer: {
        prompt: "1404",
        icon: "❓",
        title: "Quora Answer",
        category: "seo",
        caption: "Effortlessly provide answers to Quora questions, tailored to your niche and preferred language.",
        inputs: [
            {inputTitle: `Product/Brand Name`, inputCaption: 'Enter your product or brand name.', inputExample: `GreenEarth Organics`},
            {inputTitle: `Describe Niche`, inputCaption: 'Describe the niche of your product or brand.', type: 'textarea', inputExample: `Organic gardening and sustainable living`},
            {inputTitle: `What is your Quora Question ?`, inputCaption: 'Paste the Quora question here.', type: 'textarea', inputExample: `What are the best organic fertilizers for vegetable gardening?`}
        ]
    }, 
    longTailKeywordGenerator: {
        prompt: "1405",
        icon: "🔑",
        title: "Long-tail Keyword Generator",
        category: "seo",
        caption: "Generate long-tail keywords to improve your SEO strategy.",
        inputs: [
            { inputTitle: 'Seed Keyword', inputCaption: 'Enter a primary keyword or short keyword phrase.', type: 'text', inputExample: `Vegan Recipes` },
            { inputTitle: 'Target Market or Niche', inputCaption: 'Specify your target market or niche.', type: 'text', required: 'false', inputExample: `Health-conscious individuals` },
            { inputTitle: 'Intended Use', inputCaption: 'Describe how you plan to use these keywords (e.g., blog content, PPC campaigns).', type: 'text', required: 'false', inputExample: `Blog content on vegan cooking and nutrition` }
        ]
    },    
    relatedKeywordOnPageSEOGuide: {
        prompt: "1406",
        icon: "📑",
        title: "Related Keyword & On Page SEO Guide",
        category: "seo",
        caption: "Enhance your page's SEO with targeted keyword variations, search-engine friendly titles, and more.",
        inputs: [{inputTitle: `What's Your Business About?`, inputCaption: 'Describe your business or page topic.', type: 'textarea', inputExample: `A digital marketing agency specializing in content marketing and SEO services for small businesses.`}]
    }, 
    keywordClustering: {
        prompt: "1407",
        icon: "🔗",
        title: "Keyword Clustering",
        category: "seo",
        caption: "Group your keywords into relevant clusters for more effective SEO strategies.",
        inputs: [
            { inputTitle: 'Keywords List', inputCaption: 'Enter a list of keywords you want to cluster. Separate each keyword with a comma.', type: 'textarea', inputExample: `SEO tips, content marketing strategies, increasing online visibility, digital marketing tools` },
            { inputTitle: 'Clustering Criteria', inputCaption: 'Specify the criteria for clustering (e.g., topic similarity, search intent).', type: 'text', required: 'false', inputExample: `Topic similarity` }
        ]
    },    
    contentOptimizationTool: {
        prompt: "1409",
        icon: "✍️",
        title: "Content Optimization",
        category: "seo",
        caption: "Enhance your existing content to be more engaging, informative, and SEO-friendly.",
        inputs: [
            { inputTitle: 'Original Content', inputCaption: 'Paste the content that needs optimization.', type: 'textarea', inputExample: `Here are five essential SEO strategies to boost your website's ranking in search engine results pages.` },
            { inputTitle: 'Target Keywords', inputCaption: 'Enter the keywords you want to target.', type: 'text', inputExample: `SEO strategies, search engine ranking, SEO tips` }
        ]
    },
    backlinkOutreachTool: {
        prompt: "1410",
        icon: "🔗",
        title: "Backlink Outreach Templates",
        category: "seo",
        caption: "Generate personalized outreach emails for backlink opportunities.",
        inputs: [
            { inputTitle: 'Your Name/Company', inputCaption: 'Enter your name or your company\'s name.', type: 'text', inputExample: `Alex from SeoNinja` },
            { inputTitle: 'Recipient Name', inputCaption: 'Enter the name of the recipient.', type: 'text', required: 'false', inputExample: `Jordan` },
            { inputTitle: 'Recipient Website', inputCaption: 'Enter the website you are reaching out to.', type: 'text', inputExample: `healthylivingblog.com` },
            { inputTitle: 'Purpose of Email', inputCaption: 'Briefly describe the purpose of your outreach.', type: 'text', inputExample: `To inquire about guest posting opportunities` }
        ]
    },
    technicalSEOAdviceTool: {
        prompt: "1411",
        icon: "⚙️",
        title: "Technical SEO Advice",
        category: "seo",
        caption: "Get general advice on common technical SEO issues and best practices.",
        inputs: [
            { inputTitle: 'SEO Issue/Question', inputCaption: 'Describe the technical SEO issue or question you have.', type: 'textarea', inputExample: `How can I improve my website's load time to enhance SEO?` }
        ]
    },
    localSEOContentTool: {
        prompt: "1412",
        icon: "📍",
        title: "Local SEO Content",
        category: "seo",
        caption: "Generate content optimized for local SEO.",
        inputs: [
            { inputTitle: 'Business Type', inputCaption: 'Type of business or service.', type: 'text', inputExample: `Italian Restaurant` },
            { inputTitle: 'Location', inputCaption: 'Specific location or area to target.', type: 'text', inputExample: `Downtown Chicago` },
            { inputTitle: 'Key Features/Benefits', inputCaption: 'Key features or benefits of your business/service.', type: 'text', required: 'false', inputExample: `Authentic Italian cuisine, family-friendly atmosphere` }
        ]
    },
    rankingGuidelines: {
        prompt: "1408",
        icon: "📊",
        title: "Ranking Guidelines for 1 Keyword",
        category: "seo",
        caption: "Access in-depth guidelines for keyword research and optimizing SEO for a specific keyword.",
        inputs: [{inputTitle: `Enter your target keyword`, inputCaption: 'Enter the keyword to get ranking guidelines.', inputExample: `Sustainable Fashion Brands`}]
    }
}
export default SEOToolsData;
