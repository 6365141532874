const ExtrasData = {
    cvCoverLetterCrafting: {
        prompt: "601",
        icon: "📄",
        title: "CV Cover Letter Crafting",
        category: "extra",
        caption: `Create a custom cover letter that effectively highlights your skills and qualifications, aligning them with the specific job role and the company's goals.`,
        inputs: [
            { inputTitle: `What are the details of the job you are applying for?`, inputCaption: `Provide job title, company name, and key job requirements`, inputExample: `Senior Graphic Designer at Creative Studio, specializing in digital design and multimedia.`, type: 'textarea' },
            { inputTitle: `What are your qualifications?`, inputCaption: `List your academic and professional qualifications relevant to the job`, inputExample: `Bachelor's degree in Graphic Design and 5 years of experience in a leading design agency.`, type: 'textarea' },
            { inputTitle: `What skills and experience do you have that align with the job requirements and the company's goals?`, inputCaption: `Describe skills and experiences that match the job description and company's objectives`, inputExample: `Proficient in Adobe Creative Suite, experienced in brand development and digital marketing campaigns.`, type: 'textarea' },
            { inputTitle: `What are the company's goals as stated in the job description?`, inputCaption: `Optional: Mention the company's goals or values as outlined in the job description`, inputExample: `To innovate in the digital marketing space with creative and impactful designs.`, type: 'textarea', required: 'false' }
        ]
    },
    bookSummarizer: {
        prompt: "602",
        icon: "📚",
        title: "Book Summarizer",
        category: "extra",
        caption: `Effortlessly generate concise summaries of any book, capturing the essence of the content, based on the book's title and author.`,
        inputs: [
            { inputTitle: `What is the title of the book you want to summarize?`, inputCaption: `Enter the title of the book`, inputExample: `The Great Gatsby by F. Scott Fitzgerald`, type: 'text' },
            { inputTitle: `Who is the author of the book?`, inputCaption: `Provide the name of the author`, inputExample: `F. Scott Fitzgerald`, type: 'text' },
            { inputTitle: `Which type of summary would you like?`, inputCaption: `Choose the style of summary you prefer`, inputExample: `Chapter Summary`, type: "select", options: ['Brief Overview', 'Chapter Summary', 'Deep Concept Summary', 'other'] }
        ]
    },
    acronymGenerator: {
        prompt: "605",
        icon: "🔠",
        title: "Acronym Generator",
        category: "extra",
        caption: "Effortlessly create meaningful acronyms from provided words or phrases.",
        inputs: [
            { inputTitle: `Words/Phrase`, inputCaption: `Enter the words or phrase to generate an acronym.`, inputExample: `Central Intelligence Agency`, type: 'text' }
        ]
    },
    // The ENF Stories Generator tool is omitted as it involves content that may not align with providing a supportive and respectful environment.
    fourChanBizGenerator: {
        prompt: "607",
        icon: "💬",
        title: "4chan Biz Generator",
        category: "extra",
        caption: "Create posts or content ideas in the style of the 4chan Biz community.",
        inputs: [
            { inputTitle: `Topic of Discussion`, inputCaption: `What is the topic you want to discuss in the style of 4chan Biz?`, inputExample: `Cryptocurrency trends and predictions for 2024`, type: 'text' }
        ]
    },
    whatRhymesWithMeGenerator: {
        prompt: "608",
        icon: "🎵",
        title: "What Rhymes With Me Generator",
        category: "extra",
        caption: "Find rhyming words or phrases for any given word to aid in poetry or songwriting.",
        inputs: [
            { inputTitle: `Word to Rhyme With`, inputCaption: `Enter the word you need rhymes for.`, inputExample: `Time`, type: 'text' }
        ]
    },
    motivationalQuotesGenerator: {
        prompt: "609",
        icon: "💪",
        title: "Motivational Quotes Generator",
        category: "extra",
        caption: "Generate inspiring and motivational quotes to uplift and energize.",
        inputs: [
            { inputTitle: `Theme or Topic`, inputCaption: `Specify a theme or topic for the motivational quote.`, inputExample: `Overcoming challenges`, type: 'text' }
        ]
    },
    loveLetterGenerator: {
        prompt: "610",
        icon: "❤️",
        title: "Love Letter Generator",
        category: "extra",
        caption: "Compose heartfelt and romantic love letters effortlessly.",
        inputs: [
            { inputTitle: `Recipient's Name`, inputCaption: `Enter the name of the person the love letter is addressed to.`, inputExample: `Alex`, type: 'text' },
            { inputTitle: `Special Memories or Moments`, inputCaption: `Describe any special memories or moments to include in the letter.`, inputExample: `Remember our first trip to Paris together?`, type: 'textarea', required: 'false' }
        ]
    },
    aiJokeGenerator: {
        prompt: "611",
        icon: "😂",
        title: "AI Joke Generator",
        category: "extra",
        caption: "Create humorous and entertaining jokes with the help of AI.",
        inputs: [
            { inputTitle: `Joke Category`, inputCaption: `Choose a category or theme for the joke.`, inputExample: `Science`, type: 'text' },
            { inputTitle: `Keywords`, inputCaption: `Include any specific keywords to be used in the joke.`, inputExample: `atoms, gravity`, type: 'text', required: 'false' }
        ]
    },
    rhymeGenerator: {
        prompt: "612",
        icon: "🎵",
        title: "Rhyme Generator",
        category: "extra",
        caption: "Find the perfect rhymes for any word to enhance your songwriting or poetry.",
        inputs: [
            { inputTitle: `Word to Find Rhymes For`, inputCaption: `Enter a word to generate rhymes.`, inputExample: `Sky`, type: 'text' }
        ]
    },
    randomStoryGenerator: {
        prompt: "613",
        icon: "📚",
        title: "Random Story Generator",
        category: "extra",
        caption: "Generate creative and engaging stories from random prompts and ideas.",
        inputs: [
            { inputTitle: `Theme or Genre`, inputCaption: `Choose a theme or genre for the story.`, inputExample: `Fantasy`, type: 'text', required: 'false' },
            { inputTitle: `Character Details`, inputCaption: `Provide details about the main character(s), if desired.`, inputExample: `A young wizard on a quest for a legendary spell`, type: 'textarea', required: 'false' }
        ]
    },
    poetryGenerator: {
        prompt: "614",
        icon: "✒️",
        title: "Poetry Generator",
        category: "extra",
        caption: "Create beautiful and expressive poetry on a variety of themes and styles.",
        inputs: [
            { inputTitle: `Poetry Theme`, inputCaption: `What is the theme or subject of the poetry?`, inputExample: `Nature's beauty`, type: 'text' },
            { inputTitle: `Poetry Style`, inputCaption: `Specify a particular style for the poetry, if desired.`, inputExample: `Haiku`, type: 'text', required: 'false' }
        ]
    },
    poemGenerator: {
        prompt: "615",
        icon: "🌸",
        title: "Poem Generator",
        category: "extra",
        caption: "Craft unique poems with ease, tailored to your themes and emotions.",
        inputs: [
            { inputTitle: `Poem Subject`, inputCaption: `What is the subject or topic of the poem?`, inputExample: `Love and longing`, type: 'text' },
            { inputTitle: `Mood or Emotion`, inputCaption: `What mood or emotion should the poem convey?`, inputExample: `Melancholy`, type: 'text' }
        ]
    },
    businessNameGenerator: {
        prompt: "616",
        icon: "🏢",
        title: "Business Name Generator",
        category: "extra",
        caption: "Generate innovative and catchy names for your business, reflecting its nature and values.",
        inputs: [
            { inputTitle: `Business Type or Industry`, inputCaption: `Describe the type of business or industry.`, inputExample: `Sustainable Fashion Retail`, type: 'text' },
            { inputTitle: `Core Values or Themes`, inputCaption: `What are the core values or themes of your business?`, inputExample: `Eco-friendliness, innovation, and customer satisfaction`, type: 'text' }
        ]
    },
    brandNameGenerator: {
        prompt: "618",
        icon: "🌟",
        title: "Brand Name Generator",
        category: "extra",
        caption: "Create memorable and unique brandnames that stand out in the market.",
        inputs: [
            { inputTitle: `Product or Service Offered`, inputCaption: `Describe the product or service offered by the brand.`, inputExample: `Organic skincare line`, type: 'text' },
            { inputTitle: `Brand Personality`, inputCaption: `What personality or image should the brand convey?`, inputExample: `Friendly, approachable, and earth-conscious`, type: 'text' }
        ]
    },
    sloganGenerator: {
        prompt: "619",
        icon: "💬",
        title: "Slogan Generator",
        category: "extra",
        caption: "Craft compelling and memorable slogans for your brand or campaign.",
        inputs: [
            { inputTitle: `Brand or Campaign Name`, inputCaption: `Enter the name of your brand or campaign.`, inputExample: `GreenEarth Organics`, type: 'text' },
            { inputTitle: `Key Message or Theme`, inputCaption: `What is the key message or theme of the slogan?`, inputExample: `Nourish Your Skin, Nurture the Planet`, type: 'text' }
        ]
    },
};
export default ExtrasData;