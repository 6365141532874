import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert, Card } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useUserAuth } from "../context/UserAuthContext";
import './loginForm.css' 

const Signup = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState('');
  const { signUp } = useUserAuth();
  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if(validatePassword()) {
      try {
        await signUp(email, password);
        navigate("/login");
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const validatePassword = () => {
    let isValid = true
    if (password !== '' && confirmPassword !== ''){
      if (password !== confirmPassword) {
        isValid = false
        setError('Passwords does not match')
      }
    }
    return isValid
  }

  return (
    <>
     <Card className="col-4 p-0 card my-5 mx-auto loginForm"> 
    <Card.Body> 
      <div className="p-4 box">
        <h2>Let's Get Started!</h2>
        <p>Sign up to make your content strategy to the next level</p>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>

        <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Control
              type="name"
              placeholder="User name" 
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder="Email address"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              type="password"
              placeholder="Password"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-4" controlId="formBasicConfirmPassword">
            <Form.Control
              type="password"
              placeholder="Confirm password"
              value={confirmPassword} 
              required
              onChange={e => setConfirmPassword(e.target.value)}
            />
          </Form.Group>
          <div className="d-grid gap-2">
            <Button variant="primary" type="Submit">
              Sign up
            </Button>
          </div> 
          <div className="termsofService">
          By signing up, I agree to the Terms of Service and Privacy Policy of Wordkraft AI.
          </div>
        </Form>
      </div>
      <div className="p-4 box text-center">
        Already have an account? <Link to="/login">Sign in</Link>
      </div> 
     </Card.Body>
     </Card> 
    </>
  );
};

export default Signup;