import {React, useState, useEffect, useContext} from "react";
import { Button, Card, Container} from "react-bootstrap"; 
import { Link } from "react-router-dom";
import { useUserAuth } from "../../../context/UserAuthContext";  
import toast, { Toaster } from 'react-hot-toast'; 
import { FcPaid } from "react-icons/fc";
import "./Tools.css"; 
import { YouTubeIcon, TwitterIcon, QuoraIcon, FacebookIcon, LinkedinIcon, AmazonIcon, GoogleAdIcon, CustomerReviewIcon, InstagramIcon, ProductReviewIcon, BlogIcon, BlogIdeaIcon, BlogTitleIcon, BlogIntroIcon, BlogOutlineIcon, BlogConclusionIcon, ParagraphIcon, ContentRewriteIcon, WebsiteHeadingIcon, WebsiteSubHeadingIcon, MetaTitleIcon, MetaDescriptionIcon, AboutUsIcon, FaqsIcon, CallToActionIcon, AidaIcon, EmailIcon, ParaphraseIcon, UniversalIcon, ArticleIcon, BlogSectionIcon, SentenceExpanderIcon, ProsAndConsIcon, ParagraphWriterIcon, ParaphraseIcon1, ParagraphToPointsIcon, CompanyVisionIcon, GrammarCheckerIcon, ValuePropositionIcon, NewsLettersIcon, NameGeneratorIcon, PressReleasesIcon, EssayIntrosIcon, EssayOutlinesIcon, FlexyTemplateIcon, TextSummarizerIcon, StoriesIcon, SocialMediaIcon, ImageCreationIcon, KeywordResearchIcon } from "../../../assets/svg/SvgIcons";
import SidebarPanel from "../sidebar/SidebarPanel"; 

const Marketing = () => {
   
  return (
    <>  
      
      <Container className="main-layout">    
      <div className="header-txt"> 
                    <h2>Marketing</h2> 
                   <p className="text-left"> 
                    Our AI tools are designed to help you generate high quality content quick and easily.</p>  
                    </div>      
            <div className=""> 
          <div className="card-grid-row mt-1">    
                    <Card data-category="ads"> 
                     <Link to="/facebook-ad">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <FacebookIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Facebook Ad</Card.Title>
                            <Card.Text>
                            Write a Facebook Ad using AI can help you create targeted ads for a customer.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="ads"> 
                     <Link to="/google-ad">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <GoogleAdIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Google Ad</Card.Title>
                            <Card.Text>
                            Write a Google Ad using AI can help you to reach targeted ideal customers.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="ads"> 
                     <Link to="/linkedin-ad">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <LinkedinIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>LinkedIn Ad</Card.Title>
                            <Card.Text>
                            Write a LinkedIn ad that uses AI to target a specific audience & business.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="ecommerce"> 
                     <Link to="/product-description">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <FcPaid />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Product Description</Card.Title>
                            <Card.Text>
                            Write the product description which gives the reason to buy your product
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>        
                    <Card data-category="ecommerce"> 
                     <Link to="/amazon-product-description">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <AmazonIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Amazon Product Description</Card.Title>
                            <Card.Text>
                            Write an amazing Product description using AI technolgoy.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="ecommerce"> 
                     <Link to="/amazon-product-bullet-points">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <AmazonIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Amazon Product Bullet Points</Card.Title>
                            <Card.Text>
                             Generate a product bullet points form product description using AI.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="ecommerce"> 
                     <Link to="/amazon-ad-headline">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <AmazonIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Amazon Ad Headline</Card.Title>
                            <Card.Text>
                            Write an catchy Amazon AD headline using AI to grab the attention.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="ecommerce"> 
                     <Link to="/amazon-product-title">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <AmazonIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Amazon Product Title</Card.Title>
                            <Card.Text>
                            Write an effective Amazon product title using AI to catch more eyes. 
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>   
                   <Card data-category="website"> 
                     <Link to="/product-review">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <ProductReviewIcon />
                                </div>
                                </div>
                              <Card.Body> 
                            <Card.Title>Product Review</Card.Title>
                            <Card.Text>
                            Generate a product review with certain information about the product.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>     
                   <Card data-category="website"> 
                     <Link to="/customer-review-response">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <CustomerReviewIcon />
                            </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Customer Review Response</Card.Title>
                            <Card.Text>
                            A customer review response using AI can help you save time by automatically.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>     
                    
             </div>
          </div>
        </Container>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default Marketing;