const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({ apiKey: process.env.REACT_APP_OPENAI_API_KEY });
const openai = new OpenAIApi(configuration);

class ProductDescriptionAIServices {    

  getNewData = () => {
   return openai.createCompletion("text-davinci-002", { 
      prompt: `Write the complete product description for sundrop cooking oil`, 
      temperature: 0.7,
      max_tokens: 84,
      top_p: 1.0,
      frequency_penalty: 0.0,
      presence_penalty: 0.0 
    });
  }

  generateProductDescription = (formDataObj) => {
    return openai.createCompletion("text-davinci-002", { 
      // prompt: `Write the complete product description in to a detailed professional, witty and clever explanation for the following product:
      // \nProduct: ${formDataObj.productName}\nFeatures: ${formDataObj.productFeatures}\nNote:write in ${formDataObj.language} language\nTone of Voice:${formDataObj.tone}`, 
      prompt: `Write the complete product description in to a detailed explanation for the following product:
      \nProduct: ${formDataObj.productName}\nFeatures: ${formDataObj.productFeatures}\nNote:write in ${formDataObj.language} language\nTone of Voice:${formDataObj.tone}`, 
      temperature: 0.7,
      max_tokens: 84,
      top_p: 1.0,
      frequency_penalty: 0.0,
      presence_penalty: 0.0,  
      n: 3 //parseInt(`${formDataObj.variant}`) 
     });
   }

   generateMoreProductDescription = (queryObj) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Write the complete product description in to a detailed explanation for the following product: 
      \nProduct: ${queryObj.productName}\nFeatures: ${queryObj.productFeatures}\nNote:write in ${queryObj.language} language\nTone of Voice:${queryObj.tone}`,
      temperature: 0.7,  
      max_tokens: 84,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,  
      n: 3  
     });
   }

   addMoreProductDescription = (queryObj, pDes) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Expand the product description for the following product:\nProduct: ${queryObj.productName}\nProduct description: ${pDes}`,
      temperature: 0.7,  
      max_tokens: 64,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0
     });
   }

}

export default new ProductDescriptionAIServices();

 