const CopywritingToolsData = {
    AIDAmodel: {
        prompt: "301",
        icon: "📊",
        title: "A-I-D-A model",
        category: "copywriting",
        caption: `Utilize the AIDA model to create content that captures attention, builds interest, evokes desire, and prompts action.`,
        inputs: [
            { inputTitle: `What type of content you want to create?`, inputCaption: `Select the type of content to create using AIDA model.`, type: `select`, options: ['Home Page', 'About us Page', 'Product Page', 'Blog Post', 'Social Media Post', 'Email', 'Video Script', 'Ad Copy', 'Other'], inputExample: 'Product Page' },
            { inputTitle: `Who is your Ideal Customer?`, inputCaption: `Describe the interests and characteristics of your ideal customer.`, type: 'textarea', inputExample: 'Health-conscious individuals aged 25-40 looking for natural supplements' },
            { inputTitle: `What are you selling or promoting?`, inputCaption: `Include key features and benefits of your product or service.`, type: 'textarea', inputExample: 'Organic, non-GMO, vitamin C supplements that boost immunity' },
            { inputTitle: `What is the unique selling points (USPs) that differentiates your product or service from competitors?`, inputCaption: `Explain what makes your product or service unique.`, type: 'textarea', inputExample: 'Sourced from organic berries, contains no artificial additives, and supports local farmers' },
            { inputTitle: `What action do you want the audience to take after engaging with your content?`, inputCaption: `Specify the desired action you want the audience to take.`, inputExample: 'Subscribe to our newsletter for a 10% discount on your first purchase' }
        ]
    }, 
    BABmodel: {
        prompt: "302",
        icon: "👥",
        title: "B-A-B model",
        category: "copywriting",
        caption: `Effectively spotlight your product's advantages using the BAB model to address customer needs and showcase benefits.`,
        inputs: [
            { inputTitle: `What type of content you want to create?`, inputCaption: `Select the type of content to create using BAB model.`, type: `select`, options: ['Home Page', 'About us Page', 'Product Page', 'Blog Post', 'Social Media Post', 'Email', 'Video Script', 'Ad Copy', 'Other'], inputExample: 'Email' },
            { inputTitle: `What problem does your product or service aim to solve for your customers?`, inputCaption: `Describe the problem that your product/service addresses.`, type: 'textarea', inputExample: `The challenge of finding truly organic skincare that's effective and affordable` },
            { inputTitle: `Who is your Ideal Customer?`, inputCaption: `Describe your ideal customer's interests and needs.`, type: 'textarea', inputExample: 'Women aged 30-45 who prioritize skincare and are environmentally conscious' },
            { inputTitle: `What are you selling or promoting?`, inputCaption: `Detail the features and benefits of your product or service.`, type: 'textarea', inputExample: 'A line of organic, eco-friendly skincare products that hydrate and rejuvenate without harsh chemicals' },
            { inputTitle: `What Results or Benefits can customers expect from using your product or service?`, inputCaption: `Explain the benefits or results customers can expect.`, type: 'textarea', inputExample: 'Improved skin texture, reduced environmental impact, and support for sustainable practices' }
        ]
    }, 
    PASmodel: {
        prompt: "303",
        icon: "🔍",
        title: "P-A-S model",
        category: "copywriting",
        caption: `Employ the Problem-Agitate-Solve model to emphasize your product as the ideal solution for customer challenges.`,
        inputs: [
            { inputTitle: `What type of content you want to create?`, inputCaption: `Select the type of content to create using PAS model.`, type: `select`, options: ['Home Page', 'About us Page', 'Product Page', 'Blog Post', 'Social Media Post', 'Email', 'Video Script', 'Ad Copy', 'Other'], inputExample: 'Blog Post' },
            { inputTitle: `What problem does your product or service aim to solve for your customers?`, inputCaption: `Identify the problem your product/service solves.`, type: 'textarea', inputExample: 'Difficulty maintaining a healthy lifestyle with a busy schedule' },
            { inputTitle: `Who is your Ideal Customer?`, inputCaption: `Describe the interests of your ideal customer.`, type: 'textarea', inputExample: 'Busy professionals aged 25-50 who value health but struggle with time management' },
            { inputTitle: `What are you selling or promoting?`, inputCaption: `Detail the key features of your product or service.`, type: 'textarea', inputExample: 'A meal planning app that simplifies healthy eating for people on the go' }
        ]
    }, 
    PASTORmodel: {
        prompt: "304",
        icon: "🙏",
        title: "PASTOR model",
        category: "copywriting",
        caption: `Leverage the PASTOR model to address customer pain points, highlight product benefits, and encourage action.`,
        inputs: [
            { inputTitle: `What type of content you want to create?`, inputCaption: `Select the type of content to create using PASTOR model.`, type: `select`, options: ['Home Page', 'About us Page', 'Product Page', 'Blog Post', 'Social Media Post', 'Email', 'Video Script', 'Ad Copy', 'Other'], inputExample: 'Social Media Post' },
            { inputTitle: `Who is your Ideal Customer? (describe their interests)`, inputCaption: `Explain who your ideal customers are and their interests.`, type: `textarea`, inputExample: 'Entrepreneurs and business owners looking to increase their online presence' },
            { inputTitle: `What are you selling or promoting? (Include key features of your product or service)`, inputCaption: `Describe the product or service you are promoting.`, type: 'textarea', inputExample: 'Digital marketing services including SEO, content creation, and social media management' },
            { inputTitle: `What problem does your product or service aim to solve for your customers?`, inputCaption: `State the problem your product/service solves.`, type: `textarea`, inputExample: 'The challenge of reaching a wider audience and generating more leads online' },
            { inputTitle: `Is there a specific story or narrative you want to incorporate into your content?`, inputCaption: `If you have a specific story, mention it here.`, type: `textarea`, inputExample: 'A success story of a client who tripled their website traffic in 6 months', required: 'false' },
            { inputTitle: `Enter any customer testimonials that you want to incorporate into your content.`, inputCaption: `If available, add customer testimonials.`, type: `textarea`, inputExample: '"Thanks to these services, our sales have increased by 40% in just 4 months."', required: 'false' },
            { inputTitle: `What Results or Benefits can customers expect from using your product or service?`, inputCaption: `Describe the benefits customers can expect.`, type: `textarea`, inputExample: 'Increased online visibility, higher website traffic, and more customer engagement' },
            { inputTitle: `What action do you want the audience to take after engaging with your content?`, inputCaption: `Define the call to action for your audience.`, inputExample: 'Contact us today for a free consultation to boost your digital marketing strategy' }
        ]
    }, 
    fourPmodel: {
        prompt: "305",
        icon: "🖼️",
        title: "4P’s model",
        category: "copywriting",
        caption: `Develop captivating content narratives with the Picture-Promise-Prove-Push framework to engage and convert.`,
        inputs: [
            { inputTitle: `What type of content you want to create?`, inputCaption: `Select the type of content to create using 4P's model.`, type: `select`, options: ['Home Page', 'About us Page', 'Product Page', 'Blog Post', 'Social Media Post', 'Email', 'Video Script', 'Ad Copy', 'Other'], inputExample: 'Ad Copy' },
            { inputTitle: `What are you selling or promoting? (Include key features of your product or service)`, inputCaption: `Detail your product/service including its key features.`, type: 'textarea', inputExample: 'A cutting-edge project management tool with real-time collaboration and analytics' },
            { inputTitle: `Who is your Ideal Customer? (describe their interests)`, inputCaption: `Describe the interests and characteristics of your ideal customer.`, type: 'textarea', inputExample: 'Project managers and team leaders looking to streamline workflow and increase productivity' }
        ]
    },
    threeSmodel: {
        prompt: "306",
        icon: "✨",
        title: "3S’s model",
        category: "copywriting",
        caption: `Invoke the 'Star-Story-Solution' framework to craft content that’s engaging and solution-oriented.`,
        inputs: [
            { inputTitle: `What type of content you want to create?`, inputCaption: `Select the type of content to create using 3S's model.`, type: `select`, options: ['Home Page', 'About us Page', 'Product Page', 'Blog Post', 'Social Media Post', 'Email', 'Video Script', 'Ad Copy', 'Other'], inputExample: 'Video Script' },
            { inputTitle: `Is there a specific story or narrative you want to incorporate into your content?`, inputCaption: `Mention any specific story or narrative for your content.`, type: 'textarea', inputExample: 'The journey of a startup from chaos to clarity with our project management tool' },
            { inputTitle: `What are you selling or promoting?`, inputCaption: `Detail the features and benefits of your product or service.`, type: 'textarea', inputExample: 'Our tool offers streamlined task management, integrated time tracking, and customizable dashboards for maximum efficiency' }
        ]
    }, 
    fiveBasicObjectionsModel: {
        prompt: "307",
        icon: "🛑",
        title: "5 Basic Objections model",
        category: "copywriting",
        caption: `Address common customer objections effectively while showcasing your product's unique strengths.`,
        inputs: [
            { inputTitle: `What type of content you want to create?`, inputCaption: `Select the type of content to create using 5 Basic Objections model.`, type: `select`, options: ['Home Page', 'About us Page', 'Product Page', 'Blog Post', 'Social Media Post', 'Email', 'Video Script', 'Ad Copy', 'Other'], inputExample: 'Product Page' },
            { inputTitle: `Who is your Ideal Customer?`, inputCaption: `Describe the interests and characteristics of your ideal customer.`, type: 'textarea', inputExample: 'Small to medium business owners looking for affordable, effective marketing solutions' },
            { inputTitle: `What is the unique selling points (USPs) that differentiates your product or service from competitors?`, inputCaption: `Explain what makes your product or service unique.`, type: 'textarea', inputExample: 'Our marketing tool uses AI to automate and personalize campaigns, reducing costs by 50%' },
            { inputTitle: `What action do you want the audience to take after engaging with your content?`, inputCaption: `Specify the desired action you want the audience to take.`, inputExample: 'Sign up for a free trial to experience the difference firsthand' }
        ]
    }, 
    pastPresentFutureModel: {
        prompt: "308",
        icon: "⏳",
        title: "Past-Present-Future model",
        category: "copywriting",
        caption: `Explore your product's journey using the 'Past-Present-Future' model for a comprehensive narrative.`,
        inputs: [
            { inputTitle: `What type of content you want to create?`, inputCaption: `Select the type of content to create using Past-Present-Future model.`, type: `select`, options: ['Home Page', 'About us Page', 'Product Page', 'Blog Post', 'Social Media Post', 'Email', 'Video Script', 'Ad Copy', 'Other'], inputExample: 'About us Page' },
            { inputTitle: `What are you selling or promoting?`, inputCaption: `Detail the key features and benefits of your product or service.`, type: 'textarea', inputExample: 'Eco-friendly packaging solutions that reduce waste and carbon footprint' },
            { inputTitle: `What is the unique selling points (USPs) that differentiates your product or service from competitors?`, inputCaption: `Describe what sets your product or service apart from the competition.`, type: 'textarea', inputExample: 'Our patented biodegradable materials and commitment to sustainability' },
            { inputTitle: `Who is your Ideal Customer? (describe their interests)`, inputCaption: `Detail the interests of your ideal customers.`, type: 'textarea', inputExample: 'Businesses and consumers passionate about environmental conservation and sustainable living' },
            { inputTitle: `What problem does your product or service aim to solve for your customers?`, inputCaption: `Explain the problem your product/service solves.`, type: 'textarea', inputExample: 'The excessive use of non-recyclable packaging contributing to environmental pollution' },
            { inputTitle: `What action do you want the audience to take after engaging with your content?`, inputCaption: `Define the call to action for your audience.`, inputExample: 'Learn more about our mission and join us in making a difference by choosing sustainable packaging solutions' }
        ]
    }, 
    sixWmodel: {
        prompt: "309",
        icon: "❓",
        title: "6W’s model",
        category: "copywriting",
        caption: `Transform leads into customers using the '6 W's' model, highlighting the key aspects of your product.`,
        inputs: [
            { inputTitle: `What type of content you want to create?`, inputCaption: `Select the type of content to create using 6W's model.`, type: `select`, options: ['Home Page', 'About us Page', 'Product Page', 'Blog Post', 'Social Media Post', 'Email', 'Video Script', 'Ad Copy', 'Other'], inputExample: 'Email' },
            { inputTitle: `What are you selling or promoting?`, inputCaption: `Detail the features and benefits of your product or service.`, type: 'textarea', inputExample: 'A mobile app that simplifies personal finance and budgeting for millennials' },
            { inputTitle: `What is the unique selling points (USPs) that differentiates your product or service from competitors?`, inputCaption: `Explain your product's/service's unique selling points.`, type: 'textarea', inputExample: 'User-friendly interface, AI-driven insights for saving money, and personalized financial advice' },
            { inputTitle: `Who is your Ideal Customer? (describe their interests)`, inputCaption: `Describe your ideal customer's interests and needs.`, type: 'textarea', inputExample: 'Millennials interested in personal finance, saving for the future, and managing debt' },
            { inputTitle: `What action do you want the audience to take after engaging with your content?`, inputCaption: `Specify the action you want the audience to take.`, inputExample: 'Download our app and start your journey towards financial freedom today' }
        ]
    }, 
    strongWeakModel: {
        prompt: "310",
        icon: "💪",
        title: "Strong-Weak model",
        category: "copywriting",
        caption: `Craft content that accentuates the strengths and addresses the limitations of your product.`,
        inputs: [
            { inputTitle: `What type of content you want to create?`, inputCaption: `Select the type of content to create using the Strong-Weak model.`, type: `select`, options: ['Home Page', 'About us Page', 'Product Page', 'Blog Post', 'Social Media Post', 'Email', 'Video Script', 'Ad Copy', 'Other'], inputExample: 'Product Page' },
            { inputTitle: `What are you selling or promoting? (Include key features of your product or service)`, inputCaption: `Detail the features and benefits of your product or service.`, type: 'textarea', inputExample: 'A cloud-based CRM software designed for small to medium-sized businesses' },
            { inputTitle: `Who is your Ideal Customer? (describe their interests)`, inputCaption: `Describe the interests and characteristics of your ideal customer.`, type: 'textarea', inputExample: 'Business owners and sales managers looking to improve customer relationships and sales tracking' },
            { inputTitle: `What problem does your product or service aim to solve for your customers?`, inputCaption: `Explain the problem your product/service solves.`, type: 'textarea', inputExample: 'The complexity and high costs of CRM systems for small businesses' },
            { inputTitle: `What action do you want the audience to take after engaging with your content?`, inputCaption: `Specify the desired action you want the audience to take.`, inputExample: 'Sign up for a 14-day free trial to experience the simplicity and efficiency of our CRM' },
            { inputTitle: `What is the unique selling points (USPs) that differentiates your product or service from competitors?`, inputCaption: `Describe what sets your product or service apart from the competition.`, type: 'textarea', inputExample: 'Affordable pricing, user-friendly interface, and tailored features for SMEs' }
        ]
    },
    expectationSurpriseModel: {
        prompt: "311",
        icon: "🎉",
        title: "Expectation-Surprise model",
        category: "copywriting",
        caption: `Set up audience expectations and then delight them with surprising elements in your content.`,
        inputs: [
            { inputTitle: `What type of content you want to create?`, inputCaption: `Select the type of content to create using the Expectation-Surprise model.`, type: `select`, options: ['Home Page', 'About us Page', 'Product Page', 'Blog Post', 'Social Media Post', 'Email', 'Video Script', 'Ad Copy', 'Other'], inputExample: 'Email' },
            { inputTitle: `What are you selling or promoting? (Include key features of your product or service)`, inputCaption: `Detail the features and benefits of your product or service.`, type: 'textarea', inputExample: 'A revolutionary new fitness app that uses AI to personalize your workout and nutrition plan' },
            { inputTitle: `What is the unique selling points (USPs) that differentiates your product or service from competitors?`, inputCaption: `Explain what makes your product or service unique.`, type: 'textarea', inputExample: 'Our app adapts in real-time to your progress, offering a truly personalized fitness journey unlike any other on the market' },
            { inputTitle: `Who is your Ideal Customer? (describe their interests)`, inputCaption: `Describe the interests of your ideal customers.`, type: 'textarea', inputExample: 'Individuals of any fitness level looking for a personalized, adaptive workout and nutrition plan' },
            { inputTitle: `What problem does your product or service aim to solve for your customers?`, inputCaption: `State the problem your product/service solves.`, type: 'textarea', inputExample: 'The one-size-fits-all approach of most fitness apps that fails to meet individual needs' },
            { inputTitle: `What action do you want the audience to take after engaging with your content?`, inputCaption: `Define the call to action for your audience.`, inputExample: 'Download the app now to start your personalized fitness journey with a 7-day free trial' }
        ]
    },
    exclusiveInclusiveModel: {
        prompt: "312",
        icon: "🌐",
        title: "Exclusive-Inclusive model",
        category: "copywriting",
        caption: `Create content that positions your product as both exclusive and accessible to a broad audience.`,
        inputs: [
            { inputTitle: `What type of content you want to create?`, inputCaption: `Select the type of content to create using the Exclusive-Inclusive model.`, type: `select`, options: ['Home Page', 'About us Page', 'Product Page', 'Blog Post', 'Social Media Post', 'Email', 'Video Script', 'Ad Copy', 'Other'], inputExample: 'Ad Copy' },
            { inputTitle: `What are you selling or promoting? (Include key features of your product or service)`, inputCaption: `Detail the features and benefits of your product or service.`, type: 'textarea', inputExample: 'An elite membership program offering exclusive deals, early access to products, and personalized services' },
            { inputTitle: `What is the unique selling points (USPs) that differentiates your product or service from competitors?`, inputCaption: `Describe what sets your product or service apart.`, type: 'textarea', inputExample: 'Our membership includes access to a global network of luxury services and experiences not available elsewhere' },
            { inputTitle: `Who is your Ideal Customer? (describe their interests)`, inputCaption: `Explain who your ideal customers are and their interests.`, type: 'textarea', inputExample: 'Affluent individuals interested in luxury lifestyle, exclusive services, and high-end products' },
            { inputTitle: `What problem does your product or service aim to solve for your customers?`, inputCaption: `State the problem your product/service solves.`, type: 'textarea', inputExample: 'The desire for unique and exclusive experiences that differentiate from the commonplace' },
            { inputTitle: `What action do you want the audience to take after engaging with your content?`, inputCaption: `Specify the desired action from your audience.`, inputExample: 'Apply for membership today to unlock a world of exclusive benefits and experiences' }
        ]
    },
    friendExpertModel: {
        prompt: "313",
        icon: "🤝",
        title: "Friend-Expert model",
        category: "copywriting",
        caption: `Combine friendly advice with expert knowledge in your content to engage and inform.`,
        inputs: [
            { inputTitle: `What type of content you want to create?`, inputCaption: `Select the type of content to create using the Friend-Expert model.`, type: `select`, options: ['Home Page', 'About us Page', 'Product Page', 'Blog Post', 'Social Media Post', 'Email', 'Video Script', 'Ad Copy', 'Other'], inputExample: 'Blog Post' },
            { inputTitle: `What are you selling or promoting? (Include key features of your product or service)`, inputCaption: `Detail the features and benefits of your product or service.`, type: 'textarea', inputExample: 'Comprehensive online courses for digital marketing, designed by industry experts' },
            { inputTitle: `What is the unique selling points (USPs) that differentiates your product or service from competitors?`, inputCaption: `Explain what makes your product or service unique.`, type: 'textarea', inputExample: 'Real-world applications, hands-on projects, and mentorship from professionals in the field' },
            { inputTitle: `Who is your Ideal Customer? (describe their interests)`, inputCaption: `Describe the interests and characteristics of your ideal customer.`, type: 'textarea', inputExample: 'Aspiring digital marketers, small business owners, and marketing professionals looking to upgrade their skills' },
            { inputTitle: `What problem does your product or service aim to solve for your customers?`, inputCaption: `Explain the problem your product/service solves.`, type: 'textarea', inputExample: 'The gap between theoretical knowledge and practical, applicable digital marketing skills' },
            { inputTitle: `What action do you want the audience to take after engaging with your content?`, inputCaption: `Specify the desired action you want the audience to take.`, inputExample: 'Enroll in our courses today to bridge the gap in your digital marketing skills and advance your career' }
        ]
    },  
    PARmodel: {
        prompt: "314",
        icon: "💊",
        title: "P-A-R model",
        category: "copywriting",
        caption: `Use the 'Pain-Agitate-Relief' model to identify customer issues and present your product as the solution.`,
        inputs: [
            { inputTitle: `What type of content you want to create?`, inputCaption: `Select the type of content for your campaign`, type: `select`, options: ['Home Page', 'About us Page', 'Product Page', 'Blog Post', 'Social Media Post', 'Email', 'Video Script', 'Ad Copy', 'Other'], inputExample: 'Social Media Post' },
            { inputTitle: `What are you selling or promoting?`, inputCaption: `Describe your product or service briefly`, type: 'textarea', inputExample: 'An app that simplifies meal planning and grocery shopping for busy families' },
            { inputTitle: `What is the unique selling points (USPs)?`, inputCaption: `List the key features that make your offering stand out`, type: 'textarea', inputExample: 'Customizable meal plans, automated grocery lists, and integration with local supermarkets for online shopping' },
            { inputTitle: `Who is your Ideal Customer?`, inputCaption: `Describe characteristics and interests of your target audience`, type: 'textarea', inputExample: 'Busy parents and individuals looking for efficient ways to manage meal planning and grocery shopping' },
            { inputTitle: `What problem does your product solve?`, inputCaption: `Explain the main pain point your product addresses`, type: 'textarea', inputExample: 'The time-consuming nature of meal planning and grocery shopping, especially for those with hectic schedules' },
            { inputTitle: `What action do you want from the audience?`, inputCaption: `Specify the desired outcome (e.g., purchase, sign-up)`, type: 'textarea', inputExample: 'Download the app and sign up for a free trial to start simplifying your meal planning today' }
        ]
    },
    solutionSavingsSocialProofmodel: {
        prompt: "315",
        icon: "🏷️",
        title: "Solution-Savings-Social Proof model",
        category: "copywriting",
        caption: `Harness the Solution-Savings-Social Proof model to create engaging and persuasive content that resonates with your audience.`,
        inputs: [
            { inputTitle: `What type of content you want to create?`, inputCaption: `Select the type of content for your campaign`, type: `select`, options: ['Home Page', 'About us Page', 'Product Page', 'Blog Post', 'Social Media Post', 'Email', 'Video Script', 'Ad Copy', 'Other'], inputExample: 'Home Page' },
            { inputTitle: `What are you selling or promoting?`, inputCaption: `Describe your product or service briefly`, type: 'textarea', inputExample: `Eco-friendly laundry detergent that's tough on stains but gentle on the planet` },
            { inputTitle: `What is the unique selling points (USPs)?`, inputCaption: `List the key features that make your offering stand out`, type: 'textarea', inputExample: 'Made with natural ingredients, reduces water pollution, and comes in zero-waste packaging' },
            { inputTitle: `Who is your Ideal Customer?`, inputCaption: `Describe characteristics and interests of your target audience`, type: 'textarea', inputExample: 'Environmentally conscious families and individuals seeking sustainable household products' },
            { inputTitle: `What problem does your product solve?`, inputCaption: `Explain the main pain point your product addresses`, type: 'textarea', inputExample: 'The need for an effective, eco-friendly alternative to chemical-laden laundry detergents' },
            { inputTitle: `What action do you want from the audience?`, inputCaption: `Specify the desired outcome (e.g., purchase, sign-up)`, type: 'textarea', inputExample: 'Try our laundry detergent today and make the switch to a more sustainable cleaning routine' }
        ]
    },
    storySolveSellModel: {
        prompt: "316",
        icon: "📖",
        title: "Story-Solve-Sell model",
        category: "copywriting",
        caption: `Engage your audience with a captivating story, showcase your solution, and drive sales using the 'Story-Solve-Sell' model.`,
        inputs: [
            { inputTitle: `What type of content you want to create?`, inputCaption: `Select the type of content for your campaign`, type: `select`, options: ['Home Page', 'About us Page', 'Product Page', 'Blog Post', 'Social Media Post', 'Email', 'Video Script', 'Ad Copy', 'Other'], inputExample: 'Email' },
            { inputTitle: `What are you selling or promoting? (Include key features of your product or service)`, inputCaption: `Describe your product or service, focusing on key features`, type: 'textarea', inputExample: 'A revolutionary fitness tracker that monitors vital health metrics and suggests personalized workouts' },
            { inputTitle: `What is the unique selling points (USPs) that differentiates your product or service from competitors?`, inputCaption: `Explain what makes your product or service unique compared to others`, type: 'textarea', inputExample: 'Our fitness tracker offers real-time health insights, integrates with top health apps, and provides AI-driven workout recommendations' },
            { inputTitle: `Who is your Ideal Customer? (describe their interests)`, inputCaption: `Describe your target audience, including their interests and needs`, type: 'textarea', inputExample: 'Health enthusiasts, athletes, and anyone looking to improve their physical fitness and well-being' },
            { inputTitle: `What problem does your product or service aim to solve for your customers?`, inputCaption: `Detail the problem your product or service solves for customers`, type: 'textarea', inputExample: 'The challenge of tracking and improving health metrics without professional equipment' },
            { inputTitle: `What action do you want the audience to take after engaging with your content?`, inputCaption: `Specify the desired call-to-action for your audience`, type: 'textarea', inputExample: 'Purchase our fitness tracker now and take the first step towards a healthier lifestyle' }
        ]
    },
    storybrandModel: {
        prompt: "317",
        icon: "🌟",
        title: "Storybrand model",
        category: "copywriting",
        caption: `Navigate branding with a customer-centric approach, focusing on meeting their needs and positioning your brand as a guide.`,
        inputs: [
            { inputTitle: `What type of content you want to create?`, inputCaption: `Select the type of content for your branding strategy`, type: `select`, options: ['Home Page', 'About us Page', 'Product Page', 'Blog Post', 'Social Media Post', 'Email', 'Video Script', 'Ad Copy', 'Other'], inputExample: 'About us Page' },
            { inputTitle: `What are you selling or promoting? (Include key features of your product or service)`, inputCaption: `Briefly describe your product or service, emphasizing key features`, type: 'textarea', inputExample: 'Custom web design services that focus on user experience, creativity, and boosting your online presence' },
            { inputTitle: `What is the unique selling points (USPs) that differentiates your product or service from competitors?`, inputCaption: `Detail what sets your product or service apart from competitors`, type: 'textarea', inputExample: 'Our personalized approach to web design, where we work closely with clients to understand their vision and objectives' },
            { inputTitle: `Who is your Ideal Customer? (describe their interests)`, inputCaption: `Characterize your ideal customer, including their interests and preferences`, type: 'textarea', inputExample: `Small to medium-sized business owners looking to enhance their brand's online visibility and customer engagement` },
            { inputTitle: `What problem does your product or service aim to solve for your customers?`, inputCaption: `Explain the customer problem your product or service addresses`, type: 'textarea', inputExample: 'The struggle to stand out in a crowded online marketplace and attract the right audience' },
            { inputTitle: `What action do you want the audience to take after engaging with your content?`, inputCaption: `Define the desired call-to-action for your audience`, type: 'textarea', inputExample: `Contact us for a free consultation and let's start crafting your unique online identity`}
        ]
    }
}
export default CopywritingToolsData;
