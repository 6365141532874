const SupportData = {
    cannedResponses: {
        prompt: "1607",
        icon: "📝",
        title: "Canned Responses",
        category: "support",
        caption: `Maximize communication efficiency with quick generation of professional and customized responses suitable for various situations, improving customer satisfaction.`,
        inputs: [
            { inputTitle: `Please share the customer's review or message you received?`, inputCaption: `Paste the customer's message or review here`, type: 'textarea', inputExample: "Thank you for your feedback!" },
            { inputTitle: `Context of the conversation`, inputCaption: `Optional: Provide any relevant background or context of the conversation`, type: 'textarea', required: 'false', inputExample: "The customer had an issue with the product delivery." },
            { inputTitle: `Is there anything that you'd like to tell the customer?`, inputCaption: `Optional: Include any specific points or messages you want to convey to the customer`, type: 'textarea', required: 'false', inputExample: "We apologize for the inconvenience and are taking steps to ensure smoother deliveries in the future." }
        ]
    },
    customerObjections: {
        prompt: "1606",
        icon: "🛑",
        title: "Customer Objections",
        category: "support",
        caption: `Develop empathetic and personalized responses to customer objections, turning challenges into opportunities for building trust and reinforcing loyalty.`,
        inputs: [
            { inputTitle: `Can you describe your product or service and its main features & benefits?`, inputCaption: `Detail your product or service along with its features and benefits`, type: 'textarea', inputExample: "Our product is a premium-quality solution designed to meet your needs." },
            { inputTitle: `What are the concerns or objections your customers have?`, inputCaption: `List common customer concerns or objections related to your product or service`, type: 'textarea', inputExample: "Customers often raise concerns about pricing and delivery times." },
            { inputTitle: `Is there anything that you'd like to tell the customer?`, inputCaption: `Optional: Include specific messages or information you wish to communicate to the customer`, type: 'textarea', required: 'false', inputExample: "We offer competitive pricing and expedited shipping options." }
        ]
    },
    customerReviewResponse: {
        prompt: "1601",
        icon: "💬",
        title: "Customer Review Response",
        category: "support",
        caption: `Facilitate the crafting of tailored responses to customer reviews, enhancing your brand's engagement and reputation.`,
        inputs: [
            { inputTitle: `Please share the customer's review or message you received?`, inputCaption: `Paste the customer's review or message here`, type: 'textarea', inputExample: "Thank you for your positive review! We're glad you're enjoying our product." },
            { inputTitle: `Where would you like the response to be posted?`, inputCaption: `Select where you plan to post the response (e.g., Email, Social Media)`, type: "select", options: ['Email', 'Social Media', 'Website', 'Others'], inputExample: "Social Media" },
            { inputTitle: `Tell us about your business or the product that your customer is referring to.`, inputCaption: `Provide details about your business or specific product the review is about`, type: 'textarea', inputExample: "Our business specializes in providing high-quality gadgets to tech enthusiasts." }
        ]
    },
    faqGenerator: {
        prompt: "1602",
        icon: "❓",
        title: "FAQ Generator",
        category: "support",
        caption: `Efficiently create FAQs that are precisely relevant and clearly structured for your specific topic or context.`,
        inputs: [
            { inputTitle: `What is the main topic or subject for the FAQs?`, inputCaption: `Specify the topic or subject for which you need FAQs`, type: 'textarea', inputExample: "Product Usage FAQs" },
            { inputTitle: `Where will these FAQs be used?`, inputCaption: `Indicate where the FAQs will be published or utilized`, type: 'text', inputExample: "On our website's support page" }
        ]
    },
    qualityAuditForm: {
        prompt: "1603",
        icon: "🔍",
        title: "Quality Audit Form",
        category: "support",
        caption: `Streamline the quality audit process with an easy-to-use tool for evaluating critical business areas, ensuring high standards and operational excellence.`,
        inputs: [
            { inputTitle: `What is the main objective of this audit?`, inputCaption: `Describe the primary goal or focus of the audit`, type: 'textarea', inputExample: "To assess customer service performance" },
            { inputTitle: `Which department or process are you auditing?`, inputCaption: `Specify the department or process subject to the audit`, type: 'text', inputExample: "Customer Support" }
        ]
    },
    supportDocs: {
        prompt: "1604",
        icon: "📚",
        title: "Support Docs",
        category: "support",
        caption: `Generate detailed and user-centric support documentation, tailored to enhance understanding and satisfaction for your product users.`,
               inputs: [
            { inputTitle: `What's the main topic or focus of your support documentation?`, inputCaption: `Describe the primary topic or focus of the documentation`, type: 'text', inputExample: "Troubleshooting Guide" },
            { inputTitle: `Can you describe your product/service/system and its main features?`, inputCaption: `Detail your product, service, or system along with its key features`, type: 'textarea', inputExample: "Our product is a software solution designed to streamline business operations." },
            { inputTitle: `How can people use your product, service or system?`, inputCaption: `Explain how customers can use or interact with your product, service, or system`, type: 'textarea', inputExample: "Users can access our platform through a web browser or mobile app." }
        ]
    },
    userManualWriting: {
        prompt: "1605",
        icon: "📘",
        title: "User Manual Writing",
        category: "support",
        caption: `Create accessible and comprehensive user manuals that clearly and effectively communicate how to use your product or system.`,
        inputs: [
            { inputTitle: `Can you describe your product/service/system and its main features?`, inputCaption: `Provide a detailed description of your product, service, or system along with its main features`, type: 'textarea', inputExample: "Our product is a home automation system with features like smart lighting and thermostat control." },
            { inputTitle: `How can people use your product, service or system?`, inputCaption: `Detail the steps or methods for using your product, service, or system`, type: 'textarea', inputExample: "Users can control their home devices through our mobile app or voice commands." }
        ]
    }
};
export default SupportData;

