const WritingAssistantData = {  
    grammarChecker: {
        prompt: "1818",
        icon: "✏️",
        title: "Grammar Checker",
        category: "Writing Assistant",
        caption: "Check and correct grammatical errors in your text to enhance clarity and readability.",
        inputs: [
            { inputTitle: `Enter Text to Check`, inputCaption: `Paste the text you want to check for grammatical errors.`, type: 'textarea', inputExample: `This are the best solution for your problem.` }
        ]
    },
    paragraphGenerator: {
        prompt: "1811",
        icon: "📝",
        title: "Paragraph Generator",
        category: "Writing Assistant",
        caption: "Create well-structured and coherent paragraphs on a given topic or idea.",
        inputs: [
            { inputTitle: `Topic or Idea`, inputCaption: `What is the topic or idea you want to generate a paragraph about?`, type: 'text', inputExample: `Importance of daily exercise` }
        ]
    },
    paragraphRewriter: {
        prompt: "1812",
        icon: "🔄",
        title: "Paragraph Rewriter",
        category: "Writing Assistant",
        caption: "Rewrite paragraphs to improve structure, clarity, and style without changing the intended meaning.",
        inputs: [
            { inputTitle: `Original Paragraph`, inputCaption: `Paste the paragraph you want to rewrite.`, type: 'textarea', inputExample: `Daily exercise is very important for your health. It helps you stay fit, improve your mood, and reduce your risk of many diseases.` }
        ]
    },
    rewordingTool: {
        prompt: "1814",
        icon: "✍️",
        title: "Rewording Tool",
        category: "Writing Assistant",
        caption: "Reword sentences or paragraphs to improve expression and avoid redundancy.",
        inputs: [
            { inputTitle: `Text to Reword`, inputCaption: `Paste the text that needs rewording.`, type: 'textarea', inputExample: `It's essential to drink plenty of water every day to stay hydrated and maintain good health.` }
        ]
    },
    sentenceRewriterTool: {
        prompt: "1815",
        icon: "🔁",
        title: "Sentence Rewriter Tool",
        category: "Writing Assistant",
        caption: "Enhance the quality and clarity of your sentences by rewriting them more effectively.",
        inputs: [
            { inputTitle: `Sentence to Rewrite`, inputCaption: `Enter the sentence you want to rewrite.`, type: 'text', inputExample: `Eating fruits daily is good for health.` }
        ]
    },  
    paraphrasingContent: {
        prompt: "1801",
        icon: "🔄",
        title: "Paraphrasing Content",
        category: "Writing Assistant",
        caption: `Effortlessly rephrase your text with this tool, ensuring a new version that retains the original message.`,
        inputs: [
            { inputTitle: `Can you share the text you'd like paraphrased?`, inputCaption: `Paste the text you want to paraphrase here.`, rowCount:9, type: 'textarea', limit:5500, note:'Max 5500 char', inputExample: `In today's fast-paced world, managing stress is more important than ever. Finding time for relaxation and hobbies can significantly improve your mental health.` },
            { inputTitle: `How should the new version sound?`, inputCaption: 'Choose a tone for the paraphrased text (e.g., formal, casual).', required: 'false', inputExample: `formal` }
        ]
    },
    passiveVoiceChanger: {
        prompt: "1807",
        icon: "🔄", 
        title: "Passive Voice Changer",
        category: "Writing Assistant",
        caption: "Transform your sentences from passive to active voice effortlessly.",
        inputs: [
            { inputTitle: "Enter your text to transform from passive to active voice.", inputCaption: "Type or paste your text here.", type: 'textarea', inputExample: `The book was read by her every night.` }
        ]
    }, 
    sentenceExpand: {
        prompt: "1808",
        icon: "📖", 
        title: "Sentence Expand",
        category: "Writing Assistant",
        caption: "Expand your sentences for more detail and depth.",
        inputs: [
            { inputTitle: "Enter your text to expand.", inputCaption: "Type or paste your text here.", type: 'textarea', inputExample: `He's good at singing.` }
        ]
    },    
    sentenceShorten: {
        prompt: "1809",
        icon: "✂️", 
        title: "Sentence Shorten",
        category: "Writing Assistant",
        caption: "Concisely shorten your sentences for clarity and brevity.",
        inputs: [
            { inputTitle: "Enter your text to shorten.", inputCaption: "Type or paste your text here.", type: 'textarea', inputExample: `Due to the fact that the weather was cold, she decided to wear a thick jacket.` }
        ]
    },
    sentenceRephraser: {
        prompt: "1810",
        icon: "🔀", 
        title: "Sentence Rephraser",
        category: "Writing Assistant",
        caption: "Rephrase your sentences for enhanced clarity and style.",
        inputs: [
            { inputTitle: "Enter your text to rephrase.", inputCaption: "Type or paste your text here.", type: 'textarea', inputExample: `Making an investment in the stock market is a way to potentially increase your wealth.` }
        ]
    },
    textImprover: {
        prompt: "1803",
        icon: "✨",
        title: "Text Improver",
        category: "Writing Assistant",
        caption: `Enhance your writing by removing errors and unnecessary parts, making it crisp and reader-friendly.`,
        inputs: [
            { inputTitle: `Enter the text that you want to re-write or improve.`, inputCaption: `Paste the text you wish to improve.`, rowCount:9, type: 'textarea', limit:5500, note:'Max 5500 char', inputExample: `Despite the fact that he was tired, he decided that he would continue working on the project.` }
        ]
    }, 
    summarize: {
        prompt: "1804",
        icon: "📋",
        title: "Summarize",
        category: "Writing Assistant",
        caption: `Distill lengthy information into concise, easy-to-grasp summaries, enriched with emojis and hashtags.`,
        inputs: [
            { inputTitle: `Enter the Text you want to summarize`, inputCaption: `Paste the text you need summarized.`, rowCount:9, type: 'textarea', limit:5500, note:'Max 5500 char', inputExample: `Global warming is the long-term heating of Earth's climate system observed since the pre-industrial period due to human activities, predominantly fossil fuel burning, which increases heat-trapping greenhouse gas levels in Earth's atmosphere.` }
        ]
    }, 
    rewriteArticle: {
        prompt: "1805",
        icon: "🔁",
        title: "Rewrite Article (AI Detector Bypass)",
        category: "Writing Assistant",
        caption: `Revamp your articles or blog posts with this tool, ensuring they pass AI detection while maintaining originality.`,
        inputs: [
            { inputTitle: `Provide your Article/Blogpost or any other content to rewrite.`, inputCaption: `Paste your article or blog post here.`, type: 'textarea', rowCount:9, limit:7500, note:'Max 7500 char', inputExample: `Blockchain technology is transforming industries with its decentralized approach, offering security, transparency, and efficiency in transactions.` }
        ]
    },
    
    firstToThirdPersonConverter: {
        prompt: "430",
        icon: "🔄",
        title: "First to Third Person Converter",
        category: "Writing Assistant",
        caption: "Easily convert narratives from the first person to the third person perspective for varied content needs.",
        inputs: [
            { inputTitle: `Text in First Person`, inputCaption: `Enter the text that needs to be converted to third person.`, type: 'textarea', inputExample: `I believe that the new policy will benefit everyone in the long run.` }
        ]
    },
     
    addEmojis: {
        prompt: "1802",
        icon: "😊", 
        title: "Add Emojis to Text",
        category: "Writing Assistant",
        caption: `Enhance your text with the perfect emojis to make it more captivating and expressive.`,
        inputs: [
            { inputTitle: `Enter your text to add emojis.`, inputCaption: `Type or paste your text here.`, type: 'textarea', inputExample: `I love going to the beach on sunny days.` }
        ]
    }, 
    emojiTranslator: {
        prompt: "1817",
        icon: "😊",
        title: "Emoji Translator",
        category: "Writing Assistant",
        caption: "Translate your text into a fun and expressive emoji-laden language.",
        inputs: [
            { inputTitle: `Enter Text to Translate`, inputCaption: `Type the text you want to translate into emojis.`, type: 'text', inputExample: `Happy birthday! Wish you all the best.` }
        ]
    },
}
export default WritingAssistantData;
