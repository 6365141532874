const ImagePromptsData = {
    logoDesignIdeas: {
        prompt: "1001",
        icon: "🎨",
        title: "Logo Design Ideas",
        category: "image",
        caption: `Efficiently generate stylish and professional logos with ease, perfectly tailored to your brand's identity and values.`,
        inputs: [
            { inputTitle: `Product/Brand Name`, inputCaption: `Enter the name of your product or brand`, type: 'text', inputExample: "Sunrise Co." },
            { inputTitle: `What's Your Business About?`, inputCaption: `Describe your business, its values, and any visual preferences for the logo`, type: 'textarea', inputExample: "A sustainable clothing brand inspired by nature and outdoor adventures." }
        ]
    },
    aiArt: {
        prompt: "1002",
        icon: "🖌️",
        title: "AI Art",
        category: "image",
        caption: `Unleash your creativity with this digital art assistant, capable of transforming your ideas into stunning visual artworks in various styles.`,
        inputs: [
            { inputTitle: `What subject do you want to have in the image?`, inputCaption: `Describe the main subject or element of the image`, type: 'text', inputExample: "Mountain landscape" },
            { inputTitle: `What action should the subject be doing?`, inputCaption: `Optional: Specify any specific action or pose for the subject`, required: 'false', inputExample: "Standing tall against the sunset" },
            { inputTitle: `What is the time and day of the environment?`, inputCaption: `Optional: Indicate the time of day and lighting for the scene`, required: 'false', inputExample: "Late afternoon with warm sunlight" },
            { inputTitle: `Which art form to use?`, inputCaption: `Choose the artistic style for your image`, type: "select", options: ['Photography', 'Illustration', 'Watercolor', 'Oil painting', 'Comics', 'Pixar 3D', 'Digital illustration', 'Artwork', 'Paperwork', '3D', 'other'], inputExample: "Illustration" }
        ]
    },
    imageAltTextGenerator: {
        prompt: "620",
        icon: "🖼️",
        title: "Image Alt Text Generator",
        category: "image",
        caption: "Automatically generate descriptive alt text for images, enhancing web accessibility and SEO.",
        inputs: [
            { inputTitle: `Image Description`, inputCaption: `Provide a brief description of the image.`, type: 'textarea', inputExample: "A family having a picnic in the park." },
            { inputTitle: `Context or Usage`, inputCaption: `Where is the image being used? (e.g., website, blog, e-commerce)`, type: 'text', required: 'false', inputExample: "Website homepage" }
        ]
    },
    aiImageGenerationPrompts: {
        prompt: "702",
        icon: "🖼️",
        title: "AI Image Generation Prompts",
        category: "image",
        caption: `Effortlessly convert your screenplay into striking image prompts, aiding in the visualization and production process of your film.`,
        inputs: [
            { inputTitle: `What is the Genre of the film?`, inputCaption: `Specify the genre of your film for tailored image prompts`, type: 'text', inputExample: "Sci-Fi" },
            { inputTitle: `What is the Film Style?`, inputCaption: `Describe the visual style or mood of the film`, type: 'text', inputExample: "Futuristic and dystopian" },
            { inputTitle: `Enter your video script.`, inputCaption: `Paste the script or key excerpts for image generation`, type: 'textarea', inputExample: "INT. Abandoned City - Day: The protagonist wanders through the desolate streets, searching for signs of life." }
        ]
    },
};

export default ImagePromptsData;
