const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({ apiKey: process.env.REACT_APP_OPENAI_API_KEY });
const openai = new OpenAIApi(configuration);

class YoutubeVideoTagsAIServices {    
 

  generateYoutubeVideoTags = (formDataObj) => {
    return openai.createCompletion("text-davinci-002", {  
      prompt: `Generate YouTube Video Tags from the notes below::
      \nVideo Title: ${formDataObj.projectValue1}\nContent: ${formDataObj.projectValue2}\nNote:write in ${formDataObj.language} language\nTone of Voice:${formDataObj.tone}`, 
      temperature: 0.8,
      max_tokens: 100,
      top_p: 1.0,
      frequency_penalty: 0.0,
      presence_penalty: 0.0,  
      n: 1 //parseInt(`${formDataObj.variant}`) 
     });
   }

   generateMoreYoutubeVideoTags = (queryObj) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Generate YouTube Video Tags from the notes below::
      \nVideo Title: ${queryObj.projectValue1}\nContent: ${queryObj.projectValue2}\nNote:write in ${queryObj.language} language\nTone of Voice:${queryObj.tone}`, 
      temperature: 0.8,  
      max_tokens: 100,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,  
      n: 1  
     });
   }

   addMoreYoutubeVideoTags = (queryObj, pDes) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Generate YouTube Video Tags from the notes below::
      \nnWebsite: ${queryObj.projectValue1}\nContent: ${queryObj.projectValue2}\nNote:write in ${queryObj.language} language\nTone of Voice:${pDes}`,
      temperature: 0.7,  
      max_tokens: 40,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0
     });
   }

}

export default new YoutubeVideoTagsAIServices();

 