const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({ apiKey: process.env.REACT_APP_OPENAI_API_KEY });
const openai = new OpenAIApi(configuration);

class MetaTitleAIServices {    
 

  generateMetaTitle = (formDataObj) => {
    return openai.createCompletion("text-davinci-002", {  
      prompt: `Write a meta title from the following details:
      \nWebsite: ${formDataObj.projectValue1}\nContent: ${formDataObj.projectValue2}\nNote:write in ${formDataObj.language} language\nTone of Voice:${formDataObj.tone}`, 
      temperature: 0.7,
      max_tokens: 26,
      top_p: 1.0,
      frequency_penalty: 0.0,
      presence_penalty: 0.0,  
      n: 5 //parseInt(`${formDataObj.variant}`) 
     });
   }

   generateMoreMetaTitle = (queryObj) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Write a meta title from the following details:
      \nnWebsite: ${queryObj.projectValue1}\nContent: ${queryObj.projectValue2}\nNote:write in ${queryObj.language} language\nTone of Voice:${queryObj.tone}`,
      temperature: 0.7,  
      max_tokens: 26,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,  
      n: 5  
     });
   }

   addMoreMetaTitle = (queryObj, pDes) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Write a meta title from the following details:
      \nnWebsite: ${queryObj.projectValue1}\nContent: ${queryObj.projectValue2}\nNote:write in ${queryObj.language} language\nTone of Voice:${pDes}`,
      temperature: 0.7,  
      max_tokens: 20,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0
     });
   }

}

export default new MetaTitleAIServices();

 