import { db } from "../../../../firebase";

import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  setDoc,
  Timestamp
} from "firebase/firestore"; 


//const uid = localStorage.getItem("user"); 
//console.log(uid);

class ArticleInterviewListDataService {  
   
 
  addProject = (uid, newProject) => {
     //console.log(newProject)    
     const projectRef = collection(db, `projects/${uid}/ArticleCustome/`)
     return addDoc(projectRef, newProject);  
   } 
   updateProject = (uid, updateProject) => {
    // //console.log(updateProject)    
     const projectRef = doc(db, `projects/${uid}/ArticleCustome/${updateProject.prjId}/`)
     return updateDoc(projectRef, {text: updateProject.text});  
   } 
   updateProjectId = (uid, pId) => {    
     const projectIdRef = doc(db, `projects/${uid}/ArticleCustome/${pId}/`)
     updateDoc(projectIdRef, {id: pId});  
   }
 
   addProjectContent = (uid, newObj) => {
     const textObj = {text: newObj.text, projectId: newObj.projectId}  
     const projectCountRef = doc(db, `projects/${uid}/ArticleCustome/${newObj.projectId}/`)
     updateDoc(projectCountRef, {files: newObj.files});  
 
     const projectRef = collection(db, `projects/${uid}/ArticleCustome/${newObj.projectId}/content/`)
     return addDoc(projectRef, textObj);   
     //  const projectRef = doc(db, `userData/${uid}/projects/${newObj.projectId}`)
     //   updateDoc(projectRef, {content: arrayRemove({text: 'greater_virginia'})}); 
     //  return updateDoc(projectRef, {content: arrayUnion({text: 'greater_coral'})}); 
   }  
 
   getProjectData = (uid, pId) => {
     const projectRef = doc(db, `projects/${uid}/ArticleCustome/${pId}/`); 
     return getDoc(projectRef)
   }
 
   removeContent = (uid, remObj) => { 
     const projectCountRef = doc(db, `projects/${uid}/ArticleCustome/${remObj.projectId}/`)
     updateDoc(projectCountRef, {files: remObj.files});  
     const projectRef = doc(db, `projects/${uid}/ArticleCustome/${remObj.projectId}/content/${remObj.id}`); 
     return deleteDoc(projectRef)
   }
 
   removeDocContent = (uid, remObj) => { 
     const projectCountRef = doc(db, `projects/${uid}/ArticleCustome/${remObj.projectId}/`)
     updateDoc(projectCountRef, {files: remObj.files});  
     const projectRef = doc(db, `projects/${uid}/ArticleCustome/${remObj.projectId}/content/${remObj.id}`); 
      deleteDoc(projectRef)
     const projRef = doc(db, `projects/${uid}/ArticleCustome/${remObj.projectId}`); 
     return deleteDoc(projRef)
   }
 
   updateProjectContent = (uid, upObj) => { 
    // //console.log(upObj)
     const projectRef = doc(db, `projects/${uid}/ArticleCustome/${upObj.projectId}/content/${upObj.id}`); 
     return updateDoc(projectRef, {text: upObj.text})
   } 
 
   getAllProjects = (uid) => {  
     const projectRef = collection(db, `projects/${uid}/ArticleCustome/`)
     return getDocs(projectRef); 
   } 
   getAllProjectContent = (uid, prjId) => {    
     const projectRef = collection(db, `projects/${uid}/ArticleCustome/${prjId}/content/`); 
     return getDocs(projectRef)
   }
   deleteProjectContent = async (uid, prjID) => { 
       if(prjID){   
         const contentData = await this.getAllProjectContent(uid, prjID);  
         contentData.docs.map(file => {
            //console.log(file.id);  
            const projectRef = doc(db, `projects/${uid}/ArticleCustome/${prjID}/content/${file.id}`); 
            deleteDoc(projectRef)
         })
         const projRef = doc(db, `projects/${uid}/ArticleCustome/${prjID}`); 
         deleteDoc(projRef)
      }   
   }
}

export default new ArticleInterviewListDataService();

 