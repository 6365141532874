const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({ apiKey: process.env.REACT_APP_OPENAI_API_KEY });
const openai = new OpenAIApi(configuration);

class BlogSectionAIServices {    
 

  generateBlogSection = (formDataObj) => {
    return openai.createCompletion("text-davinci-002", { 
      // prompt: `Write the complete product description in to a detailed professional, witty and clever explanation for the following product:
      // \nProduct: ${formDataObj.productName}\nFeatures: ${formDataObj.productFeatures}\nNote:write in ${formDataObj.language} language\nTone of Voice:${formDataObj.tone}`, 
      prompt: `Write the complete blog intro in to a detailed explanation for the following details:
      \nBlog Title: ${formDataObj.productName}\nBlog About: ${formDataObj.productFeatures}\nNote:write in ${formDataObj.language} language\nTone of Voice:${formDataObj.tone}`, 
      temperature: 0.7,
      max_tokens: 84,
      top_p: 1.0,
      frequency_penalty: 0.0,
      presence_penalty: 0.0,  
      n: 3 //parseInt(`${formDataObj.variant}`) 
     });
   }

   generateMoreBlogSection = (queryObj) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Write the section content for the following\nBlog Title: ${queryObj.productName}\nSection: ${queryObj.productFeatures}\nNote:write in ${queryObj.language} language\nTone of Voice:${queryObj.tone}`,
      temperature: 0.7,  
      max_tokens: 84,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,  
      n: 3  
     });
   }

   addMoreBlogSection = (queryObj, pDes) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Expand the section content for the following\nBlog Title: ${queryObj.productName}\nSection Description: ${pDes}\nNote:write in ${queryObj.language} language\nTone of Voice:${queryObj.tone}`, 
      temperature: 0.7,  
      max_tokens: 64,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0
     });
   }

}

export default new BlogSectionAIServices();
 