const PodcastData = {
    podcastNameGenerator: {
        prompt: "3001",
        icon: "🎙️",
        title: "Podcast Name Generator",
        category: "podcast",
        caption: "Generate catchy and memorable names for your podcast.",
        inputs: [
            { inputTitle: `Podcast Theme`, inputCaption: `Describe the theme or main topic of your podcast.`, type: 'text', inputExample: "Tech and Innovation" },
            { inputTitle: `Keywords `, inputCaption: `Include any specific keywords or phrases to be used in the podcast name.`, type: 'text', required: 'false', inputExample: "Future, Technology, Insights" }
        ]
    },
    podcastTopicIdeasGenerator: {
        prompt: "3002",
        icon: "💡",
        title: "Podcast Topic Ideas Generator",
        category: "podcast",
        caption: "Generate fresh and engaging topic ideas for your podcast episodes.",
        inputs: [
            { inputTitle: `General Theme of Podcast`, inputCaption: `Describe the general theme or focus of your podcast.`, type: 'text', inputExample: "Health and Wellness" },
            { inputTitle: `Target Audience`, inputCaption: `Who is your primary target audience?`, type: 'text', inputExample: "Fitness enthusiasts" }
        ]
    },
    podcastEpisodeTitleGenerator: {
        prompt: "3003",
        icon: "🏷️",
        title: "Podcast Episode Title Generator",
        category: "podcast",
        caption: "Create catchy and appealing titles for your podcast episodes.",
        inputs: [
            { inputTitle: `Episode Content Summary`, inputCaption: `Briefly summarize the content or main points of the episode.`, type: 'textarea', inputExample: "Effective Workout Strategies for Busy Professionals" },
            { inputTitle: `Keywords `, inputCaption: `Include any specific keywords to be used in the episode title.`, type: 'text', required: 'false', inputExample: "Fitness, Productivity, Workouts" }
        ]
    },
    podcastScriptWriter: {
        prompt: "3004",
        icon: "📝",
        title: "Podcast Script Writer",
        category: "podcast",
        caption: "Write detailed scripts for your podcast episodes, ensuring a smooth and engaging narrative.",
        inputs: [
            { inputTitle: `Episode Topic`, inputCaption: `What is the topic of this podcast episode?`, type: 'text', inputExample: "Effective Communication Skills" },
            { inputTitle: `Main Points to Cover`, inputCaption: `List the main points or segments you want to include in the episode.`, type: 'textarea', inputExample: "Active listening, Non-verbal communication, Conflict resolution" }
        ]
    },
    podcastInterviewQuestionsGenerator: {
        prompt: "3005",
        icon: "❓",
        title: "Podcast Interview Questions Generator",
        category: "podcast",
        caption: "Generate insightful and relevant interview questions for your podcast guests.",
        inputs: [
            { inputTitle: `Guest's Background or Expertise`, inputCaption: `Provide a brief background of the guest or their area of expertise.`, type: 'text', inputExample: "Professional chef and restaurateur" },
            { inputTitle: `Specific Topics to Address`, inputCaption: `Are there any specific topics or themes you want to explore in the interview?`, type: 'textarea', required: 'false', inputExample: "Innovative culinary techniques, Restaurant management tips" }
        ]
    },
    podcastMarketingPlanCreator: {
        prompt: "3006",
        icon: "📊",
        title: "Podcast Marketing Plan Creator",
        category: "podcast",
        caption: "Develop a comprehensive marketing plan to increase the reach and audience of your podcast.",
        inputs: [
            { inputTitle: `Podcast Genre and Target Audience`, inputCaption: `Describe the genre of your podcast and the target audience.`, type: 'textarea', inputExample: "Tech and innovation enthusiasts; professionals seeking career growth" },
            { inputTitle: `Key Messaging and Goals`, inputCaption: `What are the key messages and goals of your podcast?`, type: 'textarea', inputExample: "Provide valuable insights into the latest trends; build a community of like-minded individuals" }
        ]
    },
    podcastBrandingIdeasGenerator: {
        prompt: "3007",
        icon: "🌟",
        title: "Podcast Branding Ideas Generator",
        category: "podcast",
        caption: "Create unique and memorable branding ideas for your podcast.",
        inputs: [
            { inputTitle: `Podcast Name and Theme`, inputCaption: `Enter the name and theme of your podcast.`, type: 'text', inputExample: "Mindful Living: A Journey to Inner Peace" },
            { inputTitle: `Desired Branding Style`, inputCaption: `What style or vibe do you want for your podcast branding?`, type: 'text', inputExample: "Modern and minimalist" }
        ]
    },
};

export default PodcastData;
