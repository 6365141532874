const EducationData = { 
    quizGenerator: {
        prompt: "2001",
        icon: "📝",
        title: "Quiz Generator",
        category: "Education",
        caption: "Automatically generates quizzes from educational content.",
        inputs: [
            { inputTitle: "Enter Textbook Content", inputCaption: "Paste the text or chapter content here.", inputExample: `Introduction to Biology: Cell Theory, Photosynthesis, and the structure of DNA`, type: 'textarea' }
        ]
    },
    essayTopicFinder: {
        prompt: "2007",
        icon: "🔍",
        title: "Essay Topic Finder",
        category: "Education",
        caption: "Find compelling and relevant topics for your essays.",
        inputs: [
            { inputTitle: "Enter Subject or Area of Interest", inputCaption: "Type the subject or area you're interested in writing about.", inputExample: `Environmental Science`, type: 'text' },
            { inputTitle: "Desired Essay Length", inputCaption: "Specify the desired essay length (e.g., short, medium, long).", inputExample: `Medium`, type: 'select', options: ['Short', 'Medium', 'Long'] },
            { inputTitle: "Academic Level", inputCaption: "Select your academic level.", inputExample: `Undergraduate`, type: 'select', options: ['High School', 'Undergraduate', 'Graduate'] }
        ]
    },        
    essayTitleGenerator: {
        prompt: "425",
        icon: "✍️",
        title: "Essay Title Generator",
        category: "Education",
        caption: "Come up with engaging and relevant titles for your essays to draw in readers.",
        inputs: [
            { inputTitle: `Subject or Theme of the Essay`, inputCaption: `Describe the subject or theme of the essay.`, inputExample: `The Impact of Climate Change on Biodiversity` },
            { inputTitle: `Desired Tone or Style`, inputCaption: `Specify the tone or style of the essay (e.g., academic, persuasive).`, inputExample: `Persuasive`, type: 'text' }
        ]
    },
    essayOutlineCreator: {
        prompt: "2002",
        icon: "📚",
        title: "Essay Outline Creator",
        category: "Education",
        caption: "Creates structured outlines for essays or research papers.",
        inputs: [
            { inputTitle: "Enter Essay Topic", inputCaption: "Type your main topic here.", inputExample: `The Role of Technology in Education`, type: 'text' },
            { inputTitle: "Essay Length", inputCaption: "Specify the desired essay length.", inputExample: `1500 words`, type: 'text' }
        ]
    },
    essayCreator: {
        prompt: "2011",
        icon: "✍️",
        title: "Essay Creator",
        category: "Education",
        caption: "Create well-structured and insightful essays on given topics.",
        inputs: [
            { inputTitle: "Enter Essay Topic", inputCaption: "Type the topic of the essay.", inputExample: `Exploring the Ethics of Artificial Intelligence`, type: 'text' },
            { inputTitle: "Key Points to Include", inputCaption: "List any key points or arguments you want included in the essay.", inputExample: `AI in healthcare, privacy concerns, future implications`, type: 'textarea' },
            { inputTitle: "Preferred Writing Style", inputCaption: "Choose your preferred writing style.", inputExample: `Expository`, type: 'select', options: ['Expository', 'Persuasive', 'Descriptive', 'Narrative'] }
        ]
    },        
    ebooksIdeasGenerator: {
        prompt: "423",
        icon: "📚",
        title: "Ebooks Ideas Generator",
        category: "Education",
        caption: "Generate creative and marketable ebook ideas to captivate your target audience.",
        inputs: [
            { inputTitle: `Target Audience`, inputCaption: `Describe your target audience.`, inputExample: `Young adults interested in self-improvement` },
            { inputTitle: `Preferred Genre or Topic`, inputCaption: `Mention the genre or topic for your ebook.`, inputExample: `Personal Development`, type: 'text' }
        ]
    },
    essayChecker: {
        prompt: "2013",
        icon: "✅",
        title: "Essay Checker",
        category: "Education",
        caption: "Analyze your essay for clarity, coherence, grammar, and overall quality.",
        inputs: [
            { inputTitle: 'Essay Text', inputCaption: 'Paste your essay text here.', inputExample: `The significant impact of renewable energy sources on the global economy...`, type: 'textarea' },
            { inputTitle: 'Key Focus Areas', inputCaption: 'Select the key areas you want feedback on (e.g., grammar, structure, clarity, coherence).', inputExample: `Grammar, structure`, type: 'text' },
            { inputTitle: 'Essay Type', inputCaption: 'Specify the type of essay (e.g., argumentative, descriptive, narrative).', inputExample: `Argumentative`, type: 'text', required: 'false' }
        ]
    },    
    homeworkHelper: {
        prompt: "2003",
        icon: "💡",
        title: "Homework Helper",
        category: "Education",
        caption: "Assists in understanding and solving homework problems.",
        inputs: [
            { inputTitle: "Enter Homework Question", inputCaption: "Type or paste your question here.", inputExample: `What are the main causes of the French Revolution?`, type: 'textarea' }
        ]
    },
    languageLearningAssistant: {
        prompt: "2004",
        icon: "🌐",
        title: "Language Learning Assistant",
        category: "Education",
        caption: "Tool for language practice and learning.",
        inputs: [
            { inputTitle: "Enter Text for Translation", inputCaption: "Type the words or sentences here.", inputExample: `Hello, how are you today?`, type: 'text' }
        ]
    },
    historicalEventsExplorer: {
        prompt: "2005",
        icon: "🕰️",
        title: "Historical Events Explorer",
        category: "Education",
        caption: "Provides detailed information on historical events and figures.",
        inputs: [
            { inputTitle: "Enter Historical Event or Figure", inputCaption: "Type the name of the event or person here.", inputExample: `Alexander the Great`, type: 'text' }
        ]
    },
    scienceConceptVisualizer: {
        prompt: "2006",
        icon: "🔬",
        title: "Science Concept Visualizer",
        category: "Education",
        caption: "Visual representations of scientific concepts.",
        inputs: [
            { inputTitle: "Enter Scientific Concept", inputCaption: "Type the theory, principle, or equation here.", inputExample: `Newton's Laws of Motion`, type: 'text' }
        ]
    }, 
    literatureAnalysisAssistant: {
        prompt: "2008",
        icon: "📜",
        title: "Literature Analysis Assistant",
        category: "Education",
        caption: "Insights into literary works and analysis.",
        inputs: [
            { inputTitle: "Enter Literary Work", inputCaption: "Type the title or specific passages here.", inputExample: `Shakespeare's Macbeth`, type: 'text' }
        ]
    },
    mathEquationSolver: {
        prompt: "2009",
        icon: "➗",
        title: "Math Equation Solver",
        category: "Education",
        caption: "Solves equations and explains the steps.",
        inputs: [
            { inputTitle: "Enter Math Problem", inputCaption: "Type your equation or problem here.", inputExample: `2x + 3 = 15`, type: 'textarea' }
        ]
    },
    studyPlanner: {
        prompt: "2010",
        icon: "📅",
        title: "Interactive Study Planner",
        category: "Education",
        caption: "Creates personalized study schedules and reminders.",
        inputs: [
            { inputTitle: "Enter Subjects", inputCaption: "List the subjects to study.", inputExample: `Math, Science, History`, type: 'textarea' },
            { inputTitle: "Available Time", inputCaption: "How many hours can you dedicate?", inputExample: `10 hours per week` }
        ]
    },
    thesisStatementGenerator: {
        prompt: "2012",
        icon: "🎓",
        title: "Thesis Statement",
        category: "Education",
        caption: "Craft a clear and compelling thesis statement for your academic writing.",
        inputs: [
            { inputTitle: 'Topic', inputCaption: 'Enter the main topic of your paper or research.', inputExample: `The effectiveness of online education`, type: 'text' },
            { inputTitle: 'Your Stance or Argument', inputCaption: 'Briefly describe your stance or argument on this topic.', inputExample: `Online education offers flexibility and accessibility, making it an effective method for learning.`, type: 'textarea' },
            { inputTitle: 'Key Points', inputCaption: 'List the main points or arguments that support your stance (optional).', inputExample: `Access to a wide range of resources, personalized learning paths, cost-effectiveness.`, type: 'textarea', required: 'false' }
        ]
    }         
    
};

export default EducationData;
