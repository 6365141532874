const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({ apiKey: process.env.REACT_APP_OPENAI_API_KEY });
const openai = new OpenAIApi(configuration);

class AmazonAdHeadlineAIServices {    

  generateAmazonAdHeadline = (formDataObj) => {
    return openai.createCompletion("text-davinci-002", {  
      prompt: `Generate 6 Amazon AD Headline ideas from the following product:
      \nProduct: ${formDataObj.productName}\nFeatures: ${formDataObj.productFeatures}\nKeywords: ${formDataObj.keyWords}\nNote:write in ${formDataObj.language} language\nTone of Voice:${formDataObj.tone}`, 
      temperature: 0.7,
      max_tokens: 84,
      top_p: 1.0,
      frequency_penalty: 0.0,
      presence_penalty: 0.0,  
      n: 1 
     });
   }

   generateMoreAmazonAdHeadline = (queryObj) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Generate 6 Amazon AD Headline ideas from the following product:
      \nProduct: ${queryObj.productName}\nFeatures: ${queryObj.productFeatures}\nKeywords: ${queryObj.keyWords}\nNote:write in ${queryObj.language} language\nTone of Voice:${queryObj.tone}`,
      temperature: 0.7,  
      max_tokens: 84,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,  
      n: 1 
     });
   }

   addMoreAmazonAdHeadline = (queryObj, pDes) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Expand the product description for the following product:\nProduct: ${queryObj.productName}\nProduct description: ${pDes}`,
      temperature: 0.7,  
      max_tokens: 64,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0
     });
   }

}

export default new AmazonAdHeadlineAIServices();

 