const EcommerceToolsData = {
    productDescription: {
        prompt: "401",
        icon: "📝",
        title: "Product Description",
        category: "Ecommerce",
        caption: `Enhance your e-commerce presence with engaging and SEO-rich product descriptions to drive sales.`,
        inputs: [
            { inputTitle: `Enter your product description`, inputCaption: `Describe your product, including features and benefits.`, type: 'textarea', inputExample: `An innovative fitness tracker that monitors heart rate, tracks sleep patterns, and counts steps with precision. Perfect for athletes and health enthusiasts alike.` },
            { inputTitle: `Product Name?`, inputCaption: `Enter the name of your product.`, inputExample: `FitTrack Pro` }
        ]
    }, 
    taglineGenerator: {
        prompt: "402",
        icon: "💬",
        title: "Tagline Generator",
        category: "Ecommerce",
        caption: `Effortlessly create memorable and impactful taglines for your brand using AI.`,
        inputs: [
            { inputTitle: `Product/Brand Name`, inputCaption: `Enter your product or brand name.`, inputExample: `EcoClean Solutions` },
            { inputTitle: `What are you selling or promoting?`, inputCaption: `Include key features of your product or service.`, type: 'textarea', inputExample: `Eco-friendly cleaning products made with natural ingredients, safe for families and pets.` }
        ]
    }, 
    amazonAdHeadlineGenerator: {
        prompt: "440",
        icon: "📢",
        title: "Amazon Ad Headline Generator",
        category: "Ecommerce",
        caption: "Craft compelling headlines for your Amazon ads, designed to capture attention and drive clicks.",
        inputs: [
            { inputTitle: `Product Name`, inputCaption: `Enter the name of your Amazon product.`, inputExample: `EcoClean Natural Dish Soap` },
            { inputTitle: `Key Features or Selling Points`, inputCaption: `List the main features or selling points of your product.`, inputExample: `Made with organic coconut oil, fights tough grease, soft on hands` }
        ]
    },
    amazonProductBulletPointsGenerator: {
        prompt: "441",
        icon: "🔘",
        title: "Amazon Product Bullet Points Generator",
        category: "Ecommerce",
        caption: "Generate concise and informative bullet points for your Amazon product listings, highlighting key features effectively.",
        inputs: [
            { inputTitle: `Product Name`, inputCaption: `Enter the name of your Amazon product.`, inputExample: `EcoClean Natural Dish Soap` },
            { inputTitle: `Detailed Product Features`, inputCaption: `Provide a detailed list of product features.`, type: 'textarea', inputExample: `Contains 100% natural ingredients, Eco-friendly packaging, Non-toxic formula safe for all family members` }
        ]
    },
    amazonProductDescriptionGenerator: {
        prompt: "442",
        icon: "📄",
        title: "Amazon Product Description Generator",
        category: "Ecommerce",
        caption: "Create detailed and persuasive product descriptions for your Amazon listings to enhance customer interest.",
        inputs: [
            { inputTitle: `Product Name`, inputCaption: `Enter the name of your Amazon product.`, inputExample: `EcoClean Natural Laundry Detergent` },
            { inputTitle: `Key Features and Benefits`, inputCaption: `Describe the features and benefits of your product.`, type: 'textarea', inputExample: `Our laundry detergent uses a plant-based formula to effectively remove stains while being gentle on clothes and the environment. Suitable for sensitive skin and all fabric types.` }
        ]
    },        
    amazonProductTitleGenerator: {
        prompt: "443",
        icon: "🏷️",
        title: "Amazon Product Title Generator",
        category: "Ecommerce",
        caption: "Develop SEO-friendly and descriptive product titles for Amazon to improve searchability and attract buyers.",
        inputs: [
            { inputTitle: `Brief Product Description`, inputCaption: `Provide a brief description of your Amazon product.`, inputExample: `Eco-friendly, plant-based laundry detergent in a concentrated formula for high efficiency.` },
            { inputTitle: `Key Features or Attributes`, inputCaption: `List key features or attributes of the product.`, inputExample: `Concentrated, plant-based, eco-friendly` }
        ]
    },   
    optimizeEBayListing: {
        prompt: "403",
        icon: "🔍",
        title: "Optimize eBay Listing",
        category: "Ecommerce",
        caption: `Improve your eBay listings with search-optimized titles and descriptions to attract more buyers.`,
        inputs: [
            { inputTitle: `Enter Product Name or Keyword`, inputCaption: `Type the product name or relevant keyword.`, inputExample: `Vintage Leather Backpack` },
            { inputTitle: `List any specific features of the product`, inputCaption: `Detail specific features of the product.`, type: 'textarea', inputExample: `Genuine leather, multiple compartments, adjustable straps, perfect for casual or professional use.` }
        ]
    }, 
    eBayAdHeadlineGenerator: {
        prompt: "419",
        icon: "📢",
        title: "eBay Ad Headline Generator",
        category: "Ecommerce",
        caption: "Generate captivating headlines for your eBay ads to grab attention and drive more clicks.",
        inputs: [
            { inputTitle: `What is your product category?`, inputCaption: `Describe the category or type of your eBay product.`, inputExample: `Men's Vintage Accessories` },
            { inputTitle: `Key features or selling points of your product`, inputCaption: `Highlight the main features or selling points.`, inputExample: `Authentic vintage design, premium quality leather, versatile style for any occasion` }
        ]
    },
    eBayProductBulletPointsGenerator: {
        prompt: "420",
        icon: "🔘",
        title: "eBay Product Bullet Points Generator",
        category: "Ecommerce",
        caption: "Create concise and informative bullet points for your eBay listings to showcase key features effectively.",
        inputs: [
            { inputTitle: `Product Name?`, inputCaption: `Enter the name of your eBay product.`, inputExample: `Handcrafted Wooden Chess Set` },
            { inputTitle: `Detailed Product Description`, inputCaption: `Provide a detailed description of your product.`, type: 'textarea', inputExample: `Expertly crafted from high-quality wood, this chess set includes a foldable board and intricately designed pieces. Perfect for enthusiasts and collectors.` }
        ]
    },
    eBayProductDescriptionGenerator: {
        prompt: "421",
        icon: "📄",
        title: "eBay Product Description Generator",
        category: "Ecommerce",
        caption: "Craft detailed and engaging product descriptions for your eBay listings to enhance buyer interest and credibility.",
        inputs: [
            { inputTitle: `Product Name?`, inputCaption: `Enter the name of your eBay product.`, inputExample: `Luxury Silk Scarf` },
            { inputTitle: `Product Features and Benefits`, inputCaption: `List the features and benefits of your product.`, type: 'textarea', inputExample: `Made from 100% pure silk, our scarves offer unparalleled softness and elegance. Available in a variety of patterns to complement any wardrobe.` }
        ]
    },
    eBayProductTitleGenerator: {
        prompt: "422",
        icon: "🏷️",
        title: "eBay Product Title Generator",
        category: "Ecommerce",
        caption: "Develop SEO-friendly and descriptive titles for your eBay products to improve visibility and search relevance.",
        inputs: [
            { inputTitle: `Brief Product Description`, inputCaption: `Provide a brief description of your eBay product.`, inputExample: `Compact and lightweight portable Bluetooth speaker with high-quality sound.` },
            { inputTitle: `Key Features or Attributes`, inputCaption: `List key features or attributes of the product.`, inputExample: `Bluetooth, portable, high-quality sound, long battery life` }
        ]
    },
    etsyAdHeadlineGenerator: {
        prompt: "426",
        icon: "💬",
        title: "Etsy Ad Headline Generator",
        category: "Ecommerce",
        caption: "Create compelling headlines for your Etsy ads to attract and engage potential customers.",
        inputs: [
            { inputTitle: `Product/Brand Name`, inputCaption: `Enter your product or brand name for Etsy.`, inputExample: `Handmade Ceramic Planters` },
            { inputTitle: `Key Selling Points`, inputCaption: `Highlight the key selling points of your product.`, type: 'textarea', inputExample: `Unique design, eco-friendly materials, perfect for indoor and outdoor plants` }
        ]
    },
    etsyProductBulletPointsGenerator: {
        prompt: "427",
        icon: "🔘",
        title: "Etsy Product Bullet Points Generator",
        category: "Ecommerce",
        caption: "Efficiently craft bullet points for your Etsy listings to summarize key product features.",
        inputs: [
            { inputTitle: `Product Name`, inputCaption: `Enter the name of your Etsy product.`, inputExample: `Handwoven Boho Chic Macrame Wall Hanging` },
            { inputTitle: `Detailed Product Features`, inputCaption: `Provide a detailed list of product features.`, type: 'textarea', inputExample: `Crafted with 100% cotton rope, featuring intricate patterns and fringe details, ideal for adding a bohemian touch to any room.` }
        ]
    },
    etsyProductDescriptionGenerator: {
        prompt: "428",
        icon: "📄",
        title: "Etsy Product Description Generator",
        category: "Ecommerce",
        caption: "Develop detailed and engaging product descriptions for your Etsy listings to enhance buyer interest.",
        inputs: [
            { inputTitle: `Product Name`, inputCaption: `Enter the name of your Etsy product.`, inputExample: `Organic Lavender Soy Candle` },
            { inputTitle: `Key Features and Benefits`, inputCaption: `List the features and benefits of your product.`, type: 'textarea', inputExample: `Our soy candles are hand-poured using organic ingredients with a soothing lavender scent. Ideal for relaxation and stress relief.` }
        ]
    },
    etsyProductTitleGenerator: {
        prompt: "429",
        icon: "🏷️",
        title: "Etsy Product Title Generator",
        category: "Ecommerce",
        caption: "Create attractive and SEO-optimized titles for your Etsy products to improve searchability.",
        inputs: [
            { inputTitle: `Brief Product Description`, inputCaption: `Provide a brief description of your Etsy product.`, inputExample: `Vintage-inspired, handcrafted leather journal with embossed cover.` },
            { inputTitle: `Key Features or Attributes`, inputCaption: `List key features or attributes of the product.`, inputExample: `Handcrafted, leather, embossed, vintage-inspired` }
        ]
    },
    flipkartAdHeadlineGenerator: {
        prompt: "431",
        icon: "💬",
        title: "Flipkart Ad Headline Generator",
        category: "Ecommerce",
        caption: "Create effective and attention-grabbing headlines for your Flipkart advertisements.",
        inputs: [
            { inputTitle: `Product/Brand Name`, inputCaption: `Enter your product or brand name for Flipkart.`, inputExample: `Ultra-Durable Smartphone Case` },
            { inputTitle: `Key Selling Points`, inputCaption: `Highlight the key selling points of your product.`, type: 'textarea', inputExample: `Shockproof design, waterproof, lifetime warranty` }
        ]
    },
    flipkartProductBulletPointsGenerator: {
        prompt: "432",
        icon: "🔘",
        title: "Flipkart Product Bullet Points Generator",
        category: "Ecommerce",
        caption: "Efficiently generate bullet points for your Flipkart product listings to summarize key features.",
        inputs: [
            { inputTitle: `Product Name`, inputCaption: `Enter the name of your Flipkart product.`, inputExample: `Smart Fitness Wristband Tracker` },
            { inputTitle: `Detailed Product Features`, inputCaption: `Provide a detailed list of product features.`, type: 'textarea', inputExample: `Tracks heart rate, steps, sleep patterns; waterproof; syncs with smartphones for notifications.` }
        ]
    },
    flipkartProductDescriptionGenerator: {
        prompt: "433",
        icon: "📄",
        title: "Flipkart Product Description Generator",
        category: "Ecommerce",
        caption: "Develop detailed and persuasive product descriptions for your Flipkart listings.",
        inputs: [
            { inputTitle: `Product Name`, inputCaption: `Enter the name of your Flipkart product.`, inputExample: `Multi-Purpose Blender & Food Processor` },
            { inputTitle: `Key Features and Benefits`, inputCaption: `List the features and benefits of your product.`, type: 'textarea', inputExample: `Versatile kitchen appliance for blending, chopping, and mixing. Features powerful motor and durable stainless-steel blades.` }
        ]
    },
    flipkartProductTitleGenerator: {
        prompt: "434",
        icon: "🏷️",
        title: "Flipkart Product Title Generator",
        category: "Ecommerce",
        caption: "Create concise and SEO-optimized product titles for Flipkart to enhance visibility and search relevance.",
        inputs: [
            { inputTitle: `Brief Product Description`, inputCaption: `Provide a brief description of your Flipkart product.`, inputExample: `Wireless Noise Cancelling Over-Ear Headphones` },
            { inputTitle: `Key Features or Attributes`, inputCaption: `List key features or attributes of the product.`, inputExample: `Wireless, noise cancelling, over-ear, long battery life` }
        ]
    },
    productReviewGenerator: {
        prompt: "435",
        icon: "🌟",
        title: "Product Review Generator",
        category: "Ecommerce",
        caption: "Craft comprehensive and engaging product reviews to help customers make informed decisions.",
        inputs: [
            { inputTitle: `Product Name`, inputCaption: `Enter the name of the product you're reviewing.`, inputExample: `Deluxe Espresso Machine` },
            { inputTitle: `Key Features and Personal Experience`, inputCaption: `Describe the key features of the product and your personal experience with it.`, type: 'textarea', inputExample: `Features an advanced brewing system and customizable settings. My experience has been fantastic, with consistent, cafe-quality espresso every time.` }
        ]
    },
    customerLoyaltyProgramIdeas: {
        prompt: "436",
        icon: "🏆",
        title: "Customer Loyalty Program Ideas",
        category: "Ecommerce",
        caption: "Generate creative and effective ideas for customer loyalty programs to enhance engagement and retention.",
        inputs: [
            { inputTitle: `Brand/Product Name`, inputCaption: `Enter your brand or product name.`, inputExample: `FreshFoods Grocery` },
            { inputTitle: `Target Customer Demographics`, inputCaption: `Describe the demographics of your target customers.`, type: 'textarea', inputExample: `Health-conscious individuals and families looking for organic and sustainable food options.` }
        ]
    },
    productComparisons: {
        prompt: "405",
        icon: "⚖️",
        title: "Product Comparisons",
        category: "Ecommerce",
        caption: `Facilitate product comparisons by highlighting the advantages and disadvantages, aiding in better purchase decisions.`,
        inputs: [
            { inputTitle: `Enter the name of Product A?`, inputCaption: `Name of the first product for comparison.`, inputExample: `Galaxy Smartwatch 4` },
            { inputTitle: `Details of Product A`, inputCaption: `Describe the features and specifications of Product A.`, inputExample: `Features a sleek design, health tracking, and extensive app ecosystem.` },
            { inputTitle: `Enter the name of Product B?`, inputCaption: `Name of the second product for comparison.`, inputExample: `TimeTrack Fitness Band` },
            { inputTitle: `Details of Product B `, inputCaption: `Describe the features and specifications of Product B.`, inputExample: `Focuses on fitness tracking, long battery life, and waterproof design.` }
        ]
    }, 
    productUpdateAnnouncements: {
        prompt: "406",
        icon: "📣",
        title: "Product Announcements",
        category: "Ecommerce",
        caption: `Communicate with users about your product's latest updates to maintain relevance and competitiveness.`,
        inputs: [
            { inputTitle: `Enter your product name & what it does ?`, inputCaption: `Name of the product and a brief description of its function.`, inputExample: `GreenHome Smart Thermostat - Manages your home's temperature for energy efficiency.` },
            { inputTitle: `What updates have been included in the latest product release?`, inputCaption: `Detail the updates or new features in the latest version.`, inputExample: `New features include voice control via smart assistants, improved energy-saving algorithms, and a sleeker design.` },
            { inputTitle: `Mode of communication`, inputCaption: `Select the preferred mode of communication.`, type: "select", options: ['Email', 'Social Media', 'In-app notification', 'Blog post', 'Website pop-up', 'other'], inputExample: `Email` }
        ]
    }, 
    productBuyingGuides: {
        prompt: "407",
        icon: "🛍️",
        title: "Product Buying Guides",
        category: "Ecommerce",
        caption: `Provide comprehensive product buying guides to help customers make informed purchasing choices.`,
        inputs: [
            { inputTitle: `Which product would you like to purchase?`, inputCaption: `Name the product you're interested in buying.`, inputExample: `Looking for the best 4K gaming monitors for my setup.` }
        ]
    }, 
    holidaySales: {
        prompt: "409",
        icon: "🎉",
        title: "Holiday Sales Campaign Content",
        category: "Ecommerce",
        caption: `Efficiently create dynamic content for holiday sales campaigns to captivate and engage customers.`,
        inputs: [
            { inputTitle: `Enter your product name & what it does ?`, inputCaption: `Name of your product and its main functions.`, inputExample: `Smart Fitness Watch - tracks fitness activity and health metrics` },
            { inputTitle: `What are your special offers for this holiday/event?`, inputCaption: `Detail the special offers or discounts available.`, inputExample: `20% off on all products, free shipping for orders over $50` },
            { inputTitle: `Which holiday / Sales event is this?`, inputCaption: `Name the specific holiday or sales event.`, inputExample: `Black Friday Sale` },
            { inputTitle: `Mode of communication`, inputCaption: `Select the mode for campaign communication.`, inputExample: `Email`, type: "select", options: ['Email', 'Social Media', 'In-app notification', 'Blog post', 'Website pop-up', 'other'] }
        ]
    },
    thankYouNoteGenerator: {
        prompt: "410",
        icon: "🙏",
        title: "Thank You Note Generator",
        category: "Ecommerce",
        caption: `Generate sincere and personalized thank you notes to express your gratitude to customers with ease.`,
        inputs: [
            { inputTitle: `What product they purchased from you?`, inputCaption: `Enter the name of the product purchased by the customer.`, inputExample: `Eco-friendly Yoga Mat` },
            { inputTitle: `Mode of sending note?`, inputCaption: `Choose how you want to send the thank you note.`, inputExample: `Email`, type: "select", options: ['Email', 'Personal handwriting note', 'Social media DM', 'other'] }
        ]
    },
    pricingPageCopy: {
        prompt: "413",
        icon: "💲",
        title: "Pricing Page Copy",
        category: "Ecommerce",
        caption: `Create an engaging pricing page, detailing the specifics of your product or service offerings.`,
        inputs: [
            { inputTitle: `What is your product/service?`, inputCaption: `Describe your product/service and its features.`, inputExample: `Cloud Storage Solutions - Secure and scalable` },
            { inputTitle: `How will the plans be differentiated? `, inputCaption: `Explain how different plans will vary.`, inputExample: `Based on storage size, security features, and support options`, type: 'textarea', required: 'false' },
            { inputTitle: `How many plans do you want to offer?`, inputCaption: `Select the number of plans you wish to offer.`, inputExample: `3`, type: "select", options: ['1', '2', '3', '4', '5'] }
        ]
    },
    productReviewGenerator: {
        prompt: "414",
        icon: "🌟",
        title: "Product Review Generator",
        category: "Ecommerce",
        caption: `Easily craft comprehensive and SEO-optimized product reviews for blog posts.`,
        inputs: [
            { inputTitle: `About the product or service`, inputCaption: `Describe the product or service being reviewed.`, inputExample: `Gourmet Coffee Beans - Rich and aromatic blend from Colombia` },
            { inputTitle: `What are your likes and dislikes about the product?`, inputCaption: `Mention what you like and dislike about the product.`, inputExample: `Loves the rich flavor, but packaging could be improved`, required: 'false' }
        ]
    },
    productSubscriptionRenewalReminder: {
        prompt: "416",
        icon: "🔔",
        title: "Product Subscription Renewal Reminder",
        category: "Ecommerce",
        caption: `Create appealing reminders for product subscription renewals to encourage continued engagement.`,
        inputs: [
            { inputTitle: `Enter your product name & what it does ?`, inputCaption: `Describe your product and its main function.`, inputExample: `Premium Music Streaming Service - Unlimited access to songs and playlists` },
            { inputTitle: `Mode of remainder`, inputCaption: `Select the method for sending the renewal reminder.`, inputExample: `Email`, type: "select", options: ['Email', 'In-App Notification', 'SMS', 'Phone Call', 'Social Media Message', 'other'] }
        ]
    },
    userOnboardingEmail: {
        prompt: "408",
        icon: "💌",
        title: "User Onboarding Email",
        category: "Ecommerce",
        caption: `Send welcoming and informative onboarding emails to new users, making them feel valued and knowledgeable.`,
        inputs: [
            { inputTitle: `Product Name?`, inputCaption: `Enter the name of your product.`, inputExample: `NextGen Smart Home System - Control your home from anywhere` },
            { inputTitle: `Available Features in the Product`, inputCaption: `List the key features of your product.`, inputExample: `Wireless control, Energy saving modes, Security monitoring`, type: 'textarea' }
        ]
    },
    customerTestimonialRequestEmail: {
        prompt: "412",
        icon: "📬",
        title: "Customer Testimonial Request Email",
        category: "Ecommerce",
        caption: `Compose emails requesting customer testimonials effortlessly, simplifying feedback collection.`,
        inputs: [
            { inputTitle: `What is your product/service?`, inputCaption: `Describe your product or service.`, inputExample: `Innovative Kitchen Gadgets - Simplify your cooking experience` },
            { inputTitle: `How would you want this email?`, inputCaption: `Select the format of testimonial request email.`, inputExample: `I will send a survey link`, type: "select", options: ['I will send a survey from link', 'I want to receive the customer feedback in the email itself', 'other'] }
        ]
    },
    returnAndExchangeResponseEmail: {
        prompt: "417",
        icon: "🔄",
        title: "Return and Exchange Response Email",
        category: "Ecommerce",
        caption: `Draft professional and efficient emails for managing product returns or exchanges seamlessly.`,
        inputs: [
            { inputTitle: `What is your product/service?`, inputCaption: `Describe your product or service.`, inputExample: `Luxury Bedding Collection - Sleep in ultimate comfort` },
            { inputTitle: `What message did you receive from the customer regarding the return or exchange of the product?`, inputCaption: `Detail the customer's message about return or exchange.`, inputExample: `Customer is unsatisfied with the color match`, required: 'false' },
            { inputTitle: `What is your response/answer to customer messages regarding returns or exchanges?`, inputCaption: `Provide your response to the customer's query.`, inputExample: `Offering a replacement or a full refund`, required: 'false' }
        ]
    },
    influencerCollaboration: {
        prompt: "418",
        icon: "🤝",
        title: "Influencer Outreach Email",
        category: "Ecommerce",
        caption: `Efficiently create outreach emails for influencer collaborations, tailored to your product and partnership offer.`,
        inputs: [
            { inputTitle: `What is the influencer's name?`, inputCaption: `Enter the name of the influencer.`, inputExample: `Alex Fitness Guru`, required: 'false' },
            { inputTitle: `Explain the product/feature you want to collaborate on with the influencer`, inputCaption: `Describe the product or feature for collaboration.`, inputExample: `Launching a new line of eco-friendly workout gear`, type: 'textarea' },
            { inputTitle: `Whom does your product help, and why is it beneficial?`, inputCaption: `Detail how your product helps customers and its benefits.`, inputExample: `Helps fitness enthusiasts to stay eco-conscious while being active` },
            { inputTitle: `What you’re offering for this Collaboration?`, inputCaption: `Describe what you are offering in this collaboration.`, inputExample: `Exclusive discount codes for followers, free products for review` }
        ]
    }
}
export default EcommerceToolsData;

