const StoryData = {
    storyPlotGenerator: {
        prompt: "4001",
        icon: "📖",
        title: "Story Plot Generator",
        category: "Story writing",
        caption: "Generate a core plot or premise for your story with customizable twists.",
        inputs: [
            { inputTitle: 'Genre', inputCaption: 'Select the genre of your story.', type: 'text', inputExample: "Fantasy" },
            { inputTitle: 'Themes or Keywords', inputCaption: 'Enter themes or keywords to incorporate.', type: 'textarea', inputExample: "Magic, Adventure, Destiny" },
            { inputTitle: 'Plot Twists', inputCaption: 'Would you like to include plot twists? Yes/No.', type: 'text', inputExample: "Yes" }
        ]
    },
    storyOutlineCreator: {
        prompt: "4002",
        icon: "🗺️",
        title: "Story Outline Creator",
        category: "Story writing",
        caption: "Structure your story with a detailed outline of events and character arcs.",
        inputs: [
            { inputTitle: 'Main Events', inputCaption: 'Describe the main events of the story.', type: 'textarea', inputExample: "The hero's journey begins with a call to adventure." },
            { inputTitle: 'Character Arcs', inputCaption: 'Outline the development arcs for key characters.', type: 'textarea', inputExample: "Character A starts as a reluctant hero and grows into a leader." },
            { inputTitle: 'Settings', inputCaption: 'List the primary settings of your story.', type: 'text', inputExample: "Magical kingdom of Eldoria" }
        ]
    },
    storyGenerator: {
        prompt: "4003",
        icon: "✍️",
        title: "Story Generator",
        category: "Story writing",
        caption: "Create short stories or narratives based on your inputs.",
        inputs: [
            { inputTitle: 'Characters', inputCaption: 'Describe the characters involved.', type: 'textarea', inputExample: "Sara: Brave adventurer seeking treasure" },
            { inputTitle: 'Setting', inputCaption: 'Define the setting of your story.', type: 'text', inputExample: "Mysterious forest" },
            { inputTitle: 'Key Events', inputCaption: 'List key events to occur.', type: 'textarea', inputExample: "Discovery of ancient artifact" }
        ]
    },
    storyChaptersOrganizer: {
        prompt: "4004",
        icon: "📚",
        title: "Story Chapters Organizer",
        category: "Story writing",
        caption: "Plan and structure the chapters of your story for optimal flow.",
        inputs: [
            { inputTitle: 'Chapter Titles', inputCaption: 'List your chapter titles.', type: 'textarea', inputExample: "Chapter 1: The Call to Adventure" },
            { inputTitle: 'Chapter Summaries', inputCaption: 'Provide a brief summary for each chapter.', type: 'textarea', inputExample: "Introduction of main characters and setting" }
        ]
    },
    storyFlowAssistant: {
        prompt: "4005",
        icon: "⚡",
        title: "Story Flow Assistant",
        category: "Story writing",
        caption: "Analyze and improve the pacing and engagement of your narrative.",
        inputs: [
            { inputTitle: 'Story Content', inputCaption: 'Paste your story content here.', type: 'textarea', inputExample: "Once upon a time..." }
        ]
    },
    storyRephraseTool: {
        prompt: "4006",
        icon: "🔄",
        title: "Story Rephrase Tool",
        category: "Story writing",
        caption: "Refine your story's language for clarity and impact.",
        inputs: [
            { inputTitle: 'Text to Rephrase', inputCaption: 'Enter the text you want to rephrase.', type: 'textarea', inputExample: "The quick brown fox jumps over the lazy dog." }
        ]
    },
    dialogueGenerator: {
        prompt: "4007",
        icon: "💬",
        title: "Dialogue Generator",
        category: "Story writing",
        caption: "Generate realistic dialogues for your characters.",
        inputs: [
            { inputTitle: 'Context', inputCaption: 'Describe the scene or context for the dialogue.', type: 'textarea', inputExample: "In a medieval tavern" },
            { inputTitle: 'Characters Involved', inputCaption: 'List the characters involved and their traits.', type: 'textarea', inputExample: "Character A: Brave knight\nCharacter B: Witty bard" }
        ]
    },
    developmentalEditingAssistant: {
        prompt: "4008",
        icon: "📝",
        title: "Developmental Editing Assistant",
        category: "Story writing",
        caption: "Get feedback on structure, character development, and themes.",
        inputs: [
            { inputTitle: 'Story Draft', inputCaption: 'Paste your story draft here.', type: 'textarea', inputExample: "Once upon a time..." }
        ]
    },
    aiStoryteller: {
        prompt: "4009",
        icon: "🗣️",
        title: "AI Storyteller",
        category: "Story writing",
        caption: "Experience your stories narrated with character-specific voices.",
        inputs: [
            { inputTitle: 'Story Text', inputCaption: 'Enter the text of the story to be narrated.', type: 'textarea', inputExample: "Once upon a time..." },
            { inputTitle: 'Voice Preferences', inputCaption: 'Specify any voice preferences for characters.', type: 'textarea', required: 'false', inputExample: "Male voice for Character A, Female voice for Character B" }
        ]
    },
    storyNameGenerator: {
        prompt: "4011",
        icon: "✍️",
        title: "Story Name Generator",
        category: "Story writing",
        caption: "Generate captivating names for your stories.",
        inputs: [
            { inputTitle: 'Genre', inputCaption: 'Specify the genre of your story.', type: 'text', inputExample: "Mystery", type: 'text' },
            { inputTitle: 'Key Themes', inputCaption: 'List key themes or elements of the story.', type: 'text', inputExample: "Murder, Intrigue, Suspense" },
        ]
    },
    storyRandom: {
        prompt: "4012",
        icon: "🎲",
        title: "Random Story Generator",
        category: "Story writing",
        caption: "Create unique and engaging stories with a touch of randomness.",
        inputs: [
            { inputTitle: 'Seed Elements', inputCaption: 'Enter any seed elements or keywords to include in the story (optional).', type: 'text', required: 'false', inputExample: "Magic, Adventure" },
            { inputTitle: 'Genre', inputCaption: 'Choose the genre for the story.', type: 'text', inputExample: "Fantasy" },
            { inputTitle: 'Length', inputCaption: 'Preferred length of the story (e.g., short story, novella, flash fiction).', type: 'text', required: 'false', inputExample: "Short story" }
        ]
    },
    storyBeats: {
        prompt: "4010",
        icon: "🎼",
        title: "Story Beats",
        category: "Story writing",
        caption: "Map out the crucial moments in your story.",
        inputs: [
            { inputTitle: 'Story Synopsis', inputCaption: 'Provide a brief synopsis of your story.', type: 'textarea', inputExample: "A young hero sets out on a quest to save the kingdom from darkness." },
            { inputTitle: 'Desired Emotional Arc', inputCaption: 'Describe the emotional journey you want your story to take.', type: 'textarea', required: 'false', inputExample: "From despair to triumph" }
        ]
    },
};
export default StoryData;
