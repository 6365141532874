import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Button, Nav, Accordion, Container, NavLink, Tabs, Tab } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal'; 
import { useNavigate } from "react-router";
import toast, { Toaster } from 'react-hot-toast'; 
import { Link } from "react-router-dom";
import TabContainer from 'react-bootstrap/TabContainer'
import { useUserAuth } from "../../context/UserAuthContext";   
import { getFunctions, httpsCallable } from "firebase/functions";   
import Loader from "../shared/utilities/loader/Loader"; 
import axios from "axios";
import "./Pricing.css";  
import commonServices from "../../services/common.services";
import InlineLogin from "../InlineLogin";
import { LogoSvg } from "../../assets/svg/SvgIcons";
import Loader2 from "../shared/utilities/loader2/Loader2";

 
const OfferCode = () => {  
  
  const functions = getFunctions(); 
  const [offerCode, setOfferCode] = useState('');
  const [message, setMessage] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  
  //const { user } = useContext(FirebaseAuthContext);
  const { user } = useUserAuth();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [codeTier, setCodeTier] = useState();
  
  const [input, setInput] = useState('');
  const [offerCodes, setOfferCodes] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [loginState, setloginState] = useState(false); 
  const handleClose = () => setShowModal(false);
  const [isShown, setIsShown] = useState(false);  

  useEffect(async () => { 
     const getUserData = await commonServices.getUsageData(user.uid);  
     setUserData(getUserData.data())
     const userData = getUserData.data();
     if(userData.redeemedCodes){
        const codeLength = userData.redeemedCodes.length;
        console.log(codeLength)
        if(codeLength === 1){
          setCodeTier('Tier 1')
        } else if(codeLength === 2){
          setCodeTier('Tier 2')
        } else{
          setCodeTier('Tier 3')
        }
     } 
  }, [])

  const isAuthenticated = () => {
    if(user){
      return true;
    } else {
      setloginState(true)
      setShowModal(true) 
      return false;
    } 
  }
  const handleLoginSuccess = (user) => { 
    setloginState(false)
    setShowModal(false) 
    console.log('Login successful:');
  }; 

  const handleChange = (event) => {
    setOfferCode(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSuccessMsg('')
    setMessage('')
    if (isAuthenticated()) {
      setLoading(true)
      if (!user) {
        setMessage('Please sign in before submitting an offer code.');
        setLoading(false)
        return;
      }

      try { 
        const redeemOfferCode = httpsCallable(functions, 'redeemOfferCode');
        const response = await redeemOfferCode({ userId: user.uid, code: offerCode });
        if (response.data.success) {
          setLoading(false)
          setSuccessMsg(response.data.message);

          const getUserData = await commonServices.getUsageData(user.uid); 
         // console.log(getUserData.data()); 
          setUserData(getUserData.data())
          const userData = getUserData.data();
          const codeLength = userData.redeemedCodes.length;
          if(codeLength === 1){
            setCodeTier('Tier 1')
          } else if(codeLength === 2){
            setCodeTier('Tier 2')
          } else{
            setCodeTier('Tier 3')
          }
        } else {
          setLoading(false)
          setMessage(response.data.message || 'An error occurred while redeeming the offer code.');
        }
      } catch (error) {
        setLoading(false)
        setMessage(error.message || 'An error occurred while redeeming the offer code.');
      }
    }
  };

  // const handleCodeSubmit = () => {
  //   if (input.trim()) {
  //     const newCodes = input.split('\n').map(code => code.trim());
  //     setOfferCodes([...offerCodes, ...newCodes]);      
  //     commonServices.addOfferCodes(newCodes);
  //     setInput('');
  //   }
  // };
 

  return (
    <>
    <div className="offer-page">
    {/* {!user && <><div className="logoClass"><LogoSvg/></div> */}
    <h5>Welcome to Wordkraft.ai!!</h5>
    {userData && userData.redeemedCodes && <>
      <h2 className="col">AppSumo Plan <span>Codes Redeemed: {userData.redeemedCodes.length}</span></h2>
      <div className="userData"> 
        <div className="row">
          <span className="col">Plan Type:</span> <span className="col">{userData.planType} </span>  
        </div>
        <div className="row">
          <span className="col">Words Limit:</span> <span className="col">{userData.wordsLimit} words pm</span>  
        </div> 
        <div className="row">
          <span className="col">AppSumo Code Tier:</span> <span className="col">{codeTier}</span>  
        </div> 
        <div className="row">
          <span className="col">Promo codes redeemed:</span> <span className="col"><ul>{userData.redeemedCodes.map((code) => <li>{code}</li>) }</ul></span>  
        </div>
      </div>
      </>
    }

    <h2>Reedem your AppSumo code here</h2>
    <div className="offer-code" >
      <form onSubmit={handleSubmit}>
      <label htmlFor="offerCode">AppSumo Code</label>
      <input type="text" id="offerCode" value={offerCode} onChange={handleChange} />
      <Button variant="primary" type="submit" disabled={loading}>
      {user && 'Apply code'}{!user && 'Login to Apply code'}
        </Button> 
      {message && <p>{message}</p>}
      {successMsg && <p className="success">{successMsg}</p>}
      {loading && <div className="my-3 mx-auto"><Loader2 /></div>}
    </form>
    </div> 
    {/* <div className="offercodes offer-code">
      <h2>Add Offer Codes</h2>
      <textarea
        rows="10"
        cols="30"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Enter offer codes, each on a new line"
      />
      <Button variant="primary"  onClick={handleCodeSubmit}>Submit</Button> 
       <ul>
        {offerCodes.map((code, index) => (
          <li key={index}>{code}</li>
        ))}
      </ul> 
    </div> */}
    </div>  
    
    <Modal show={showModal} onHide={handleClose} keyboard={false} className={loginState ? "loginState" : ""}> 
    {loginState && <Modal.Body className="text-center">  
       <InlineLogin onLoginSuccess={handleLoginSuccess}/> 
    </Modal.Body>} 
   </Modal> 
   </> 
  );
};

export default OfferCode;