import {React, useState, useEffect, useContext, useRef} from "react";  
import { getFunctions, httpsCallable } from "firebase/functions"; 
import "./KeywordResearch.css";  
import { Container, Button, Row, Col, Form, FormControl, FormLabel, FormGroup, Table, OverlayTrigger, Popover   } from 'react-bootstrap'; 
import { Bar } from 'react-chartjs-2'; 
import { CompititionIcon, CpcIcon, TrendIcon, VolumeIcon } from "../../../assets/svg/SvgIcons";
import Loader from "../../shared/utilities/loader/Loader";
import Loader2 from "../../shared/utilities/loader2/Loader2"; 
import SidebarPanel from "../../dashboard/sidebar/SidebarPanel";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from "react-router";
import ArticlePost from "../articlePost/ArticlePost"; 

const KeywordResearch = () => { 
  const [keywords, setKeywords] = useState("");
  const [country, setCountry] = useState("us");
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(false);
  const functions = getFunctions();  
  const navigate = useNavigate();

  const [relatedKeywords, setRelatedKeywords] = useState();
  const [longTailKeywords, setLongTailKeywords] = useState();
  const [keywordQuestions, setKeywordQuestions] = useState();
  const [loadingRel, setLoadingRel] = useState(false);
  const [loadingLong, setLoadingLong] = useState(false);
  const [loadingQuest, setLoadingQuest] = useState(false);
 
  const [totalGrowth, setTotalGrowth] = useState(0);
  const [searchResult, setSearchResult] = useState(null);
 const sharedData = {
    keywords: ['organic blue tea', 'blue tea leaf varieties', 'types of blue tea'],
    title: 'The Rise of Blue Tea as a Superfood',
  };
  const renderKeywordTable = (data) => ( 
    <table>
    <thead>
      <tr>
        <th width="75%">Keyword</th>
        <th width="15%">Volume</th>
        <th width="10%">Comp</th>
      </tr>
    </thead>
    <tbody>
     {data.map((item, index) => (
      <tr key={index}>
      <td> 
      <Form.Check
              id={`related-checkbox-${index}`}
              inline
              label={item.keyword}
              type="checkbox"
              onChange={(e) => handleSelectChange(e, item.keyword)}
            /> 
           {/* <a className="abtn-link" onClick={getKeywordStat} name={item.keyword}>{item.keyword}</a>  */}
       </td>
       <td>
       <span>{item.vol}</span>
       </td>
       <td>
       <span>{item.competition}</span>
       </td>
   </tr> 
     ))}
  </tbody> 
  </table>
  );
  const renderTable = (data) => ( 
    <ul>
    {data.map((item, index) => (
      <li key={index}>  
         <Form.Check
              id={`custom-radio-${index}`}
              inline
              label={item}
              name="custom-radio"
              type="radio"
              onChange={(e) => handleSelectBlogTitle(e, item)}
            />
          {/* <a className="abtn-link" onClick={getKeywordStat} name={item}>{item}</a>  */}
      </li>
    ))}
   </ul>
 );

 const [blogTitle, setBlogTitle] = useState(null); 
 const handleSelectBlogTitle = (e, item) => {
  setBlogTitle(item);
 };
 
 const renderLongKeywordTable = (data) => ( 
     <table>
      <thead>
        <tr>
        <th width="75%">Keyword</th>
        <th width="15%">Volume</th>
        <th width="10%">Comp</th>
        </tr>
      </thead>
      <tbody>
      {data.map((item, index) => (
       <tr key={index}>
          <td>
             <Form.Check
              id={`custom-checkbox-${index}`}
              inline
              label={item.keyword}
              type="checkbox"
              onChange={(e) => handleSelectChange(e, item.keyword)}
            /> 
          </td>
          <td>   
           <span>{item.vol}</span>  
          </td>
          <td>   
           <span>{item.competition}</span>  
          </td>
       </tr>
     ))}
      </tbody> 
   </table>
  ); 

  const [selectedTitles, setSelectedTitles] = useState([]);

  const handleSelectChange = (e, item) => {
    if (e.target.checked) {
      setSelectedTitles((prevSelectedTitles) => [...prevSelectedTitles, item]);
    } else {
      setSelectedTitles((prevSelectedTitles) =>
        prevSelectedTitles.filter((title) => title !== item)
      );
    } 
  };

  const [myObject, setMyObject] = useState();
  const createArticle = () =>{
    //console.log("keywords" + selectedTitles)
    //console.log("blog title" + blogTitle)
    const getData = {
      keywords: selectedTitles,
      title:blogTitle
    } 
    setMyObject(getData)
   // console.log(getData)
    navigate('/article-writer', { state: { myProp: getData } }); 
  }

  const copyText = (e) => {
    const textToCopy = e.target.getAttribute('name');
    const textarea = document.createElement('textarea');
    textarea.value = textToCopy;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy'); 
    document.body.removeChild(textarea);
    toast.success("Text copied!")
  };
  const renderBarChart = () => {
    if(!results){
         return;
    }
    const data = {labels: results.trend.map((item) => (item.month.slice(0, 3) + " " + item.year)),
      datasets: [
        {
          label: 'Volume',
          data: results.trend.map((item) => item.value),
          backgroundColor: '#6a3dfd78',
          borderColor: '#6a3dfd78',
          borderWidth: 0,
          barThickness: 15
        } 
      ],
    };
    
    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };
    
    return <Bar data={data} options={options} className="bar-chart" />;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();  
    setLoading(true)
    setLoadingRel(true)
    setLoadingLong(true)
    setLoadingQuest(true) 
   // console.log(keywords) 
    //  try {
    //   const getAIdata = await httpsCallable(functions, 'wordkraftAI');
    //     const param = {
    //       type: "relatedKeywords", 
    //       about: keywords, 
    //       language: "English", 
    //       wcount:100,
    //       creative:0.85,
    //       num:1 
    //     }
    //       getAIdata(param).then((response) => { 
    //             let oldOutLine = response.data.choices[0].text;
    //             let sentences = oldOutLine.split(/\r?\n/);
    //             var updatedList = [];
    //             sentences.filter(removeNum);
    //             function removeNum(value) {
    //             updatedList.push(value.toString().replace(/\d+.\s/,'').replace(/['"]+/g, ''));
    //             }
    //             let newOutLine = updatedList.filter(removeEmpty);
    //             function removeEmpty(value) {
    //                return value.length > 6;
    //              } 
    //               // console.log(newOutLine) 
    //               const kwObj = {
    //                 kwType: "relatedKw",
    //                 kwArray: newOutLine
    //               }   
    //             getKeywordsData(kwObj)
    //            // setRelatedKeywords(newOutLine) 
    //             setLoadingRel(false)
    //       })
    //   } catch (error) {
    //     console.log(error.message); 
    //     setLoading(false)
    //   }

    //   try {
    //     const getAIdata = await httpsCallable(functions, 'wordkraftAI');
    //       const param = {
    //         type: "longTailKeywords", 
    //         about: keywords, 
    //         language: "English", 
    //         wcount:100,
    //         creative:0.85,
    //         num:1 
    //       }
    //         getAIdata(param).then((response) => { 
    //               let oldOutLine = response.data.choices[0].text;
    //               let sentences = oldOutLine.split(/\r?\n/);
    //               var updatedList = [];
    //               sentences.filter(removeNum);
    //               function removeNum(value) {
    //               updatedList.push(value.toString().replace(/\d+.\s/,'').replace(/['"]+/g, ''));
    //               }
    //               let newOutLine = updatedList.filter(removeEmpty);
    //               function removeEmpty(value) {
    //                  return value.length > 6;
    //                } 
    //              // console.log(newOutLine) 
    //               const kwObj = {
    //                 kwType: "longKw",
    //                 kwArray: newOutLine
    //               } 
    //               getKeywordsData(kwObj) 
    //              // setLongTailKeywords(newOutLine) 
    //               setLoadingLong(false)
    //         })
    //     } catch (error) {
    //       console.log(error.message); 
    //       setLoading(false)
    //     }
  
  // Call the functions
    fetchRelatedKeywords();
    fetchLongTailKeywords();
    fetchLongSuggestedTitles();

        // try {
        //   const getAIdata = await httpsCallable(functions, 'wordkraftAI');
        //     const param = {
        //       type: "questionsKeywords", 
        //       about: keywords, 
        //       language: "English", 
        //       wcount:250,
        //       creative:0.85,
        //       num:1 
        //     }
        //       getAIdata(param).then((response) => { 
        //             let oldOutLine = response.data.choices[0].text;
        //             let sentences = oldOutLine.split(/\r?\n/);
        //             var updatedList = [];
        //             sentences.filter(removeNum);
        //             function removeNum(value) {
        //             updatedList.push(value.toString().replace(/\d+.\s/,'').replace(/['"]+/g,''));
        //             }
        //             let newOutLine = updatedList.filter(removeEmpty);
        //             function removeEmpty(value) {
        //                return value.length > 6;
        //              } 
        //           //  console.log(newOutLine) 
        //             setKeywordQuestions(newOutLine) 
        //             setLoadingQuest(false) 
        //       })
        //   } catch (error) {
        //     console.log(error.message); 
        //     setLoading(false)
        //   }

          try {
            setLoading(true);
            const functionUrl = "https://us-central1-wordkraft-bb683.cloudfunctions.net/getKeywordAnalysisData2";      
            const response = await fetch(functionUrl, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                keywords: keywords.split(","),
                country,
              }),
            });      
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }      
            const resdata = await response.json();
            setResults(resdata.data[0]);
            const getTrend = resdata.data[0].trend;
      
            if (getTrend) {
              setTotalGrowth(calculateTotalGrowth(getTrend));
            }
          } catch (error) {
            console.error("Error fetching keyword data:", error);
          } finally {
            setLoading(false);
          } 
    // try {
    //     setLoading(true)
    //     const getKeywordAnalysisData = httpsCallable(functions, "getKeywordAnalysisData"); 
    //     const response = await getKeywordAnalysisData({ keywords: keywords.split(","), country }); 
    //     const resdata = response.data;
    //     //setResults(JSON.parse(resdata).data); 
    //     setResults(JSON.parse(resdata).data[0]); 
    //     const getTrend = JSON.parse(resdata).data[0].trend;
        
    //    if(getTrend){ 
    //     setTotalGrowth(calculateTotalGrowth(getTrend));
    //    } 
    // } catch (error) {
    //     console.error("Error fetching keyword data:", error);
    // } finally {
    //     setLoading(false);
    // } 
};
const fetchData = async (params) => {
  try {
    setLoading(true); 
    const functionUrl = "https://us-central1-wordkraft-bb683.cloudfunctions.net/getAIkeywords";

    const response = await fetch(functionUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const resdata = await response.json();
    let oldOutLine = resdata.data; // Adjusted line
    let sentences = oldOutLine.split(/\r?\n/);
    let updatedList = [];
    sentences.filter(removeNum);

    function removeNum(value) {
      updatedList.push(value.toString().replace(/\d+.\s/, "").replace(/['"]+/g, ""));
    }

    let newOutLine = updatedList.filter(removeEmpty);

    function removeEmpty(value) {
      return value.length > 6;
    }

    return {
      kwType:
        params.type === "relatedKeywords"
          ? "relatedKw"
          : params.type === "longTailKeywords"
          ? "longKw"
          : "questionsKw",
      kwArray: newOutLine,
    };
  } catch (error) {
    console.error("Error fetching AI data:", error);
  } finally {
    setLoading(false);
  }
}

const fetchRelatedKeywords = async () => {
  const param = {
    type: "relatedKeywords",
    about: keywords,
    language: "English",
    wcount: 100,
    creative: 0.85,
    num: 1,
  };

  const kwObj = await fetchData(param);
  getKeywordsData(kwObj);
  setLoadingRel(false);
};

const fetchLongTailKeywords = async () => {
  const param = {
    type: "longTailKeywords",
    about: keywords,
    language: "English",
    wcount: 100,
    creative: 0.85,
    num: 1,
  };

  const kwObj = await fetchData(param);
  getKeywordsData(kwObj);
  setLoadingLong(false);
};
const fetchLongSuggestedTitles = async () => {
  const param = {
    type: "questionsKeywords",
    about: keywords,
    language: "English",
    wcount: 250,
    creative: 0.85,
    num: 1,
  };

  const kwObj = await fetchData(param); 
  setKeywordQuestions(kwObj.kwArray) 
  setLoadingQuest(false) 
};

const getKeywordsData = async (kwObj) => {
  if(kwObj.kwArray){
    try { 
      const functionUrl = "https://us-central1-wordkraft-bb683.cloudfunctions.net/getKeywordAnalysisData2";      
      const response = await fetch(functionUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          keywords: kwObj.kwArray,
          country,
        }),
      });      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }      
      const resdata = await response.json();
     
      if(kwObj.kwType == "relatedKw"){
        setRelatedKeywords(resdata.data) 
      }
      if(kwObj.kwType == "longKw"){
        setLongTailKeywords(resdata.data)
      }  
      
    } catch (error) {
      console.error("Error fetching keyword data:", error);
    } finally {
      setLoading(false);
    } 
  } 
}

const calculateTotalGrowth = (array) => {
  const initialValue = array[0].value;
 // console.log(initialValue)
  const finalValue = array[array.length - 1].value;

  return calculateGrowth(initialValue, finalValue);
};
const calculateGrowth = (initialValue, finalValue) => {
  return ((finalValue - initialValue) / initialValue) * 100;
};
const handleChangeKeywords = (event) => {
  setKeywords(event.target.value);
};
const handleChangeCountry = (event) => {
  setCountry(event.target.value);
};
 
  const getKeywordStat = async (e) => {
    e.preventDefault();
    //console.log(e.target.name)
    const keywordQuerry = e.target.name;
    const keyArray = [keywordQuerry]
    setKeywords(keyArray)
    try {
      setLoading(true)
      const getKeywordAnalysisData = httpsCallable(functions, "getKeywordAnalysisData"); 
      const response = await getKeywordAnalysisData({ keywords: keyArray, country  }); 
      const resdata = response.data; 
      setResults(JSON.parse(resdata).data[0]);  
      const getTrend = JSON.parse(resdata).data[0].trend;        
      if(getTrend){
      // console.log(getTrend) 
       setTotalGrowth(calculateTotalGrowth(getTrend));
      }
      } catch (error) {
          console.error("Error fetching keyword data:", error);
      } finally {
          setLoading(false);
      }
  };
  
  

return (   
 <div className="main-layout"> 
  <Container className="keywordResearch">
  <div className="search-container mb-5">
  <section className="header-txt m-0 p-0 text-center">
    <h2 className="text-center">Keyword Research</h2>
  <p>Get accurate and real-time data with suggested titles for your blog post or article.</p>
  </section> 
  <Row className="search-row mt-3 mb-4"> 
  <div className="keywordSearchForm">
    <Form onSubmit={handleSubmit}>
            <Row className="justify-content-md-center">
              <Col> 
                <FormGroup>
                    <FormControl
                        type="text"
                        value={keywords}
                        onChange={handleChangeKeywords}
                        className="search-input" 
                        placeholder="Eg: Green tea"
                    />
                </FormGroup>
              </Col>
              <Col> 
              <Form.Control
      as="select"
      value={country}
      onChange={handleChangeCountry}
      className="select-input form-control form-select"
    >
      <option value="">Select country</option>
      <option value=" ">Global</option>
      <option value="us">United States</option>
      <option value="gb">United Kingdom</option>
      <option value="in">India</option>
      <option value="au">Australia</option>
      <option value="ca">Canada</option>
      <option value="nz">New Zealand</option>
      <option value="za">South Africa</option>
    </Form.Control>
              </Col>
              <Col>
                <Button variant="primary" type="submit" disabled={!keywords}>
                  Search
                </Button>
              </Col>
            </Row>
          </Form> 
          </div>

      </Row> 
  </div>
  {loading && <div>
            <Loader2/> 
              </div>}  

  {results && !loading && 
  <div className="keywordResult">
  <div className="keywordOverview rowSection mb-4"> 
    <Row className="overviewCard"> 
      <Col className="col-sm-12 col-md-6">
    <h2>Keyword Overview: <span className="keyWord">{keywords}</span></h2>
    <div className="keyOverview">
      <Row className="firstRow">
    <div className="col-sm-12 col-md-6 borderBottom borderRight">
       <Row className="card"> 
       <Col className="col-md-10">
       <h6>Volume</h6> 
      <h4>{results.vol}</h4>
       </Col>
       <Col className="text-right kIcon volIcon">
       <VolumeIcon/>
       </Col>
       </Row>
      </div>
    <div className="col-sm-12 col-md-6 borderBottom">
      <Row className="card">
        <Col className="col-md-10">
        <h6>CPC</h6> 
    <h4>{results.cpc.currency}{results.cpc.value}</h4>
        </Col>
        <Col className="text-right kIcon cpcIcon">
        <CpcIcon/>
        </Col>
      </Row>
    </div>
    </Row> 
    <Row>
          <div className="col-sm-12 col-md-6 borderRight">
           <Row className="card">
            <Col className="col-md-10">
            <h6>Competition</h6>
    {/* <h4>{(results.competition * 100).toFixed(0)}</h4> */}
    <h4>{results.competition.toFixed(2)}</h4>
     {/* {results.competition.toFixed(2)}   */}
            </Col>
            <Col className="text-right kIcon compIcon">
              <CompititionIcon/>
            </Col>
           </Row>

          </div>
    <div className="col-sm-12 col-md-6">
     <Row className="card">
      <Col className="col-md-10">
      <h6>Keyword Difficulty</h6>
      <h4 className="kd">
        {((results.competition * 100).toFixed(0) < 70) && ((results.competition * 100).toFixed(0) > 30) && <span className="medium">Medium</span>}
        {(results.competition * 100).toFixed(0) > 70 && <span className="heigh">High</span>}
        {(results.competition * 100).toFixed(0) <= 30 && <span className="low">Low</span>}
      </h4>
      {/* <h4>{(results.competition * 100).toFixed(0)}</h4>   */}
      </Col>
      <Col className="text-right kIcon trendIcon">
        <TrendIcon/>
      </Col>
     </Row>
    </div>
    </Row>

    </div>
    </Col>
  <Col className="col-md-6">
    <h2>Keyword Trend</h2>
    {results && renderBarChart()} 
  </Col> 

    </Row>
  </div>
  
  <div className="keywordIdea rowSection m-0">  
  <div className="float-left w-100">
  {/* <h2 className="float-left">Keyword Ideas</h2> <div className="createArticleBtn float-right">  
  </div> */}
  </div>
   <Row className="secondRow mt-1">    
    <div className="col-sm-12 col-md-6">
        <h3>Related Keywords</h3>
      <div className="card p-4">
        {loadingRel && <div> <Loader2/>  </div>}
        {relatedKeywords && !loadingRel &&  <>{renderKeywordTable(relatedKeywords)}</>  }
      </div>
    </div>
 
    <div className="col-sm-12 col-md-6">
      <h3>Long-Tail Keywords</h3>
    <div className="card p-4">
      {loadingLong && <div> <Loader2/>  </div>}
      {longTailKeywords &&  !loadingLong && <>{renderLongKeywordTable(longTailKeywords)}</>  }
    </div>
    </div>
    </Row> 
    <Row>
    <div className="mt-5 col-sm-12 col-md-8">
      <h3>Suggested Article Titles</h3>
    <div className="card p-4">
      {loadingQuest && <div> <Loader2/>  </div>}
      {keywordQuestions && !loadingQuest && <>{renderTable(keywordQuestions)}</> }
    </div>
    </div>
    <div className="mt-5 col-sm-12 col-md-4">
      <h3><br/> </h3>
    <div className="card p-4 seo-selected">
          <h3 className="mb-4">Generate SEO optimized Article</h3>
          {/* <p>Selected keywords and blog title to create a SEO optmized Article/Blog post</p> */}
         <div className="seo-blog-title mb-3">
          <strong>Article Title</strong> <br/>
          {!blogTitle && <p><i>No title selected</i></p>}
          {blogTitle && <p>{blogTitle}</p>}
         </div>  
         <div className="seo-keywords">
          <strong>Keywords</strong> <br/>
          {(selectedTitles.length < 1) && <p><i>No keywords selected</i></p>}
          {selectedTitles && <p>{selectedTitles.join(", ")}</p>}
         </div>
         <Button variant="primary" className="mt-3" onClick={createArticle} disabled={(selectedTitles.length < 1) && (!blogTitle)}>⁺₊✧  Generate Article</Button>
    </div>
    </div>
    </Row> 
  </div>  

  </div>
  }
  </Container>
  <Toaster position="top-center" reverseOrder={false} />
</div>
  );
  }

export default KeywordResearch;



