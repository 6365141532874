const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({ apiKey: process.env.REACT_APP_OPENAI_API_KEY });
const openai = new OpenAIApi(configuration);

class WebsiteHeadlinesAIServices {    

  generateWebsiteHeadlines = (formDataObj) => {
    return openai.createCompletion("text-davinci-002", {  
      prompt: `Write a website headline for Product: ${formDataObj.productName}\nFeatures: ${formDataObj.productFeatures}\nNote:write in ${formDataObj.language} language\nTone of Voice:${formDataObj.tone}`, 
      temperature: 0.7,
      max_tokens: 20,
      top_p: 1.0,
      frequency_penalty: 0.0,
      presence_penalty: 0.0,  
      n: 5 //parseInt(`${formDataObj.variant}`) 
     });
   }

   generateMoreWebsiteHeadlines = (queryObj) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Write a website headline for Product: ${queryObj.productName}\nFeatures: ${queryObj.productFeatures}\nNote:write in ${queryObj.language} language\nTone of Voice:${queryObj.tone}`, 
      temperature: 0.7,  
      max_tokens: 20,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,  
      n: 3  
     });
   }

   addMoreWebsiteHeadlines = (queryObj, pDes) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Expand the product description for the following product:\nProduct: ${queryObj.productName}\nProduct description: ${pDes}`,
      temperature: 0.7,  
      max_tokens: 10,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      stop:['.']
     });
   }

}

export default new WebsiteHeadlinesAIServices();

 