import {React, useState, useEffect, useContext, useRef} from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button, Navbar, Nav, Dropdown, Popover, Card, Form, Container, Row, Col, Alert } from "react-bootstrap";
import { useNavigate } from "react-router"; 
import { useUserAuth } from "../../../context/UserAuthContext";
import { SubscriberContext } from "../../../context/subscriberContext";  
import { FcList } from "react-icons/fc";
import { VscCopy, VscNewFile, VscInfo, VscChevronUp, VscChevronDown, VscTrash } from "react-icons/vsc";
import "./BlogPost.css"; 
import Loader from "../../shared/utilities/loader/Loader";
import ContentEditable from 'react-contenteditable';
import Modal from 'react-bootstrap/Modal';
import Overlay from 'react-bootstrap/Overlay';
import TextareaAutosize from 'react-textarea-autosize';
import BlogPostAIServices from "./services/blogPostAI.services"; 
import ProjectDataService from "./services/blogPost.services"; 
import CommonDataServices from "../../../services/common.services";
import { sendPasswordResetEmail } from "firebase/auth";
import { useParams, Link  } from "react-router-dom"; 
import toast, { Toaster } from 'react-hot-toast'; 
import { serverTimestamp } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";  
import SidebarPanel from "../../dashboard/sidebar/SidebarPanel"; 
import Dashboard from "../../dashboard/Dashboard";  
import { EditorSvg, InfoIcon, SideArrow, UpgradeIcon} from "../../../assets/svg/SvgIcons";
import RichTextEditor from "../../shared/utilities/rich-text-editor/RichTextEditor";
import { RiDeleteBinLine } from "react-icons/ri";
import Loader2 from "../../shared/utilities/loader2/Loader2";
import InlineLogin from "../../InlineLogin";   
import InlinePricing from "../../views/InlinePricing";

const BlogPost = () => {
  const { user } = useUserAuth();
  const functions = getFunctions(); 
  //if(user){ localStorage.setItem("user", user.uid)} 
  const navigate = useNavigate(); 
  const [message, setMessage] = useState(null) 
  const params = useParams();
  const [projects, setProjects] = useState(null);
  const [blogIdeaData, setBlogIdeaData] = useState([]);
  const [blogIntroData, setBlogIntroData] = useState([]);
  const [blogOutline, setBlogOutline] = useState(null);
  const [blogPost, setBlogPost] = useState({idea: "", title:"", intro:"", sections:[] });
  const [blogOutlineData, setBlogOutlineData] = useState([]);
  const [activeSection, setActiveSection] = useState(null);
  const [loading, setLoading] = useState(false);
  const [contGen, setContGen] = useState(false);
  const [error, setError] = useState(null);
  const [copied, setCopied] = useState(false);
  const [wizard, setWizard] = useState('step1');
  const [introBtn, setIntroBtn] = useState(false);
  const [outlineBtn, setOutlineBtn] = useState(false);
  const [newBlogTitle, setNewBlogTitle] = useState('Write your blog title..');
  const [showBlogTitle, setShowBlogTitle] = useState(false);
  const [editIntro, setEditIntro] = useState(false);
  const [selTitle, setSelTitle] = useState();
  const [selIntro, setSelIntro] = useState();
  const [ideaValue, setIdeaValue] = useState('');
  const [blogEditor, setBlogEditor] = useState(false);
  const [wordCount, setWordCount] = useState(null);
  const [selOutline, setSelOutline] = useState([])
  const [sectionData, setSectionData] = useState([])

  const {subscriber, setSubscriber} = useContext(SubscriberContext);
  const [showModal, setShowModal] = useState(false); 
  const handleShow = () => setShowModal(true);
  const [modalData, setModalData] = useState({})
  const [accept, setAccept] = useState(false);

  const [showDetails, setShowDetails] = useState(false);
  const [target, setTarget] = useState(null); 
  const [justTxt, setJustTxt] = useState('hello');  
  const [queryData, setQueryData] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [projectSections, setProjectSections] = useState(null)
  const [projectLoading, setProjectLoading] = useState(null);

  const [startOver, setStartOver] = useState(false); 
  const [modalType, setModalType] = useState(null);  
  const [value, setValue] = useState("sdf sadfdsaf sadf");

  const [upgradeTxt, setUpgradeTxt] = useState({ title: 'Upgrade your account', 
  discription: 'You have either exceeded the word limit or your plan has expired. Please upgrade your account.', 
  action:true})
  const handleClick = (event) => {
    setShowDetails(!showDetails);
    setTarget(event.target);
  };
 
  const startNewPost = (e) => {
    setShowModal(false);
    setWizard('step1')
    setBlogEditor(false) 
    setBlogPost({idea: "", title:"", intro:"", sections:[] })
   // navigate("/blog-post");
     setBlogIdeaData([])
     setBlogIntroData([])
     setBlogOutline(null)
     setBlogOutlineData([])
  }
  const handleClose = (e) => {
     setShowModal(false);    
  }
  
  useEffect(() => {
    if(params.id){
      //console.log(params.id);
      getProject(params.id); 
      setBlogEditor(true);
      setWizard('step5')
    }
   },[])
  
   const aiBoard = useRef(null); 
  const scollDown = () => {
    aiBoard.current.scrollTo(0, aiBoard.current.scrollHeight);
  }
  const updateSubscriber = async () =>{
    const subData = await CommonDataServices.getUsageLimit(user.uid);
    if(subData.data()){
      //console.log(subData.data())
      setSubscriber(subData.data())
    }
  } 

const fetchAPIData = async (apiRequestBody) => {
  let fetchURL = "https://us-central1-wordkraft-bb683.cloudfunctions.net/tool_api3";
  if(apiRequestBody.title == "blogTitle"){
    fetchURL = "https://us-central1-wordkraft-bb683.cloudfunctions.net/tool_api";
  }
    const fetchData = await fetch(fetchURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(apiRequestBody)
    });  
    const response = await fetchData.json();
    if(apiRequestBody.title != "blogTitle"){
      //console.log(response)
      CommonDataServices.usageTrackingData3(user.uid, response).then(() => {
        updateSubscriber();
      });
    }
    return response;
}

const [loginState, setloginState] = useState(false); 
const isAuthenticated = () => {
  if(user){
    return true;
  } else {
    setloginState(true)
    setShowModal(true) 
    return false;
  } 
}
const handleLoginSuccess = (user) => { 
  setloginState(false)
  setShowModal(false) 
  console.log('Login successful:');
}; 
const getBlogIdea = async (e) => { 
     e.preventDefault();
     const currentDate = Math.floor(Date.now() / 1000)  
    if(subscriber.totalWords >= subscriber.wordsLimit || subscriber.premiumState === false || currentDate > subscriber.planEndDate){
       setModalData(upgradeTxt)
       setShowModal(true)  
      return;
     } 
     setLoading(true)
     setWizard('step2') 
     setShowBlogTitle(false)

     const formData = new FormData(e.target);
     const formDataObj = Object.fromEntries(formData.entries());
    // //console.log(formDataObj)  
     setQueryData(formDataObj);  
     try{
      // const getAIdata = await httpsCallable(functions, 'wordkraftAI');
      // const param = {
      //   type:"blogTitle", 
      //   about: formDataObj.blogIdea,  
      //   atStop:"7.",
      //   wcount:100,
      //   creative:0.85,
      //   num:1,
      //   language: formDataObj.language,
      //   keywords: formDataObj.blogKeywords ? formDataObj.blogKeywords : null
      // }
      //console.log(param)
      const apiRequestBody = {
        "title": "blogTitle",
        "query": formDataObj.blogIdea, 
        "language":formDataObj.language,
        "tone": "formal",
      }        
      const response = await fetchAPIData(apiRequestBody);
     // console.log(response) 

      if(response){      
        //console.log(response); 
        let oldOutLine = response.data; 
        let sentences = oldOutLine.split(/\r?\n/);
        var updatedList = []; 
        sentences.filter(removeNum);
        function removeNum(value) {  
          updatedList.push(value.toString().replace(/\d+.\s/,'').replace(/['"]+/g, '')); 
        } 
        
        let newOutLine = updatedList.filter(removeEmpty);  
        function removeEmpty(value) {   
           return value.length > 6;
         }  
  
         let updatedValue = {idea: formDataObj.blogIdea};
         setBlogPost(blogPost => ({
               ...blogPost,
               ...updatedValue
             }));
         wordCounter(oldOutLine);
         setBlogIdeaData(newOutLine); 
         setLoading(false)
       };
      } catch (error) {
        //console.log(error.message);
        setLoading(false)
      }
  }; 

  const reGetBlogTitle = async (e) => {  
    const currentDate = Math.floor(Date.now() / 1000) 
if(subscriber.totalWords >= subscriber.wordsLimit || subscriber.premiumState === false || currentDate > subscriber.planEndDate){
       setModalData(upgradeTxt)
      setShowModal(true) 
      return;
     }
    let blogIdeaValue = ideaValue;
    setLoading(true); 
    try {
      const getAIdata = await httpsCallable(functions, 'wordkraftAI');
      const param = {
        type:"blogTitle", 
        about: blogIdeaValue,  
        atStop:"7.",
        wcount:100,
        creative:0.85,
        num:1 
      }
      getAIdata(param).then((response) => { 
       CommonDataServices.usageTrackingData(user.uid, response).then(() => {
          updateSubscriber();
        });
       let oldOutLine = response.data.choices[0].text; 
       let sentences = oldOutLine.split(/\r?\n/);
       var updatedList = []; 
       sentences.filter(removeNum);
       function removeNum(value) {  
         updatedList.push(value.toString().replace(/\d+.\s/,'').replace(/['"]+/g, '')); 
       } 
       
       let newOutLine = updatedList.filter(removeEmpty);  
       function removeEmpty(value) {   
          return value.length > 6;
        }  
      
        let updatedBlogIdeaData = blogIdeaData.concat(newOutLine)
        setBlogIdeaData(updatedBlogIdeaData); 
        setLoading(false)
      });
    } catch (error) {
      //console.log(error.message);
      setLoading(false)
    }
  }

  const getBlogIntro = async (e) => { 
    const currentDate = Math.floor(Date.now() / 1000) 
if(subscriber.totalWords >= subscriber.wordsLimit || subscriber.premiumState === false || currentDate > subscriber.planEndDate){
       setModalData(upgradeTxt)
      setShowModal(true) 
      return;
     }
    e.preventDefault();
    setLoading(true)
    
    //const formData = new FormData(e.target);
    //const formDataObj = Object.fromEntries(formData.entries());
    let lang = null;
    let tone = null;

    if(!queryData){ 
      lang = "English";
      tone = "Formal";
      let queryObj = {
        language: "English",
        tone: "Formal"
      }
      setQueryData(queryObj);  
    } else{
      lang = queryData.language ? queryData.language: "English"
      tone = queryData.tone ? queryData.tone: "Formal"
    }
 
    //console.log(queryData)
    //console.log(formDataObj) 
    try {
      // const getAIdata = await httpsCallable(functions, 'wordkraftAI'); 
      // const param = {
      //   type:"blogIntro", 
      //   title:blogPost.title,         
      //   language: lang,
      //   tone: tone, 
      //   wcount:250,
      //   creative:0.75,
      //   num:2 
      // }
      const apiRequestBody = {
        "title": "blogIntro",
        "query": blogPost.title, 
        "language":lang,
        "tone": tone,
        "num":2 
      }  
      
      const response = await fetchAPIData(apiRequestBody);
     // console.log(response) 
      
      if(response){   
        let joinArray = blogIntroData.concat(response);  
        setBlogIntroData(joinArray); 
        setLoading(false)
        setIntroBtn(true)
      };
    } catch (error) {
      //console.log(error.message);
      setLoading(false)
    }
 }; 

const reGetBlogIntro = async () =>{
  const currentDate = Math.floor(Date.now() / 1000) 
if(subscriber.totalWords >= subscriber.wordsLimit || subscriber.premiumState === false || currentDate > subscriber.planEndDate){
    let newFileModal = { title: 'Upgrade your account', discription: 'You have either exceeded the word limit or your plan has expired. Please upgrade your account.', action:true}
    setModalData(newFileModal)
    setShowModal(true) 
    return;
   }
  setLoading(true) 
  try {
    const getAIdata = await httpsCallable(functions, 'wordkraftAI'); 
    const param = {
      type:"blogIntro", 
      title:blogPost.title,         
      language: queryData.language ? queryData.language: "English",
      tone: queryData.tone ? queryData.tone : "formal", 
      wcount:250,
      creative:0.75,
      num:2 
    }
    getAIdata(param).then((response) => {   
      CommonDataServices.usageTrackingData(user.uid, response).then(() => {
          updateSubscriber();
        });
      //console.log(blogIntroData)
      //console.log(response.data.choices);
      let updateIntroData = blogIntroData.concat(response.data.choices) //joining two arrays
      setBlogIntroData(updateIntroData); 
      setLoading(false) 
    });
  } catch (error) {
    //console.log(error.message);
    setLoading(false)
  }
}
 
 const getBlogOutline = async () => {  
  const currentDate = Math.floor(Date.now() / 1000) 
if(subscriber.totalWords >= subscriber.wordsLimit || subscriber.premiumState === false || currentDate > subscriber.planEndDate){
    let newFileModal = { title: 'Upgrade your account', discription: 'You have either exceeded the word limit or your plan has expired. Please upgrade your account.', action:true}
    setModalData(newFileModal)
    setShowModal(true) 
    return;
   }
    setLoading(true)
    let blogTitle = blogPost.title;  
    try {
      // const getAIdata = await httpsCallable(functions, 'wordkraftAI'); 
      // const param = { 
      //   type:"blogPostOutline", 
      //   title:blogPost.title,        
      //   language: queryData.language ? queryData.language: "English",
      //   tone: queryData.tone ? queryData.tone : "formal", 
      //   wcount:250,
      //   creative:0.7,  
      //   num:1 
      // }
      const apiRequestBody = {
        "title": "blogOutline",
        "query": blogPost.title,  
        "language": queryData.language ? queryData.language: "English",
        "tone": "formal",
        "num":1
      }   
      const response = await fetchAPIData(apiRequestBody);
     // console.log(response)  
      // if(response){  
      //   let joinArray = data.concat(response); 
      //   setData(joinArray);  
      //   setLoading(false)
      // }; 

      if(response){   
         let oldOutLine = response[0].text; 
         let sentences = oldOutLine.split(/\r?\n/); 
         const newOutLine = sentences.filter(myFunction); 
         function myFunction(value) { 
            return value.length > 6;
         }        

         let newArry = [];
         let updatedValue = [];
         newOutLine.forEach(function(element, index) { 
             let outVal = element.replace(/^(?=[MDCLXVI])M*(C[MD]|D?C{0,3})(X[CL]|L?X{0,3})(I[XV]|V?I{0,3})\.\s*|\d+\.\s*/, ''); 
            newArry.push({item: outVal, status:'none'})
         // updatedValue.push({item: element, status:'none'})
         }) 

         let joinArray = blogOutlineData.concat(newArry);    
         setBlogOutlineData(joinArray); // new in use
         
         setBlogOutline(newOutLine);  //old not used
         setLoading(false);
         setOutlineBtn(true);  
      }; 
    } catch (error) {
      //console.log(error.message);
      setLoading(false)
    }
 };

 const reGetBlogOutline = async () => {  
  const currentDate = Math.floor(Date.now() / 1000) 
if(subscriber.totalWords >= subscriber.wordsLimit || subscriber.premiumState === false || currentDate > subscriber.planEndDate){
    let newFileModal = { title: 'Upgrade your account', discription: 'You have either exceeded the word limit or your plan has expired. Please upgrade your account.', action:true}
    setModalData(newFileModal)
    setShowModal(true) 
    return;
   }
  setLoading(true)
  let blogTitle = blogPost.title;  
  try {
    const getAIdata = await httpsCallable(functions, 'wordkraftAI'); 
    const param = {
      type:"blogOutline", 
      title:blogPost.title,        
      language: queryData.language ? queryData.language: "English",
      tone: queryData.tone ? queryData.tone : "formal", 
      wcount:250,
      creative:0.7,
      num:1 
    }
    getAIdata(param).then((response) => {    
       CommonDataServices.usageTrackingData(user.uid, response).then(() => {
          updateSubscriber();
        });
       let oldOutLine = response.data.choices[0].text; 
       let sentences = oldOutLine.split(/\r?\n/); 
       const newOutLine = sentences.filter(myFunction); 
       function myFunction(value) { 
          return value.length > 6;
       }         
       
       let newArry = []; 
       newOutLine.forEach(function(element, index) { 
        let outVal = element.replace(/\d+.\s/,'');
        outVal = outVal.replace(/^\s+|\s+$/gm,'');
        newArry.push({item: outVal, status:'none'})  
       })
       //console.log(newArry)
       let attachArray = blogOutlineData.concat(newArry);
       setBlogOutlineData(attachArray); // new in use

       let updatedOlogOutline = blogOutline.concat(newOutLine); // old not used
       setBlogOutline(updatedOlogOutline);        // old not used
       setLoading(false);
       setOutlineBtn(true);  
    }); 
  } catch (error) {
    //console.log(error.message);
    setLoading(false)
  }
};
 
useEffect(() => { 
  //console.log(blogOutlineData); 
 
}, [blogOutlineData]);
 
// const selectBlogItem=(e)=>{
//   let strId = e.target.dataset.id
//   //let str = e.target.value;
//   // let str = blogIdeaData[strId];
//   // let titleVal = str.replace(/\d+.\s/,'');
//   //console.log(titleVal);
    
//     setSelTitle(strId); 
//  }
const selectBlogItem=(e)=>{
  let strId = parseInt(e.target.dataset.id); 
  let str = blogIdeaData[strId];  
  let updatedValue = {title: str};
  setBlogPost(blogPost => ({
        ...blogPost,
        ...updatedValue
      })); 
      setWizard('step3') 
}
 
const cusBlogTitle=(e)=>{
  let titleData = e.target.value; 
  let updatedValue = {title: titleData};
  setBlogPost(blogPost => ({
        ...blogPost,
        ...updatedValue
      }));
      
      setWizard('step3')
}
const goNextTitle=(e)=>{
  let strId = parseInt(e.target.dataset.id); 
  let str = blogIdeaData[strId];
  let updatedValue = {title: str};
  setBlogPost(blogPost => ({
        ...blogPost,
        ...updatedValue
      }));
      
      setWizard('step3')
}

 const updateBlogTitle = (e) =>{
  let str = e.target.innerHTML; 
  let sid = parseInt(e.target.dataset.id);
  let updateVal = blogIdeaData.splice(sid, 1, str);
  //console.log(str)
  //console.log(updateVal)
  //console.log(blogIdeaData)
  // setBlogIdeaData({...blogIdeaData, updateVal})
  // //console.log(blogIdeaData)
 }    
//  const selectBlogIntro=(e)=>{ 
//     let introVal = e.target.value; 
//     setSelIntro(introVal)
//    // //console.log(introVal);
  
//      }
const selectBlogIntro=(e)=>{ 
  const ind = parseInt(e.currentTarget.id);
  // let introVal = e.target.value;
  let introVal = blogIntroData[ind].text; 
  //console.log(introVal)
  let updatedValue = {intro: introVal};
   
  //console.log(introVal);
  let spaceRemoveData = introVal.replace(/(\r\n|\n|\r){2}/,"");  
  //console.log(spaceRemoveData);

  setBlogPost(blogPost => ({
       ...blogPost,
       intro: spaceRemoveData
     })); 
     setIntroValue([ {children : [{text: spaceRemoveData }]}]);
     setWizard('step4')

   }
  const updateBlogTitleData = (e) => {
    const ind = parseInt(e.currentTarget.id);
    let targetValue = e.target.value;
    //console.log(blogIdeaData) 
    let newArry = blogIdeaData;
                  newArry[ind] = targetValue;   
                  setBlogIdeaData(newArry); 
    //console.log(blogIdeaData) 
  }
  const updateBlogIntroData = (e) => { 
    const ind = parseInt(e.currentTarget.id);
    let targetValue = e.target.value; 
    let newArry = blogIntroData;
                  newArry[ind].text = targetValue;   
                  setBlogIntroData(newArry); 
    //console.log(blogIntroData) 
  }
 const goNextIntro =(e)=>{
  let introVal = e.target.value; 
  let updatedValue = {intro: introVal};
  setBlogPost(blogPost => ({
       ...blogPost,
       intro: introVal
     })); 
     setIntroValue([ {children : [{text: introVal }]}]);
     setWizard('step4')
 }    

  const selectBlogOutline=(e)=>{ 
   //let str = e.target.value; 
   let strId = parseInt(e.target.dataset.id);  
   let str = blogOutlineData[strId].item; 
   let sectionArray = blogPost.sections; 
    // //console.log(blogOutlineData[strId].status)
   if (e.target.checked == true){ 
        let updatedValue = sectionArray.push({secTitle:str, secData:''})
        setBlogPost(blogPost => ({
          ...blogPost,
          ...updatedValue
        })); 
    } else {  
      sectionArray = sectionArray.filter(function( obj ) {
        return obj.secTitle !== str;
      }); 
      let updatedValue = blogPost.sections = sectionArray;     
    } 
  }
  const updateBlogOutline = (e) => {
    const ind = parseInt(e.currentTarget.id);
    let targetValue = e.target.value; 
    let newArry = blogOutlineData;
                  newArry[ind].item = targetValue;  
   // //console.log(newArry);  
    setBlogOutlineData(newArry);  
  }

const deleteBlogOutline = (e) => {
  const ind = parseInt(e.currentTarget.id); 
  //console.log(blogOutlineData[ind].item);  

  let sectionArray = blogPost.sections; 
    sectionArray = sectionArray.filter(function( obj ) {
    return obj.secTitle !== blogOutlineData[ind].item;
  }); 
  blogPost.sections = sectionArray;  

   setBlogOutlineData(blogOutlineData.filter(data => data.item != blogOutlineData[ind].item)); 
  //console.log(blogOutlineData);
}
     const handleSelect = (eventKey) => { 
        setWizard(eventKey) 
        blogPost.sections = [] 
        if(eventKey == "step1"){
          blogPost.title = "";
          blogPost.intro = "";
        }
        if(eventKey == "step2"){ 
          blogPost.intro = "";
        }
        if(eventKey == "step3"){ 
          blogPost.sections = [];
        }
     };
     const goStep = (e) => { 
        setWizard(e.target.value) 
     };

    const genSection = async () => { 
        setBlogEditor(true);
        setWizard('step5')
        setLoading(true)    

        //const getAIdata = httpsCallable(functions, 'wordkraftAIapi');  
        
        const generateAllSections = async () => {   
        let sectionData = [];
        let response;

         
      for (let index = 0; index < blogPost.sections.length; index++) { 
          const objSec = {
            postTitle: blogPost.title,
            secTitle: blogPost.sections[index].secTitle,
            keyWords: "",
            wordRange: 1000
          } 
          const result = await fetchArticleData(objSec);

          if (!result) {
            console.error("Error: result is undefined..."); 
            return null;
          }  
          
          sectionData.push(result) 

        }; 
        return sectionData; 
      }
       const mySections = await generateAllSections()
       //console.log(mySections); 
       
       const allResponse = mySections;
            //console.log(allResponse)  
            allResponse.map((response, index) => {
              //console.log(response)   
              let respData = response.data;
              let spaceRemoveData = respData.replace(/(\r\n|\n|\r){2}/,"")

              let withFullStopData = spaceRemoveData.split(/\.(?=[^\.]+$)/); 
          //    console.log(withFullStopData)
              let updatedValue = blogPost.sections[index].secData = withFullStopData[0];
             // let updatedValue = blogPost.sections[index].secData = spaceRemoveData;
              setBlogPost(blogPost => ({
                             ...blogPost,
                              ...updatedValue
                           }));  
                    })  
                    setProjects(blogPost) 
                    addNewProject();   
    }

    
const fetchArticleData = async (objSec, retryCount = 0) => {
  const secArry = blogPost.sections;
  const outLine = secArry.map(item => item.secTitle).join('\n');
  
  const apiRequestBody = {
    "title": "article sections",
    "postTitle": objSec.postTitle,
    "secTitle": objSec.secTitle,
    "keyWords": objSec.keyWords ? objSec.keyWords : " ",
    "wordRange": "blogpost",
    "outLine": outLine
  };
  
  const functionUrl = "https://us-central1-wordkraft-bb683.cloudfunctions.net/tool_api2";
  
  try {
    const response = await fetch(functionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(apiRequestBody),
    });

    if (response.status === 500) {
      if (retryCount < 2) {
        // Retry the request after a delay
        console.log("500 error")
        await new Promise(resolve => setTimeout(resolve, 3000));
        return fetchArticleData(objSec, retryCount + 1); // Retry the fetchArticleData function with an incremented retry count
      } else {
        throw new Error('Maximum retries exceeded');
      }
    }
   
    const data = await response.json();

    CommonDataServices.usageTrackingData2(user.uid, data)
    .then(() => {
      updateSubscriber();
    });

      return data;
    } catch (error) {
      setLoading(false);
      console.error('Error during fetch operation:', error); 
      toast.error('A network error occurred. Please check your connection and try again.');
    }
  };
    

  useEffect(() => {  
    addNewProject(projects); 
  }, [projects]);
 
  
  // Add new project  
  const addNewProject = async (projectsData) => {
    //console.log(projectData)
    const newProject = { 
      idea: '',
      title: '',
      intro: '',
      type: '',
      keywords: '',
      language: ''
    } 
    if(projectsData){ 
      newProject.idea = projectsData.idea;
      newProject.title = projectsData.title;
      newProject.intro = projectsData.intro; 
      newProject.type = "blog_post_builder"; 
      newProject.keywords = queryData.blogKeywords ? queryData.blogKeywords: ''; 
      newProject.language = queryData.language ? queryData.language: '';
    }
    if(newProject.title.length > 4){
        try{
          //console.log(newProject) 
          await ProjectDataService.addProjects(user.uid, newProject).then((data) =>{ 
            //console.log("Project added successfully!!!")
            //console.log(data);
            const projectId = data.id; 
            projectsData.wordCount = 0;
            projectsData.creationDate = Math.floor(Date.now() / 1000); //serverTimestamp();
            projectsData.id = data.id; 
            ProjectDataService.updateProjectIntro(user.uid, projectsData); 
            addProjectSections(projectId, projectsData); 
          }); 
        //  setMessage({ error: false, msg: "New Project added sucessfully!"});                        
        } catch (error) {
        //  setMessage({ error: true, msg: err.message});   
          //console.log(error.message) 
          setLoading(false)
        }
    } 
  } 

  const addProjectSections = async (projectId, projectsData) => {
    const projId = projectId; 
    const projIdObj = {
      pId: projectId,
      uid: user.uid
    }
    //console.log(projId)
    let projectSections = [];
    if(projectsData){ 
      projectSections = projectsData.sections; 
      //console.log(projectSections)

      projectSections.forEach((section, index) => {
         const secObj = {
           order: index, 
           sectionTitle: section.secTitle,
           sectionDescription: section.secData
         }
         ProjectDataService.addProjectSections(projIdObj, secObj);  
         if(projectSections.length == (index + 1)){
           getProject(projId); 
           //console.log('all done to publish'); 
         }
      })
      
    }  
}

 const getProject = async (projId) => {
  setProjectLoading(true);
  const doc = await ProjectDataService.getProject(user.uid, projId);
  //console.log(doc.data()); 
  setProjectData({id:projId, ...doc.data()})
  getProjectSections(projId)
  // setProjectData(data.doc.map(doc => {
  //     return{
  //       id: doc.id,
  //       viewing: false,
  //       ...doc.data()
  //     }
  // }))    
}

const getProjectSections = async (projId) => {  
  const data = await ProjectDataService.getProjectSections(user.uid, projId);
  //console.log(data); 
  setProjectSections(data.docs.map(doc => {
    //console.log(doc.data());
    return{
      id: doc.id,
      projectId: projId, 
      ...doc.data()
    }
  })) 
  setLoading(false)
  setProjectLoading(false);
  //console.log(projectSections); 
}
 
//const [editor] = useState(() => withReact(createEditor()))
const [introValue, setIntroValue] = useState([
  {
      type: 'paragraph',
      children: [{ text: 'A line of text in a paragraph.' }], 
  },
])



 const wordCounter = () => {

  //var textCount = text.split(' ').filter(word => word !== '').length;  
  
  let secWords = 0; 
  if(projectSections){
    //console.log(projectSections)
    projectSections.map(item => {
      var secTitleCount = 0;
      var secDataCount = 0;
      if(item.sectionTitle){
        secTitleCount = item.sectionTitle.split(' ').filter(word => word !== '').length;  
      }
      if(item.sectionDescription){
        secDataCount = item.sectionDescription.split(' ').filter(word => word !== '').length; 
      } 
      secWords = secWords + secTitleCount + secDataCount; 
    })
    //console.log(secWords)
    function countWords(str) {
      const arr = str.split(' ');    
      return arr.filter(word => word !== '').length;
    }
    
    let titleCount = countWords(projectData.title);
    let introCount = countWords(projectData.intro);

    let totalWords = titleCount + introCount + secWords;
    if(totalWords){
       //console.log(totalWords)
      setWordCount(totalWords) 
      
    }  
    // if(totalWords){  
    //   let updateProjData = projectData;
    //       updateProjData.wordCount = totalWords;
    //   setProjectData(updateProjData);
    //   ProjectDataService.updateProjectIntro(updateProjData);   
    //  } 
    
    } 
 }
 
 useEffect(() => {
     wordCounter();  
  }, [projectSections])

 useEffect(() => { 
    setWordCount(wordCount);  
     //console.log(wordCount) 
  }, [wordCount])
  
 
    const handleBlogTitle = (e) =>{
      let titleData = e.target.value;       
      projectData.title = titleData; 
      setProjectData('');
      setProjectData(projectData)
      wordCounter();
    }
 
    const handleBlogIntro = (e) =>{ 
      let introData = e.target.value;    
      projectData.intro = introData; 
      setProjectData('');
      setProjectData(projectData)
      wordCounter();
    }

    const updateBlogIntro = () =>{    
      try{  
        ProjectDataService.updateProjectIntro(user.uid, projectData);                       
      } catch (error) { 
        //console.log(error.message) 
        setLoading(false)
      }
      // save Count    
      wordCountSubmit()
    }
    

    const handleSecTitle = (e) =>{    
       let secID = e.currentTarget.id;
       let newData = e.target.value;  
       //console.log(secID) 
       projectSections[secID].sectionTitle = newData; 
       setProjectSections([]);
       setProjectSections(projectSections); 
       wordCounter();
    }
    const handleSecContent = (e) =>{ 
      let secID = e.currentTarget.id;
      let newData = e.target.value;   
      function strip_html_tags(str){
        if ((str===null) || (str===''))
            return false;
        else
        str = str.toString();
        return str.replace("<div>", "").replace("</div>", "");
       // return str.replace(/<[^>]*>/g, '');
      }  
      const newText = strip_html_tags(newData);  
      //console.log(newText)
      projectSections[secID].sectionDescription = newText; 
      setProjectSections([]);
      setProjectSections(projectSections); 
      wordCounter();
    }
  
    const updateBlogSection = (e) => {
      let secID = e.currentTarget.id; 
      let projSec = projectSections[secID];  
      //console.log(projSec);
      try{  
        ProjectDataService.updateProjectSection(user.uid, projSec);                        
      } catch (error) { 
        //console.log(error.message) 
        setLoading(false)
      }
      // save Count    
      wordCountSubmit()
    }
  const [indexNumber, setIndexNumber] = useState(null)

  const reGenerate = async (e) =>{  
    let userState = checkUserStatus(); 
    if(!userState){return;}
    //console.log(e.target.name)
    setModalType("startOver") 
    let newFileModal = { title: 'Want to write new?', discription: 'The current content will replace with new content', action:true} 
    setModalData(newFileModal)
    setShowModal(true) 
    const index = parseInt(e.target.name);
    setIndexNumber(index)
  }
  
const regNewContent = async () => { 
      setContGen(true)
      setShowModal(false)  
     // const index = parseInt(e.target.name);
      const index = indexNumber;
      const postTitle = projectData.title;
      const secTitle = projectSections[index].sectionTitle; 
      setActiveSection(index)   
      //console.log(secTitle)
      try{
        // const getAIdata = await httpsCallable(functions, 'wordkraftAI');
        //   const param = {
        //     type:"blogSectionNew", 
        //     title:postTitle,
        //     about:secTitle,  
        //     wcount:200,
        //     creative:0.85,
        //     num:1 
        //   }

          const apiRequestBody = {
            "title": "blogSection",
            "blogTitle":postTitle, 
            "blogHeading": secTitle, 
            "language": "english",
            "keyWords":"",
            "tone": "formal",
            "num":1 
          }            
          const response = await fetchAPIData(apiRequestBody);
         // console.log(response)  
          
         if(response){      
         let str = response[0].text; 
          projectSections[index].sectionDescription = str;
          setProjectSections([]);
          setProjectSections(projectSections);
          setContGen(false) 
          if(str.length > 4){
            try{  
              ProjectDataService.updateProjectSection(user.uid, projectSections[index]);
              wordCounter();                        
            } catch (error) { 
              setLoading(false)
            }
          }  
        };
      } catch (error){
        //console.log(error.message);
        setLoading(false)
      }
      // save Count    
      wordCountSubmit()
      setStartOver(false);
      setModalType(null);
      setIndexNumber(null);
}

  const writeMore = async (e) =>{   
    let userState = checkUserStatus(); 
    if(!userState){return;}
    //console.log(e.target.name) 
    setContGen(true) 

    const index = parseInt(e.target.name); 
    const secId = projectSections[index].id; 
    const projId = projectSections[index].projectId;
    const secTitle = projectSections[index].sectionTitle; 
    let secDesc = projectSections[index].sectionDescription; 
 
    setActiveSection(index)  
    
    let checkFullStop =  secDesc.endsWith(".");
    //console.log(checkFullStop)
    if(checkFullStop){
      secDesc = secDesc + " ";
      //console.log("given some space")
    }

    try{
      // const getAIdata = await httpsCallable(functions, 'wordkraftAI');
      //   const param = {
      //     type:"blogSectionAdd", 
      //     title:secTitle,
      //     about:secDesc,  
      //     wcount:200,
      //     creative:0.85,
      //     num:1 
      //   }
      const apiRequestBody = {
        "title": "blogSectionAdd",
        "secDesc": secDesc, 
        "secTitle": secTitle,
        "blogTitle": projectData.title,
        "num":1 
      }            
      const response = await fetchAPIData(apiRequestBody);
     // console.log(response)  

        if(response){  
        
        let appendData = projectSections[index].sectionDescription + response[0].text; 
        projectSections[index].sectionDescription = appendData;   
        setProjectSections([]);
        setProjectSections(projectSections);
        setContGen(false) 
        // Update database 
          
        if(appendData.length > 4){
          try{  
            ProjectDataService.updateProjectSection(user.uid, projectSections[index]);
            wordCounter();
          //  setMessage({ error: false, msg: "New Project added sucessfully!"});                        
          } catch (error) {
          //  setMessage({ error: true, msg: err.message}); 
            //console.log(error.message) 
          }
        }  
      };
    } catch (error){
      //console.log(error.message);
      setLoading(false)
    }
    // save Count    
    wordCountSubmit()
  }   

  const reGenerateNewIntro = async () =>{  
    //console.log(e.target.name)
    let userState = checkUserStatus(); 
    if(!userState){return;}
    setModalType("startOverIntro") 
    let newFileModal = { title: 'Want to write new?', discription: 'The current content will replace with new content', action:true}
    setModalData(newFileModal)
    setShowModal(true) 
  }

  const reGenerateIntro = async () =>{
    setShowModal(false)  
    const currentDate = Math.floor(Date.now() / 1000) 
if(subscriber.totalWords >= subscriber.wordsLimit || subscriber.premiumState === false || currentDate > subscriber.planEndDate){
       setModalData(upgradeTxt)
      setShowModal(true) 
      return;
     }
    setEditIntro(true) 
    const postTitle = projectData.title;

    try{
      // const getAIdata = await httpsCallable(functions, 'wordkraftAI');
      // const param = {
      //   type:"blogIntroNew", 
      //   title:postTitle,  
      //   wcount:250,
      //   creative:0.85,
      //   num:1 
      // }
      const apiRequestBody = {
        "title": "blogIntro",
        "query": postTitle, 
        "language":"english",
        "tone": "formal",
        "num":1 
      }  
      
      const response = await fetchAPIData(apiRequestBody);
     // console.log(response) 
      
      if(response) {   
      projectData.intro = response[0].text;
      try{  
        ProjectDataService.updateProjectIntro(user.uid, projectData); 
        setProjectData('');
        setProjectData(projectData)             
        wordCounter();         
      } catch (error) { 
        //console.log(error.message) 
      } 
        setEditIntro(false) 
      };
    } catch (error){
      //console.log(error.message);
      setLoading(false)
    }
      // save Count    
      wordCountSubmit()      
      setStartOver(false);
      setModalType(null);
      setIndexNumber(null);
  }

  const writeMoreIntro = async () =>{
    const currentDate = Math.floor(Date.now() / 1000) 
if(subscriber.totalWords >= subscriber.wordsLimit || subscriber.premiumState === false || currentDate > subscriber.planEndDate){
       setModalData(upgradeTxt)
      setShowModal(true) 
      return;
     }
    setEditIntro(true)   
    let postIntro = projectData.intro;
    let checkFullStop =  postIntro.endsWith(".");
    //console.log(checkFullStop);
    if(checkFullStop){
      postIntro = postIntro + " ";
      //console.log("given some space")
    }
    try{
      // const getAIdata = await httpsCallable(functions, 'wordkraftAI');
      // const param = {
      //   type:"blogIntroAdd", 
      //   about:postIntro,  
      //   wcount:80,
      //   creative:0.85,
      //   num:1 
      // }
      const apiRequestBody = {
        "title": "blogSectionAdd",
        "secDesc": postIntro, 
        "secTitle": "Introduction",
        "blogTitle": projectData.title,
        "num":1 
      }            
      const response = await fetchAPIData(apiRequestBody);
     // console.log(response)  

      if(response){        
          let appendData = projectData.intro + response[0].text;   
          projectData.intro = appendData;
          try{  
            ProjectDataService.updateProjectIntro(user.uid, projectData); 
            setProjectData('');
            setProjectData(projectData)     
            wordCounter();

          } catch (error) { 
            //console.log(error.message) 
          }   
          setEditIntro(false)  
        };  
      } catch (error){
        //console.log(error.message);
        setLoading(false)
      }
      // save Count    
      wordCountSubmit()
  }

  const delSection = (e) =>{
    setModalType("deleteSection") 
    let newFileModal = { title: 'Delete this section?', discription: 'Your content will be deleted permanently.', action:true}
    setModalData(newFileModal)
    setShowModal(true) 
    const index = parseInt(e.currentTarget.id);
    setIndexNumber(index)
  }

  const deleteSection = () =>{
    setShowModal(false);  
    let index = indexNumber;
    //console.log(index)
    //let index = parseInt(e.currentTarget.id); 
    const secObj = {
      id: projectSections[index].id,
      projectId: projectSections[index].projectId
    }    
    if(secObj.projectId.length > 4){
      try{  
        ProjectDataService.deleteProjectSection(user.uid, secObj);    
        setProjectSections(projectSections.filter(item => item.id !== secObj.id));           
        
      } catch (error) { 
        //console.log(error.message) 
        setLoading(false)
      }
    } 
    wordCounter();   
    setStartOver(false);
    setModalType(null);
    setIndexNumber(null);
  }

  const moveUp = (e) =>{   
    let fromIndex = parseInt(e.currentTarget.id);
    let toIndex = fromIndex - 1;
    let newArray = []
      projectSections.forEach((item) => {
        let newObject = {
              id : item.id,
              projectId : item.projectId,
              sectionDescription : item.sectionDescription,
              sectionTitle : item.sectionTitle,
              order : item.order
        }
        newArray.push(newObject);
      })
      
    function moveArrayItemToNewIndex(arr, old_index, new_index) {
      if (new_index >= arr.length) {
          var k = new_index - arr.length + 1;
          while (k--) {
              arr.push(undefined);
          }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; 
  }; 
  if(fromIndex !== 0){
    setProjectSections(moveArrayItemToNewIndex(newArray, fromIndex, toIndex));
   }
  }

  const moveDown = (e) =>{
    let fromIndex = parseInt(e.currentTarget.id);
    let toIndex = fromIndex + 1;   
     let newArray = []
      projectSections.forEach((item) => {
        let newObject = {
              id : item.id,
              projectId : item.projectId,
              sectionDescription : item.sectionDescription,
              sectionTitle : item.sectionTitle,
              order : item.order
        }
        newArray.push(newObject);
      }) 
 
    function moveArrayItemToNewIndex(arr, old_index, new_index) {
      if (new_index >= arr.length) {
          var k = new_index - arr.length + 1;
          while (k--) {
              arr.push(undefined);
          }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; 
  }; 
 
   if(projectSections.length !== toIndex){
    setProjectSections(moveArrayItemToNewIndex(newArray, fromIndex, toIndex));
   } 
  }

  const customBlogTitle = (e) =>{
      //console.log(e.target.value);
  }
  const handleNewBlogTitle = (e) =>{ 
      setNewBlogTitle(e.target.value);
  }
  const handleShowBlogTitle = () =>{
      if(showBlogTitle){setShowBlogTitle(false)} else {setShowBlogTitle(true)}      
  }
  const handleAddSection = async () =>{  
    //console.log(projectSections)
    //console.log(projectData)
    let userState = checkUserStatus(); 
    if(!userState){return;}
    const indexNo = projectSections.length;
    const projId = projectData.id;
    const d = new Date();
    let newSecId = d.valueOf() + parseInt(indexNo);

    const newSection = { 
      uid: user.uid, 
      id: newSecId,
      order: indexNo,
      projectId: projectData.id,
      sectionDescription: "Add your description..",
      sectionTitle: "Add your heading.." 
    }
    if(newSection.projectId.length > 4){
      try{
        //added user id in newScection as uid
        ProjectDataService.addOneSections(projId, newSection).then((response) => {     
            //console.log(response)      
            projectSections.push(newSection)
            //console.log(projectSections)
            setProjectSections([])
            setProjectSections(projectSections)
        }); 
      } catch (error) {
        //console.log(error.message);
        setLoading(false) 
      }
    } 
  }
  const newPost = () => {
      setModalType("newPost")
      let newFileModal = { title: 'Want to start a new post?', discription: 'The current post data will save under "My Content" blog post.', action:true}
      setModalData(newFileModal)
      setShowModal(true)

  }
  const copyText = () => {
      let copyTitleIntro = `${projectData.title}\n\n${projectData.intro}\n`;
      let copySection = ''; 
      const result =  projectSections.map(function(element) { 
        copySection += '\n' + element.sectionTitle + '\n\n' + element.sectionDescription + '\n';
       })
      
      let newCopySec = copySection.replace((/<br\s*[\/]?>/gi), "\n").replace(/&nbsp;/g, ' ');  

      let copyText = copyTitleIntro + newCopySec
     // //console.log(copyText); 
      navigator.clipboard.writeText(copyText)

    // save Count    
    wordCountSubmit()
    
    toast.success('Copied to clipboard!')  
  }

  const copyHTML = () => {
      let copyTitleIntro = `<h1>${projectData.title}</h1>\n<p>${projectData.intro}</p>\n\n`;
      let copySection = ''; 
      const result =  projectSections.map(function(element) { 
        copySection += '<h2>' + element.sectionTitle + '</h2>\n<p>' + element.sectionDescription + '</p>\n\n';
       })

      let copyText = copyTitleIntro + copySection
      //console.log(copyText); 
      navigator.clipboard.writeText(copyText)

      // save Count    
      wordCountSubmit()

      toast.success('Copied to clipboard!')  
}
const wordCountSubmit = () => {
  // save Count   
  //console.log("tigger count submit" + wordCount) 
  if(wordCount){  
    let updateProjData = projectData;
        updateProjData.wordCount = wordCount;
    setProjectData(updateProjData);
    ProjectDataService.updateProjectIntro(user.uid, updateProjData);   
}
}
// const changeValue = (e) => {
   
// }
const blurValue = (e) => {
  //console.log("on blur") 
  //console.log(e) 
  // let secID = e.currentTarget.id; 
  // let projSec = projectSections[secID];  
  //console.log(projSec);
//   {id: 1655441706858, projectId: '5KOAjUsFFj6hrN3jkjMb', sectionTitle: 'Digital marketing can help businesses to reach a wider audience.', sectionDescription: "If your business isn't using digital marketing, yo…tion (SEO) can help you make sure u did great job"}
// id: 1655441706858
// projectId: "5KOAjUsFFj6hrN3jkjMb"
// sectionDescription: "If your business isn't using digital marketing, you're missing out on a golden opportunity to reach a wider audience. Here are some of the top benefits of digital."
// sectionTitle: "Digital marketing can help businesses to reach a wider audience."}
  try{  
    ProjectDataService.updateProjectSection(user.uid, e);                        
  } catch (error) { 
    //console.log(error.message) 
    setLoading(false)
  }
  // save Count    
  wordCountSubmit()
}
const goToTitle = () =>{
  setWizard('step2')
  setShowBlogTitle(true)
} 
const goToIdeas = () =>{
  setWizard('step1')
  setShowBlogTitle(false)
}  
const checkUserStatus = () => {  
  const currentDate = Math.floor(Date.now() / 1000)
  if(subscriber.totalWords >= subscriber.wordsLimit || subscriber.premiumState === false || currentDate > subscriber.planEndDate){
    let newFileModal = { title: 'Upgrade your account', discription: 'You have either exceeded the word limit or your plan has expired. Please upgrade your account.', action:true}
    setModalData(newFileModal)
    setShowModal(true) 
    return false;
   } else{
    return true;
   }
} 
  return (
    <> {blogEditor && 
       <section className="header-txt sticky-top"> 
            <div className="editorHeader">
             <h5 className="float-left editor-icon"><EditorSvg/> Editor</h5> 
             <span><b>{wordCount}</b> :Words</span>
             <Dropdown> 
              <Dropdown.Toggle variant="link" id="dropdown-copy" className="mr-0" disabled={contGen}><VscCopy/> Copy</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as="button" onClick={copyText}>Copy Text</Dropdown.Item>
                <Dropdown.Item as="button" onClick={copyHTML}>Copy HTML</Dropdown.Item> 
              </Dropdown.Menu>
            </Dropdown>
            <Button variant="link" onClick={handleClick} disabled={contGen}><VscInfo/> Details</Button>
             <Overlay
              show={showDetails}
              target={target}
              placement="bottom" 
              containerPadding={20}
            >
              <Popover id="popover-contained">
                <Popover.Header as="h3">Content Brief</Popover.Header>
                {projectData && 
                <Popover.Body>
                  <div className="mb-2"><strong>Blog Idea:</strong> {projectData.idea ? projectData.idea : '-'}</div>
                  <div className="mb-2"><strong>Blog Title:</strong> {projectData.title}</div>
                  <div className="mb-2"><strong>Blog Keywords:</strong> {projectData.keywords ? projectData.keywords : '-'}</div>
                  <div className="mb-2"><strong>Language:</strong> {projectData.language ? projectData.language : 'English'}</div>
                </Popover.Body>
                }
              </Popover>
            </Overlay>
            <Button variant="link" onClick={newPost} disabled={contGen}><VscNewFile/> New</Button>  
              </div>
          </section> } 
      <Container>
      <div className="main-layout blog-post-wrap">
      
          <section className="tool-panel">
          
              {!blogEditor && <> 
              {error && <Alert variant="danger" onClose={() => setError(null)} dismissibl> {error.message}  <Button className="closeAlert" onClick={() => setError(null)}> X </Button>
               </Alert> }
              <section className="header-txt mt-3 text-center pb-0">
              <h2 className="text-center">Blog Post Generator</h2>  
              <p>Create an impactful, long-form SEO friendly blog post</p>
              </section>  
              <Card className="blog-steps-wrap mb-3 form-wizard">
                    <Navbar onSelect={handleSelect} >
                        <Nav className="form-wizard-steps clearfix">
                            <Nav.Link eventKey="step1" className={"activated active" + (wizard == 'step1' ? 'active' : '')}>
                                <span>1</span>
                                <div>Ideas</div>
                            </Nav.Link>
                            <Nav.Link eventKey="step2" className={(blogPost.title.length > 1 ? 'activated' : '') + (wizard == 'step2' ? 'active' : '')}>
                                <span>2</span>
                                <div>Title</div>
                            </Nav.Link>
                            <Nav.Link eventKey="step3" className={(blogPost.intro.length > 1 ? 'activated' : '') + (wizard == 'step3' ? 'active' : '')} disabled={!blogPost.title}>
                                    <span>3</span>
                                    <div>Intro</div>
                             </Nav.Link> 
                             <Nav.Link eventKey="step4" className={(wizard == 'step4' ? 'active' : '')} disabled={!blogPost.intro}>
                                <span>4</span>
                        <div>Outline</div>
                            </Nav.Link>
                            <Nav.Link eventKey="step5" className={(wizard == 'step5' ? 'active' : '')} disabled>
                                    <span>5</span>
                        <div>Editor</div>
                             </Nav.Link>
                        </Nav>
                   </Navbar> 
           
              </Card>
              </>
             }
              <Row> 
                  <Col md={12} className="ai-board"> 
                  {!blogEditor && <div className="infoTooltip2"><InfoIcon/>
        <span className="tooltiptext">Content generated automatically using AI engine. Please excuse irrelevant content and reach out to us for any issues.</span>
      </div> }
                    <Card>  
                    {!blogEditor && 
                    <div className="ai-board-header">
                    {wizard == 'step1' && 
                     <div>
                         <h5 className="float-left">Blog Post Idea</h5> 
                         <Button variant="link" className="desk d-block m-0 p-0 float-right haveTitle" disabled={!user} onClick={goToTitle}>
                          Already have a <strong>Blog Title</strong>? Click here..</Button>
                         <Button variant="link" className="mobi d-block m-0 p-0 float-right haveTitle" disabled={!user} onClick={goToTitle}>
                          Have a <strong>Blog Title</strong>?</Button>       
                     </div>
                    } 
                    {wizard == 'step2' && 
                        <div>
                            <div className="float-left sec-form-head">
                            <h5>Post Title</h5>
                            <span>Select a title or write your own</span>
                              </div>
                            {!showBlogTitle && <Button variant="outline-primary" size="sm" className="float-right" onClick={goToTitle}>Write your own</Button>}
                            {/* <Button variant="success" size="sm" className="float-right" value="step1" data-id={selTitle} onClick={goNextTitle} disabled={!selTitle}>Next Step</Button> */}
                        </div>
                   } 
                    {wizard == 'step3' && 
                        <div>
                            <h5 className="float-left">
                             {blogPost && blogPost.title} 
                             
                              </h5>
                            {/* {introBtn && <Button variant="outline-primary" size="sm" className="float-right" onClick={reGetBlogIntro}>Re-Generate Intro</Button>} */}
                            {/* <Button variant="success" size="sm" className="float-right" value={selIntro} onClick={goNextIntro} disabled={!selIntro}>Next Step</Button> */}
                           
                        </div>
                    }
                     {wizard == 'step4' && 
                        <div>
                            <h5 className="float-left">
                              {blogPost && blogPost.title} 
                              </h5> 
                            {/* <Button variant="success" size="sm" className="float-right" onClick={genSection}>Generate full Content</Button> */} 
                                      {/* <Button variant="success" size="sm" className="float-right" onClick={genSection} disabled={blogPost.sections.length < 1}>Generate full Content</Button>  */}
                        </div>
                    }
                     {wizard == 'step5' && 
                        <div>
                            <h5 className="float-left">Editor</h5> 
                            <Button variant="success" size="sm" value="step2" className="float-right">Copy</Button>
                        </div>
                    }
                    </div>     
                  }        
                    <Card.Body className="ai-board-body" ref={aiBoard}> 
                                 
                                 {wizard == 'step1' && <>
                                  <Form onSubmit={getBlogIdea}> 
                                  <Form.Group className="mb-3">
                                  <Form.Label>What blog post do you want to write</Form.Label>  <span className="float-right text-muted in-text">{ideaValue.length}/200</span>
                                      <Form.Control required as="textarea" maxLength="200" onChange={((e) => {setIdeaValue(e.target.value)})} 
                                      placeholder="eg: A blog on how digital marketing helps the success of a business." style={{ height: '64px' }} name="blogIdea"  /> 
                                  </Form.Group>
                                  <div className="row"> 
                                  <Form.Group className="mb-3 col-6">
                                      <Form.Label>Keywords (optional)</Form.Label>
                                      <Form.Control type="text" name="blogKeywords" placeholder="eg: Paid marketing, SEO" />
                                  </Form.Group> 
                                  <Form.Group className="mb-3 col-6">
                                      <Form.Label>Language</Form.Label>
                                      <Form.Select name="language" id="language" className="form-control">
																		<option value="English (US)">English (US)</option>
<option value="English (UK)">English (UK)</option>
<option value="French">French</option>
<option value="Spanish">Spanish</option>
<option value="German">German</option>
<option value="Italian">Italian</option>
<option value="Dutch">Dutch</option>
<option value="Arabic">Arabic</option>
<option value="Portuguese">Portuguese</option>
<option value="Portuguese (BR)">Portuguese (BR)</option>
<option value="Swedish">Swedish</option>
<option value="Norwegian">Norwegian</option>
<option value="Danish">Danish</option>
<option value="Finnish">Finnish</option>
<option value="Romanian">Romanian</option>
<option value="Czech">Czech</option>
<option value="Slovak">Slovak</option>
<option value="Slovenian">Slovenian</option>
<option value="Hungarian">Hungarian</option>
<option value="Hebrew">Hebrew</option>
<option value="Polish">Polish</option>
<option value="Turkish">Turkish</option>
<option value="Russian">Russian</option>
<option value="Hindi">Hindi</option>
<option value="Thai">Thai</option>
<option value="Japonese">Japonese</option>
<option value="Chinese (Simplified)">Chinese (Simplified)</option>
<option value="Korean">Korean</option>
<option value="Indonesian">Indonesian</option>
<option value="Greek">Greek</option>
<option value="Bulgarian">Bulgarian</option>
<option value="Ukrainian">Ukrainian</option>
<option value="Estonian">Estonian</option>
<option value="Latvian">Latvian</option>
<option value="Lithuanian">Lithuanian</option>
<option value="Croatian">Croatian</option>
<option value="Serbian">Serbian</option>
<option value="Bosnian">Bosnian</option>
<option value="Macedonian">Macedonian</option>
<option value="Albanian">Albanian</option>
<option value="Vietnamese">Vietnamese</option>
																	  </Form.Select>
                                  </Form.Group>
                                  </div> 
                                  {user &&  <Button variant="primary" className="mt-3 mb-3 mr-auto ml-auto" type="submit">
                                      Generate Title
                                  </Button>}
                                  {!user && <><Button variant="primary" className="mt-3 mb-3 mr-auto ml-auto" onClick={isAuthenticated}>
                                      Login to Generate
                                  </Button></>}
                                  {/* <Button variant="link" className="d-block mt-3 mb-3 mr-auto ml-auto haveTitle" onClick={goToTitle}>Already have a <strong>Blog Title</strong>? Click here..</Button> */}
                                  </Form>
                                 <form>
  
                                  </form></>}

                                  {wizard == 'step2' && <>
                                    {/* {!blogIdeaData && <div className="wizard-placeholder">
                                                <p>Generate amazing blog ideas by simply adding basic information about the topic. Click button "Get Ideas"</p>
                                                </div>} */}
                                    <div className="ai-section height-align text-center">
                                        {showBlogTitle &&  <div className="float-left w-100 mb-4">  
                                        <label className="ai-content customBlogTitle row border-0 m-0 mt-5" >
                                        <span style={{margin: "10px 0 10px -10px"}}>Write your blog title and click "Add Title" button:</span>
                                            <Form.Control type="text" name="blogTitle" className="col-md-9 col-sm mb-3" placeholder="Add your blog title" onChange={handleNewBlogTitle} />
                                            <div className="col-md-3 col-sm">
                                               <Button variant="primary text-center d-block" onClick={cusBlogTitle} value={newBlogTitle} disabled={!user || !newBlogTitle && newBlogTitle.length < 20}>Add Title</Button> 
                                            </div> 
                                        </label>  
                                        </div>
                                        }
                                    </div>
                                    { blogIdeaData.length > 2 && <p className="text-in">Select your blog title</p>}
                                    { blogIdeaData && blogIdeaData.map((text, index) => (
                                            <div className="ai-section height-align text-center" key={index}>
                                                <div className="ai-content flexbox" >  

                                                {/* <div className="selectBtn">
                                                  <span>
                                                  <input type="radio" value={text} data-id={index}
                                                    onChange={selectBlogItem} id={index} name="blogTitle" />
                                                     <label htmlFor={index}></label>  
                                                </span>
                                                 </div> */}

                                                {/* <div className="text-box" onBlur={updateBlogTitle} data-id={index} >{text}</div>  */}
                                                <ContentEditable html={text} id={index} defaultValue={text} 
                                                onChange={updateBlogTitleData} className="cursor"/>

                                                <div className="selectBtn">
                                                 <Button variant="outline-primary float-right" onClick={selectBlogItem} size="sm" value={text} data-id={index}>Select</Button> 
                                                 </div>
                                                </div>
                                            </div>
                                        ))} 
                                        <div>
                                           <div> 
                                           {blogIdeaData.length > 2 && <Button variant="outline-primary" size="sm"  className="float-right" onClick={reGetBlogTitle}>Re-Generate Blog Title</Button> }
                                           </div> 
                                        </div>
                                        

                                  {loading && <div className="text-center mt-3">
                                  <p>Generating a blog titles from your idea</p> 
                                  <div className="mt-3"><Loader2 /> </div>
                                  </div> }

                                  </>
                                    }
                               
                                  {wizard == 'step3' && <div>
                                  <Form onSubmit={getBlogIntro} > 
                                    <Form.Group>
                                    {/* <Form.Label>Selected blog title</Form.Label>  */}
                                        <Form.Control as="textarea" placeholder="Add your blog title" style={{display:"none", height: '64px' }} name="blogTitle" className="mb-3"  defaultValue={blogPost && blogPost.title}/> 
                                    </Form.Group>
                                    {/* <Form.Group className="mt-3 mb-3">
                                        <Form.Label>Keywords</Form.Label>
                                        <Form.Control type="text" name="blogKeywords" placeholder="Example: Amazon affiliates" />
                                    </Form.Group>  */}
                                    {!blogIntroData.length && !loading && 
                                    <div className="wizard-placeholder">
                                    <p>Generate the blog post introduction</p>
                                    <Button variant="primary" className="mt-3 mb-3 mr-auto ml-auto" type="submit">
                                      Generate Intro
                                      </Button>
                                    </div>
                                    
                                    }                                     
                                    </Form> 
                                  
                                            {(blogIntroData.length > 1) && <p className="text-in">Select your blog introduction</p>}
                                            {(blogIntroData.length > 1) && blogIntroData.map((item, index) => (
                                            <div className="ai-section height-align text-center" key={index + 1}> 
                                                <div className="ai-content flexbox" > 
                                                {/* <div className="selectBtn">
                                                  <span>
                                                      <input type="radio" value={text} id={index + 1}
                                                    onChange={selectBlogIntro} name="blogIntro" />
                                                <label htmlFor={index + 1}> </label> 
                                                </span>
                                                 </div> */}
                                                    {/* <div className="text-box">{text}</div>  */}
                                                    <ContentEditable html={item.text} id={index} defaultValue={item.text} 
                                                onChange={updateBlogIntroData} className="cursor breakSpaces"/>
                                                    <div className="selectBtn">
                                                    <Button variant="outline-primary float-right" id={index} onClick={selectBlogIntro} size="sm" value={item.text} data-id={index}>Select</Button> 
                                                    </div>
                                                </div>
                                            </div> 
                                            ))}  
                                           {(blogIntroData.length > 1) && <div>
                                            <Button variant="outline-primary" size="sm" className="float-right" onClick={getBlogIntro}>More Intros</Button>
                                            </div>}
                                            {loading && <div className="text-center mt-3"> 
                                  <p>Generating blog post introduction</p>
                                  <div className="mt-3"><Loader2 /> </div> 
                                  </div> }
                                      </div>}  

                                      {wizard == 'step4' && <div> 
                                      {!blogOutline && !loading && <>
                                      <div className="wizard-placeholder">
                                      <p>Generate the blog post outline</p>
                                      <Button variant="primary" onClick={getBlogOutline} className="mt-3 mb-3 mr-auto ml-auto" type="submit"> Generate Outline  </Button> 
                                      </div>
                                     </>} 
                                     
                                            {blogOutline && <p className="text-in">Select your blog section headings</p>}
                                            {blogOutlineData && blogOutlineData.map((element, index) => (
                                               
                                            <div className="ai-section height-align text-center" key={index + 1}>
                                               <div className="ai-content flexbox" > 
                                                {/* <div className="text-box" >{element.item}</div>  */}
                                                <ContentEditable html={element.item} id={index} defaultValue={element.item} 
                                                onChange={updateBlogOutline} className="cursor"/>
                                               <i></i>
                                                <div className="selectBtn">
                                                  <span>
                                                  <input type="checkbox" value={element.item} id={index + 1} data-id={index} data-status={element.status} 
                                                    onChange={selectBlogOutline} name="blogOutline"/>
                                                  <label htmlFor={index + 1}> </label> 
                                                  </span>

                                                  <span>
                                                  {/* <Button variant="outline-secondary" size="sm" id={index} onClick={deleteBlogOutline}><RiDeleteBinLine/></Button> */}
                                                  </span>
                                                </div>
                                            </div>
                                            </div>
                                            ))}  

{loading && <div className="text-center mt-3"> 
                                  <p>Generating an excellent blog outline for your post</p> 
                                  <div className="mt-3"><Loader2 /> </div>
                                  </div> }
                                  {blogOutline && <div>
                                              <Button variant="outline-primary" className="float-left" size="sm" onClick={getBlogOutline} type="submit"> Re-Generate Outline  </Button>
                                              <Button variant="success" size="md" className="float-right" onClick={genSection} disabled={blogPost.sections.length < 1}>Generate full Content</Button>  
                                              </div>
                                            // <Form onSubmit={getBlogOutline} >  
                                            //     <Form.Control style={{display:"none", height: '64px' }} name="blogTitle" defaultValue={blogPost && blogPost.title} /> 
                                            //     <Button variant="outline-primary" size="sm" className="float-right" type="submit"> Re-Generate Outline  </Button> 
                                            // </Form>  
                                          }
                                      </div>} 

                                   
                                           

                                      {wizard == 'step5' && <div>  
                                      {loading && <div className="text-center mt-3">
                                  <p className="text-center mt-5"><strong>Generate full blog post content..</strong> <br/> 
                                  Please wait it may take up to few minutes. </p>
                                  <div className="mt-3"><Loader2 /> </div>
                                   </div> }
                                     {blogPost && !loading && 
                                            
                                    <div className="editor-main">  
                                    {projectLoading &&    <div className="mt-5 mx-auto"><Loader2 /> </div>   }  
                                              
                                    {/* Database */}
                                    {!projectLoading && projectData &&
                                      <>
                                      <div className="editor-main">                           
                                          <div className="blog-title">  
                                                <h1 className="input" role="textbox" >
                                                   <ContentEditable className="breakSpaces" html={projectData.title} defaultValue={projectData.title} onChange={handleBlogTitle} onBlur={updateBlogIntro}  />
                                                </h1>
                                          </div>
                                          <div className="blog-section blog-intro"> 
                                          {editIntro && <div className="contGenLoader"><Loader2 /></div> } 
                                          <ContentEditable className="editableContent breakSpaces" html={projectData.intro} onChange={handleBlogIntro} onBlur={updateBlogIntro} defaultValue={projectData.intro}/>  
                                          <div className="section-toolbox">
                                                  <div>
                                                    <Button variant="primary" className="w-100" size="sm" onClick={reGenerateNewIntro}>New Intro</Button>
                                                    <Button variant="outline-primary" className="w-100" size="sm" onClick={writeMoreIntro}>Write More</Button>
                                                     
                                                  </div>
                                            </div>
                                          </div>
                                          

                                          {projectSections && projectSections.map((project, index) => ( 
 
                                            <section className="blog-section" key={project.id}>
                                            <div className="section-heading">  
                                              <h2 className="input" role="textbox"> 
                                                <ContentEditable className="breakSpaces" html={project.sectionTitle} onChange={handleSecTitle} id={index} onBlur={updateBlogSection} defaultValue={project.sectionTitle} data-id={project.id} data-placeholder="Section title..."/></h2>
                                            </div>
                                            <div className={"section-content " + (`sec-` + index)}>  
                                                {contGen &&  activeSection == index && <div className="contGenLoader"><Loader2 /></div> }  
                                                <ContentEditable className="editableContent breakSpaces" html={project.sectionDescription} onChange={handleSecContent} onBlur={updateBlogSection} defaultValue={project.sectionDescription} data-id={project.id} id={index} data-placeholder="Section content..."/>  
                                                {/* <RichTextEditor changeValue={setValue} blurValue={blurValue} defaultContent={project.sectionDescription} onChange={handleSecContent} onBlur={updateBlogSection} tabIndex={project.id} data-id={project.id} id={index} />  */}
                                            </div> 
                                            <div className="section-toolbox">
                                                <div>
                                                <Button variant="primary" className="w-100" size="sm" name={index} onClick={reGenerate} disabled={(project.sectionTitle == '' || contGen)}>
                                                Start new</Button>
                                              <Button variant="outline-primary" className="w-100" size="sm" name={index} onClick={writeMore} disabled={(project.sectionDescription == '' || project.sectionTitle == '' || contGen)}>Write more</Button>
                                                </div>
                                            </div>
                                            <div className="section-controls"> 
                                                <Button variant="link" size="md" onClick={delSection} id={index} disabled={contGen}><VscTrash /></Button>
                                                <div><Button variant="link" size="md" id={index} onClick={moveUp} disabled={contGen}><VscChevronUp/></Button> 
                                                <Button variant="link" size="md" id={index} onClick={moveDown} disabled={contGen}><VscChevronDown/></Button></div>  
                                            </div>
                                            </section> 

                                          ))}
                                      </div>
                                      </>
                                    } 

                                  {!projectLoading && projectData &&
                                     <div className="addSection text-center">
                                        <Button variant="outline-primary mt-3 ml-auto mr-auto" size="sm" onClick={handleAddSection} disabled={contGen}>Add Section</Button>
                                      
                                       </div>           
                                    }   
                                     </div>  
                                     }  
                                       </div>}  
                                      {/* <div>{params.id}</div>
                                     <pre>Project ID: { JSON.stringify(params)}</pre>  */}
                                      <div>
                                     {/* <pre>{ JSON.stringify(projectData, undefined, 2)}</pre>
                                     <pre>{ JSON.stringify(projectSections, undefined, 2)}</pre>                                     */}
                                      {/* <pre>{ JSON.stringify(sectionData, undefined, 2)}</pre>   */}
                                     
                                     </div>  
                                   
                        </Card.Body>  
                    </Card>  
                  </Col>
              </Row> 
             
          </section>
        </div>
        </Container>
      <Toaster position="top-center" reverseOrder={false} />  
      
      {!loginState && 
      <Modal
        show={showModal}
        onHide={handleClose} 
        keyboard={false}
        className="upgradeModal"
      >  
        <Modal.Body className="text-center">
          {modalData && 
            <>
            {/* {modalData.title == "Upgrade your account" && <d iv className="upgradeIcon"><UpgradeIcon/></d> } */}
            {modalData.title != "Upgrade your account" && <>
              <h4>{modalData.title}</h4>
              <p>{modalData.discription}</p>            
              </>}
            {modalData.title == "Upgrade your account" &&  <InlinePricing/> }
            </>
          }
          <div className="text-center">
          {modalType == "newPost" &&  <>
           <Button variant="secondary" size="md" className="mr-4" onClick={handleClose}> No </Button>
          <Button variant="primary" size="md" onClick={startNewPost} data-id="newPost">Yes I Want</Button></>
          }
          {!modalData.action && modalData.title !== "Upgrade your account" &&
           <Button variant="primary" size="md" onClick={handleClose}>Ok</Button>
          } 

          {modalType == "startOver" && modalData.title !== "Upgrade your account" && <>
            <Button variant="secondary" size="md" className="mr-4" onClick={handleClose}> Cancel </Button>
            <Button variant="primary" size="md" onClick={regNewContent} data-id="newPost">Write new</Button></>
          } 

          {modalType == "startOverIntro" && modalData.title !== "Upgrade your account" && <>
            <Button variant="secondary" size="md" className="mr-4" onClick={handleClose}> Cancel </Button>
            <Button variant="primary" size="md" onClick={reGenerateIntro} data-id="newPost">Write new</Button></>
          }  
          
          {modalType == "deleteSection" && modalData.title !== "Upgrade your account" && <>
            <Button variant="secondary" size="md" className="mr-4" onClick={handleClose}> Cancel </Button>
            <Button variant="primary" size="md" onClick={deleteSection} data-id="newPost">Delete</Button></>
          }  
         </div>
        </Modal.Body> 
      </Modal> }
      
      {loginState &&
      <Modal show={showModal} onHide={handleClose} keyboard={false} className={loginState ? "loginState" : "upgradeModal"}> 
            <Modal.Body className="text-center">  
              <InlineLogin onLoginSuccess={handleLoginSuccess}/>  
            </Modal.Body> 
       </Modal> 
       } 
    </>
  );
};

export default BlogPost;



