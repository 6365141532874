const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({ apiKey: process.env.REACT_APP_OPENAI_API_KEY });
const openai = new OpenAIApi(configuration);

class GoogleAdAIServices {    
 

  generateGoogleAd = (formDataObj) => {
    return openai.createCompletion("text-davinci-002", {  
      prompt: `Generate 4 short and crisp "Google Ads" Copy Headlines from the notes below: each headline should be no more than 30 characters long.
      \nProduct Name: ${formDataObj.projectValue1}
      \nProduct Description: ${formDataObj.projectValue2} 
      \nAudience: ${formDataObj.projectValue3}
      \nLanguage:write in ${formDataObj.language} language
      \nTone of Voice:${formDataObj.tone}
      \nNote: get no more than 30 characters each`,
      temperature: 0.8,
      max_tokens: 100,
      top_p: 1.0,
      frequency_penalty: 0.0,
      presence_penalty: 0.0,  
      n: 2 //parseInt(`${formDataObj.variant}`) 
     }); 
   }

   generateGoogleAd2 = (formDataObj) => {
    return openai.createCompletion("text-davinci-002", {  
      prompt: `Generate 4 "Google Ads" Copy the description from the notes below: each description should be no more than 90 characters long.
      \nProduct Name: ${formDataObj.projectValue1}
      \nProduct Description: ${formDataObj.projectValue2} 
      \nAudience: ${formDataObj.projectValue3}
      \nLanguage:write in ${formDataObj.language} language
      \nTone of Voice:${formDataObj.tone}
      \nNote: get no more than 90 characters each`,
      temperature: 0.8,
      max_tokens: 100,
      top_p: 1.0,
      frequency_penalty: 0.0,
      presence_penalty: 0.0,  
      n: 2 //parseInt(`${formDataObj.variant}`) 
     });
   }

   generateMoreGoogleAd = (queryObj) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Generate Google Ad copies (Headline:, Description:) from the below notes:
      \nProduct Name: ${queryObj.projectValue1}\nProduct Description: ${queryObj.projectValue2}\nAudience: ${queryObj.projectValue3}\nNote:write in ${queryObj.language} language\nTone of Voice:${queryObj.tone}`, 
      temperature: 0.8,  
      max_tokens: 100,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,  
      n: 2  
     });
   }

   addMoreGoogleAd = (queryObj, pDes) => {
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Expand the Google Ad copies from the below notes:
      \nFacebook Ad: ${pDes}\nNote:write in ${queryObj.language} language\nTone of Voice:${queryObj.tone}`,
      temperature: 0.8,  
      max_tokens: 64,  
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      stop:["."]
     });
   }

}

export default new GoogleAdAIServices();

 