import {React, useState, useEffect, useContext} from "react";
import { Button, Card, Container} from "react-bootstrap"; 
import { Link } from "react-router-dom";
import { useUserAuth } from "../../../context/UserAuthContext";  
import toast, { Toaster } from 'react-hot-toast'; 
import { FcPaid } from "react-icons/fc";
import "./Tools.css"; 
import { YouTubeIcon, TwitterIcon, QuoraIcon, FacebookIcon, LinkedinIcon, AmazonIcon, GoogleAdIcon, CustomerReviewIcon, InstagramIcon, ProductReviewIcon, BlogIcon, BlogIdeaIcon, BlogTitleIcon, BlogIntroIcon, BlogOutlineIcon, BlogConclusionIcon, ParagraphIcon, ContentRewriteIcon, WebsiteHeadingIcon, WebsiteSubHeadingIcon, MetaTitleIcon, MetaDescriptionIcon, AboutUsIcon, FaqsIcon, CallToActionIcon, AidaIcon, EmailIcon, ParaphraseIcon, UniversalIcon, ArticleIcon, BlogSectionIcon, SentenceExpanderIcon, ProsAndConsIcon, ParagraphWriterIcon, ParaphraseIcon1, ParagraphToPointsIcon, CompanyVisionIcon, GrammarCheckerIcon, ValuePropositionIcon, NewsLettersIcon, NameGeneratorIcon, PressReleasesIcon, EssayIntrosIcon, EssayOutlinesIcon, FlexyTemplateIcon, TextSummarizerIcon, StoriesIcon, SocialMediaIcon, ImageCreationIcon, KeywordResearchIcon } from "../../../assets/svg/SvgIcons";
import SidebarPanel from "../sidebar/SidebarPanel"; 

const BlogContent = () => {
   
  return (
    <>  
      
      <Container className="main-layout">    
      <div className="header-txt"> 
                    <h2>Blog Content</h2> 
                   <p className="text-left"> 
                    Our AI tools are designed to help you generate high quality content quick and easily.</p>  
                    </div>      
            <div className=""> 
          <div className="card-grid-row mt-1">   
                 
                   <Card data-category="blog"> 
                     <Link to="/article-writer">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                             <ArticleIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Article Writer</Card.Title>
                            <Card.Text>
                            Write articles in seconds with the most advanced AI writing assistant. 
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>   
                
                   <Card data-category="blog"> 
                     <Link to="/blog-post">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                             <BlogIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Blog Post Writer</Card.Title>
                            <Card.Text>
                            Write a long-form blog post within a minutes using the most advanced AI.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>   
                  
                      <Card data-category="blog"> 
                     <Link to="/keyword-research">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon"> 
                             <ParagraphToPointsIcon/>
                             </div>
                             </div>
                              <Card.Body>  
                            <Card.Title>Keyword Research</Card.Title>
                            <Card.Text>
                            Get latest keyword research for improved SEO and increased traffic!
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                   
                   <Card data-category="blog"> 
                     <Link to="/blog-ideas">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <BlogIdeaIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Blog Ideas</Card.Title>
                            <Card.Text> 
                            Generate blog ideas using AI for unique and interesting Ideas.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
                    
                   <Card data-category="blog"> 
                     <Link to="/blog-title">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <BlogTitleIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Blog Title</Card.Title>
                            <Card.Text>
                            Write the SEO perfect blog title using AI from your inputs and ideas.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
                  
                   <Card data-category="blog"> 
                     <Link to="/blog-intro">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <BlogIntroIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Blog Intro</Card.Title>
                            <Card.Text>
                            Write the Blog Intro using AI to make a good impression on your readers.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>   
                    
                   <Card data-category="blog"> 
                     <Link to="/blog-outline">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <BlogOutlineIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Blog Outline</Card.Title>
                            <Card.Text>
                             Write an outline for a blog post using AI to engage your readers.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
 
                   <Card data-category="blog"> 
                     <Link to="/blog-section">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <ParagraphIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Blog Section</Card.Title>
                            <Card.Text>
                            Generate a blog section using blog sub-heading and title.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                     
                   <Card data-category="blog"> 
                     <Link to="/blog-conclusion">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <BlogConclusionIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Blog Conclusion</Card.Title>
                            <Card.Text>
                            Write a conclusion for your blog post using AI to wow your readers.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
                     
                   <Card data-category="blog"> 
                     <Link to="/article-rewriter">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <ContentRewriteIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Article Rewriter</Card.Title>
                            <Card.Text>
                            Re-write your existing article into a new version of article using AI.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
                   
                   <Card data-category="blog"> 
                     <Link to="/sentence-rewriter">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <ContentRewriteIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Sentence Rewriter</Card.Title>
                            <Card.Text>
                            Rewrite your existing sentence into a new version using AI.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
                   
                   <Card data-category="blog"> 
                     <Link to="/sentence-expander">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon"> 
                                <SentenceExpanderIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Sentence Expander</Card.Title>
                            <Card.Text>
                            Expand your existing sentence into a paragraph using AI.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
                     
                   <Card data-category="blog"> 
                     <Link to="/pros-cons">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon"> 
                                <ProsAndConsIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Pros & Cons</Card.Title>
                            <Card.Text>
                            Generate pros and cons for your product, service, website, and blog articles.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
                     
                   <Card data-category="blog"> 
                     <Link to="/paragraph-writer">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon"> 
                                <ParagraphWriterIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Paragraph Writer</Card.Title>
                            <Card.Text>
                            Quickly generate entire paragraphs of content on its own.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                     
                   <Card data-category="blog"> 
                     <Link to="/paragraph-points">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon"> 
                                <ParagraphToPointsIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Paragraph to Points</Card.Title>
                            <Card.Text>
                            Quickly generate entire paragraphs of content on its own.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                   
                   <Card data-category="more"> 
                     <Link to="/paraphrase">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">  
                                <ParaphraseIcon1/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Paraphrase</Card.Title>
                            <Card.Text>
                             Repharase the meaning of a text or passage using other words.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
                    
             </div>
          </div>
        </Container>
      
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default BlogContent;