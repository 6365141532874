import {React, useState, useEffect, useContext} from "react";
import { Button, Card, Container} from "react-bootstrap"; 
import { Link } from "react-router-dom";
import { useUserAuth } from "../../../context/UserAuthContext";  
import toast, { Toaster } from 'react-hot-toast'; 
import { FcPaid } from "react-icons/fc";
import "./Tools.css"; 
import { YouTubeIcon, TwitterIcon, QuoraIcon, FacebookIcon, LinkedinIcon, AmazonIcon, GoogleAdIcon, CustomerReviewIcon, InstagramIcon, ProductReviewIcon, BlogIcon, BlogIdeaIcon, BlogTitleIcon, BlogIntroIcon, BlogOutlineIcon, BlogConclusionIcon, ParagraphIcon, ContentRewriteIcon, WebsiteHeadingIcon, WebsiteSubHeadingIcon, MetaTitleIcon, MetaDescriptionIcon, AboutUsIcon, FaqsIcon, CallToActionIcon, AidaIcon, EmailIcon, ParaphraseIcon, UniversalIcon, ArticleIcon, BlogSectionIcon, SentenceExpanderIcon, ProsAndConsIcon, ParagraphWriterIcon, ParaphraseIcon1, ParagraphToPointsIcon, CompanyVisionIcon, GrammarCheckerIcon, ValuePropositionIcon, NewsLettersIcon, NameGeneratorIcon, PressReleasesIcon, EssayIntrosIcon, EssayOutlinesIcon, FlexyTemplateIcon, TextSummarizerIcon, StoriesIcon, SocialMediaIcon, ImageCreationIcon, KeywordResearchIcon } from "../../../assets/svg/SvgIcons";
import SidebarPanel from "../sidebar/SidebarPanel"; 

const WebsiteSEO = () => {
   
  return (
    <>  
      
      <Container className="main-layout">    
      <div className="header-txt"> 
                    <h2>Website & SEO</h2> 
                   <p className="text-left"> 
                    Our AI tools are designed to help you generate high quality content quick and easily.</p>  
                    </div>      
            <div className=""> 
          <div className="card-grid-row mt-1">    
                   <Card data-category="website"> 
                     <Link to="/website-headlines">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <WebsiteHeadingIcon /> 
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Website Headlines</Card.Title>
                            <Card.Text>
                            Write an SEO frendly website headlines using AI with more accurately.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>     
                   <Card data-category="website"> 
                     <Link to="/website-sub-headline">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <WebsiteSubHeadingIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Website Sub-Headline</Card.Title>
                            <Card.Text>
                            Write a website sub-headlines using AI in order to improve engagement.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                   <Card data-category="website"> 
                     <Link to="/meta-title">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <MetaTitleIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Meta Title</Card.Title>
                            <Card.Text> 
                            Generate SEO frendly Meta Title for your blog post and website using AI.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>      
                   <Card data-category="website"> 
                     <Link to="/meta-description">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <MetaDescriptionIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Meta Descriptions</Card.Title>
                            <Card.Text>
                            The use of AI in Meta descriptions can help to improve the quality of the page.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>      
                   <Card data-category="website"> 
                     <Link to="/faqs">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <FaqsIcon /> 
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>FAQs</Card.Title>
                            <Card.Text>
                            Generate a FAQ using AI from your product and services description.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>      
                   <Card data-category="website"> 
                     <Link to="/about-us">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <AboutUsIcon />
                                </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>About Us</Card.Title>
                            <Card.Text>
                            Write About Us page on our website can be easily completed using AI.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>     
                    <Card data-category="website"> 
                     <Link to="/company-vision">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon"> 
                                <CompanyVisionIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Company Vision</Card.Title>
                            <Card.Text>
                            Align your business values and goals into your vision statement.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="website"> 
                     <Link to="/company-mission">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <AidaIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Company Mission</Card.Title>
                            <Card.Text>
                            Convert your company core values into a clear mission statement.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                   <Card data-category="website"> 
                     <Link to="/call-to-action">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon"> 
                                <CallToActionIcon />
                                </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Call To Action</Card.Title>
                            <Card.Text>
                            Write the call to action using AI for more effective way to communicate.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>   
                    <Card data-category="website"> 
                     <Link to="/aida-content">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <AidaIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>AIDA Content</Card.Title>
                            <Card.Text> 
                            Generate a content with Attention, Interest, Desire and Action.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="website"> 
                     <Link to="/quest-formula">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <AidaIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>QUEST Formula</Card.Title>
                            <Card.Text> 
                            Generate a Qualify, Understand, Educate, Stimulate, and Transition copy.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="website"> 
                     <Link to="/pain-agitate-solution">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <AidaIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Pain Agitate Solution</Card.Title>
                            <Card.Text>
                            A copywriting formula to explain how to avoid pain to get gain.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>     
                    
             </div>
          </div>
        </Container>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default WebsiteSEO;