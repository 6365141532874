import {React, useState, useEffect, useContext} from "react";
import { Button, Card, Container} from "react-bootstrap"; 
import { Link } from "react-router-dom";
import { useUserAuth } from "../../../context/UserAuthContext";  
import toast, { Toaster } from 'react-hot-toast'; 
import { FcPaid } from "react-icons/fc";
import "./Tools.css"; 
import { YouTubeIcon, TwitterIcon, QuoraIcon, FacebookIcon, LinkedinIcon, AmazonIcon, GoogleAdIcon, CustomerReviewIcon, InstagramIcon, ProductReviewIcon, BlogIcon, BlogIdeaIcon, BlogTitleIcon, BlogIntroIcon, BlogOutlineIcon, BlogConclusionIcon, ParagraphIcon, ContentRewriteIcon, WebsiteHeadingIcon, WebsiteSubHeadingIcon, MetaTitleIcon, MetaDescriptionIcon, AboutUsIcon, FaqsIcon, CallToActionIcon, AidaIcon, EmailIcon, ParaphraseIcon, UniversalIcon, ArticleIcon, BlogSectionIcon, SentenceExpanderIcon, ProsAndConsIcon, ParagraphWriterIcon, ParaphraseIcon1, ParagraphToPointsIcon, CompanyVisionIcon, GrammarCheckerIcon, ValuePropositionIcon, NewsLettersIcon, NameGeneratorIcon, PressReleasesIcon, EssayIntrosIcon, EssayOutlinesIcon, FlexyTemplateIcon, TextSummarizerIcon, StoriesIcon, SocialMediaIcon, ImageCreationIcon, KeywordResearchIcon } from "../../../assets/svg/SvgIcons";
import SidebarPanel from "../sidebar/SidebarPanel"; 

const OtherTools = () => {
   
  return (
    <>  
      
      <Container className="main-layout">    
      <div className="header-txt"> 
                    <h2>Misc..</h2> 
                   <p className="text-left"> 
                    Our AI tools are designed to help you generate high quality content quick and easily.</p>  
                    </div>      
            <div className=""> 
          <div className="card-grid-row mt-1">   
                  
                   <Card data-category="more"> 
                     <Link to="/image-generator">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon"> 
                             <ImageCreationIcon/>
                             </div>
                             </div>
                              <Card.Body>  
                              <span className="newFeature">New</span>
                            <Card.Title>Image Generator</Card.Title>
                            <Card.Text>
                            Generate high-quality image for art, design, and advertising needs.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>    
                    <Card data-category="more"> 
                     <Link to="/cold-email">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <EmailIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Cold Email</Card.Title>
                            <Card.Text>
                            Generate an focused and attractive email to get more attention.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="more"> 
                     <Link to="/welcome-email">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <EmailIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Welcome Email</Card.Title>
                            <Card.Text>
                            Generate an impressive welcome email for your new customers.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="more"> 
                     <Link to="/cancellation-email">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <EmailIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Cancellation Email</Card.Title>
                            <Card.Text>
                            Generate an impactful email to re-engage inactive customers.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="more"> 
                     <Link to="/follow-up-email">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <EmailIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Follow-Up Email</Card.Title>
                            <Card.Text>
                            Generate unique and effective follow-up email draft.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="more"> 
                     <Link to="/email-subject-line">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <EmailIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Email Subject Line</Card.Title>
                            <Card.Text>
                            Generate exceptionally and impactful email subject lines.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="more"> 
                     <Link to="/thank-you-note">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <EmailIcon />
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Thank You Note</Card.Title>
                            <Card.Text>
                            Generate a professional thank you note for your personal or business.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="more"> 
                     <Link to="/grammar-checker">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon"> 
                               <GrammarCheckerIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Grammar Checker</Card.Title>
                            <Card.Text>
                            Instantly corrects grammar, spelling, and other writing errors in your text.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="more"> 
                     <Link to="/text-summarizer">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <TextSummarizerIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Text Summarizer (TL;DR)</Card.Title>
                            <Card.Text>
                            Summmarize the paragraph into short and easy to understand text.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="more"> 
                     <Link to="/story-creator">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <StoriesIcon/> 
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Story Creator</Card.Title>
                            <Card.Text>
                            Create your own creative story using the AI technology
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="ads"> 
                     <Link to="/value-proposition">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <ValuePropositionIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Value Proposition</Card.Title>
                            <Card.Text>
                            Create impressive one-line sentence to your target customers. 
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="ads"> 
                     <Link to="/newsletters">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <NewsLettersIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Newsletters</Card.Title>
                            <Card.Text>
                            Generate informative and engaging company Newsletter using AI assitance.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="ads"> 
                     <Link to="/name-generator">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <NameGeneratorIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Name Generator</Card.Title>
                            <Card.Text>
                            Generate creative, trendy and catchy names for every thing.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="ads"> 
                     <Link to="/press-releases">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <PressReleasesIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Press Releases</Card.Title>
                            <Card.Text>
                            Write a press release about your product or service announcments to media.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>  
                    <Card data-category="more"> 
                     <Link to="/essay-intros">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <EssayIntrosIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Essay Intros</Card.Title>
                            <Card.Text>
                             Write best informative introduction of your essay idea.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                    <Card data-category="more"> 
                     <Link to="/essay-outlines">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                                <EssayOutlinesIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Essay Outlines</Card.Title>
                            <Card.Text>
                            Generate the structure or talking points of your essay.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card> 
                   <Card data-category="blog"> 
                     <Link to="/flexy-template">
                       <div className="card-wrap">
                             <div className="card-icon">
                               <div className="svgIcon">
                               <FlexyTemplateIcon/>
                             </div>
                             </div>
                              <Card.Body> 
                            <Card.Title>Flexy Template</Card.Title>
                            <Card.Text>
                            Design your template to generate uniqe content using the most advanced AI.
                            </Card.Text> 
                           </Card.Body> 
                          </div>
                         </Link>
                    </Card>        
                    
             </div>
          </div>
        </Container>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default OtherTools;