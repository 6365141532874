const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({ apiKey: process.env.REACT_APP_OPENAI_API_KEY });
const openai = new OpenAIApi(configuration);

class WebsiteSubHeadlineAIServices {    

  generateWebsiteSubHeadline = (formDataObj) => {
    return openai.createCompletion("text-davinci-002", {   
      prompt: `Generate 6 website subheaders for ${formDataObj.blogAbout} 
      \nNote:write in ${formDataObj.language} language
      \nTone of Voice:${formDataObj.tone}
      \nYear: 2022`, 
      temperature: 0.85,
      max_tokens: 100,
      top_p: 1.0,
      frequency_penalty: 0.52,
      presence_penalty: 0.5,
      logprobs:1,
      stop: ["7."],
     });
   }

   generateMoreWebsiteSubHeadline = (queryObj) => { 
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Generate 6 website subheaders for ${queryObj.blogAbout} 
      \nNote:write in ${queryObj.language} language
      \nTone of Voice:${queryObj.tone}
      \nYear: 2022`, 
      temperature: 0.85,
      max_tokens: 100,
      top_p: 1.0,
      frequency_penalty: 0.52,
      presence_penalty: 0.5,
      logprobs:1,
      stop: ["7."],
     });
   } 
  
}

export default new WebsiteSubHeadlineAIServices();

 