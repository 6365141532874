const API_VERSION = 'v14.0'; // Replace with the API version you're using
const PIXEL_ID = '311318251435587'; // Replace with your Pixel ID
const TOKEN = 'EAAPCIls9QksBO4R4g5fBKKjj4kZAcEY0new4Oh6I12F9CoKeXFVAWIlD3N1beCVyqgxbgtA8VLxAwuadydXXFuA9iblMfZC5UBz5CytfA5yjZCcSlBG4VsMZAqVTd5BdQ8YlatySVBMwQP9JFZCi92ZCfeFnzjuEp3i3HKhVbxgmrUW6GQZCaEd0m4zTHAZAZBQZDZD'; // Replace with your Access Token

const sendEventToFacebook = async (eventData) => {
    const url = `https://graph.facebook.com/${API_VERSION}/${PIXEL_ID}/events?access_token=${TOKEN}`;
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ data: [eventData] })
    };

    try {
        const response = await fetch(url, options);
        const data = await response.json();
        console.log('Event sent successfully:', data);
    } catch (error) {
        console.error('Error sending event:', error);
    }
};

export default sendEventToFacebook;
