import {React, useState, useEffect, useContext, useRef} from "react";
import { getFunctions, httpsCallable } from "firebase/functions";  
import SidebarPanel from "../../dashboard/sidebar/SidebarPanel"; 
import Modal from 'react-bootstrap/Modal'; 
import toast, { Toaster } from 'react-hot-toast';
import { BlogIdeaIcon, SideArrow, UpgradeIcon  } from "../../../assets/svg/SvgIcons"; 
import { Button, Card, Form, FormControl, FormGroup, FormLabel,Table, ProgressBar, Container, Row, Col } from "react-bootstrap";
import Loader from "../../shared/utilities/loader/Loader";
import Loader2 from "../../shared/utilities/loader2/Loader2";
import CommonDataServices from "../../../services/common.services";

import { useNavigate } from "react-router";  
import { useUserAuth } from "../../../context/UserAuthContext";
import { SubscriberContext } from "../../../context/subscriberContext";
import UsageBadge from "../../shared/utilities/usage-badge/UsageBadge";
import "./KeywordAnalysis.css"; 


const KeywordAnalysis = () => {
    const { user } = useUserAuth();   
    const navigate = useNavigate();  

    const [keywords, setKeywords] = useState("");
    const [country, setCountry] = useState("us");
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const functions = getFunctions(); 

    const {subscriber, setSubscriber} = useContext(SubscriberContext);
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);

    const updateSubscriber = async () =>{
      const subData = await CommonDataServices.getUsageLimit(user.uid);
      if(subData.data()){
        //console.log(subData.data())
        setSubscriber(subData.data())
      }
    }

    const handleChangeKeywords = (event) => {
        setKeywords(event.target.value);
    };

    const handleChangeCountry = (event) => {
        setCountry(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const currentDate = Math.floor(Date.now() / 1000) 
        if(subscriber.totalWords >= subscriber.wordsLimit || subscriber.premiumState === false || currentDate > subscriber.planEndDate){
              setShowModal(true)
              return;
             }
        setLoading(true);

        try {
            const getKeywordAnalysisData = httpsCallable(functions, "getKeywordAnalysisData"); 
            const response = await getKeywordAnalysisData({ keywords: keywords.split(","), country });
            //console.log(response.data)
            const resdata = response.data;
            setResults(JSON.parse(resdata).data);
            console.log(JSON.parse(resdata).data)

            let imgCredits = 30; 
            CommonDataServices.imageUsageTrackingData(user.uid, imgCredits).then(() => {
              updateSubscriber();
            });

        } catch (error) {
            console.error("Error fetching keyword data:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
      <>
      <Container className="columns justify"> 
      
      <div className="main-layout"> 
      <Container>
      <div className="header-txt"><h2>Keyword Analysis</h2><p className="text-center">
        {/* <strong>Get volume, CPC, competition, trends for a set of keywords.</strong><br/> */}
        Maximize Your Website's Performance with Strategic Keyword Analysis!</p></div> 
      <Row className="mt-3 mb-5">
        <Col>
          <Form onSubmit={handleSubmit}>
            <Row className="justify-content-md-center">
              <Col className="col col-lg-5">
                <FormLabel>Keywords (comma-separated)</FormLabel>
                <FormGroup>
                    <FormControl
                        type="text"
                        value={keywords}
                        onChange={handleChangeKeywords}
                        placeholder="Eg: green tea benefits, black tea benefits, coffee benefits"
                    />
                </FormGroup>
              </Col>
              <Col className="col col-lg-2">
                <Form.Label>Country</Form.Label>
                <FormControl
                  as="select"
                  value={country}
                  onChange={handleChangeCountry}
                >
                  <option value="">Select country</option>
                  <option value=" ">Global</option>
                  <option value="us">United States</option>
                  <option value="gb">United Kingdom</option>
                  <option value="ca">India</option>
                  <option value="au">Australia</option>
                  <option value="ca">Canada</option>
                  <option value="nz">New Zealand</option>
                  <option value="za">South Africa</option>
                </FormControl> 
              </Col>
              <Col className="col-md-auto d-flex align-items-end">
                <Button variant="primary" type="submit" disabled={!keywords}>
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

        <div> 
            {loading && <p>
              <Loader/>
              </p>} 
              {Array.isArray(results) && results.length > 0 && !loading &&
            <Table striped bordered hover>
      <thead>
        <tr>
          <th>Keywords</th>
          <th>Volume</th>
          <th>CPC</th>
          <th>Competition</th>
          <th>Trends</th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(results) && results.map((result, index) => (
          <tr key={index}> 
            <td>{result.keyword}</td>
            <td>{result.vol}</td>
            <td>{result.cpc.currency}{result.cpc.value}</td>
            <td>{result.competition.toFixed(2)}</td>
             <td>
              {result.trend.slice(-5).map((trendData, idx) => (
                <div key={idx} style={{ marginBottom: '0px', display: 'flex' }}>
                  <span style={{flexGrow: "1", fontSize:"11px"  }}>
                    {trendData.month} {trendData.year}:
                  </span>
                  <ProgressBar
                    className="trendBar"
                    now={trendData.value}
                    max={Math.max(...result.trend.map(t => t.value))}
                    label={trendData.value}
                    style={{ width: '50%', marginLeft: '8px', fontSize:"11px" }}
                  />
                </div>
              ))}
            </td> 
          </tr>
        ))}
      </tbody>
      </Table>
      }
        </div>
        </Container>
        </div>
      </Container>

<Toaster position="top-center" reverseOrder={false} />
<Modal show={showModal} onHide={handleClose} keyboard={false} className="upgradeModal">
  <Modal.Body className="text-center">
       <div className="upgradeIcon"><UpgradeIcon/></div>                   
       <h4>Upgrade your account</h4>
       <p>You have either exceeded the word limit or your plan has expired. Please upgrade your account.</p>
       <Button variant="primary" size="md" onClick={()=> navigate(`/pricing`)}>Upgrade</Button>
  </Modal.Body>
</Modal>
</>
    );
};

export default KeywordAnalysis;
