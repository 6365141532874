const SocialMediaToolsData = { 
    allInOneSocialPost: {
        prompt: "1501",
        icon: "🌐",
        title: "All in one Social Post",
        category: "social media",
        caption: "Efficiently design posts for various platforms like Facebook and YouTube, ensuring adherence to character limits, with appropriate hashtags, emojis, and keywords.",
        inputs: [{ inputTitle: `What is your post about?`, inputValue: '', inputCaption: 'Describe the main idea or message of your post', type: "textarea", inputExample: `Tips for Sustainable Living` }]
    },
    instagramCaption: {
        prompt: "1506",
        icon: "📝",
        title: "Instagram Caption",
        category: "social media",
        caption: "Enhance your Instagram presence with optimized hashtags for greater visibility and engagement.",
        inputs: [{ inputTitle: `What is your post about?`, inputValue: '', inputCaption: 'Describe the content or theme of your Instagram post', type: "textarea", inputExample: `Exploring the beauty of the Pacific Northwest` }]
    },
    instagramCarousel: {
        prompt: "1505",
        icon: "📷",
        title: "Instagram Carousel",
        category: "social media",
        caption: "Create compelling Instagram carousel content effortlessly, from brainstorming ideas to crafting the perfect caption.",
        inputs: [
            { inputTitle: `What is your post about?`, inputValue: '', inputCaption: 'Briefly describe the theme or topic for your Instagram carousel', type: "textarea", inputExample: `The Journey of Coffee: From Bean to Cup` },
            { inputTitle: `No. of slides required?`, inputValue: '', inputCaption: 'Select the number of slides you need for your carousel', type: "select", options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'], inputExample: `5` }
        ]
    }, 
    facebookPost: {
        prompt: "1504",
        icon: "📘",
        title: "Facebook Post",
        category: "social media",
        caption: "Utilize our AI tool to create engaging Facebook posts, adhering to the 4A writing formula for better audience engagement.",
        inputs: [{ inputTitle: `What is your post about?`, inputValue: '', inputCaption: 'Describe the content or topic of your Facebook post', type: "textarea", inputExample: `Our Community Garden Project` }]
    },
    linkedInPost: {
        prompt: "1507",
        icon: "🔗",
        title: "LinkedIn Post",
        category: "social media",
        caption: "Elevate your LinkedIn presence with concise, impactful posts on diverse topics, ensuring professionalism and originality.",
        inputs: [{ inputTitle: `What is your post about?`, inputValue: '', inputCaption: 'Briefly describe the topic or main idea of your LinkedIn post', type: "textarea", inputExample: `The Future of Remote Work` }]
    },
    pinterestPin: {
        prompt: "1508",
        icon: "📌",
        title: "Pinterest Pin",
        category: "social media",
        caption: "Enhance your Pinterest presence with engaging, creative titles, captions, and relevant hashtags.",
        inputs: [{ inputTitle: `What is your post about?`, inputValue: '', inputCaption: 'Explain the subject or focus of your Pinterest pin', type: "textarea", inputExample: `DIY Home Decor Ideas` }]
    },
    instagramHashtagGenerator: {
        prompt: "1509",
        icon: "⭐",
        title: "Instagram Hashtag Generator",
        category: "social media",
        caption: "Elevate your Instagram content with finely-tuned hashtags for enhanced visibility and engagement.",
        inputs: [
            { inputTitle: `What is your Profile About?`, inputValue: '', inputCaption: 'Describe the overall theme of your Instagram profile (e.g., E-Learning, Travelling)', inputExample: `Travel Photography` },
            { inputTitle: `Title of the Post`, inputValue: '', inputCaption: 'Provide the title or main idea of your Instagram post (e.g., Black Friday 60% Offer)', inputExample: `Sunset Views Around the World` },
            { inputTitle: `What is your Profile About?`, inputValue: '', inputCaption: 'Elaborate on the content or theme of your profile', type: "textarea", inputExample: `Capturing and sharing unique sunset views from different countries.` }
        ]
    },
    scriptForTikTokVideo: {
        prompt: "1511",
        icon: "🎥",
        title: "Script for TikTok Video",
        category: "social media",
        caption: "Generate dynamic TikTok video scripts to boost engagement, tailored to your video's title and key points.",
        inputs: [
            {
                inputTitle: "What is the main theme or topic of your TikTok video?",
                inputValue: '', inputCaption: "Enter the main theme or topic here.", inputExample: `5 Quick Yoga Poses for Stress Relief`
            },
            {
                inputTitle: "What are the key talking points or topics that need to be included in your video?",
                inputValue: '', inputCaption: "List your key talking points or topics.", inputExample: `Breathing techniques, easy-to-follow poses, benefits of each pose`, type: "textarea", required: false
            },
            {
                inputTitle: "Who is your target audience for your video?",
                inputValue: '', inputCaption: "Specify your target audience.", inputExample: `People looking for quick stress relief methods`, required: false
            }
        ]
    },
    scriptForInstagramReel: {
        prompt: "1512",
        icon: "🎞️",
        title: "Script for Instagram Reel",
        category: "social media",
        caption: "Craft detailed and captivating scripts for Instagram Reels, personalized for your content.",
        inputs: [
            {
                inputTitle: "What is the topic or title of your Instagram Reel?",
                inputValue: '', inputCaption: "Enter the topic or title of your Reel.", inputExample: `Easy Vegan Lunch Recipes`
            },
            {
                inputTitle: "What are the key talking points or topics that need to be included in your video?",
                inputValue: '', inputCaption: "Include key talking points or topics for your Reel.", inputExample: `Ingredients list, step-by-step preparation, nutritional benefits`, type: "textarea", required: false
            },
            {
                inputTitle: "Who is your target audience for your video?",
                inputValue: '', inputCaption: "Identify the target audience for your Reel.", inputExample: `Vegan food enthusiasts and busy professionals`, required: false
            }
        ]
    },
    twitterPost: {
        prompt: "1513",
        icon: "🐦",
        title: "Twitter Post",
        category: "social media",
        caption: "Generate tweets that resonate with your audience, tailored to your topic and preferred style.",
        inputs: [
            {
                inputTitle: "What is the topic you wish the tweet to be about?",
                inputValue: '', inputCaption: "Specify the topic of your tweet.", inputExample: `Sustainable Fashion Tips`
            },
            {
                inputTitle: "What mood do you want to convey in the tweet?",
                inputValue: '', inputCaption: "Choose the mood for your tweet.", inputExample: `Inspirational`, type: "select", options: ['Informative', 'Humorous', 'Inspirational', 'Thought-Provoking', 'Conversational', 'Formal', 'Casual', 'Motivational', 'Sarcastic', 'Nostalgic', 'Playful', 'Poetic', 'Satirical', 'Passionate', 'Uplifting', 'Empowering', 'Supportive', 'Mysterious', 'Challenging', 'Celebratory', 'Romantic', 'Heartfelt', 'Curious', 'Bold', 'Adventurous']
            },
            {
                inputTitle: "Who is your Target Audience?",
                inputValue: '', inputCaption: "Define your target audience for the tweet.", inputExample: `Eco-conscious individuals looking for fashion advice`, required: false
            }
        ]
    },
    hookGenerator: {
        prompt: "1514",
        icon: "🪝",
        title: "Hook Generator",
        category: "social media",
        caption: "Create compelling hooks for various content types, based on your main topic and theme.",
        inputs: [
            {
                inputTitle: "What is the Main Topic or Theme of your content?",
                inputValue: '', inputCaption: "Describe the main topic or theme.", inputExample: `Overcoming Procrastination`
            },
            {
                inputTitle: "What type of content are you creating a hook for?",
                inputValue: '', inputCaption: "Select the content type for your hook.", inputExample: `Motivational`, type: "select", options: ['Informative', 'Humorous', 'Inspirational', 'Thought-Provoking', 'Conversational', 'Formal', 'Casual', 'Motivational', 'Sarcastic', 'Nostalgic', 'Playful', 'Poetic', 'Satirical', 'Passionate', 'Uplifting', 'Empowering', 'Supportive', 'Mysterious', 'Challenging', 'Celebratory', 'Romantic', 'Heartfelt', 'Curious', 'Bold', 'Adventurous']
            },
            {
                inputTitle: "Who is your Target Audience?",
                inputValue: '', inputCaption: "Identify your target audience.", inputExample: `Young adults struggling with time management`, required: false
            }
        ]
    },
    motivationalPosts: {
        prompt: "1515",
        icon: "💪",
        title: "Motivational Posts",
        category: "social media",
        caption: "Produce inspiring and engaging content for your motivational posts with ease.",
        inputs: [
            {
                inputTitle: "What is the Main Topic or Theme for this motivational post?",
                inputValue: '', inputCaption: "Enter the main topic or theme.", inputExample: `Chasing Your Dreams`
            },
            {
                inputTitle: "Who is your Target Audience?",
                inputValue: '', inputCaption: "Specify your target audience.", inputExample: `Aspiring entrepreneurs`, required: false
            }
        ]
    },
    tikTokCaption: {
        prompt: "1516",
        icon: "🎵",
        title: "TikTok Caption",
        category: "social media",
        caption: "Craft engaging captions for TikTok that perfectly encapsulate your video's theme and boost interaction.",
        inputs: [
            {
                inputTitle: "What is the main theme or topic of your TikTok video?",
                inputValue: '', inputCaption: "Describe the theme or topic of your video.", inputExample: `DIY Home Organization Hacks`
            }
        ]
    },
    instagramThreadsGenerator: {
        prompt: "1517",
        icon: "🧵",
        title: "Instagram Threads Generator",
        category: "social media",
        caption: "Effortlessly create captivating Instagram Threads that engage your audience.",
        inputs: [
            {
                inputTitle: "Can you share what the Instagram Thread is about?",
                inputValue: '', inputCaption: "Briefly describe your Instagram Thread.", inputExample: `The Impact of Mindfulness on Daily Life`
            },
            {
                inputTitle: "Who is your Target Audience?",
                inputValue: '', inputCaption: "Define the target audience for your thread.", inputExample: `Individuals interested in personal development and mental health`, required: false
            }
        ]
    },
    socialMediaBioGenerator: {
        prompt: "1522",
        icon: "👤",
        title: "Social Media Bio Generator",
        category: "social media",
        caption: "Create engaging and impactful bios for your social media profiles.",
        inputs: [
            { inputTitle: `Personal or Brand Profile`, inputCaption: `Is this bio for a personal or brand profile?`, type: 'text', inputExample: `Brand Profile` },
            { inputTitle: `Key Interests or Focus Areas`, inputCaption: `What are the key interests or focus areas to highlight in the bio?`, type: 'textarea', inputExample: `Sustainable Fashion, Eco-Friendly Practices` },
            { inputTitle: `Tone of the Bio`, inputCaption: `What tone should the bio have? (e.g., professional, casual, humorous)`, type: 'text', inputExample: `Inspirational and Educational` }
        ]
    },
    instagramBioGenerator: {
        prompt: "1523",
        icon: "📸",
        title: "Instagram Bio Generator",
        category: "social media",
        caption: "Craft captivating and memorable bios specifically for Instagram profiles.",
        inputs: [
            { inputTitle: `Profile Type (Personal/Brand)`, inputCaption: `Is this bio for a personal profile or a brand?`, type: 'text', inputExample: `Personal Profile` },
            { inputTitle: `Main Themes or Interests`, inputCaption: `Describe the main themes or interests for the Instagram bio.`, type: 'textarea', inputExample: `Travel Adventures, Cultural Experiences` },
            { inputTitle: `Preferred Style`, inputCaption: `Specify the preferred style (e.g., artistic, minimalistic, quirky).`, type: 'text', inputExample: `Adventurous and Storytelling` }
        ]
    },
    instagramNameGenerator: {
        prompt: "1524",
        icon: "🏷️",
        title: "Instagram Name Generator",
        category: "social media",
        caption: "Generate unique and appealing Instagram usernames.",
        inputs: [
            { inputTitle: `Primary Interests or Niche`, inputCaption: `What are your primary interests or niche?`, type: 'text', inputExample: `Fitness and Wellness` },
            { inputTitle: `Desired Keywords`, inputCaption: `Include any specific keywords to be used in the username.`, type: 'text', required: 'false', inputExample: `Yoga, Mindfulness` }
        ]
    },
    tikTokUsernameGenerator: {
        prompt: "1525",
        icon: "🎵",
        title: "TikTok Username Generator",
        category: "social media",
        caption: "Create catchy and memorable usernames for TikTok.",
        inputs: [
            { inputTitle: `Personal Style or Interests`, inputCaption: `Describe your personal style or interests.`, type: 'text', inputExample: `Urban Streetwear, Graffiti Art` },
            { inputTitle: `Unique Traits or Characteristics`, inputCaption: `List any unique traits or characteristics to include in the username.`, type: 'text', inputExample: `Bold, Creative` }
        ]
    },
    repliesForInstagram: {
        prompt: "1518",
        icon: "💬",
        title: "Replies for Instagram",
        category: "social media",
        caption: "Streamline your Instagram engagement by crafting personalized responses to comments.",
        inputs: [
            {
                inputTitle: "Please share the message or comment you received?",
                inputValue: '', inputCaption: "Paste the message or comment here.", inputExample: `I love your products! When is the next release?`,
                type: 'textarea'
            },
            {
                inputTitle: "Context of the conversation",
                inputValue: '', inputCaption: "Provide additional context if any.", inputExample: `Comment on a post about our upcoming product line`, required: false
            }
        ]
    },
    repliesForLinkedIn: {
        prompt: "1519",
        icon: "💼",
        title: "Replies for LinkedIn",
        category: "social media",
        caption: "Efficiently create custom replies to LinkedIn messages and comments, enhancing interaction.",
        inputs: [
            {
                inputTitle: "Please share the message or comment you received?",
                inputValue: '', inputCaption: "Paste the message or comment here.", inputExample: `Can you tell me more about your services?`,
                type: 'textarea'
            },
            {
                inputTitle: "Context of the conversation",
                inputValue: '', inputCaption: "Provide additional context if any.", inputExample: `Inquiry received after a post about our consulting services`, required: false
            }
        ]
    },
    repliesForTwitter: {
        prompt: "1520",
        icon: "🐤",
        title: "Replies for Twitter",
        category: "social media",
        caption: "Generate unique, tailored responses to Twitter interactions, enhancing your online conversation.",
        inputs: [
            {
                inputTitle: "Please share the message or comment you received?",
                inputValue: '', inputCaption: "Paste the message or comment here.", inputExample: `How does your app compare to others in the market?`,
                type: 'textarea'
            },
            {
                inputTitle: "Context of the conversation",
                inputValue: '', inputCaption: "Provide additional context if any.", inputExample: `Question asked during a Twitter Q&A session about our new app`, required: false
            }
        ]
    },
    repliesForFacebook: {
        prompt: "1521",
        icon: "🗨️",
        title: "Replies for Facebook",
        category: "social media",
        caption: "Create tailored responses for Facebook interactions, streamlining your social media engagement.",
        inputs: [
            {
                inputTitle: "Please share the message or comment you received?",
                inputValue: '', inputCaption: "Paste the message or comment here.", inputExample: `I'm interested in volunteering. What are the next steps?`,
                type: 'textarea'
            },
            {
                inputTitle: "Context of the conversation",
                inputValue: '', inputCaption: "Provide additional context if any.", inputExample: `Response to a call for volunteers on our latest community project`, required: false
            }
        ]
    },
    hiddenFactFinder: {
        prompt: "1502",
        icon: "🔍",
        title: "Hidden Fact Finder",
        category: "social media",
        caption: "Discover astonishing facts in your preferred language on any topic, presented in a randomized sequence.",
        inputs: [
            { inputTitle: `Enter your keyword here to get a list of hidden facts.`, inputValue: '', inputCaption: 'Type a keyword to generate facts about (e.g., space, nutrition)', inputExample: `Ocean Conservation` }
        ]
    },
}
export default SocialMediaToolsData;
