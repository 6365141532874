const YoutubeToolsData = {  
    YoutubeIdeaContentPlanner: {
        prompt: "1901",
        icon: "💡",
        title: "Youtube Idea & Content Planner",
        category: "youtube",
        caption: "Generate captivating YouTube video concepts, detailed outlines, and key discussion points, customized to fit your unique niche.",
        inputs: [{ inputTitle: 'What specific topic do you want your YouTube videos to focus on?', inputCaption: 'Enter a topic for your videos, such as Finance, Fitness, Cooking, Travel, etc.', inputExample: `Sustainable Living Tips` }]
    },    
    titleThumbnailIdeas: {
        prompt: "1902",
        icon: "🎬", 
        title: "Youtube Title & Thumbnail Ideas",
        category: "youtube",
        caption: "Leverage AI to craft eye-catching YouTube video titles and thumbnail designs for enhanced viewer engagement.",
        inputs: [{ inputTitle: 'Enter your keyword(s)', inputCaption: 'Type keywords or phrases related to your video content (e.g., Healthy Recipes, Budget Travel Tips)', type: 'textarea', inputExample: `Quick and Healthy Breakfast Recipes` }]
    },       
    scriptforYouTubeVideo: {
        prompt: "1510",
        icon: "🎬",
        title: "Script for YouTube Video",
        category: "youtube",
        caption: "Create engaging YouTube scripts that captivate your audience, based on your video title and key points.",
        inputs: [
            { inputTitle: `What is your YouTube Video title?`, inputCaption: 'Enter the title of your YouTube video (e.g., 10 Ways to Increase Instagram Users)', inputExample: `10 Easy Yoga Poses for Beginners` },
            { inputTitle: `What are the key talking points or topics that need to be included in your video?`, inputCaption: 'List the main points or subjects to cover in the video (e.g., Product description and its key features)', type: "textarea", required: 'false', inputExample: `Introduction to Yoga, Benefits of Yoga, Step-by-step guide to each pose, Tips for beginners` },
            { inputTitle: `Who is your target audience for your video?`, inputCaption: 'Describe the target audience for your video', required: 'false', inputExample: `Beginners looking to start their yoga journey` }
        ]
    }, 
    youtubeDescriptionTags: {
        prompt: "1904",
        icon: "📝",
        title: "Youtube Description & Tags",
        category: "youtube",
        caption: "Develop SEO-optimized YouTube titles, craft 800-character descriptions, and formulate 17 keyword tags and 8 hashtags in your chosen language.",
        inputs: [{ inputTitle: 'Enter your video title & the points you want to cover?', inputCaption: 'Write your video title and detail the topics or messages to include in the video description.', type: 'textarea', inputExample: `The Ultimate Guide to Solo Travel: Tips, Destinations, and How to Make the Most of Your Trip` }]
    },        
    timestamps: {
        prompt: "1905",
        icon: "⏱️", 
        title: "Timestamps",
        category: "youtube",
        caption: "Easily identify and timestamp key moments in your video transcripts with this efficient tool.",
        inputs: [{ inputTitle: 'Enter your video script with timestamps.', inputCaption: 'Paste your video script here and mark timestamps for key points or sections.', type: 'textarea', inputExample: `00:00 Intro, 01:30 Benefits of Solo Travel, 03:00 Top Solo Travel Destinations, 05:00 Solo Travel Tips` }]
    },         
    shortsRealsCreator: {
        prompt: "1906",
        icon: "🎥",
        title: "Shorts & Reels Creator",
        category: "youtube",
        caption: "Simplify creating YouTube Shorts and Reels by highlighting essential points from your video content for a powerful impact.",
        inputs: [{ inputTitle: `Enter your video's title or key points.`, inputCaption: 'Type the title of your video or list the main points to focus on for Shorts or Reels.', type: 'textarea', inputExample: `5 Quick Exercises for a Busy Day` }]
    },        
    explainerVideoScript: {
        prompt: "1907",
        icon: "🔍",
        title: "Explainer Video Script",
        category: "youtube",
        caption: "Our AI tool simplifies writing explainer video scripts, ensuring they resonate with your audience, showcase your solution, and encourage action.",
        inputs: [
            { inputTitle: `Describe your Product / Service`, inputCaption: 'Describe what you\'re promoting, your target audience, and the key benefits of your product/service.', type: 'textarea', inputExample: `Our app helps busy professionals track their health and fitness goals with personalized plans.` },
            { inputTitle: `Enter Minimum words`, inputCaption: 'Specify the minimum word count for the script.', inputExample: `300` },
            { inputTitle: `Enter Maximum words`, inputCaption: 'Specify the maximum word count for the script.', inputExample: `500` }
        ]
    }, 
    youtubeTagGenerator: {
        prompt: "1908",
        icon: "🏷️",
        title: "Youtube Tag Generator",
        category: "youtube",
        caption: "Optimize your YouTube Shorts with SEO-friendly tags in various languages, generated by our AI.",
        inputs: [{ inputTitle: `Enter your video's title or key points.`, inputCaption: 'Write the title of your video or key topics to generate relevant tags.', type: 'textarea', inputExample: `DIY Home Decor Ideas on a Budget` }]
    }
}
export default YoutubeToolsData;
