import {React, useState, useEffect, useContext, useRef} from "react";
import { Button, ProgressBar } from "react-bootstrap"; 
import { useNavigate } from "react-router";
import { InfoIcon } from "../../../../assets/svg/SvgIcons";
import { SubscriberContext } from "../../../../context/subscriberContext";  
import "./UsageBadge.css"; 

const UsageBadge = () => {  
  const navigate = useNavigate();
  const { subscriber, setSubscriber } = useContext(SubscriberContext); 
  const [usagePercentage, setUsagePercentage] = useState(0); 
  useEffect(() => { 
    if(subscriber){
      const now = (subscriber.totalWords / subscriber.wordsLimit) * 100; 
      setUsagePercentage(Math.round(now))
    } 
  }); 
  return (
    <>  
    {subscriber && (subscriber.wordsLimit < 380000) && 
      <div className="planStatus">
          <div>
          <div><strong>{subscriber.planType} {subscriber.planType == "free" && <>plan</>} </strong> </div>
          <div><ProgressBar now={usagePercentage} /> </div>  
          <div className="labelTxt"><strong> {subscriber.totalWords} </strong> 
          of {subscriber.wordsLimit} words used</div>
          </div>
          {(subscriber.planType == "free" || subscriber.totalWords > subscriber.wordsLimit) &&
          <div>
            <Button variant="outline-primary" size="sm" onClick={()=> navigate(`/pricing`)}>Upgrade</Button>
          </div>
          } 
      </div>
      } 
    </>
  );
};

export default UsageBadge;


// <div className="infoTooltip"><InfoIcon/> 
// <span className="tooltiptext">Content generated automatically using AI engine. Please excuse irrelevant content and reach out to us for any issues.</span>
// </div>