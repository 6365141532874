import React, { useState, useEffect, useRef, useContext } from "react";
import { useQuill } from "react-quilljs"; 
import './Chat.css'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { useUserAuth } from "../../../context/UserAuthContext";
import { useNavigate } from "react-router";  
import { useParams, Link } from "react-router-dom";
import { SubscriberContext } from "../../../context/subscriberContext";   
import { RiFileCopy2Line, RiSaveLine} from "react-icons/ri"; 
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap"; 
import { ChatIcon, CopyTextIcon, PaperPlaneIcon, RewriteIcon, SideArrow, ExpandIcon} from "../../../assets/svg/SvgIcons"; 
import CommonDataServices from "../../../services/common.services";
import ChatDataService from "./services/Chat.services";  
import Modal from 'react-bootstrap/Modal';
import toast, { Toaster } from 'react-hot-toast'; 
import { useLocation } from 'react-router-dom'; 
import InlinePricing from '../../views/InlinePricing';
import Loader2 from "../../shared/utilities/loader2/Loader2";

const API_KEY = "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";
// "Explain things like you would to a 10 year old learning how to code."
const systemMessage = { //  Explain things like you're talking to a software professional with 5 years of experience.
  "role": "system", 
  "content": "Explain things like you're talking to a 20 year old person."
}

function Chat() {
  const { user } = useUserAuth();
  const [loading, setLoading] = useState(false);  
  const params = useParams();

  const [data, setData] = useState([]);
  const [queryObj, setQueryObj] = useState(null);
  const [mainParam, setMainParam] = useState(null); 
  const [projectId, setProjectId] = useState(null);
  const [files, setFiles] = useState(1);
  const [editMode, setEditMode] = useState(false)  
  const [tockenWords, setTockenWords] = useState(1); 
  const [formValue, setFormValue] = useState({name:'', benefits:''});
  const [textLength, setTextLength] = useState(0);
  const {subscriber, setSubscriber} = useContext(SubscriberContext);
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true); 
  const handleClose = () => setShowModal(false);

  const navigate = useNavigate();  


  const theme = 'snow';
  var toolbarOptions = [    
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }], 
    ['bold', 'italic', 'underline', 'strike'],            
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],      
    [{ 'indent': '-1'}, { 'indent': '+1' }],   
    [{ 'align': [] }], 
    ['clean']                                      
  ];
  const modules = { 
    toolbar: toolbarOptions 
  };
 
  const placeholder = '';
  const formats = [
    'header',
    'align',
    'bold', 'italic', 'underline', 
    'list', 'bullet',
    'indent', 'indent',
    'strike','clean'
];
  const { quill, quillRef } = useQuill({ theme, modules, formats, placeholder }); 

  // const [messages, setMessages] = useState([
  //   {
  //     message: "", //Hello, I'm SmartChat! Ask me anything!
  //     sentTime: "just now",
  //     sender: "ChatGPT"
  //   }
  // ]);
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);

  const getProjectContent = async (prjID) => {
    setLoading(true)
    setEditMode(false) 
    setProjectId(prjID)
    if(prjID){   
        const getProjectDetails = await ChatDataService.getProjectData(user.uid, prjID); 
        const dataObj = getProjectDetails.data();   
       // console.log(dataObj)         
        if (quill){ 
           quill.insertText(0, dataObj.text, 'bold', false);
        }        
        setMessages(dataObj.chat) 
        setQueryObj({
          productName: dataObj.title,
          variant: 1,
          type: dataObj.type,
          files: dataObj.files,
          text: dataObj.text,
          chat: dataObj.chat, 
          tone: dataObj.tone,
          id:dataObj.id,
          status: dataObj.status,
          language:dataObj.language,
          title: dataObj.title,
          creationDate: dataObj.creationDate
        })
    }  
    setLoading(false); 
  };  

  const handleNewChat = async () => { 
    navigate('/chat');
    setMessages([]) 
    setQueryObj(null);
    setProjectId(null);
    setIsButtonDisabled(true)
    if (quill) {
      quill.setContents([]);
    }
    //navigate(0);
  };

  const location = useLocation();
  useEffect(() => {    
  const fileId = params.id 
    //console.log(fileType);
    // console.log(queryObj);   
    if(fileId){ 
      getProjectContent(fileId);  
      setQueryObj(queryObj); 
    }  
    window.scrollTo(0, 0);       
  },[location, quill]) 

  const updateSubscriber = async () =>{
    if(user){
      const subData = await CommonDataServices.getUsageLimit(user.uid);
      
      if(subData.data()){ 
        setSubscriber(subData.data())
      }
    } 
  } 

  const handleSend = async (message) => {
    const currentDate = Math.floor(Date.now() / 1000) 
    if(subscriber){
      if(subscriber.totalWords >= subscriber.wordsLimit || subscriber.premiumState == false || currentDate > subscriber.planEndDate){ 
        setShowModal(true)
        return;
      }
     } 
    const newMessage = {
      message,
      direction: 'outgoing',
      sender: "user"
    };
    const newMessages = [...messages, newMessage];    
    setMessages(newMessages);
    // Initial system message to determine ChatGPT functionality
    // How it responds, how it talks, etc.
    setIsTyping(true);
    await processMessageToChatGPT(newMessages);
  };

  async function processMessageToChatGPT(chatMessages) {
    let apiMessages = chatMessages.map((messageObject) => {
      let role = "";
      if (messageObject.sender === "ChatGPT") {
        role = "assistant";
      } else {
        role = "user";
      }
      return { role: role, content: messageObject.message}
    });
  
    const apiRequestBody = {
      "model": "gpt-3.5-turbo",
      "messages": [
        systemMessage,
        ...apiMessages
      ]
    }
    //console.log(apiRequestBody)
    try {
      const response = await fetch("https://us-central1-wordkraft-bb683.cloudfunctions.net/chatKraft", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(apiRequestBody)
      });
  
      const data = await response.json();
     // console.log(data)
      const responseData = {data: data.choices[0].message.content} 

      CommonDataServices.usageTrackingData2(user.uid, responseData).then(() => {
        updateSubscriber();
      });

      setMessages([...chatMessages, {
        message: data.choices[0].message.content,
        sender: "ChatGPT"
      }]);
    } catch (error) {
      console.error("Error:", error);
      setIsTyping(false);
    } finally {
      setIsTyping(false);
    }
  }

useEffect(() => {
    if (quill) {
      quill.on('editor-change', function(eventName, ...args) {
        if (eventName === 'text-change') {
          // args[0] will be delta  
        } else if (eventName === 'selection-change') { 
        //  //console.log('selection-change', args); 
          // if(args){
          //   setTextLength(args[0].length)
          // }  
        }
      }); 
      quill.on('selection-change', function (delta, old, source) {
          console.log(delta) 
          if(delta.length){
            setTextLength(delta.length)
          } 
       }); 
    }
  }, [quill]);
 
  
  const wordAndCharacterCount = (text) => {
    const words = text.trim().split(/\s+/).length;
    const characters = text.replace(/\s+/g, '').length;
    return `${words} words / ${characters} characters`;
  };
  
const renderMessages = () => {
  return messages
    .map((message, index) => {
      const senderClass = message.sender === "user" ? "user-message" : "assistant-message";
      const showCopyText = hoveredMessageIndex === index && message.sender === "ChatGPT";
      const countText = wordAndCharacterCount(message.message);

      return (
        <div
          key={index}
          className={`message-container ${senderClass}`}
          onMouseEnter={() => setHoveredMessageIndex(index)}
          onMouseLeave={() => setHoveredMessageIndex(null)}
        >
          <div className={`message-bubble ${senderClass}`}>{message.message}</div>
          {showCopyText && (
            <div className="action-btns">
              <div className="word-character-count">{countText}</div>
               <div className="btns-wrap">
               <button className="btn btn-outline" onClick={() => handleCopyText(message.message)}><CopyTextIcon/> Copy </button>
              <button className="btn btn-outline" onClick={() => handleAddToEditor(message.message)}><SideArrow/> Add to editor </button>
                </div> 
            </div>
          )}
        </div>
      );
    })
      .concat(
        isTyping ? (
          <div key={"typing"} className={`message-container assistant-message`}>
            <div className={`message-bubble assistant-message`}>
              <div className="typing-indicator">
                 SmartChat is typing 
                <span className="dot">.</span>
                <span className="dot">.</span>
                <span className="dot">.</span>
              </div>
            </div>
          </div>
        ) : null
      );
  };
  
  const handleAddToEditor = (text) => {
    navigator.clipboard.writeText(text).then(
      () => { 
        toast.success('Text added to editor');
        
        // Get the current length of the editor's content
        const currentLength = quill.getLength();
  
        // Insert the text at the end of the editor's content
        quill.insertText(currentLength - 1, text + "\n\n");
  
      },
      (err) => {
      //  console.error("Error while adding to editor:", err);
        toast.error('Error while adding to editor');  
      }
    );
  };
  


  const handleCopyText = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
       // console.log("Text copied to clipboard");
        toast.success('Text copied to clipboard') 
      },
      (err) => {
       // console.error("Error copying text to clipboard:", err);
        toast.error('Error copying text')  
      }
    );
  };

  const handleTryAgain = async () => {
    const newMessages = messages.slice(0, -1);
    setMessages(newMessages);
  
    setIsTyping(true);
    await processMessageToChatGPT(newMessages);
  };
  
  const handleClearChat = async () => { 
    setMessages([]); 
    setIsButtonDisabled(true)
  }; 



  const messagesContainerRef = useRef(null);
  const [hoveredMessageIndex, setHoveredMessageIndex] = useState(null);
  const handleButtonClick = () => {
    const textarea = document.getElementById("user-input");
    handleSend(textarea.value);
    textarea.value = "";
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  const scrollToBottom = () => {
    const container = messagesContainerRef.current;
    container.scrollTop = container.scrollHeight;
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent the default behavior of the Enter key  
      const inputValue = e.target.value.trim(); // Remove leading and trailing whitespace  
      if (inputValue.length > 0) {
        handleSend(inputValue);
        e.target.value = "";
      }
    }
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const handleInputChange = (e) => {
    const inputValue = e.target.value.trim();
    setIsButtonDisabled(inputValue.length === 0);
  };
  
  
  const reWrite = async() => {  
    setLoading(true)   
    var range = quill.getSelection();
    let reWriteText;
    if (range) {
      if (range.length === 0){
        //console.log("User cursor is at index", range.index);
        setLoading(false)  
      } else {
        reWriteText = quill.getText(range.index, range.length); 
        //console.log("User has highlighted: ", reWriteText); 
      }
    } else {
      //console.log("User cursor is not in editor");
      setLoading(false)  
    } 
    if(reWriteText){
      try{
        let paramConfig = mainParam; 

        const apiRequestBody = {
          "title": "paraphrase",
          "query": reWriteText,   
          "language": "english",
          "tone": "formal",
          "num": 1
        }   
        const response = await fetchAPIData(apiRequestBody);
       // console.log(response) 
       if(response){  
          let newData = response[0].text; 
          if(newData.length < 4){
            toast.error('Try different inputs!')            
            setLoading(false)  
            return
          }
          if(newData){
            quill.deleteText(range.index, range.length);
            quill.cursorPosition = range.index;
            quill.clipboard.dangerouslyPasteHTML(quill.cursorPosition, newData); 
          } 
          wordCounter();  
          setLoading(false)   
        };
      } catch (error) {
        //console.log(error.message);
        setLoading(false)
      }
    } 
  };
  const fetchAPIData = async (apiRequestBody) => {
    const fetchData = await fetch("https://us-central1-wordkraft-bb683.cloudfunctions.net/tool_api3", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(apiRequestBody)
    });  
    const response = await fetchData.json();
    //console.log(response)
   CommonDataServices.usageTrackingData3(user.uid, response).then(() => {
      updateSubscriber();
    });
    return response;
}
const expandTxt = async() => {  
    setLoading(true)   
    var range = quill.getSelection();
    let expandText = "";
    if (range) {
      if (range.length === 0){
        //console.log("User cursor is at index", range.index);
        setLoading(false)  
      } else {
        expandText = quill.getText(range.index, range.length); 
        //console.log("User has highlighted: ", expandText); 
      }
    } else {
      //console.log("User cursor is not in editor");
      setLoading(false)  
    } 
    if(expandText){
      try{
        let paramConfig = mainParam; 
        const apiRequestBody = {
          "title": "SentenceExpander",
          "query": expandText,  
          "language": "english",
          "tone": "formal",
          "num": 1
        }     
        const response = await fetchAPIData(apiRequestBody);
        if(response){   
          let newData = response[0].text; 
          let getTxt = quill.getText(range.index, range.length);
          if(newData.length < 4 || newData == getTxt){
            toast.error('Try different inputs!')            
            setLoading(false)  
            return
          }
          if(newData){
            quill.deleteText(range.index, range.length);
            quill.cursorPosition = range.index;
            if (newData.includes(getTxt)) { 
              quill.clipboard.dangerouslyPasteHTML(quill.cursorPosition, newData); 
            } else{
              quill.clipboard.dangerouslyPasteHTML(quill.cursorPosition, getTxt + newData); 
            }
          } 
          wordCounter();  
          setLoading(false)         
        };
      } catch (error) {
        setLoading(false)
      }
    } 
  };
  
  const wordCounter = () => {  
    if(data){
      data.map((item, i) => {
        let count = item.text.split(' ').filter(word => word !== '').length; 
        data[i].count = count; 
      }) 
    } 
    setData(data) 
   }
  const copyMyText = (e) => { 
    let edtorTxt = quill.getText();
    if(edtorTxt.length > 5){ 
      navigator.clipboard.writeText(edtorTxt) 
      toast.success('Copied to clipboard!')
    } else{
      toast.error('There is NO content to copy!')
    }
   }
   
const saveContent = (e) => {
  const id = e.currentTarget.id;
  //console.log(messages);
  if(id){    
   if(!projectId){ 
     const contentObj = {
       type: "chat",
       creationDate: Math.floor(Date.now() / 1000),  
       chat: messages,
       files: files,
       status: true,
       language: 'english',
       tone: 'formal',
       text: quill.getText()
     } 
     try{
      ChatDataService.addProject(user.uid, contentObj).then((data) => {  
         if(data){
           setProjectId(data.id); 
           const projectId = data.id;
           toast.success('Chat content saved!')  
           try{
            ChatDataService.updateProjectId(user.uid, projectId);
           } catch (err){
             console.log(err.message);
             toast.error('Oops! some thing went worng!')  
           } 
         }
         //console.log(projectId)
       });    
     } catch (err) { 
       console.log(err.message);
       toast.error("Oops! something went wrong. Please check with the support team.")
     }    
   } else{ 
     const contentObj = { 
       type: "chat",
       updatedDate: Math.floor(Date.now() / 1000),  
       chat: messages,
       files: files,
       status: true,
       language: 'english',
       tone: 'formal',
       text: quill.getText(),
       prjId: projectId
     } 
     try{
           ChatDataService.updateProject(user.uid, contentObj);
           toast.success('Chat content updated!')  
       } catch (err){
           console.log(err.message);
           toast.error('Oops! some thing went worng!')  
       } 
   } 
 }
}
  return (
    <>          
      <div className="main-layout"> 
      <div className="row chat-layout">
      <div className="col-md-5">
              <section className="tool-panel chat-section">  
                  <div className="chatbot-container">
                  <div className="messages-container" ref={messagesContainerRef}>
                  {renderMessages()}
                </div>
                {(messages.length === 0) &&
                <div className="smartChart">
                  <div className="chatIcon"><ChatIcon/></div>
                  <h3>SmartChat</h3> 
                  <p><strong>Hey there! Why don't you try out SmartChat?</strong> <br/>
                    Just type in a task and SmartChat can do the rest for you.</p> 
                </div>}
                <div className="user-input-wrap">
                  <div className="user-info-wrap">
                    {/* <div className="loadingTxt"> 
                    {isTyping && (
                        <div className="typing-indicator">
                          SmartChat is typing
                          <span className="dot">.</span>
                          <span className="dot">.</span>
                          <span className="dot">.</span>
                        </div>
                      )}
                  </div> */}
                   {messages.some((msg) => msg.sender === "ChatGPT") && (<>
                  <button
                    className="btn btn-outline btnTry sub-btn"
                    disabled={isTyping}
                    onClick={() => handleNewChat()}
                  > 
                    New Chat
                  </button> 
               
                  <button
                    className="btn btn-outline btnTry"
                    disabled={isTyping}
                    onClick={() => handleTryAgain()}
                  >
                    <RewriteIcon />
                    Try again
                  </button>
                  
                  <button
                    className="btn btn-outline btnTry sub-btn"
                    disabled={isTyping}
                    onClick={() => handleClearChat()}
                  > 
                    Clear Chat
                  </button>
                
                  </> )}
              </div>

              <div className="user-input-container"> 
                <textarea
                  rows="1"
                  id="user-input"
                  className="user-input"
                  placeholder="Type your message..."
                  onKeyPress={handleKeyPress}
                  onChange={handleInputChange}
                  disabled={isTyping}
                ></textarea>
               <button className={`send-button ${isButtonDisabled ? 'disabled-button' : ''}`}
                    onClick={handleButtonClick}
                    disabled={isButtonDisabled}>
                    <PaperPlaneIcon />
                  </button>

              </div>
                </div>
           
              {showModal && (
                <div className="modal">
                  {/* Render modal content, such as a subscription upgrade message */}
                </div>
              )}
            </div>
                  </section>

      </div>
          <div className="col-md-7">
            <Card className="qTextEditor">
            <div className="customEditorBtns"> 
                          <div className="wktooltip">
                          <Button className="ml-auto mr-auto rewrite" variant="outline-secondary" size="sm" 
                                                                      onClick={reWrite} disabled={textLength < 50 || textLength > 200}><RewriteIcon/> Rewrite</Button>
                            <span className="wktooltiptext">Rewrite sentence</span>
                          </div>
                          <div className="wktooltip">
                          <Button className="ml-1 mr-auto expand" variant="outline-secondary" size="sm" 
                                                        onClick={expandTxt} disabled={textLength < 80 || textLength > 300}><ExpandIcon/> Extend</Button>
                            <span className="wktooltiptext">Extend sentence</span>
                          </div>
                          <div className="wktooltip">
                          <Button variant="outline-secondary copy" size="sm" className="ml-1 mr-auto" onClick={copyMyText}><RiFileCopy2Line /> Copy</Button>
                            <span className="wktooltiptext">Copy</span>
                          </div>
                          <div className="wktooltip">
                          <Button variant="outline-secondary save" size="sm" className="ml-1 mr-auto" id={1} onClick={saveContent}><RiSaveLine /> Save</Button> 
                            <span className="wktooltiptext">Save</span>
                          </div>  </div>
                <Card.Body>                        
                      <div className="qullTextEditor"> <div ref={quillRef} /> </div> 
                      {loading &&<div className="genMoreLoad"> <Loader2 /> </div> }  
                </Card.Body>  
            </Card> 
          </div>
          </div>
        </div> 
      <Toaster position="top-center" reverseOrder={false} />

      <Modal show={showModal} onHide={handleClose} keyboard={false} className="upgradeModal">        
      <Modal.Body className="text-center">  
              <InlinePricing/> 
          </Modal.Body>
    </Modal> 
    </> 
  )
}

export default Chat