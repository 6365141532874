import {React, useState, useEffect, useContext, useRef} from "react";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router"; 
import { useUserAuth } from "../../../context/UserAuthContext";
import { SubscriberContext } from "../../../context/subscriberContext";   
import { RiDeleteBinLine, RiFileCopy2Line, RiSaveLine, RiFileAddLine, RiSaveFill} from "react-icons/ri";
import Loader from "../../shared/utilities/loader/Loader";
import Loader2 from "../../shared/utilities/loader2/Loader2";
import ContentEditable from 'react-contenteditable';
import ContentDataService from "./services/ProsCons.services"; 
import { useParams, Link  } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'; 
import toast, { Toaster } from 'react-hot-toast';
import CommonDataServices from "../../../services/common.services";
import ProsConsAIServices from "./services/ProsConsAI.services"
import { ProsAndConsIcon, SideArrow, UpgradeIcon  } from "../../../assets/svg/SvgIcons";
import UsageBadge from "../../shared/utilities/usage-badge/UsageBadge";
import {FcPaid } from "react-icons/fc";
import "./ProsCons.css";  
import { getFunctions, httpsCallable } from "firebase/functions";  
import SidebarPanel from "../../dashboard/sidebar/SidebarPanel"; 
import Dashboard from "../../dashboard/Dashboard"; 
import InlineLogin from "../../InlineLogin";   
import InlinePricing from "../../views/InlinePricing";

const ProsCons = () => { 
  const { user } = useUserAuth();
  const functions = getFunctions(); 
  //if(user){ localStorage.setItem("user", user.uid)} 
  const navigate = useNavigate(); 

  const params = useParams();
  const [data, setData] = useState([]);
  const [queryObj, setQueryObj] = useState(null);  
  const [mainParam, setMainParam] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [copied, setCopied] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [projectId, setProjectId] = useState(null);
  const [files, setFiles] = useState(1);
  const [editMode, setEditMode] = useState(false) 
  const [updating, setUpdating] = useState(false) 
  const [activeSection, setActiveSection] = useState(null);
  const [tockenWords, setTockenWords] = useState(1); 
  const [formValue, setFormValue] = useState({name:'', benefits:''});

  const {subscriber, setSubscriber} = useContext(SubscriberContext);
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const [modalData, setModalData] = useState({})
  const handleClose = () => setShowModal(false);
  const [sideNav, setSideNav] = useState(false);
  
  const aiBoard = useRef(null); 
  const scollDown = () => {
    aiBoard.current.scrollTo(0, aiBoard.current.scrollHeight);
  }
  const updateSubscriber = async () =>{
    const subData = await CommonDataServices.getUsageLimit(user.uid);
    if(subData.data()){
      //console.log(subData.data())
      setSubscriber(subData.data())
    }
  }
  useEffect(() => {
    if(params.id){
      //console.log(params.id);
      getProjectContent(params.id);  
      setQueryObj(queryObj);
    }  
    window.scrollTo(0, 0);  
   },[]) 
   useEffect(() => {
    setTockenWords(tockenWords)
    //console.log(tockenWords)
  },[tockenWords])   
  const getProjectContent = async (conId) => {
    setLoading(true)
    setEditMode(true)
    const contentData = await ContentDataService.getAllProjectContent(user.uid, conId);
    const prjID = contentData.docs[0].data().projectId;
    setData(contentData.docs.map(doc => {
      // //console.log(doc);
      // //console.log(doc.data());
        return{
          id:doc.id,
          projectId: doc.data().projectId,
          text: doc.data().text,
          status: true 
        }
    }))  
    if(prjID){  
        //console.log(prjID)
        const getProjectDetails = await ContentDataService.getProjectData(user.uid, prjID); 
        //console.log(getProjectDetails.data()) 
        const dataObj = getProjectDetails.data();  
        setQueryObj({
          productName: dataObj.title,
          variant: 2,
          type: dataObj.type,
          files: 3,
          productFeatures: "",
          creationDate: dataObj.creationDate
        })
    }  
    setLoading(false); 
  };  

  
  const [loginState, setloginState] = useState(false); 
  const isAuthenticated = () => {
    if(user){
      return true;
    } else {
      setloginState(true)
      setShowModal(true) 
      return false;
    } 
  }
  const handleLoginSuccess = (user) => { 
    setloginState(false)
    setShowModal(false) 
    console.log('Login successful:');
  }; 
  const onFormSubmit = async (e) => { 
     e.preventDefault();
     const currentDate = Math.floor(Date.now() / 1000) 
if(subscriber.totalWords >= subscriber.wordsLimit || subscriber.premiumState === false || currentDate > subscriber.planEndDate){
      setShowModal(true)
      return;
     }
     setLoading(true)
     const formData = new FormData(e.target);
     const formDataObj = Object.fromEntries(formData.entries());
     setQueryObj(formDataObj); 
     //console.log(formDataObj)   
     let paramConfig = {
      type: "ProsCons",
      titleVal: formDataObj.productName,
      aboutVal : formDataObj.productFeatures,
      toneVal : formDataObj.tone ? formDataObj.tone: "Formal",
      languageVal : formDataObj.language ? formDataObj.language: "English",
      variantVal : formDataObj.variant ? formDataObj.variant: 2,
      creativeVal : formDataObj.creativity ? formDataObj.creativity: 0.85,
     }  
     setMainParam(paramConfig) 
     try {
      // const getAIdata = await httpsCallable(functions, 'wordkraftAI'); 
      // const param = {
      //   type:paramConfig.type, 
      //   title:paramConfig.titleVal,
      //   about:paramConfig.aboutVal,         
      //   language:paramConfig.languageVal,
      //   tone:paramConfig.toneVal, 
      //   wcount:250,
      //   creative:parseFloat(paramConfig.creativeVal),
      //   num:parseInt(paramConfig.variantVal) 
      // }
      const apiRequestBody = {
        "title": "ProsCons",
        "proTitle": paramConfig.titleVal,  
        "proDesc": paramConfig.aboutVal, 
        "language":paramConfig.languageVal.toString(),
        "tone": paramConfig.toneVal,
        "num":parseInt(paramConfig.variantVal)
      }   
      const response = await fetchAPIData(apiRequestBody);
     // console.log(response)  
      if(response){  
        let joinArray = data.concat(response); 
        setData(joinArray);  
        setLoading(false)
      }; 
    } catch (error) {
      //console.log(error.message);
      setLoading(false)
    } 
  }; 
  const fetchAPIData = async (apiRequestBody) => {
    const fetchData = await fetch("https://us-central1-wordkraft-bb683.cloudfunctions.net/tool_api3", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(apiRequestBody)
    });  
    const response = await fetchData.json();
    //console.log(response)
   CommonDataServices.usageTrackingData3(user.uid, response).then(() => {
      updateSubscriber();
    });
    return response;
}
  useEffect(() => {
    setData(data) 
    wordCounter();   
  }, [data])

  const wordCounter = () => {  
    if(data){
      data.map((item, i) => {
        let count = item.text.split(' ').filter(word => word !== '').length; 
        data[i].count = count; 
      }) 
    } 
    setData(data) 
   }
  

  const generateMore = async () => {  
    setLoading(true)
    try{
      let paramConfig = mainParam;
      const getAIdata = await httpsCallable(functions, 'wordkraftAI'); 
      const param = {
        type:paramConfig.type, 
        title:paramConfig.titleVal,
        about:paramConfig.aboutVal,         
        language:paramConfig.languageVal,
        tone:paramConfig.toneVal, 
        wcount:150,
        creative:parseFloat(paramConfig.creativeVal),
        num:parseInt(paramConfig.variantVal) 
      }
      getAIdata(param).then((response) => {    
        //console.log(response);  
        //console.log(response.data.choices); 
        //console.log(data) 
        let updatedValue = response.data.choices  
        let newArry = data.concat(updatedValue); 
        setData(newArry)  
        CommonDataServices.usageTrackingData(user.uid, response).then(() => {
          updateSubscriber();
        });
        setLoading(false) 
 scollDown();
      });
    } catch (error) {
      //console.log(error.message);
      setLoading(false)
    }  
  }

  const addMoreContent = async (e) => {  
    const currentDate = Math.floor(Date.now() / 1000) 
    if(subscriber.totalWords >= subscriber.wordsLimit || subscriber.premiumState === false || currentDate > subscriber.planEndDate){
          setShowModal(true)
          return;
         }
         //console.log(e.target.id)
        setUpdating(true)
        const id = parseInt(e.target.id);
        setActiveSection(id) 
        let projectData = data[id].text; 
        let result =  projectData.endsWith(".");
        if(result){
          projectData = projectData + " ";
        }
        //console.log(result)
        //console.log(data[id].text) 
        try{ 
          const getAIdata = await httpsCallable(functions, 'wordkraftAI'); 
          const param = {
            type:"ProsConsMore", 
            title:mainParam.titleVal,
            about: projectData,         
            language: mainParam.languageVal,
            tone: mainParam.toneVal, 
            wcount:75,
            creative:parseFloat(mainParam.creativeVal),
            num:parseFloat(mainParam.variantVal)
          }
          //console.log(param)
          getAIdata(param).then((response) => {   
            //console.log(response)
            let newData = response.data.choices[0].text; 
            //console.log(newData)
            const fullData = projectData + newData;
            data[id].text = fullData; 
            wordCounter(); 
            setData(data.filter(item => item));
            setUpdating(false)  
            //console.log(data[id])
            //console.log(data[id].id)
            if(data[id].id){
              //console.log(data[id].id)
              try{  
                ContentDataService.updateProjectContent(user.uid, data[id]);                       
              } catch (err) { 
                console.log(err.message); 
              }
            } 
            CommonDataServices.usageTrackingData(user.uid, response).then(() => {
              updateSubscriber();
            }); 
          });
        } catch (error) {
          //console.log(error.message);
          setLoading(false)
        }
     }
 const deleteCotent = (e) => {
  const id = parseInt(e.target.id)
  const remObj = {
    projectId : data[id].projectId,
    id: data[id].id,
    files: (data.length - 1)
  } 
  try{
      if(data.length == 1){ 
          ContentDataService.removeDocContent(user.uid, remObj).then(() => {  
            setData(data.filter(item => item.id !== remObj.id));
            navigate(`/my-content`);
          });
        } else{
          ContentDataService.removeContent(user.uid, remObj).then(() => { 
toast.success('Content deleted!')  
            setData(data.filter(item => item.id !== remObj.id));
          });
        } 
    } catch (err) { 
      console.log(err.message);
        toast.error("Oops! something went wrong. Please check with the support team.")
    }   
 }

 const updateContent = (e) => { 
    const id = parseInt(e.currentTarget.id);  
    function strip_html_tags(str){
      if ((str===null) || (str===''))
          return false;
      else
      str = str.toString();
      return str.replace("<div>", "").replace("</div>", "");
     // return str.replace(/<[^>]*>/g, '');
    }  
    const newText = strip_html_tags(e.target.value); 
    data[id].text = newText; 
    //console.log(data[id].text)
    data[id].count = data[id].text.split(' ').filter(word => word !== '').length; 
    setData(data.filter(item => item));   
 }
 

const sendContent = (e) => {  
  const id = parseInt(e.currentTarget.id);   
  //console.log(data[id]) 
  //console.log("on blur....");    
  if(data[id].id){
    try{  
      ContentDataService.updateProjectContent(user.uid, data[id]);                       
    } catch (err) { 
      console.log(err.message); 
    } 
  }
}

const saveContent = (e) => {
   const id = e.target.id;
   //console.log(id);
   if(id){  
    setUpdating(true)
    setActiveSection(id) 
    if(!projectId){
      //console.log(projectId)
      const contentObj = {
        type: "pros-cons",
        creationDate: Math.floor(Date.now() / 1000),
        title: queryObj.productName,
        files: files,
        status: true 
        // content: [{text: data[id].text}]
      } 
      try{
        ContentDataService.addProject(user.uid, contentObj).then((data) => {  
          if(data){
            setProjectId(data.id);
            addContent(id, data.id);
            const projectId = data.id;
            try{
              ContentDataService.updateProjectId(user.uid, projectId);
            } catch (err){
              console.log(err.message);
            } 
          }
          //console.log(projectId)
        });    
      } catch (err) { 
        console.log(err.message);
        toast.error("Oops! something went wrong. Please check with the support team.")
      }       
      
    } else{ 
      addContent(id, projectId)
    } 
  }
 }

 const addContent = (id, projId) => {
    const newContentObj = {
      projectId: projId,
      text: data[id].text,
      files: files
    } 
    //console.log(data[id])
    //console.log(projId)
    try{
      ContentDataService.addProjectContent(user.uid, newContentObj).then((sec) => {  
        if(sec){
          //console.log(sec.id)  
          data[id].id = sec.id; 
          data[id].projectId = projId;
          setData([...data]) 
          let count = files + 1;
          setFiles(count)
          setUpdating(false)
        } 
      });     
    } catch (err) {
      setUpdating(false)
      console.log(err.message);
        toast.error("Oops! something went wrong. Please check with the support team.")
    } 
    //console.log(data)
 }

 const removeContent = () => {

 }

 const copyText = (e) => {  
  toast.success('Copied to clipboard!')
  const id = parseInt(e.target.id);  
  let copyText = data[id].text.replace(/\n/g, '').replace((/<br\s*[\/]?>/gi), "\n").replace(/&nbsp;/g, ' ');     
  navigator.clipboard.writeText(copyText) 
}
  const handleSideNav = () => {
  if(sideNav) {
    setSideNav(false)
   } else 
   {setSideNav(true)
   }
}
  return (
    <> 
           
      <div className="main-layout">
     {/* <Button className="backToDashboard" variant="link"  onClick={handleSideNav}> <SideArrow/> </Button> */}
      {/*<Link className="backToDashboard mobileView" to="/tools"><SideArrow/></Link> */}
    
          <section className="tool-panel">
              <Row>
                {!editMode &&
                    <Col md={4}>  
                    <Card>    
                          <Card.Body>  
                          <section className="tool-header">
<div>
      <div className="svgIcon"> 
      <ProsAndConsIcon/>
        </div>
      <div>
      <h2>Pros & Cons</h2> 
      <p>Generate pros and cons for your product, service, website, and blog articles.</p>
      </div>
          </div>
<UsageBadge/> 
</section>  
                          {/* <Card.Title><FcViewDetails /> Product description</Card.Title> */}
                              <Form onSubmit={onFormSubmit}>
                                  <Form.Group className="mb-3" controlId="brandName">
                                      <Form.Label>Product Name</Form.Label>
                                      <Form.Control required type="text" name="productName" placeholder="Write product name" onChange={((e) => {setFormValue({...formValue, name: e.target.value})})}/>
                                      {/* <Form.Text className="text-muted">
                                          Like iPhone
                                      </Form.Text> */}
                                  </Form.Group>
                                  <Form.Group className="mt-3 mb-3" controlId="productFeatures">
                                  <Form.Label>Product Description </Form.Label> <span className="float-right text-muted in-text">{formValue.benefits.length}/400</span>
                                      <Form.Control
                                      as="textarea"
                                      maxLength="400"
                                      placeholder="Write about the product features or benifits"
                                      style={{ height: '155px' }}
                                      name="productFeatures"
                                      required
                                      onChange={((e) => {setFormValue({...formValue, benefits: e.target.value})})}
                                      /> 
                                  </Form.Group>
                                  <Form.Group className="mt-3 mb-3 row">
                                    <Col>
                                        <Form.Label>Language</Form.Label>    
                                        <Form.Select name="language" id="language" className="form-control">
																		<option value="English (US)">English (US)</option>
<option value="English (UK)">English (UK)</option>
<option value="French">French</option>
<option value="Spanish">Spanish</option>
<option value="German">German</option>
<option value="Italian">Italian</option>
<option value="Dutch">Dutch</option>
<option value="Arabic">Arabic</option>
<option value="Portuguese">Portuguese</option>
<option value="Portuguese (BR)">Portuguese (BR)</option>
<option value="Swedish">Swedish</option>
<option value="Norwegian">Norwegian</option>
<option value="Danish">Danish</option>
<option value="Finnish">Finnish</option>
<option value="Romanian">Romanian</option>
<option value="Czech">Czech</option>
<option value="Slovak">Slovak</option>
<option value="Slovenian">Slovenian</option>
<option value="Hungarian">Hungarian</option>
<option value="Hebrew">Hebrew</option>
<option value="Polish">Polish</option>
<option value="Turkish">Turkish</option>
<option value="Russian">Russian</option>
<option value="Hindi">Hindi</option>
<option value="Thai">Thai</option>
<option value="Japonese">Japonese</option>
<option value="Chinese (Simplified)">Chinese (Simplified)</option>
<option value="Korean">Korean</option>
<option value="Indonesian">Indonesian</option>
<option value="Greek">Greek</option>
<option value="Bulgarian">Bulgarian</option>
<option value="Ukrainian">Ukrainian</option>
<option value="Estonian">Estonian</option>
<option value="Latvian">Latvian</option>
<option value="Lithuanian">Lithuanian</option>
<option value="Croatian">Croatian</option>
<option value="Serbian">Serbian</option>
<option value="Bosnian">Bosnian</option>
<option value="Macedonian">Macedonian</option>
<option value="Albanian">Albanian</option>
<option value="Vietnamese">Vietnamese</option>
																	  </Form.Select>
                                    </Col>
                                    <Col>
                                        {/* <Form.Label>Variant</Form.Label>   
                                        <Form.Select aria-label="Default select example" name="variant" className="form-control">
                                            <option>1</option>
                                            <option>3</option>
                                            <option>5</option> 
                                        </Form.Select>  */}
                                        <Form.Label>Tone of voice</Form.Label>   
                                        <Form.Select aria-label="Default select example" name="tone" className="form-control">
                                            <option>Formal</option>
                                            <option>Professional</option> 
                                            <option>Informative</option>
                                            <option>Friendly</option>
                                            <option>Bold</option>
                                            <option>Witty</option>
                                            <option>Adventurous</option>
                                           <option>Persuasive</option>
                                        </Form.Select>
                                    </Col> 
                                  </Form.Group>
                                  <Form.Group className="mt-3 mb-3 row"> 
                                    <Col>
                                        <Form.Label>Variants</Form.Label>
                                        <Form.Select aria-label="Number of Variants" name="variant" className="form-control">
                                            <option value="1">1 variant</option>
                                            <option value="2">2 variants</option>
                                            <option value="3">3 variants</option> 
                                            <option value="4">4 variants</option>
                                            <option value="5">5 variants</option>
                                            <option value="6">6 variants</option> 
                                        </Form.Select>  
                                    </Col>
                                    <Col>                                    
                                    <Form.Label>Creativity</Form.Label>
                                        <Form.Select aria-label="creativity Level" name="creativity" className="form-control">
                                            <option value="0.75">Regular</option>
                                            <option value="0.35">Low</option>
<option value="0.65">Medium</option>
<option value="1">High</option> 
                                        </Form.Select>
                                    </Col>
                           </Form.Group>
                                  { user && <>
                                  <Button variant="primary" className="mt-3 w-100" type="submit" 
                                  disabled={loading && `disabled`}>
                                      ⁺₊✧  Generate content
                                  </Button> 
                                  </>}
                                  {!user && <><Button variant="primary" className="mt-3 w-100" onClick={isAuthenticated}>
                                      Login to Generate
                                  </Button></>}
                                  </Form>
                                  {/* <pre>{ JSON.stringify(formValue.name.length, undefined, 2)}</pre>       */}
                            </Card.Body>  
                      </Card>
                    </Col>
                   }
                  <Col className={`ai-board ${editMode ? "col-md-12" : "col-md-8"}`}>  
                    <Card className={`${editMode ? "editMode" : ""}`}>     
                    <Card.Title className="ai-board-header"> 
                      {data && queryObj && <div>{queryObj.productName}</div>} 
                     </Card.Title>     
                    <Card.Body className="ai-board-body" ref={aiBoard}> 
                              {!data.length && !loading &&                                    
                                  <div className="noResults"> 
                                    <h5><i>🚀</i> Get start now...</h5>
                                    <p>Generate high quality content by adding your inputs</p></div> 
                              } 
                              {queryObj && data && data.map((item, i) => (
                                  <div className="ai-section height-align text-center" key={i + 1}>
                                      <div className="ai-content">
                                          <div><ContentEditable className="breakSpaces" html={item.text} defaultValue={item.text} id={i} onChange={updateContent} onBlur={sendContent}/></div> 
                                          <span className="index">{i + 1}</span>  
                                          <div className="btn-group-wrap text-right">
                                          <div className="float-left display-flex">
                                            <span className="wordCount float-left">Words: {item.count}</span>{updating && activeSection == i &&  <span className="sectionUpdate"><Loader2/></span> }
                                          </div>
                                            <div style={{ pointerEvents: updating ? 'none' : 'auto' }}>
                                                <Button variant="outline-secondary" size="sm" id={i} onClick={copyText}><RiFileCopy2Line /> Copy </Button>
                                                {!item.id && <Button variant="outline-secondary" size="sm" id={i} onClick={saveContent} className={`ml-1 ${updating && activeSection == i ? "updating" : ""}`}><RiSaveLine /> save</Button>}
                                                {item.id && <Button className="ml-1" variant="outline-secondary" size="sm" id={i} onClick={removeContent}><RiSaveFill /> Saved</Button>} 
                                                {/* <Button className="ml-1" variant="outline-secondary" id={i} size="sm" onClick={addMoreContent} ><RiFileAddLine /> Write More</Button>  */}
                                                {editMode && <Button className="ml-1" variant="outline-secondary" id={i} size="sm" onClick={deleteCotent}><RiDeleteBinLine /> Delete</Button> } 
                                             </div> 
                                             </div>
                                      </div>
                                   </div>
                                ))}   
                               {loading &&<div className="genMoreLoad"> <Loader /> </div> } 
                              {/* {!editMode && data  && <Button className="ml-auto mr-auto" variant="outline-primary" size="sm" onClick={generateMore} disabled={loading && `disabled`}>Generate More</Button> } */}
                        </Card.Body>  
                    </Card>  
                  </Col>
              </Row>
          </section>
        </div>
      <Toaster position="top-center" reverseOrder={false} />
          <Modal show={showModal} onHide={handleClose} keyboard={false} className={loginState ? "loginState" : "upgradeModal"}> 
      {loginState && <Modal.Body className="text-center">  
        <InlineLogin onLoginSuccess={handleLoginSuccess}/>  
      </Modal.Body>} 
      {!loginState && 
      <Modal.Body className="text-center">                 
              <InlinePricing/>
          </Modal.Body>}
    </Modal> 
    </>
  );
};

export default ProsCons;



