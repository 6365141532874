import {React, useState, useEffect, useContext, useRef} from "react";
import { useQuill } from "react-quilljs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button, Navbar, Nav, Dropdown, Popover, Card, Form, Container, Row, Col, Alert, ModalTitle } from "react-bootstrap"; 
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useNavigate } from "react-router";
import { useUserAuth } from "../../../context/UserAuthContext";
import { SubscriberContext } from "../../../context/subscriberContext";
import { FcList } from "react-icons/fc";
import { VscCopy, VscNewFile, VscInfo, VscChevronUp, VscChevronDown, VscTrash } from "react-icons/vsc";
import { RiFileEditLine} from "react-icons/ri";
import "./ArticleInterviewList.css";
import Loader from "../../shared/utilities/loader/Loader";
import Loader2 from "../../shared/utilities/loader2/Loader2";
import ContentEditable from 'react-contenteditable';
import Modal from 'react-bootstrap/Modal';
import Overlay from 'react-bootstrap/Overlay';
import TextareaAutosize from 'react-textarea-autosize'; 
import ArticleInterviewListDataService from "./services/ArticleInterviewList.services";
import CommonDataServices from "../../../services/common.services";
import { sendPasswordResetEmail } from "firebase/auth";
import { useParams, Link  } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Accordion from 'react-bootstrap/Accordion';
import { serverTimestamp } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";  
import SidebarPanel from "../../dashboard/sidebar/SidebarPanel";
import Dashboard from "../../dashboard/Dashboard";
import { EditorSvg, InfoIcon, SideArrow, UpgradeIcon, ExpandIcon, RewriteIcon, WriteMoreIcon, SectionIcon } from "../../../assets/svg/SvgIcons";
import RichTextEditor from "../../shared/utilities/rich-text-editor/RichTextEditor";
import { RiDeleteBinLine } from "react-icons/ri";
import { RiFileCopy2Line, RiSaveLine} from "react-icons/ri";
import "quill/dist/quill.snow.css"; // Add css for snow theme
import { useLocation } from 'react-router-dom';
import articleCustomeServices from "./services/ArticleInterviewList.services";

const ArticleInterviewList = () => {
  const { user } = useUserAuth();
  const functions = getFunctions();
  //if(user){ localStorage.setItem("user", user.uid)}


  const theme = 'snow';
  var toolbarOptions = [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['image', 'link'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],
    [{ 'align': [] }]
  ];
  const modules = {
    toolbar: toolbarOptions
  };

  const placeholder = '🚀 Get start now...';
  const formats = [
    'header',
    'align',
    'bold', 'italic', 'underline', 'image', 'link',
    'list', 'bullet',
    'indent', 'indent',
    'strike'
];

  const navigate = useNavigate();
  const [message, setMessage] = useState(null)
  const params = useParams();
  const [projects, setProjects] = useState(null);
  const [blogIdeaData, setBlogIdeaData] = useState([]);
  const [blogIntroData, setBlogIntroData] = useState(null);
  const [blogOutline, setBlogOutline] = useState(null);
  const [articlePost, setArticlePost] = useState({idea: "", title:"", keywords:" ", language:"Engilsh", sections:[] });
  const [blogOutlineData, setBlogOutlineData] = useState([{secData:"", secTokens:400, secTitle:"Introduction"},{secData:"", secTokens:400, secTitle:""}]);
  const [activeSection, setActiveSection] = useState(null);
  const [loading, setLoading] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [contGen, setContGen] = useState(false);
  const [error, setError] = useState(null);
  const [copied, setCopied] = useState(false);
  const [wizard, setWizard] = useState('step1');
  const [introBtn, setIntroBtn] = useState(false);
  const [outlineBtn, setOutlineBtn] = useState(false);
  const [newBlogTitle, setNewBlogTitle] = useState('Write your blog title..');
  const [showBlogTitle, setShowBlogTitle] = useState(false);
  const [editIntro, setEditIntro] = useState(false);
  const [selTitle, setSelTitle] = useState();
  const [selIntro, setSelIntro] = useState();
  const [ideaValue, setIdeaValue] = useState('');
  const [blogEditor, setBlogEditor] = useState(false);
  const [wordCount, setWordCount] = useState(null);
  const [selOutline, setSelOutline] = useState([])
  const [sectionData, setSectionData] = useState([])

  const {subscriber, setSubscriber} = useContext(SubscriberContext);
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const [modalData, setModalData] = useState({})
  const [accept, setAccept] = useState(false);

  const [showDetails, setShowDetails] = useState(false);
  const [target, setTarget] = useState(null);
  const [justTxt, setJustTxt] = useState('hello');
  const [queryData, setQueryData] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [projectSections, setProjectSections] = useState(null)
  const [projectLoading, setProjectLoading] = useState(null);

  const [metaData, setMetaData] = useState(null);
  const [queryObj, setQueryObj] = useState(null);
  const [mainParam, setMainParam] = useState(null);
  const [textLength, setTextLength] = useState(null);
  const [wordChangeStatus, setWordChangeStatus] = useState(false);

  const [startOver, setStartOver] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [tagType, setTagType] = useState(null);
  const [newAcceptedData, setNewAcceptedData] = useState(null);
  const [getContent, setGetContent] = useState(null)
  const [noRecords, setNoRecords] = useState(false); 

  const [tagCount, setTagCount] = useState(null) 
  const [article, setArticle] = useState([]) 
  const [userState, setUserState] = useState(null) 
  const [postHTML, setPostHTML] = useState(null) 

  const [upgradeTxt, setUpgradeTxt] = useState({ title: 'Upgrade your account',
  discription: 'You have either exceeded the word limit or your plan has expired. Please upgrade your account.',
  action:true})
  const handleClick = (event) => {
    setShowDetails(!showDetails);
    setTarget(event.target);
  };

  const startNewPost = (e) => {
    setShowModal(false);
    setWizard('step1')
    setBlogEditor(false)
    setArticlePost({idea: "", title:"", keywords:"", sections:[] })
   // navigate("/blog-post");
     setBlogIdeaData([])
     setBlogIntroData(null)
     setBlogOutline(null)
     setBlogOutlineData([])
  }
  const acceptChange = (e) => {
      setWordChangeStatus(true)
      setShowModal(false);
  }
  const handleClose = (e) => {
     setShowModal(false);
  }

  const { quill, quillRef } = useQuill({ theme, modules, formats, placeholder });


  const location = useLocation();

  useEffect(() => {
  const fileId = new URLSearchParams(location.search).get("id")
    //console.log(fileId);

    if(fileId){
      getProjectContent(fileId);
      setQueryObj(queryObj);
    }  
    window.scrollTo(0, 0); 
},[location, quill])


const getProjectContent = async (prjID) => {
  setLoading(true)
  setBlogEditor(true)
  setProjectId(prjID)
  if(prjID){
      //console.log(prjID)
      const getProjectDetails = await ArticleInterviewListDataService.getProjectData(user.uid, prjID);
      const dataObj = getProjectDetails.data();
      if (quill){
        var delta = quill.clipboard.convert(dataObj.text);
        quill.setContents(delta, 'silent');
      } 
      let updatedValue = {};
          updatedValue = {title: dataObj.title, language:dataObj.language };
      setArticlePost(articlePost => ({
            ...articlePost,
            ...updatedValue
          })); 

      setQueryObj({
        text: dataObj.text,
        id:dataObj.id,
        status: dataObj.status,
        keywords: dataObj.keywords,
        language: dataObj.language,
        title: dataObj.title,
        creationDate: dataObj.creationDate
      })
      // //console.log(articlePost)
  }
  setLoading(false);
};



  useEffect(() => {
    getArticle();
    if(params.id){
      //console.log(params.id);
      setBlogEditor(true);
      setWizard('step5')
    }
    updateSubscriber();

   },[])

  const getArticle = async () => {
  setNoRecords(false)
  let newArry = []
  //setLoading(true);
  try{ 
    await articleCustomeServices.getAllProjects(user.uid).then((data) => { 
      //console.log(data.docs);
          if(data.docs.length > 0){ 
              data.docs.map(doc => { 
                let newObj = doc.data();
                newArry.push(newObj)
                //console.log(doc.data().type)
               // setLoading(false);
            })
          } else { 
              //  setLoading(false);
                setNoRecords(true)
          }
      }); 
      setArticle(newArry)   
    } catch (err) { 
    console.log(err.message); 
  }
}
const deleteArticle = async (e) => {
  let projId = e.currentTarget.id;  
  await articleCustomeServices.deleteProjectContent(user.uid, projId).then(() => {
    setArticle(article.filter(item => item.id !== projId)); 
    toast.success('Content deleted!') 
  })   
} 


   const aiBoard = useRef(null);
  const scollDown = () => {
    aiBoard.current.scrollTo(0, aiBoard.current.scrollHeight);
  }
  const updateSubscriber = async () =>{
    const subData = await CommonDataServices.getUsageLimit(user.uid);
    if(subData.data()){
      //console.log(subData.data())
      setSubscriber(subData.data())
    }
  }


 const reGetBlogOutline = async () => { 

  setLoading(true)
  let blogTitle = articlePost.title;
  try {
    const getAIdata = await httpsCallable(functions, 'wordkraftAI');
    const param = {
      type:"articleOutline",
      title:articlePost.title,
      keywords: articlePost.keywords,
      language: articlePost.language,
      tone: "formal",
      wcount:150,
      creative:0.65,
      num:1
    }
    getAIdata(param).then((response) => {
       CommonDataServices.usageTrackingData(user.uid, response).then(() => {
          updateSubscriber();
        });
       let oldOutLine = response.data.choices[0].text;
       let sentences = oldOutLine.split(/\r?\n/);
       const newOutLine = sentences.filter(myFunction);
       function myFunction(value) {
          return value.length > 6;
       }

       let newArry = [];
       newOutLine.forEach(function(element, index) {
        let outVal = element.replace(/\d+.\s/,'');
        outVal = outVal.replace(/^\s+|\s+$/gm,'');
        newArry.push({secTitle: outVal, secData:''})
       })
       //console.log(newArry)
       let attachArray = blogOutlineData.concat(newArry);
       setBlogOutlineData(attachArray); // new in use
       setLoading(false);
       let updatedOlogOutline = blogOutline.concat(newOutLine); // old not used
       setBlogOutline(updatedOlogOutline); // old not used
       setOutlineBtn(true);
    });
  } catch (error) {
    //console.log(error.message);
    setLoading(false)
  }
};

useEffect(() => {
  //console.log(blogOutlineData);

}, [blogOutlineData]);


const addArticleTitle=(e)=>{
  let titleData = e.target.value;
 // //console.log(titleData)
  let updatedValue = {title: titleData};
  setArticlePost(articlePost => ({
        ...articlePost,
        ...updatedValue
      }));

  const resumeTitle = titleData;
  setBlogOutlineData([ 
  {secData:"", secTokens:2000, secTitle:`Top ${resumeTitle} Interview Questions and Answers`, 
  secParam:`Write Top ${resumeTitle} Interview Questions and Answers (Answer in multiple paragraphs)\nNote: Add ### to the interview question`},  
])
  
  let addTitle = {title: `${resumeTitle} Interview Questions and Answers`};
  setArticlePost(articlePost => ({
        ...articlePost,
        ...addTitle
      }));
     
}
const addArticleKeywords=(e)=>{
  let keywordsData = e.target.value;
 // //console.log(keywordsData)
  let updatedValue = {keywords: keywordsData};
  setArticlePost(articlePost => ({
        ...articlePost,
        ...updatedValue
      }));
}

const addArticleLanguage=(e)=>{
  let languageData = e.target.value;
 // //console.log(languageData)
  let updatedValue = {language: languageData};
  setArticlePost(articlePost => ({
        ...articlePost,
        ...updatedValue
      }));
}
  const selectBlogOutline=(e)=>{
   //let str = e.target.value;
   let strId = parseInt(e.target.dataset.id);
   let str = blogOutlineData[strId].item;
   let sectionArray = articlePost.sections;
  // //console.log(articlePost)
    // //console.log(blogOutlineData[strId].status)
   if (e.target.checked == true){
        let updatedValue = sectionArray.push({secTitle:str, secData:''})
        setArticlePost(articlePost => ({
          ...articlePost,
          ...updatedValue
        }));
    } else {
      sectionArray = sectionArray.filter(function( obj ) {
        return obj.secTitle !== str;
      });
      let updatedValue = articlePost.sections = sectionArray;
    }
  }
  const updateBlogOutline = (e) => {
    const ind = parseInt(e.currentTarget.id);
    let targetValue = e.target.value;
   // //console.log(ind);
    let newArry = blogOutlineData;
                  newArry[ind].secTitle = targetValue;
   // //console.log(newArry);
    setBlogOutlineData(newArry);
  }
const checkUpdate = (e) => {

}
const deleteBlogOutline = (e) => {
  const ind = parseInt(e.currentTarget.id);
  const index = ind;
  if (index > -1) {
    blogOutlineData.splice(index, 1);
  }
   setBlogOutlineData(blogOutlineData.filter(data => data));
}

const addSubHeading = (e) => {
  blogOutlineData.push({secTitle:'  ', secData:''})
  // //console.log(blogOutlineData) 
  if(blogOutlineData.length > 4){
    if(subscriber.planType == "free" && subscriber.wordsLimit == 1000){
      toast.error('The free plan has limited access!')
      return;
    }
  }
  setBlogOutlineData(blogOutlineData.filter(data => data));
}
 /*---------------------
ALL SECTION GENERATOR
 ----------------------*/
const checkUserStatus = () => {  
  const currentDate = Math.floor(Date.now() / 1000)
  if(subscriber.totalWords >= subscriber.wordsLimit || subscriber.premiumState === false || currentDate > subscriber.planEndDate){
    let newFileModal = { title: 'Upgrade your account', discription: 'You have either exceeded the word limit or your plan has expired. Please upgrade your account.', action:true}
    setModalData(newFileModal)
    setShowModal(true) 
    return false;
   } else{
    return true;
   }
} 

const genSection = async () => {
 

        setBlogEditor(true); 
        setLoading(true)

       // //console.log(quill)
    
       let secValue = articlePost.sections = blogOutlineData;
        setArticlePost(articlePost => ({
          ...articlePost,
          ...secValue
        }));

        console.log(articlePost)

        const getAIdata = httpsCallable(functions, 'wordkraftArticleCustomeApi');

        const generateAllSections = () => {
        let sectionData = [];
        let response;
       //remove empty subtitle item
        const articlePostFilter = articlePost.sections.filter(element => {
          if (Object.keys(element.secParam).length !== 0) {
            return true;
          }
          return false;
        });
        articlePost.sections = articlePostFilter;
        articlePost.sections.map((item, index) => {
          const objSec = {
            postTitle: articlePost.title,
            secParam: articlePost.sections[index].secParam,
            secTokens:articlePost.sections[index].secTokens
          }
          console.log(objSec)
          sectionData.push(getAIdata(objSec)) 
        });
        return Promise.all(sectionData);
      }
       const mySections = generateAllSections()
       //console.log(mySections);
      let fullText; 
       mySections.then((allResponse) => {
             console.log(allResponse)
            var wordCount = 0;
            var textGroup = '';
            allResponse.map((response, index) => {
               console.log(response)
              let respData = response.data.choices[0].text;
              fullText = response.data.choices[0].text;
              textGroup += response.data.choices[0].text;
             // //console.log(response.data.choices[0])

              let spaceRemoveData = respData.replace(/(\r\n|\n|\r){2}/,"");
              // spaceRemoveData = respData.replace(/\●|\●  |\•|\• |\•  |\•   /g,'- ');
              //spaceRemoveData = respData.replace(/\●/g,'- ').replace(/\● /g,'- ').replace(/\•/g,'- ').replace(/\• /g,'- ').replace(/\•  /g,'- ').replace(/\•   /g,'- ');  
              if(index == 1){
                spaceRemoveData = respData.replace(/-/g,'- ').replace(/●/g,'- ').replace(/● /g,'- ').replace(/•/g,'- ').replace(/• /g,'- ').replace(/•  /g,'- ').replace(/•   /g,'- ').replace(/Summary:|Summary|SUMMARY:|SUMMARY/, "")
                .replace(/Core Skills|Core Skills:|CORE SKILLS|CORE SKILLS:/g, "**Core Skills**").replace(/Professional Experience|Professional Experience:|PROFESSIONAL EXPERIENCE|PROFESSIONAL EXPERIENCE:/g, "**Professional Experience**").replace(/Education|Education:|EDUCATION|EDUCATION:/g, "**Education**");
               }

              if(index > 1 && index < 8){
              spaceRemoveData = respData.replace(/-/g,'- ').replace(/●/g,'- ').replace(/● /g,'- ').replace(/•/g,'- ').replace(/• /g,'- ').replace(/•  /g,'- ').replace(/•   /g,'- ').replace(/•/g, '>>>>> ').replace(/Summary:|Summary|SUMMARY:|SUMMARY/, "")
              .replace(/Core Skills|Core Skills:|CORE SKILLS|CORE SKILLS:/g, "**Core Skills**").replace(/Responsibilities|Responsibilities:|RESPONSIBILITIES|RESPONSIBILITIES:/g, "**Responsibilities**");
              } 

              let updatedValue = articlePost.sections[index].secData = spaceRemoveData;       
             
              setArticlePost(articlePost => ({
                             ...articlePost,
                              ...updatedValue
                           }));  
               wordCount += parseInt(fullText.split(' ').filter(word => word !== '').length); 

               if((allResponse.length - 1) == index){
                    // //console.log("Last index trigger...") 
                    // //console.log(wordCount)
                    // //console.log(textGroup)
                    response.data.choices[0].text = textGroup;
                    CommonDataServices.usageTrackingData(user.uid, response).then(() => {
                      updateSubscriber();
                    });
               }            

             })



               setProjects(articlePost)

               let articleSections = articlePost.sections;
 
              articleSections.forEach((element, index) => {
                 let secTitle = element.secTitle;
                 let secData = element.secData;
                 secData = secData.replace(/^\s+|\s+$/g, '');

                
             


                // fullText = `<h2>${secTitle}</h2>\n\n\n${secData}\n\n\n`
                 //fullSec = fullSec.replace(/^\s+|\s+$/g, '');
                // fullText += fullText

               /* if(index == 0){
                  console.log("intro")
                  updatedValue = articlePost.sections[index].secData = spaceRemoveData;
                }
                if(index == 1){
                  console.log("resume example")
                  let resumeContainer = `
                  <div className="resume-example-template"><div className="wp-block-group__inner-container"> 
                  ${spaceRemoveData}
                  </div></div>`
                  updatedValue = articlePost.sections[index].secData = resumeContainer;
                }
                if(index == 2){
                  console.log("resume example")
                  let resumeContainer = `
                  <div className="resume-example-template"> 
                  ${spaceRemoveData}
                   </div>`
                  updatedValue = articlePost.sections[index].secData = resumeContainer;
                }*/

                if(index === 0){
                  quill.clipboard.dangerouslyPasteHTML(0, `<h1>${articlePost.title}</h1>`);
                 }
                 var length1 = quill.getLength();

                quill.insertText(length1, `## ${secTitle}`,'normal', false);
                
                var length2 = quill.getLength(); 
                quill.insertText(length2, secData, 'normal', false);
               
                var length3 = quill.getLength();                 
                let space2 = `\n`;
                quill.insertText(length3, space2, 'normal', false);

                if(index < 8){
                var length4 = quill.getLength();
                let dashed = `\n`;                 
                quill.insertText(length4, dashed, 'normal', false); 
                }

               });
               setLoading(false)
        });

    }




 useEffect(() => {
    setWordCount(wordCount);
     //console.log(wordCount)
  }, [wordCount])


  const [indexNumber, setIndexNumber] = useState(null)


  const moveUp = (e) =>{
    let fromIndex = parseInt(e.currentTarget.id);
    let toIndex = fromIndex - 1;
    let newArray = []
      projectSections.forEach((item) => {
        let newObject = {
              id : item.id,
              projectId : item.projectId,
              sectionDescription : item.sectionDescription,
              sectionTitle : item.sectionTitle,
              order : item.order
        }
        newArray.push(newObject);
      })

    function moveArrayItemToNewIndex(arr, old_index, new_index) {
      if (new_index >= arr.length) {
          var k = new_index - arr.length + 1;
          while (k--) {
              arr.push(undefined);
          }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
  };
  if(fromIndex !== 0){
    setProjectSections(moveArrayItemToNewIndex(newArray, fromIndex, toIndex));
   }
  }

  const moveDown = (e) =>{
    let fromIndex = parseInt(e.currentTarget.id);
    let toIndex = fromIndex + 1;
     let newArray = []
      projectSections.forEach((item) => {
        let newObject = {
              id : item.id,
              projectId : item.projectId,
              sectionDescription : item.sectionDescription,
              sectionTitle : item.sectionTitle,
              order : item.order
        }
        newArray.push(newObject);
      })

    function moveArrayItemToNewIndex(arr, old_index, new_index) {
      if (new_index >= arr.length) {
          var k = new_index - arr.length + 1;
          while (k--) {
              arr.push(undefined);
          }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
  };

   if(projectSections.length !== toIndex){
    setProjectSections(moveArrayItemToNewIndex(newArray, fromIndex, toIndex));
   }
  }

  const copySelectedText = (e) => { 
    var range = quill.getSelection();
    let edtorTxt = quill.getText(range.index, range.length); 
    if(edtorTxt.length > 5){
      navigator.clipboard.writeText(edtorTxt)
      toast.success('Copied the selected Text to clipboard!')
    } else{
      toast.error('There is NO content to copy!')
    }
   }
  const handleKeyDown = (event)=>{
    event.preventDefault();
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if((event.ctrlKey || event.metaKey) && charCode === 'c') {
      var range = quill.getSelection();
      let edtorTxt = quill.getText(range.index, range.length); 
      if(edtorTxt.length > 5){
        navigator.clipboard.writeText(edtorTxt)
        toast.success('Copied the selected Text to clipboard!')
      } else{
        toast.error('There is NO content to copy!')
      }
    }
    // if((event.ctrlKey || event.metaKey) && charCode === 's') {
    //   alert("CTRL+S Pressed");
    // }else if((event.ctrlKey || event.metaKey) && charCode === 'c') {
    //   alert("CTRL+C Pressed");
    // }else if((event.ctrlKey || event.metaKey) && charCode === 'v') {
    //   alert("CTRL+V Pressed");
    // }
}   
const copyMyText = (e) => {
  let edtorTxt = quill.getText();
  if(edtorTxt.length > 5){
    navigator.clipboard.writeText(edtorTxt)
    toast.success('Copied Text to clipboard!')
  } else{
    toast.error('There is NO content to copy!')
  }
 }
 const copyMyHTML = (e) => {
  let edtorTxt = quill.root.innerHTML
  if(edtorTxt.length > 5){
    navigator.clipboard.writeText(edtorTxt)
    toast.success('Copied HTML to clipboard!')
  } else{
    toast.error('There is NO content to copy!')
  }
 }


  const reWrite = async() => {
    let userState = checkUserStatus(); 
        if(!userState){return;}
    setLoading(true)
    var range = quill.getSelection();
    let reWriteText;
    if (range) {
      if (range.length === 0){
        //console.log("User cursor is at index", range.index);
        setLoading(false)
      } else {
        reWriteText = quill.getText(range.index, range.length);
        //console.log("User has highlighted: ", reWriteText);
      }
    } else {
      //console.log("User cursor is not in editor");
      setLoading(false)
    }
    if(reWriteText){
      try{
        let paramConfig = mainParam;
        const getAIdata = await httpsCallable(functions, 'wordkraftAI');
        const param = {
          type:"paraphrase",
          about: reWriteText,
          language: articlePost.language,
          tone:"formal",
          wcount:75,
          creative:0.7,
          num:1
        }
        getAIdata(param).then((response) => {
          CommonDataServices.usageTrackingData(user.uid, response).then(() => {
            updateSubscriber();
          });
          let newData = response.data.choices[0].text;
          newData = newData.replace(/^\s+|\s+$/g, ''); 

          if(newData.length < 4){
            toast.error('Try different inputs!')
            setLoading(false)
            return
          }   

          setModalType("newPost")
          let newFileModal = {
            title: 'Rewrite sentence',
            discription: 'Please check the re-written sentence below.',
            oldData:reWriteText,
            newData:newData,
            action:true}
          setModalData(newFileModal)
          setShowModal(true)

          setLoading(false)
          CommonDataServices.usageTrackingData(user.uid, response).then(() => {
            updateSubscriber();
          });

          if(newData){
            quill.deleteText(range.index, range.length);
            quill.cursorPosition = range.index;
            //quill.clipboard.dangerouslyPasteHTML(quill.cursorPosition, newData);
            setNewAcceptedData({postion:quill.cursorPosition, data: newData, oldData: reWriteText})
          }

        });
      } catch (error) {
        //console.log(error.message);
        setLoading(false)
      }
    }
  };

  const writeMore = async() => {
    let userState = checkUserStatus(); 
        if(!userState){return;}
    setLoading(true)
    if(getContent){
      // //console.log(getContent)
      try{ 
        const getAIdata = await httpsCallable(functions, 'wordkraftAI');
        const param = {
          type:"paragraphWriterMore",
          about: getContent.wContent,
          language:articlePost.language ? articlePost.language: queryObj.language,
          tone:'formal',
          wcount:100,
          creative:0.7,
          num:1
        }
        getAIdata(param).then((response) => {
          CommonDataServices.usageTrackingData(user.uid, response).then(() => {
            updateSubscriber();
          });
          let newData = response.data.choices[0].text; 
          newData = newData.replace(/^\s+|\s+$/g, ''); 
          // //console.log(newData);
          
          let getTxt = getContent.wContent;

          if(newData.length < 4 || newData == getTxt){
            toast.error('Try different inputs!')
            setLoading(false)
            return
          }

          setModalType("newPost")
          let newFileModal = {
            title: 'Write more',
            discription: 'Please check the AI generated content for your article.', 
            newData:newData,
            action:true}
            setModalData(newFileModal)
            setShowModal(true)

          setLoading(false)
          CommonDataServices.usageTrackingData(user.uid, response).then(() => {
            updateSubscriber();
          });

          if(newData){
            setNewAcceptedData({postion:getContent.wPosition, data:' ' + newData})
          }

          setLoading(false)

        });
      } catch (error) {
        //console.log(error.message);
        setLoading(false)
      }
    }
  };


  const expandTxt = async() => {
    let userState = checkUserStatus(); 
        if(!userState){return;}
    setLoading(true)
    var range = quill.getSelection();
    let expandText = "";
    if (range) {
      if (range.length === 0){
        //console.log("User cursor is at index", range.index);
        setLoading(false)
      } else {
        expandText = quill.getText(range.index, range.length);
        //console.log("User has highlighted: ", expandText);
      }
    } else {
      //console.log("User cursor is not in editor");
      setLoading(false)
    }
    if(expandText){
      try{
        let paramConfig = mainParam;
        const getAIdata = await httpsCallable(functions, 'wordkraftAI');
        const param = {
          type:"blogSectionMore",
          about: expandText,
          language:articlePost.language ? articlePost.language: queryObj.language,
          tone:'formal',
          wcount:100,
          creative:0.7,
          num:1
        }
        getAIdata(param).then((response) => {
          CommonDataServices.usageTrackingData(user.uid, response).then(() => {
            updateSubscriber();
          });
          let newData = response.data.choices[0].text;
          newData = newData.replace(/^\s+|\s+$/g, ''); 
          let getTxt = quill.getText(range.index, range.length);

          if(newData.length < 4 || newData == getTxt){
            toast.error('Try different inputs!')
            setLoading(false)
            return
          }

          setModalType("newPost")
          let newFileModal = {
            title: 'Expand sentence',
            discription: 'Please check the expanded sentence below.',
            oldData:expandText,
            newData:newData,
            action:true}
            setModalData(newFileModal)
            setShowModal(true)

          setLoading(false)
          CommonDataServices.usageTrackingData(user.uid, response).then(() => {
            updateSubscriber();
          });

          if(newData){
          //  quill.deleteText(range.index, range.length);
            quill.cursorPosition = range.index + range.length;
            setNewAcceptedData({postion:quill.cursorPosition, data:' ' + newData, oldData: expandText})

            // if (newData.includes(getTxt)) {
            //  // quill.clipboard.dangerouslyPasteHTML(quill.cursorPosition, newData);
            //   setNewAcceptedData({postion:quill.cursorPosition, data: newData, oldData: expandText})
            // } else{
            //   //quill.clipboard.dangerouslyPasteHTML(quill.cursorPosition, getTxt + newData);
            //   setNewAcceptedData({postion:quill.cursorPosition, data: getTxt + newData, oldData: expandText})
            // }
          }
          setLoading(false)

        });
      } catch (error) {
        //console.log(error.message);
        setLoading(false)
      }
    }
  };


  const paragraphTxt = async() => {
    let userState = checkUserStatus(); 
        if(!userState){return;}
    setLoading(true)
    var range = quill.getSelection();
    let expandText = "";
    if (range) {
      if (range.length === 0){
        //console.log("User cursor is at index", range.index);
        setLoading(false)
      } else {
        expandText = quill.getText(range.index, range.length);
        //console.log("User has highlighted: ", expandText);
      }
    } else {
      //console.log("User cursor is not in editor");
      setLoading(false)
    }
    if(!articlePost.keywords){
      articlePost.keywords = "";
    }
    // //console.log(expandText)    
    // //console.log(articlePost.title) 
    // //console.log(articlePost.keywords) 
    // //console.log(articlePost.language)  
    if(expandText){
      try{ 
        const getAIdata = await httpsCallable(functions, 'wordkraftAI');
        const param = {
            type: "articleParagraph",
            title: articlePost.title ? articlePost.title: queryObj.title,
            about: expandText,
            keywords: articlePost.keywords ? articlePost.keywords: queryObj.title ? articlePost.keywords: " ",
            tone: "Formal",
            language: articlePost.language ? articlePost.language: queryObj.language,
            variant:1,
            creative: 0.7
        }
        //console.log(param)
        getAIdata(param).then((response) => {
          CommonDataServices.usageTrackingData(user.uid, response).then(() => {
            updateSubscriber();
          });
          let newData = response.data.choices[0].text;
          //console.log(newData)
          newData = newData.replace(/^\s+|\s+$/g, ''); 
          //console.log(newData)
          let getTxt = quill.getText(range.index, range.length);

          if(newData.length < 4 || newData == getTxt){
            toast.error('Try different inputs!')
            setLoading(false)
            return
          }

          setModalType("newPost")
          let newFileModal = {
            title: 'Paragraph generator',
            discription: 'Please check the paragraph.',
            oldData:expandText,
            newData:newData,
            action:true}
          setModalData(newFileModal)
          setShowModal(true)

          setLoading(false)
          CommonDataServices.usageTrackingData(user.uid, response).then(() => {
            updateSubscriber();
          });

          if(newData){
           // quill.deleteText(range.index, range.length);
            quill.cursorPosition = range.index + range.length + 1;
            //console.log(quill.cursorPosition)
            // //console.log(range.index)
            // //console.log(range.length)
            let formatData = `${newData}`
            if (newData.includes(getTxt)) {
             // quill.clipboard.dangerouslyPasteHTML(quill.cursorPosition, newData);
              setNewAcceptedData({postion:quill.cursorPosition, data: formatData, oldData: expandText})
            } else{
              //quill.clipboard.dangerouslyPasteHTML(quill.cursorPosition, getTxt + newData);
              setNewAcceptedData({postion:quill.cursorPosition, data: formatData, oldData: expandText})
            }
          }
          setLoading(false)

        });
      } catch (error) {
        //console.log(error.message);
        setLoading(false)
      }
    }
  };
  const acceptedData = () => {
    if(newAcceptedData){
     //quill.clipboard.dangerouslyPasteHTML(newAcceptedData.postion, newAcceptedData.data); 
     quill.insertText(newAcceptedData.postion, newAcceptedData.data, 'normal', false);
     setShowModal(false) 
    }
  }

  const retainOldData = () => {
    //console.log("retain old..")
    if(newAcceptedData){
      //quill.clipboard.dangerouslyPasteHTML(newAcceptedData.postion, newAcceptedData.oldData);
         quill.insertText(newAcceptedData.postion, newAcceptedData.oldData, 'normal', false);
      setShowModal(false)
    }
  } 

  
  const [loginState, setloginState] = useState(false); 
  const isAuthenticated = () => {
    if(user){
      return true;
    } else {
      setloginState(true)
      setShowModal(true) 
      return false;
    } 
  }
  const handleLoginSuccess = (user) => { 
    setloginState(false)
    setShowModal(false) 
    console.log('Login successful:');
  }; 
  const onFormSubmit = async (e) => { 
    e.preventDefault(); 
    const formData = new FormData(e.target);
    const formDataObj = Object.fromEntries(formData.entries());
    // //console.log(formDataObj.projectValue1)
 }; 

  useEffect(() => {
    // var selection = window.getSelection();
    // if (selection.rangeCount > 0) {
    //      //console.log(window.getSelection().anchorNode.ATTRIBUTE_NODE)
    // } 
    if (quill) { 
      quill.on('editor-change', function(eventName, ...args) {
        if (eventName === 'text-change') {
          // args[0] will be delta
          var text = quill.getText();
          var wordCounter = text.split(/\s+/).length;
          setWordCount(wordCounter)

          let htmlDoc = quill.root.innerHTML; 
          let p = (htmlDoc.match(/<p>/g) || []).length;
          let pb = (htmlDoc.match(/<p><b/g) || []).length;
          let h1 = (htmlDoc.match(/<h1>/g) || []).length;
          let h2 = (htmlDoc.match(/<h2>/g) || []).length;
          let link = (htmlDoc.match(/<a/g) || []).length;
          let img = (htmlDoc.match(/<img/g) || []).length;
          let ul = (htmlDoc.match(/<ul/g) || []).length;
          setTagCount({
            pTag: (p - pb),
            h1Tag: h1,
            h2Tag: h2,
            linkTag: link,
            imgTag: img,
            ulTag: ul
          })  
        } else if (eventName === 'selection-change') {
          var range = quill.getSelection();
          if(range){
            var cursorPosition = range.index;
            var content = quill.getText((range.index - 400), 400)
            // var contentHtml = quill.root.innerHTML
            // //console.log(contentHtml)
            setGetContent({wPosition: cursorPosition, wContent: content}) 
            //console.log(getContent)
          }    
          let tagOfType = window.getSelection().anchorNode.parentElement.nodeName;
          setTagType(tagOfType) 
          if(args){ 
            if(args[0]){
              setTextLength(args[0].length)
            }
          }
        }
      });
      // quill.on('selection-change', function (delta, old, source) {  
      //  }); 
       // quill.insertText(0, "hellow", 'bold', false);
    }
  }, [quill]);


const saveContent = () => {
      if(!projectId){
        const contentObj = {
          type: "article-writer",
          creationDate: Math.floor(Date.now() / 1000),
          title:articlePost.title,
          keywords: articlePost.keywords ? articlePost.keywords:"",
          language: articlePost.language,
          status: true,
          tone: 'formal',
          text: quill.root.innerHTML,
          wordCount: wordCount
        }
        try{
          ArticleInterviewListDataService.addProject(user.uid, contentObj).then((data) => {
            if(data){
              setProjectId(data.id);
              const projectId = data.id;
              toast.success('Article saved!')
              try{
                ArticleInterviewListDataService.updateProjectId(user.uid, projectId);
              } catch (err){
                console.log(err.message);
                toast.error('Oops! some thing went worng!')
              }
            }
            //console.log(projectId)
          });
        } catch (err) {
          console.log(err.message);
          toast.error("Oops! something went wrong. Please check with the support team.")
        }
      } else{
        const contentObj = {
          type: "article-writer",
          creationDate: Math.floor(Date.now() / 1000),
          title:articlePost.title,
          keywords: articlePost.keywords ? articlePost.keywords:"",
          language: articlePost.language,
          status: true,
          tone: 'formal',
          text: quill.root.innerHTML,
          prjId: projectId,
          wordCount: wordCount
        }
        try{
          ArticleInterviewListDataService.updateProject(user.uid, contentObj);
              toast.success('Article updated!')
          } catch (err){
              console.log(err.message);
              toast.error('Oops! some thing went worng!')
          }
      }
    }
   
  return (
    <>
    <Container> {blogEditor &&
       <section className="header-txt article-post-header">
            <div className="editorHeader">
             <h5 className="float-left editor-icon"><EditorSvg/>Article Editor</h5>
             <div className="float-right customEditorBtns">
                  <div className="wktooltip">
                  <span><b>{wordCount}</b> words</span>
                  <Button className="ml-auto mr-auto rewrite" variant="link" size="sm"
                                                              onClick={writeMore}> <WriteMoreIcon/> Write More</Button>
                  <Button className="ml-auto mr-auto rewrite" variant="link" size="sm"
                                                              onClick={reWrite} disabled={textLength < 20 || textLength > 700}><RewriteIcon/> Rewrite</Button>
                  </div>
                  <div className="wktooltip">
                  <Button className="ml-1 mr-auto expand" variant="link" size="sm"
                                              onClick={expandTxt} disabled={textLength < 20 || textLength > 700 || tagType != "P"}><ExpandIcon/> Expand</Button>
                  </div>
                  <div className="wktooltip">
                  <Button className="ml-1 mr-auto expand" variant="link" size="sm"
                                                onClick={paragraphTxt} disabled={tagType != "H2" && tagType != "H3" && tagType != "H4" && tagType != "H5"}><ExpandIcon/> {tagType} Paragraph</Button>
                  </div>
                  <div className="wktooltip">
                  <Dropdown>
                  <Dropdown.Toggle variant="link" id="dropdown-copy" className="mr-0" disabled={contGen}><VscCopy/> Copy</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as="button" onClick={copySelectedText}>Copy Selected Text</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={copyMyText}>Copy Text</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={copyMyHTML}>Copy HTML</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                  {/* <Button variant="link copy" size="sm" className="ml-1 mr-auto" onClick={copyMyText}><RiFileCopy2Line /> Copy</Button> */}
                  </div>
                  <div className="wktooltip">
                  <Button variant="link save" size="sm" className="ml-1 mr-auto" id={1} onClick={saveContent}><RiSaveLine /> Save</Button>
                  </div>
              </div>


            {/*   <Button variant="link" onClick={handleClick} disabled={contGen}><VscInfo/> Details</Button>
             <Overlay
              show={showDetails}
              target={target}
              placement="bottom"
              containerPadding={20}
            >
              <Popover id="popover-contained">
                <Popover.Header as="h3">Content Brief</Popover.Header>
                {projectData &&
                <Popover.Body>
                  <div className="mb-2"><strong>Blog Idea:</strong> {projectData.idea ? projectData.idea : '-'}</div>
                  <div className="mb-2"><strong>Blog Title:</strong> {projectData.title}</div>
                  <div className="mb-2"><strong>Blog Keywords:</strong> {projectData.keywords ? projectData.keywords : '-'}</div>
                  <div className="mb-2"><strong>Language:</strong> {projectData.language ? projectData.language : 'English'}</div>
                </Popover.Body>
                }
              </Popover>
            </Overlay>
            <Button variant="link" onClick={newPost} disabled={contGen}><VscNewFile/> New</Button>   */}
              </div>
          </section> }
      <Container>
      <div className={`main-layout blog-post-wrap article-post ${blogEditor ? "inEditor" : "outEditor"}`}>

          <section className="tool-panel">

              {!blogEditor && <>
              {error && <Alert variant="danger" onClose={() => setError(null)} dismissibl> {error.message}  <Button className="closeAlert" onClick={() => setError(null)}> X </Button>
               </Alert> }
              <section className="header-txt mt-3 text-center">
              <h2>Topic</h2>
              <p>Create an impressive long article using AI in just few seconds</p>
              </section>
              {/* <div className="dashboardNav2 text-center  mb-4">
              <Link className="backToDashboard2" to="/tools">Back to Tools</Link>
                </div> */}
              </>
             }
              <Row>
                  <Col md={12} className="ai-board">
                  {!blogEditor && <div className="infoTooltip2"><InfoIcon/>
        <span className="tooltiptext">Content generated automatically using AI engine. Please excuse irrelevant content and reach out to us for any issues.</span>
      </div> }
      {!blogEditor &&  <>
                    <Card>
                    <Card.Body className="ai-board-body" ref={aiBoard}>
                                    <div className="ai-section height-align text-center">
                                        <div className="float-left w-100 mb-1 pt-4">
                                        {/* <label className="ai-content customBlogTitle row border-0 m-0 mt-5" >
                                            <div className="col-md-3 col-sm">
                                               <Button variant="primary text-center d-block" onClick={cusBlogTitle} value={newBlogTitle} disabled={!newBlogTitle && newBlogTitle.length < 20}>Add Title</Button>
                                            </div>
                                        </label>  */}
                                          <div className="row">
                                  <Form.Group className="mb-3 col-12 text-left">
                                    <Form.Label>Write Your Title</Form.Label>
                                    <Form.Control type="text" name="blogTitle" className="col-md-12" placeholder="16 Java" onChange={addArticleTitle}/>
                                  </Form.Group>
                                           </div>
                                  
                                        </div>
                                    </div>
                                     
                                      {loading && 
                                      <div className="col">
                                        <Loader />
                                      </div>
                                      }
                                      </Card.Body>
                                      </Card>
                                      <Button variant="success" size="md" className="mx-auto mt-4 mb-4" onClick={genSection} >Generate Article</Button>
                                   </> } 

                          {/* <Card className={`mt-0 ${blogEditor ? "showEditor" : "hideEditor"}`}>
                          <Card.Body className="ai-board-body p-0 qullEditorWrap" ref={aiBoard}>
                                <div className="qullTextEditor"> <div ref={quillRef} /> </div>
                                {loading &&<div className="genMoreLoad"> <Loader /> </div> }
                          </Card.Body>
                          </Card> */}


                  </Col>
              </Row>

          </section>
        </div>
      </Container>
      <Container className={`articleEditor article-post mt-0 ${blogEditor ? "showEditor" : "hideEditor"}`}>
<Row className="h-100">
<Col className="col-md-2 editorOptions articleNav px-0"> 
<Card className={`mt-0 p-3 ${blogEditor ? "showEditor" : "hideEditor"}`}>
  {article &&                
      <div>
                <table className="table table-hover myArticles table-fixed1">
                <thead> 
                <tr> 
                  <th width="100%">Articles <span className="aCount">{article.length}</span></th>    
                </tr>
              </thead>
              <tbody> 
              {article && article.map( (project, index) => {
              if(project.type == 'article-writer'){
              return (                      
                <tr key={project.id}> 
                  <td>
                    <div>
                    <a className="link" size="sm" id={project.id} disabled={loading} onClick={(e) => {navigate(`/article-writer/?id=${project.id}`); } }>
                    {project.title}</a> 
                    </div>
                    <div className="footerTxt float-left w-100">
                       <span><strong>Date:</strong> {new Date(project.creationDate * 1000).toDateString()} </span> 
                       <span><strong>Words:</strong> {project.wordCount}</span>  
                       {/* <span className="float-right"><a className="deleteLink" size="sm" id={project.id} onClick={deleteArticle}><RiDeleteBinLine/></a></span> */}
                  </div>
                    </td>  
                </tr> 
                  )}                                    
              })} 
              {article.length == 0 && 
                      <tr className="noResults">
                        <td colSpan="4"> <span>No content found!.</span></td>
                      </tr>
                }
              </tbody> 
          </table> 
           
      </div>  }
      </Card>
  </Col>
     <Col className="col-md-8 editorMain px-0">
         <Card className={`mt-0 ${blogEditor ? "showEditor" : "hideEditor"}`}>
         <div className="editorTools">
                     <div className="wktooltip"> 
                     <Button className="ml-auto mr-auto rewrite" variant="link" size="sm"
                                                                 onClick={writeMore} disabled={textLength > 1}><WriteMoreIcon/> Write More</Button>
                     <Button className="ml-auto mr-auto rewrite" variant="link" size="sm"
                                                                 onClick={reWrite} disabled={textLength < 20 || textLength > 700}><RewriteIcon/> Rewrite</Button>
                     </div>
                     <div className="wktooltip expandBtn">
                     <Button className="ml-1 mr-auto expand" variant="link" size="sm"
                                                 onClick={expandTxt} disabled={textLength < 20 || textLength > 700 || tagType != "P"}><ExpandIcon/> Expand</Button>
                     </div>
                     <div className="wktooltip">
                     <Button className="ml-1 mr-auto expand" variant="link" size="sm"
                                                   onClick={paragraphTxt} disabled={tagType != "H2" && tagType != "H3" && tagType != "H4" && tagType != "H5"}><SectionIcon/> Paragraph</Button>
                     </div>  
                 </div>
         <Card.Body className="ai-board-body p-0 qullEditorWrap" ref={aiBoard}>
              
         {/* <div>
          <ContentEditable className="breakSpaces" html={postHTML} defaultValue={postHTML} /></div> 
                                          */} 
               <div className="qullTextEditor"> <div ref={quillRef} onKeyDown={handleKeyDown}/> </div>
               {loading &&<div className="genMoreLoad"> <Loader /> </div> }
         </Card.Body> 
         </Card>
     </Col>
     <Col className="col-md-2 editorOptions pl-0 pr-0">
         <Card className={`mt-0 ${blogEditor ? "showEditor" : "hideEditor"}`}>
             <Card.Body>
              {/* <h5>Article</h5> */}
              <div className="row sec-action mt-3 mb-5">
                
              <div className="col-12 mb-3">   
       
              <Button variant="primary save" size="md" className="ml-1 mr-auto" id={1}   onClick={copySelectedText}><RiSaveLine /> Copy Selected Text</Button>
                     {/* <Button variant="primary save" size="md" className="ml-1 mr-auto" id={1} onClick={saveContent}><RiSaveLine /> Save</Button> */}
                     </div> 
                {/* <div className="col-12">
                  <Dropdown>
                     <Dropdown.Toggle variant="secondary" size="md" id="dropdown-copy" className="mr-0" disabled={contGen}><VscCopy/> Copy content</Dropdown.Toggle>
                     <Dropdown.Menu>
                       <Dropdown.Item as="button" onClick={copySelectedText}>Copy Selected Text</Dropdown.Item>
                       <Dropdown.Item as="button" onClick={copyMyText}>Copy Text</Dropdown.Item>
                       <Dropdown.Item as="button" onClick={copyMyHTML}>Copy HTML</Dropdown.Item>
                     </Dropdown.Menu>
                   </Dropdown>
                </div>  */}
              </div>   
        <div className="row mb-5">
            <div className="col-md-12">
                <div className="tile">
                    <div className="wrapper">
                        {/* <div className="header">SEO Stats</div>  */}
                        {tagCount && <>
                        <div className="stats"> 
                            <div>
                                <strong>Words</strong> {wordCount}
                            </div> 
                            <div>
                                <strong>H1</strong> {tagCount.h1Tag}
                            </div> 
                        </div>
                        <div className="stats"> 
                            <div>
                                <strong>H2</strong> {tagCount.h2Tag}
                            </div>

                            <div>
                                <strong>Paragraphs</strong> {tagCount.pTag}
                            </div> 
                        </div> 
                        <div className="stats"> 
                           <div>
                                <strong>Links</strong> {tagCount.linkTag}
                            </div>
                            <div>
                                <strong>Images</strong> {tagCount.imgTag}
                            </div> 
                        </div> 
                        </>}
                    </div> 
            </div>
            </div>
            </div>
            <div className="row mb-5">
              <div className="col-md-12">
              <div className="advanceOptions" style={{display:"none"}}>
                                  <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Meta description</Accordion.Header>
        <Accordion.Body> 
        <Form onSubmit={onFormSubmit}> 
                                  <Form.Group className="mt-3 mb-3" controlId="projectValue1">
                                  <Form.Label>Article Title</Form.Label> <span className="float-right text-muted in-text">200</span>
                                      <Form.Control
                                      as="textarea"
                                      maxLength="200"
                                      placeholder="Write about website / blog"
                                      style={{ height: '25px' }}
                                      name="projectValue1"
                                      required 
                                      /> 
                                  </Form.Group>  
                                  <Button variant="primary" className="mt-3 w-100" type="submit" 
                                  disabled={loading && `disabled`}>
                                      ⁺₊✧  Generate content
                                  </Button> 
                                  </Form>
                                  {metaData &&    
                                     <div>
                                       {metaData}
                                      </div>  
                                    }
        </Accordion.Body>
      </Accordion.Item> 
    </Accordion>
                                  </div>
              </div>
            </div>
             </Card.Body>
           </Card>                              
     </Col>
</Row> 
</Container>
</Container>
      <Toaster position="top-center" reverseOrder={false} />


      <Modal
        show={showModal}
        onHide={handleClose}
        keyboard={false}
        backdrop="static"
        className="aiModal upgradeModal"
        >
        <Modal.Body className="text-center">
          {modalData &&
            <>
              {modalData.title == "Upgrade your account" && <>
                <div className="upgradeIcon"><UpgradeIcon/></div>
                <h3 className="m-0 mb-2">{modalData.title}</h3>
                <p>{modalData.discription}</p>
                </>
             }
              <div className="row px-4">
                 {modalData.title == 'Rewrite sentence' && <>
                  <h6>Old Sentence</h6>
                      <div className="colz">
                          {modalData.oldData}
                      </div>
                      <h6>New Sentence</h6>
                  </>}
                  {modalData.newData && 
                  <div className="colz">
                      {modalData.newData}
                  </div>
                  }
                </div>
            </>
          }
          <div className="text-center mt-3">
            {modalType == "newPost" &&  <>
         
            {modalData.title == "Rewrite sentence" && <Button variant="secondary" size="md" className="mr-4" onClick={retainOldData}> Cancel </Button> }
            {modalData.title != "Rewrite sentence" && <Button variant="secondary" size="md" className="mr-4" onClick={handleClose}> Cancel </Button> }
            <Button variant="primary" size="md" onClick={acceptedData} data-id="newPost">Accept new</Button></>
            }
            {!modalData.action && modalData.title !== "Upgrade your account" &&
            <Button variant="primary" size="md" onClick={handleClose}>Ok</Button>
            }
             {modalData.title == "Upgrade your account" && <>
             <Button variant="secondary" size="md" className="mr-4" onClick={handleClose}> Cancel </Button>
           <Button variant="primary" size="md" onClick={()=> navigate(`/pricing`)}>Upgrade</Button>
           </>}
         </div>
        </Modal.Body>
      </Modal>
     </>
  );
};

export default ArticleInterviewList;



