import { db } from "../../../../firebase"; 
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc, 
  doc 
} from "firebase/firestore";   

//const uid = localStorage.getItem("user");
//console.log(uid);

class GrammarCheckerDataService {   

 
  addProject = (uid, newProject) => {
    //console.log(newProject)    
    const projectRef = collection(db, `projects/${uid}/grammarChecker/`)
    return addDoc(projectRef, newProject);  
  } 

  updateProjectId = (uid, pId) => {    
    const projectIdRef = doc(db, `projects/${uid}/grammarChecker/${pId}/`)
    updateDoc(projectIdRef, {id: pId});  
  }

  addProjectContent = (uid, newObj) => {
    const textObj = {text: newObj.text, projectId: newObj.projectId}  
    const projectCountRef = doc(db, `projects/${uid}/grammarChecker/${newObj.projectId}/`)
    updateDoc(projectCountRef, {files: newObj.files});  

    const projectRef = collection(db, `projects/${uid}/grammarChecker/${newObj.projectId}/content/`)
    return addDoc(projectRef, textObj);   
    //  const projectRef = doc(db, `userData/${uid}/projects/${newObj.projectId}`)
    //   updateDoc(projectRef, {content: arrayRemove({text: 'greater_virginia'})}); 
    //  return updateDoc(projectRef, {content: arrayUnion({text: 'greater_coral'})}); 
  }  

  getProjectData = (uid, pId) => {
    const projectRef = doc(db, `projects/${uid}/grammarChecker/${pId}/`); 
    return getDoc(projectRef)
  }

  removeContent = (uid, remObj) => { 
    const projectCountRef = doc(db, `projects/${uid}/grammarChecker/${remObj.projectId}/`)
    updateDoc(projectCountRef, {files: remObj.files});  
    const projectRef = doc(db, `projects/${uid}/grammarChecker/${remObj.projectId}/content/${remObj.id}`); 
    return deleteDoc(projectRef)
  }

  removeDocContent = (uid, remObj) => { 
    const projectCountRef = doc(db, `projects/${uid}/grammarChecker/${remObj.projectId}/`)
    updateDoc(projectCountRef, {files: remObj.files});  
    const projectRef = doc(db, `projects/${uid}/grammarChecker/${remObj.projectId}/content/${remObj.id}`); 
     deleteDoc(projectRef)
    const projRef = doc(db, `projects/${uid}/grammarChecker/${remObj.projectId}`); 
    return deleteDoc(projRef)
  }

  updateProjectContent = (uid, upObj) => { 
    //console.log(upObj)
    const projectRef = doc(db, `projects/${uid}/grammarChecker/${upObj.projectId}/content/${upObj.id}`); 
    return updateDoc(projectRef, {text: upObj.text})
  } 

  getAllProjects = (uid) => {  
    const projectRef = collection(db, `projects/${uid}/grammarChecker/`)
    return getDocs(projectRef); 
  } 
  getAllProjectContent = (uid, prjId) => {    
    const projectRef = collection(db, `projects/${uid}/grammarChecker/${prjId}/content/`); 
    return getDocs(projectRef)
  }
  deleteProjectContent = async (uid, prjID) => { 
      if(prjID){   
        const contentData = await this.getAllProjectContent(uid, prjID);  
        contentData.docs.map(file => {
           //console.log(file.id);  
           const projectRef = doc(db, `projects/${uid}/grammarChecker/${prjID}/content/${file.id}`); 
           deleteDoc(projectRef)
        })
        const projRef = doc(db, `projects/${uid}/grammarChecker/${prjID}`); 
        deleteDoc(projRef)
     }   
  }
 
}

export default new GrammarCheckerDataService();