const HRToolsData = {
    jobDescriptionGenerator: {
        prompt:"801",
        icon: "📝", 
        title:"Job Description Generator",
        category:"hr",
        caption:`Efficiently create detailed and attractive job descriptions for LinkedIn tailored to the specific role, company, and requirements.`,
        inputs: [
            { inputTitle: `Role`, inputCaption: `Enter the job title (e.g., Software Engineer, Marketing Manager)`, inputExample: `Software Engineer` },
            { inputTitle: `Company Name`, inputCaption: `Type your company's name here`, inputExample: `Tech Innovations Inc.` },
            { inputTitle: `Tell about your Company?`, inputCaption: `Describe your company's mission, culture, and values`, inputExample: `We are a leading tech company focused on building innovative solutions to empower businesses. Our culture thrives on creativity, inclusivity, and continuous learning.`, type: 'textarea' },
            { inputTitle: `Requirements`, inputCaption: `List the qualifications and skills required for the role`, inputExample: `Bachelor's degree in Computer Science, proficiency in Java and Python, excellent problem-solving skills.`, type: 'textarea' }
        ]
    }, 
    interviewSchedulingEmailGenerator: {
        prompt:"802",
        icon: "📅", 
        title:"Interview Scheduling Email Generator",
        category:"hr",
        caption:`Streamline your interview scheduling with professional email drafts, making the organization process effortless.`,
        inputs: [
            { inputTitle: `Company Name`, inputCaption: `Enter your company's name`, inputExample: `Global Tech Solutions` },
            { inputTitle: `Role`, inputCaption: `Specify the role for the interview (e.g., Project Manager)`, inputExample: `Project Manager` },
            { inputTitle: `What is the interview date and time?`, inputCaption: `Provide the date and time for the interview`, inputExample: `March 15th, 2023, at 10:00 AM` },
            { inputTitle: `What is the interview address?`, inputCaption: `Enter the physical or online address for the interview`, inputExample: `123 Business St, Conference Room B or via Zoom link provided`, type: 'textarea', required: 'false' },
            { inputTitle: `What is the interview mode?`, inputCaption: `Select the mode of interview`, inputExample: `online`, type: "select", options: ['campus', 'telephonic', 'online', 'offline', 'other'] },
            { inputTitle: `Any other information you want to add?`, inputCaption: `Include any additional instructions or information`, inputExample: `Please prepare a short presentation on a recent project you managed.`, type: 'textarea', required: 'false' }
        ]
    }, 
    employeeConfirmationEmail: {
        prompt:"803",
        icon: "✅",
        title:"Employee Confirmation Email",
        category:"hr",
        caption:`Draft formal confirmation letters to newly hired employees, confirming their role and start date with clarity.`,
        inputs: [
            { inputTitle: `Company Name`, inputCaption: `Type your company's name here`, inputExample: `Innovatech Ltd.` },
            { inputTitle: `What is your employee name?`, inputCaption: `Enter the name of the employee being confirmed`, inputExample: `Jamie Lannister` },
            { inputTitle: `Role`, inputCaption: `Specify the employee's role in the company`, inputExample: `Digital Marketing Specialist` },
            { inputTitle: `Work location`, inputCaption: `Specify the work location (e.g., New York office, remote)`, inputExample: `remote` }
        ]
    }, 
    onboardingProcessEmail: {
        prompt:"804",
        icon: "👥",
        title:"On-boarding Process Email",
        category:"hr",
        caption:`Facilitate a seamless onboarding experience with efficient email communication to welcome and guide new employees.`,
        inputs: [
            { inputTitle: `Company Name`, inputCaption: `Type your company's name here`, inputExample: `GreenTech Solutions` },
            { inputTitle: `What is your employee name?`, inputCaption: `Enter the name of the new employee`, inputExample: `Arya Stark` },
            { inputTitle: `Role`, inputCaption: `Specify the role of the new employee`, inputExample: `UI/UX Designer` },
            { inputTitle: `Work location`, inputCaption: `Specify the location where the employee will be working`, inputExample: `Berlin office` },
            { inputTitle: `What is the joining date and Reporting time?`, inputCaption: `Enter the start date and time for the new employee`, inputExample: `April 10th, 2023, at 9:00 AM` },
            { inputTitle: `Any other information you want to add?`, inputCaption: `Provide any additional onboarding details or instructions`, inputExample: `Please bring your ID and educational certificates for verification.`, type: 'textarea' }
        ]
    }, 
    jobRejectionEmailGenerator: {
        prompt:"805",
        icon: "❌",
        title:"Job Rejection Email Generator",
        category:"hr",
        caption:`Compose respectful and professional job rejection emails quickly with this AI-powered tool.`,
        inputs: [
            { inputTitle: `Company Name`, inputCaption: `Type your company's name here`, inputExample: `FutureTech Innovations` },
            { inputTitle: `Role`, inputCaption: `Enter the job title for the position applied (e.g., Graphic Designer)`, inputExample: `Graphic Designer` }
        ]
    }, 
    employeeSurveyQuestionnaireGenerator: {
        prompt:"806",
        icon: "📊",
        title:"Employee Survey Questionnaire Generator",
        category:"hr",
        caption:`Generate comprehensive employee survey questionnaires easily to meet various organizational objectives.`,
        inputs: [
            { inputTitle: `What is your survey objectives?`, inputCaption: `Choose the main objective of your survey`, inputExample: `Improving Workplace Morale`, type: "select", options: ['Improving Workplace Morale', 'Identifying Areas of Concern', 'Assessing Employee Engagement', 'Job Satisfaction', 'Evaluating Communication Effectiveness', 'Measuring Employee Well-being', 'Identifying Training and Development Needs', 'Evaluating Diversity and Inclusion', 'Understanding Career Growth and Advancement', 'Evaluating Work Environment and Facilities', 'Measuring Job Performance', 'Improving Employee Benefits', 'Measuring Employee Retention Factors', 'Assessing Team Dynamics', 'Measuring Employee Motivation', 'Monitoring Employee Satisfaction Over Time', 'Evaluating Workload Distribution', 'Identifying Leadership Potential', 'other'] },
            { inputTitle: `What is your Industry or Field?`, inputCaption: `Type your industry or field (e.g., Technology, Healthcare)`, inputExample: `Technology` }
        ]
    }, 
    employeeReferralEmailGenerator: {
        prompt:"807",
        icon: "🔗",
        title:"Employee Referral Email Generator",
        category:"hr",
        caption:`Create effective referral emails for potential employees, describing job roles and company values professionally.`,
        inputs: [
            { inputTitle: `Company Name`, inputCaption: `Type your company's name here`, inputExample: `Digital Dreams Ltd.` },
            { inputTitle: `Role`, inputCaption: `Specify the job title for the referral (e.g., Sales Manager)`, inputExample: `Sales Manager` },
            { inputTitle: `What are the roles and responsibilities of the job?`, inputCaption: `Describe the key responsibilities and duties for the role`, inputExample: `Overseeing sales team, developing sales strategies, and achieving sales targets.`, type: 'textarea' },
            { inputTitle: `What are the eligibility criteria for the job?`, inputCaption: `List the qualifications and skills required for the job referral`, inputExample: `Bachelor's degree in Business Administration, proven sales track record, excellent communication skills.`, type: 'textarea' },
            { inputTitle: `Any other information you want to add?`, inputCaption: `Include any additional details about the job or referral process`, inputExample: `Referrals will receive a bonus upon successful hire of the candidate.`, type: 'textarea' }
        ]
    }, 
    exitInterviewQuestionnaireGenerator: {
        prompt:"808",
        icon: "🚪",
        title:"Exit Interview Questionnaire Generator",
        category:"hr",
        caption:"Craft targeted exit interview questionnaires to gain valuable insights from departing employees.",
        inputs: [
            { inputTitle: `What is your Industry or Field?`, inputCaption: `Enter the industry or field of your organization (e.g., Finance, Education)`, inputExample: `Education` },
            { inputTitle: `Role`, inputCaption: `Specify the job title of the exiting employee (e.g., IT Analyst)`, inputExample: `IT Analyst` }
        ]
    }, 
    interviewAssessmentQuestionnaireGenerator: {
        prompt:"809",
        icon: "🔍",
        title:"Interview Assessment Questionnaire Generator",
        category:"hr",
        caption:`Develop detailed assessment questionnaires for interviews to thoroughly evaluate candidate suitability.`,
        inputs: [
            { inputTitle: `Role`, inputCaption: `Enter the job title for which the candidate is being interviewed`, inputExample: `Content Strategist` },
            { inputTitle: `Requirements`, inputCaption: `Detail the specific skills and qualifications required for the role`, inputExample: `Strong writing and editing skills, experience with SEO strategies, and ability to manage multiple content projects.`, type: 'textarea' }
        ]
    }, 
    interviewFeedback: {
        prompt:"810",
        icon: "💬", 
        title:"Interview Feedback / AI Assessment Feedback",
        category:"hr",
        caption:`Analyze interview transcripts and provide comprehensive feedback, complete with candidate ratings.`,
        inputs: [
            { inputTitle: `Role`, inputCaption: `Specify the role for which the interview was conducted`, inputExample: `Web Developer` },
            { inputTitle: `Enter your meeting Transcript`, inputCaption: `Paste the transcript of the interview here for analysis`, inputExample: `Interviewer: Can you describe your experience with front-end technologies? Candidate: Yes, I've worked extensively with HTML, CSS, and JavaScript...`, type: 'textarea' }
        ]
    }, 
    funFridayIdeas: {
        prompt:"811",
        icon: "🎉",
        title:"Fun Friday Ideas",
        category:"hr",
        caption:`Generate exciting and engaging ideas for Fun Friday events to enhance team spirit and camaraderie.`,
        inputs: [
            { inputTitle: `Where would you prefer the Fun Friday event to take place?`, inputCaption: `Choose the location for the Fun Friday event`, inputExample: `At the office`, type: "select", options: ['At the office', 'Open space (Eg: Play ground)', 'Virtually (online)', 'other'] },
            { inputTitle: `What is your group size / how many people are participating?`, inputCaption: `Enter the number of participants expected for the event`, inputExample: `20-30 employees` },
            { inputTitle: `How long do you have to prepare for the event?`, inputCaption: `Specify the time available for event preparation (e.g., 1 week, 3 days)`, inputExample: `2 weeks` },
            { inputTitle: `How long is the event going to be?`, inputCaption: `Indicate the duration of the event (e.g., 2 hours, half-day)`, inputExample: `half-day` },
            { inputTitle: `Anything else you want to note?`, inputCaption: `Add any specific requirements or ideas for the event`, inputExample: `Include team-building activities and a small awards ceremony.`, type: 'textarea', required: 'false' }
        ]
    }, 
    potentialCandidateReachOutEmailGenerator: {
        prompt:"812",
        icon: "📬", 
        title:"Potential Candidate Reach Out Email Generator",
        category:"hr",
        caption:`Efficiently create personalized outreach emails to connect with prospective job candidates.`,
        inputs: [
            { inputTitle: `Company Name`, inputCaption: `Enter the name of your company`, inputExample: `NextGen Innovations` },
            { inputTitle: `Tell about your Company?`, inputCaption: `Describe your company, including culture, values, and achievements`, inputExample: `At NextGen Innovations, we're at the forefront of the tech industry, championing innovation, diversity, and a culture of continuous learning. We're proud of our collaborative environment and our track record of groundbreaking projects.`, type: 'textarea' },
            { inputTitle: `What are the roles and responsibilities of the job?`, inputCaption: `Describe the key responsibilities and duties for the role`, inputExample: `As a Sales Manager, you'll lead our dynamic sales team, strategize on new market entry, and drive our sales targets to new heights.`, type: 'textarea' },
            { inputTitle: `What is the candidate name?`, inputCaption: `Enter the name of the candidate you are reaching out to`, inputExample: `Samwell Tarly`, type: 'textarea' },
            { inputTitle: `Where/how you found their profile?`, inputCaption: `Mention the platform or method where you found the candidate's profile (e.g., LinkedIn, job fair)`, inputExample: `LinkedIn` },
            { inputTitle: `Tell more about the person you’re contacting?`, inputCaption: `Provide details about the candidate, such as their skills or background`, inputExample: `With a strong background in sales and marketing, and a consistent track record of exceeding sales targets, we believe your experience aligns perfectly with our goals.`, type: 'textarea' }
        ]
    }
}
export default HRToolsData;
