const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({apiKey: process.env.REACT_APP_OPENAI_API_KEY});
const openai = new OpenAIApi(configuration);

class YoutubeVideoTitleAIServices {    

  generateYoutubeVideoTitle = (formDataObj) => {
    return openai.createCompletion("text-davinci-002", {   
      prompt: `Generate 7 YouTube Video topics and consider keywords from the notes below:
      \nProduct: ${formDataObj.projectValue1}
      \nProduct Description: ${formDataObj.projectValue2}
      \nKeywords: ${formDataObj.projectValue3}
      \nLanguage:write in ${formDataObj.language} language
      \nTone of Voice:${formDataObj.tone}
      \nYear: 2022`, 
      temperature: 0.85,
      max_tokens: 100,
      top_p: 1.0,
      frequency_penalty: 0.52,
      presence_penalty: 0.5,
      logprobs:1,
      stop: ["8."],
     });
   }

   generateMoreYoutubeVideoTitle = (queryObj) => { 
    return openai.createCompletion("text-davinci-002", { 
      prompt: `Generate 7 YouTube Video topics and consider keywords from the notes below:
      \nProduct: ${queryObj.projectValue1}
      \nProduct Description: ${queryObj.projectValue2}
      \nKeywords: ${queryObj.projectValue3}
      \nLanguage:write in ${queryObj.language} language
      \nTone of Voice:${queryObj.tone}
      \nYear: 2022`, 
      temperature: 0.85,
      max_tokens: 100,
      top_p: 1.0,
      frequency_penalty: 0.52,
      presence_penalty: 0.5,
      logprobs:1,
      stop: ["8."],
     });
   } 
  
}

export default new YoutubeVideoTitleAIServices();

 